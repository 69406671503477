<template>
  <div>
    <div class="lg:block" v-if="selectEvent != ''">
      <div>
        <div class="flex justify-between">
          <div class="flex items-center">
            <div
              class="w-[42px] h-[42px] rounded-full overflow-hidden relative"
            >
              <img
                v-if="selectObj.f大頭貼 == ''"
                class="w-[100%]"
                src="@/assets/img/icon-user.svg"
                alt="male icon"
              />
              <img
                v-else
                class="w-[100%]"
                :src="$appsetting.AppUrl + '\\' + selectObj.f大頭貼"
                style="position: absolute; top: 0; bottom: 0; margin: auto 0"
                alt="male icon"
              />
            </div>
            <span class="ml-3">{{ selectObj.f姓名 }}</span>
            <div
              class="ml-3 text-white px-2 py-1 rounded"
              :class="
                selectObj.f身分 == '個人戶' ? 'bg-[#71b57c]' : 'bg-[#b57175]'
              "
              style="border-radius: 0.35rem"
            >
              {{ selectObj.f身分 }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="text-bold mr-3 cursor-pointer" @click="setL2">
              二級審核
            </div>
            <div class="text-bold mr-8 cursor-pointer" @click="setL1">
              一級資料
            </div>
            <dropdown-menu
              v-if="false"
              v-model="show"
              :right="true"
              :hover="false"
              :interactive="true"
            >
              <button class="text-[#cc232a]">
                <img class="w-[100%]" src="@/assets/img/icon-download.svg" />
              </button>
              <div
                class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
                slot="dropdown"
              >
                <ul
                  class="py-1 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li class="hover:bg-[#cc232a]">
                    <a
                      href="#"
                      class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                      >匯出CSV</a
                    >
                  </li>
                  <!-- <li class="hover:bg-[#cc232a]">
                    <a
                      href="#"
                      class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                      >匯出PDF</a
                    >
                  </li> -->
                </ul>
              </div>
            </dropdown-menu>
          </div>
        </div>
        <div class="mt-5 mb-5 h-[70vh] overflow-auto">
          <span class="text-[#ff628c]" v-if="selectObj.f狀態描述 != ''"
            >失敗原因 :
            <span
              class="mx-1"
              v-for="(vitem, vkey) in selectObj.f狀態描述"
              :key="vkey"
              >({{ vkey + 1 }}){{ vitem }}</span
            ></span
          >
          <div
            class="py-3 px-4 flex justify-between items-center border border-[#ececec]"
            :class="idx % 2 == 0 ? 'bg-[#ececec]' : ''"
            v-for="(item, idx) in mockdata"
            :key="idx"
          >
            <span class="text-[#878787]" style="line-height: 0.95rem"
              >{{ item.Name }}<br />{{ item.Sub }}</span
            >
            <template
              ><span
                v-if="Object.hasOwn(item, 'Event')"
                class="text-[#389568] cursor-pointer"
                @click="item.Event.click"
                >{{ item.Value }}</span
              ><span v-else class="text-[#389568]">{{
                item.Value
              }}</span></template
            >
          </div>
          <div class="flex overflow-x-auto mt-3">
            <div
              v-for="(item, key) in 圖片列表"
              :key="key"
              class="min-w-[150px] mr-3"
              @click="ALBUM.index = key"
            >
              <img
                class="w-[100%]"
                :src="$appsetting.AppUrl + '\\' + item"
                :alt="key"
              />
            </div>
          </div>
        </div>
        <div class="flex" v-if="selectObj.審核狀態 == 0">
          <div
            class="form-btn"
            style="background-color: #289556"
            @click="通過()"
          >
            同意
          </div>
          <div
            class="form-btn"
            style="background-color: #ce0000"
            @click="$modal.show('reject')"
          >
            不同意
          </div>
        </div>
      </div>
    </div>
    <popcorn
      name="reject"
      :width="320"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="red" type="dialog">
        <template #header>
          <p>請填入審核不通過的原因</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="pt-[12px] text-xl">
              <ul
                class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                <li
                  class="mt-1"
                  v-for="(item, idx) in reject.options"
                  :key="idx"
                >
                  <div
                    class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <input
                      :id="`checkbox-item-${idx}`"
                      type="checkbox"
                      :value="item"
                      v-model="reject.value"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      :for="`checkbox-item-${idx}`"
                      class="w-full ml-2 text-left font-medium text-gray-900 rounded dark:text-gray-300"
                      >{{ item }}</label
                    >
                  </div>
                </li>
                <li class="mt-1">
                  <div
                    class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <input
                      id="`checkbox-item-99`"
                      type="checkbox"
                      value="其它"
                      v-model="reject.value"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="`checkbox-item-99`"
                      class="w-full ml-2 text-left font-medium text-gray-900 rounded dark:text-gray-300"
                      >其它</label
                    >
                  </div>
                </li>
              </ul>
              <input
                style="border: 1px solid black"
                type="text"
                v-model="reject.other"
                placeholder="勾選其它 請輸入審核不通過原因"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="red"
            text="white"
            size="popup-md"
            @click="駁回()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      class="block lg:hidden"
      name="addMembership"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>新增白名單</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請輸入白名單姓名"
                hint="gray"
                v-model="manager.name"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入白名單錢包地址"
                hint="gray"
                v-model="manager.wallet"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入白名單備註"
                hint="gray"
                v-model="manager.info"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="doubleCheck"
      :width="335"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #close>
          <!-- <Icon
            icon="icon-cross"
            class="absolute top-[5px] right-[5px] rounded-full w-[27px] h-[27px] bg-red-100 text-white"
            @click="$modal.hide('doubleCheck')"
          /> -->
        </template>
        <template #header>
          <p>請再次確認新增的白名單</p>
        </template>
        <template #body>
          <template>
            <div class="pt-2 pb-[17px]">
              <p class="h-[40px]">{{ manager.name }}</p>
              <p class="h-[40px]">{{ manager.wallet }}</p>
              <p class="h-[40px]">{{ manager.info }}</p>
            </div>
          </template>
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認新增"
            color="blue"
            text="white"
            size="popup-md"
            @click="confirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('doubleCheck')"
          />
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
        </template>
      </Card>
    </popcorn>
    <Album
      :album="圖片列表"
      :show="ALBUM.index"
      @closed="ALBUM.index = ''"
      @change="
        (val) => {
          ALBUM.index = val;
        }
      "
    />
    <popcorn
      name="重置密碼"
      :width="200"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div style="padding: 15px 18px">
        <input
          style="
            border: 1px solid black;
            width: 100%;
            margin-bottom: 10px;
            padding: 5px 9px;
          "
          type="text"
          v-model="重置密碼"
          placeholder="請輸入重置密碼"
        />
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-around;
          "
        >
          <Button
            label="確認新增"
            color="green"
            text="white"
            style="font-size: 16px; padding: 2px 5px"
            @click="確認重置密碼按鈕()"
          />
          <Button
            label="取消"
            color="gray"
            text="white"
            style="font-size: 16px; padding: 2px 5px; margin-left: 16px"
            @click="$modal.hide('重置密碼')"
          />
        </div>
      </div>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button, Input, Album } from "@/components/Shared";
import DropdownMenu from "@innologica/vue-dropdown-menu";
export default {
  name: "VerifyMembership",
  components: { Card, Button, Input, Album, DropdownMenu },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
      require: true,
    },
  },
  data() {
    return {
      重置密碼: "",
      目前fId: "",
      show: false,
      ALBUM: {
        index: "",
      },
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      reject: {
        options: [
          "身分證驗證失敗",
          "個人資料驗證失敗",
          "銀行驗證失敗",
          "手持身分證照片驗證失敗",
          "公司資料驗證失敗",
          "資金來源照片驗證失敗",
        ],
        value: [],
        other: "",
      },
      mockdata: [],
      L1_mockdata: [
        { Name: "電話號碼", Sub: "Phone Number", Value: "阿萬" },
        { Name: "電子郵箱", Sub: "E-mail", Value: "阿萬" },
        { Name: "BSC錢包地址", Sub: "Address in BEP-20", Value: "阿萬" },
        { Name: "登入密碼", Sub: "Password", Value: "阿萬" },
        { Name: "使用狀態", Sub: "Status of use", Value: "阿萬" },
      ],
      Member_L2_mockdata: [
        { Name: "國籍", Sub: "Country", Value: "阿萬" },
        { Name: "真實姓名", Sub: "Name", Value: "阿萬" },
        { Name: "性別", Sub: "Gender", Value: "阿萬" },
        { Name: "護照/身分證", Sub: "Passport/ID Card", Value: "阿萬" },
        {
          Name: "身分證字號/護照號碼",
          Sub: "ID number/Passport number",
          Value: "阿萬",
        },
        {
          Name: "身分證發證日期/護照日期",
          Sub: "ID Card /Passport issue date",
          Value: "阿萬",
        },
        {
          Name: "身分證發證地點",
          Sub: "ID Card Issuing Location",
          Value: "阿萬",
        },
        {
          Name: "身分證領補發類別",
          Sub: "ID Card Type",
          Value: "阿萬",
        },
        {
          Name: "職業",
          Sub: "Profession",
          Value: "阿萬",
        },
        {
          Name: "收入來源",
          Sub: "Source of income",
          Value: "阿萬",
        },
        {
          Name: "收入級距",
          Sub: "Income range",
          Value: "阿萬",
        },
        {
          Name: "出生日期",
          Sub: "Date Of Birth",
          Value: "阿萬",
        },
        {
          Name: "戶籍地址",
          Sub: "Residence address",
          Value: "阿萬",
        },
        {
          Name: "通訊地址",
          Sub: "Mailing address",
          Value: "阿萬",
        },
        {
          Name: "銀行",
          Sub: "Bank",
          Value: "阿萬",
        },
        {
          Name: "銀行分行",
          Sub: "Branch Name",
          Value: "阿萬",
        },
        {
          Name: "銀行帳號",
          Sub: "Bank Account",
          Value: "阿萬",
        },
        {
          Name: "使用狀態",
          Sub: "Status of use",
          Value: "阿萬",
        },
      ],
      Company_L2_mockdata: [
        { Name: "公司名稱", Sub: "Company name", Value: "阿萬" },
        { Name: "統一編號", Sub: "Uniform numbers", Value: "阿萬" },
        { Name: "公司登記地址", Sub: "Corporate Address", Value: "阿萬" },
        { Name: "公司通訊地址", Sub: "Corporate Address", Value: "阿萬" },
        {
          Name: "代理人姓名",
          Sub: "Agent name",
          Value: "阿萬",
        },
        {
          Name: "代理人國籍",
          Sub: "Agent country",
          Value: "阿萬",
        },
        {
          Name: "性別",
          Sub: "Gender",
          Value: "阿萬",
        },
        {
          Name: "代理人出生日期",
          Sub: "Date Of Birth",
          Value: "阿萬",
        },
        {
          Name: "代理人護照/身分證",
          Sub: "Passport/ID Card",
          Value: "阿萬",
        },
        {
          Name: "代理人身分證字號/護照號碼",
          Sub: "ID number/Passport number",
          Value: "阿萬",
        },
        {
          Name: "身分證發證日期/護照日期",
          Sub: "ID Card /Passport issue date",
          Value: "阿萬",
        },
        {
          Name: "身分證發證地點",
          Sub: "ID Card Issuing Location",
          Value: "阿萬",
        },
        {
          Name: "身分證領補發類別",
          Sub: "ID Card Type",
          Value: "阿萬",
        },
        {
          Name: "銀行",
          Sub: "Bank",
          Value: "阿萬",
        },
        {
          Name: "銀行分行",
          Sub: "Branch Name",
          Value: "阿萬",
        },
        {
          Name: "銀行帳號",
          Sub: "Bank Account",
          Value: "阿萬",
        },
        {
          Name: "使用狀態",
          Sub: "Status of use",
          Value: "阿萬",
        },
      ],
    };
  },
  watch: {
    selectObj: function () {
      if (Object.keys(this.selectObj.f銀行認證).length == 0) {
        this.setL1();
      } else {
        this.setL2();
      }
    },
  },
  mounted() {
    // this.$modal.show("addMembership");
    // this.mockdata = this.Member_L2_mockdata;
    if (Object.keys(this.selectObj.f銀行認證).length == 0) {
      this.setL1();
    } else {
      this.setL2();
    }
  },
  computed: {
    圖片列表: function () {
      const 身分驗證 = this.exportImg(this.selectObj.f會員認證, "AuthFile");
      const 銀行驗證 = this.exportImg(this.selectObj.f銀行認證, "AuthFile");

      return { ...身分驗證, ...銀行驗證 };
    },
  },
  methods: {
    確認重置密碼按鈕() {
      if (this.重置密碼 == "") {
        this.$notify.warning("請輸入重置密碼");
        return;
      }
      let obj = {
        fId: this.目前fId,
        PassWord: this.重置密碼,
      };
      this.$store
        .dispatch("membership/A08_修改_t會員_密碼", this.$tools.ObjToForm(obj))
        .then((res) => {
          if (res.status == "Success") {
            this.目前fId = "";
            this.重置密碼 = "";
            this.$notify.success("修改成功");
            this.$modal.hide("重置密碼");
          }
        })
        .catch((err) => {
          if (err.response.data) {
            if (
              JSON.parse(err.response.data).data &&
              JSON.parse(err.response.data).data.Errors.PassWord != undefined
            ) {
              this.$notify.warning("密碼須包含大小寫英文字母");
            } else {
              this.$notify.warning("發生錯誤，請重新操作");
            }
          } else {
            this.$notify.warning("發生錯誤，請重新操作");
          }
        });
    },
    exportImg: function (obj, path) {
      let _ex = {};
      for (const [key, value] of Object.entries(obj)) {
        if (value.indexOf(path) != -1) {
          _ex[key] = value;
        }
      }
      return _ex;
    },
    駁回: function () {
      let str = JSON.stringify(this.reject.value);
      str = str.replace("其它", this.reject.other);
      let obj = {
        fId: this.selectObj.fVid,
        駁回原因: str,
      };
      this.$store
        .dispatch("apiVerify/A04_審核駁回", this.$tools.ObjToForm(obj))
        .then((res) => {
          console.log(res);
          this.reject.value = [];
          this.reject.other = "";
          this.closeWindow();
          this.$emit("successEvent");
          this.$notify.success("修改成功");
        });
    },
    通過: function () {
      let obj = {
        fId: this.selectObj.fVid,
      };
      this.$store
        .dispatch("apiVerify/A03_審核通過", this.$tools.ObjToForm(obj))
        .then((res) => {
          console.log(res);
          this.reject.value = [];
          this.reject.other = "";
          this.closeWindow();
          this.$emit("successEvent");
          this.$notify.success("修改成功");
        });
    },
    setL1: function () {
      this.mockdata = [
        {
          Name: "電話號碼",
          Sub: "Phone Number",
          Value: this.selectObj.f連絡電話,
        },
        { Name: "電子郵箱", Sub: "E-mail", Value: this.selectObj.f信箱 },
        {
          Name: "BSC錢包地址",
          Sub: "Address in BEP-20",
          Value: this.selectObj.fBSC地址,
        },
        {
          Name: "登入密碼",
          Sub: "Password",
          Value: "立即重置",
          Event: {
            click: () => {
              this.$modal.show("重置密碼");
              this.目前fId = this.selectObj.fId;
            },
          },
        },
        {
          Name: "使用狀態",
          Sub: "Status of use",
          Value:
            this.selectObj.審核狀態 == 0
              ? "審核中"
              : this.selectObj.審核狀態 == 1
              ? "審核通過"
              : this.selectObj.審核狀態 == 2
              ? "審核失敗"
              : this.selectObj.審核狀態,
        },
      ];
    },
    setL2: function () {
      if (this.selectObj.f身分 == "個人戶") {
        this.mockdata = this.member_L2(this.selectObj);
      } else if (this.selectObj.f身分 == "公司戶") {
        this.mockdata = this.company_L2(this.selectObj);
      }
    },
    company_L2: function (obj) {
      return [
        {
          Name: "公司名稱",
          Sub: "Company name",
          Value: obj.f會員認證.公司名稱,
        },
        {
          Name: "統一編號",
          Sub: "Uniform numbers",
          Value: obj.f會員認證.統一編號,
        },
        {
          Name: "公司登記地址",
          Sub: "Corporate Address",
          Value: obj.f會員認證.公司登記地址,
        },
        {
          Name: "公司通訊地址",
          Sub: "Corporate Address",
          Value: obj.f會員認證.公司通訊地址,
        },
        {
          Name: "代理人姓名",
          Sub: "Agent name",
          Value: obj.f會員認證.姓名,
        },
        {
          Name: "代理人國籍",
          Sub: "Agent country",
          Value: obj.f會員認證.國籍,
        },
        {
          Name: "性別",
          Sub: "Gender",
          Value: obj.f會員認證.性別,
        },
        {
          Name: "代理人出生日期",
          Sub: "Date Of Birth",
          Value: obj.f會員認證.出生日期,
        },
        {
          Name: "代理人護照/身分證",
          Sub: "Passport/ID Card",
          Value: obj.f會員認證.證件類型,
        },
        {
          Name: "代理人身分證字號/護照號碼",
          Sub: "ID number/Passport number",
          Value: obj.f會員認證.證件號碼,
        },
        {
          Name: "身分證發證日期/護照日期",
          Sub: "ID Card /Passport issue date",
          Value: obj.f會員認證["發證有效日期"],
        },
        {
          Name: "身分證發證地點",
          Sub: "ID Card Issuing Location",
          Value: obj.f會員認證.發證地點,
        },
        {
          Name: "身分證領補發類別",
          Sub: "ID Card Type",
          Value: obj.f會員認證.發證類型,
        },
        {
          Name: "銀行",
          Sub: "Bank",
          Value: `${obj.f銀行認證.銀行代號} ${obj.f銀行認證.銀行名稱}`,
        },
        {
          Name: "銀行分行",
          Sub: "Branch Name",
          Value: obj.f銀行認證.分行名稱,
        },
        {
          Name: "銀行帳號",
          Sub: "Bank Account",
          Value: obj.f銀行認證.銀行帳戶,
        },
        {
          Name: "使用狀態",
          Sub: "Status of use",
          Value:
            obj.審核狀態 == 0
              ? "審核中"
              : obj.審核狀態 == 1
              ? "審核通過"
              : obj.審核狀態 == 2
              ? "審核失敗"
              : obj.審核狀態,
        },
      ];
    },
    member_L2: function (obj) {
      console.log(obj);
      return [
        { Name: "國籍", Sub: "Country", Value: obj.f會員認證.國籍 },
        { Name: "真實姓名", Sub: "Name", Value: obj.f姓名 },
        { Name: "性別", Sub: "Gender", Value: obj.f會員認證.性別 },
        {
          Name: "護照/身分證",
          Sub: "Passport/ID Card",
          Value: obj.f會員認證.證件類型,
        },
        {
          Name: "身分證字號/護照號碼",
          Sub: "ID number/Passport number",
          Value: obj.f會員認證.證件號碼,
        },
        {
          Name: "身分證發證日期/護照日期",
          Sub: "ID Card /Passport issue date",
          Value: obj.f會員認證["發證有效日期"],
        },
        {
          Name: "身分證發證地點",
          Sub: "ID Card Issuing Location",
          Value: obj.f會員認證.發證地點,
        },
        {
          Name: "身分證領補發類別",
          Sub: "ID Card Type",
          Value: obj.f會員認證.發證類型,
        },
        {
          Name: "職業",
          Sub: "Profession",
          Value: obj.f會員認證.職業,
        },
        {
          Name: "收入來源",
          Sub: "Source of income",
          Value: obj.f會員認證.收入來源,
        },
        {
          Name: "收入級距",
          Sub: "Income range",
          Value: obj.f會員認證.收入級距,
        },

        // {
        //   Name: "收入級距",
        //   Sub: "Income range",
        //   Value: obj.f會員認證,
        // },
        {
          Name: "出生日期",
          Sub: "Date Of Birth",
          Value: obj.f會員認證.出生日期,
        },
        {
          Name: "戶籍地址",
          Sub: "Residence address",
          Value: obj.f會員認證.戶籍地址,
        },
        {
          Name: "通訊地址",
          Sub: "Mailing address",
          Value: obj.f會員認證.通訊地址,
        },
        {
          Name: "銀行",
          Sub: "Bank",
          Value: `${obj.f銀行認證.銀行代號} ${obj.f銀行認證.銀行名稱}`,
        },
        {
          Name: "銀行分行",
          Sub: "Branch Name",
          Value: obj.f銀行認證.分行名稱,
        },
        {
          Name: "銀行帳號",
          Sub: "Bank Account",
          Value: obj.f銀行認證.銀行帳戶,
        },
        {
          Name: "使用狀態",
          Sub: "Status of use",
          Value:
            obj.審核狀態 == 0
              ? "審核中"
              : obj.審核狀態 == 1
              ? "審核通過"
              : obj.審核狀態 == 2
              ? "審核失敗"
              : obj.審核狀態,
        },
      ];
    },
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("reject");
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
      return re.test(email);
    },
    firstConfirm() {
      if (this.manager.wallet == "" || this.manager.name == "") {
        // alert("輸入不完整");
        this.$notify.warning("輸入不完整");
        return;
      }
      // this.$emit("toggleEvent", "");
      // this.$modal.hide("addMembership");
      // this.$modal.show("doubleCheck");
      // this.doubleConfirm = true;
    },
    confirm() {
      let obj = {
        fAccount: this.manager.account,
        fPWd: this.manager.password,
        fName: this.manager.name,
        fEmail: JSON.stringify({
          address: this.manager.wallet,
          onChain: false,
          info: this.manager.info,
        }),
      };
      console.log("當前角色", this.role, this.$tools);
      this.$store
        .dispatch("apiAdmin/SignUpNewGenAdmin", this.$tools.ObjToForm(obj))
        .then((res) => {
          if (res.data != 0) {
            this.$emit("toggleEvent", "");
            this.$emit("successEvent");
            this.$notify.success("新增成功");
            this.$modal.hide("doubleCheck");
          } else {
            // alert("操作失敗，請聯絡程式人員");
            this.$notify.warning("操作失敗，請聯絡程式人員");
          }
        });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
::placeholder {
  color: #bbbbbb;

  font-size: 0.8em;
  margin-bottom: 2px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
