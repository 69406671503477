<template>
  <label
    :class="[
      'w-full h-full',
      'flex items-center',
      'rounded-default',
      { 'px-[15px]': !dense },
      readonly ? 'text-gray-600' : textColor[text],
    ]"
  >
    <span :class="[{ 'whitespace-nowrap': label }, 'text-gray-600']">
      {{ label }}
    </span>
    <slot name="front" />
    <div
      :class="[
        { 'py-[6px]': !dense },
        'content h-full',
        alignRight ? 'ml-auto' : 'flex-1',
      ]"
    >
      <textarea
        v-model.trim="inputValue"
        :placeholder="placeholder"
        :type="inputType"
        :class="[
          'bg-transparent',
          'focus:outline-none ',
          'w-[175px] lg:w-[350px] h-full',
          'resize-none no-scrollbar',
          { 'cursor-default': readonly },
          { 'cursor-not-allowed': disabled },
          { 'text-right': alignRight },
          placeholderColor[hint],
          type === 'wallet_address' ? 'text-xs' : 'lg:text-sm',
        ]"
        :readonly="readonly"
        :disabled="disabled"
        @focus="focus = true"
        @blur="focus = false"
      />
    </div>
    <slot name="end" />
    <div
      v-if="clearable && !readonly"
      @click="clearContent"
      class="cursor-pointer ml-3"
    >
      <Icon
        :dense="dense"
        :img="require('@/assets/img/icon-clear.svg')"
        alt="移除內容icon"
        class=""
      />
    </div>
  </label>
</template>

<script>
import Icon from "./Icon.vue";
import { textColor, placeholderColor, bgColor } from "./utils";

export default {
  name: "Textarea",
  components: { Icon },
  props: {
    type: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "gray",
    },
    hint: {
      type: String,
      default: "gray",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputType: this.type,
      seePassword: false,
      textColor,
      placeholderColor,
      bgColor,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        const vm = this;
        if (this.debounce) {
          setTimeout(() => {
            vm.$emit("input", value);
          }, vm.debounce);
        } else {
          vm.$emit("input", value);
        }
      },
    },
  },
  methods: {
    clearContent() {
      this.inputValue = "";
    },
    showPassword() {
      this.seePassword = !this.seePassword;
      if (this.seePassword) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-input-placeholder {
  line-height: 33px;
  vertical-align: middle;
}
:-moz-placeholder {
  /* Firefox 18- */
  line-height: 33px;
  vertical-align: middle;
}
::-moz-placeholder {
  /* Firefox 19+ */
  line-height: 33px;
  vertical-align: middle;
}
:-ms-input-placeholder {
  line-height: 33px;
  vertical-align: middle;
}
</style>
