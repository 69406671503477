<template>
  <Card class="" type="confirm">
    <template #header>
      <p class="text-[#6f6f6f]">你確定{{ title }}</p>
    </template>
    <template #body>
      <div class="flex flex-col items-center pb-[6px]">
        <Icon
          :img="require('@/assets/img/icon-bigLock.svg')"
          class="mb-[10px]"
        />
        <div
          class="w-[126px] h-[44px] flex flex-col w-full justify-evenly items-center text-orange-200 text-sm"
        >
          <p class="">我怕你手滑</p>
          <p class="">還是提醒你一下好了</p>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex w-full border-t border-gray-600 text-center cursor-pointer"
      >
        <div class="flex-1 border-r border-gray-600 p-4" @click="confirm">
          確 認
        </div>
        <div class="flex-1 p-4" @click="cancel">取 消</div>
      </div>
    </template>
  </Card>
</template>

<script>
import { Card, Icon } from "@/components/Shared";
export default {
  name: "BlockConfirm",
  components: {
    Card,
    Icon,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    verifyOK() {
      this.$store
        .dispatch(
          "apiAdmin/VerifyOK",
          this.$tools.ObjToForm({
            fId: this.userData.id,
          })
        )
        .then((res) => {
          if (res.data != 0) {
            this.$store.commit(
              "validation/deleteLevel3Application",
              this.userData.id
            );
            this.$emit("confirm");
            this.$notify.success("操作成功");
          }
        });
    },
    confirm() {
      console.log("where", this.role);
      switch (this.role) {
        case "membership2":
          this.$store.commit("membership/blockMembership2", {
            id: this.userData.id,
          });
          this.$emit("confirm");
          break;
        case "membership3":
          this.$store.commit("membership/blockMembership3", {
            id: this.userData.id,
          });
          this.$emit("confirm");
          break;
        case "manager":
          this.$store
            .dispatch("ast/USDT白名單資格", this.userData.wallet_address)
            .then((res) => {
              if (!res) {
                this.$store
                  .dispatch(
                    "ast/設定兌換USDT白名單",
                    this.userData.wallet_address
                  )
                  .then((res) => {
                    console.log(res);
                    this.verifyOK();
                  });
              } else {
                this.verifyOK();
              }
            });

          console.log("通過三級審核");
          break;
      }
      // this.$store.commit("togglePopup", false);
    },
    cancel() {
      console.log("取消");
      this.$store.commit("togglePopup", false);
    },
  },
};
</script>
