<template>
  <header
    class="bg-default shadow-header-sm lg:shadow-header-lg bg:bg-white text-white lg:text-green-300 flex z-10 lg:px-7"
  >
    <!-- 手機顯示內容 -->
    <div class="flex flex-row w-full lg:hidden">
      <Icon icon="icon-chevron-left1" @click="backLastPage" class="px-5" />
      <p class="flex-grow flex justify-center items-center text-lg">
        {{ $store.state.activePage }}
      </p>
      <Icon
        icon="icon-hamburger"
        class="ml-auto px-5"
        v-click-outside="closeNav"
        @click="openNav"
      />
    </div>
    <!-- 桌機顯示內容 -->
    <div class="hidden lg:flex lg:flex-row ml-auto items-center space-x-[10px]">
      <!-- <Icon icon="icon-hamburger" class="mr-auto" @click="openNav" /> -->
      <!-- <Icon
        icon="icon-hamburger"
        class="mr-auto"
        v-click-outside="closeNav"
        @click="openNav"
      /> -->
      <p class="whitespace-nowrap text-white">
        管理者帳號 <span>{{ $store.state.login.account }}</span>
      </p>
      <Button
        :disabled="accountShow != ''"
        outline="white"
        text="white"
        :label="accountShow != '' ? accountShow : '鏈結錢包'"
        size="login"
        @click="changeNetwork()"
      ></Button>
      <router-link to="/">
        <Button outline="none" text="white" label="登出" size="login" />
      </router-link>
    </div>
  </header>
</template>

<script>
import { Icon, Button } from "@/components/Shared";
// import erc20 from "@/assets/abi/erc20.json";
// import ast from "@/assets/abi/ast.json";

export default {
  name: "Header",
  components: {
    Icon,
    Button,
  },
  data() {
    return {
      deskTop: false,
      account: "",
      accountShow: "",
    };
  },
  watch: {
    account: {
      handler: function (newVal, oldVal) {
        if (newVal == "") return;
        console.log("change", newVal, oldVal);
        this.accountShow =
          newVal.substring(0, 4) +
          "..." +
          newVal.substring(newVal.length - 5, newVal.length);

        //檢查
        // this.metamask().check();
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    // this.changeNetwork();
  },
  methods: {
    changeNetwork() {
      window.ethereum &&
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61", //chainId: "0x38",
                chainName: "BSC",
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"], //rpcUrls: ["https://bsc-dataseed.binance.org/"],
                blockExplorerUrls: ["https://testnet.bscscan.com"], //blockExplorerUrls: ["https://bscscan.com/"],
              },
            ],
          })
          .then(() => {
            this.$notify.warning("當前區塊鏈網路:BSC TESTNET");
            this.metamask().connect();
          })
          .catch((e) => {
            this.$notify.warning("網路切換發生錯誤");
            console.log(e);
          });
    },
    metamask() {
      const self = this;
      const fn = {
        logout: () => {
          self.account = "";
        },
        connect: () => {
          if (window.ethereum) {
            //讀取錢包
            window.ethereum
              .enable()
              .then((accounts) => {
                self.account = accounts[0];
                self.$store.commit("ast/setAccount", accounts[0]);
                self.$notify.success("錢包連結成功");
                console.log(window);
              })
              .catch(() => {
                self.$notify.warning("請檢查MetaMask錢包");
                // alert("請檢查MetaMask錢包", e);
              });
            //偵測帳號改變
            window.ethereum.on("accountsChanged", function (accounts) {
              self.account = accounts[0];
              self.$store.commit("ast/setAccount", accounts[0]);
              self.$notify.success("錢包連結成功");
            });
          } else {
            // alert("請安裝MetaMask錢包");
            self.$notify.warning("請安裝MetaMask錢包");
          }

          console.log("CONNECT");
        },
      };
      return fn;
    },
    backLastPage() {
      this.$router.go(-1);
    },
    closeNav() {
      this.$store.commit("toggleNav", false);
    },
    openNav() {
      this.$store.commit("switchNav");
      this.$store.commit("togglePopup", false);
    },
  },
};
</script>
