<template>
  <div>
    <Card :color="color" type="dialog">
      <template #header>
        <p class="text-4xl">{{ baseCurrency }} 兌換 {{ quoteCurrency }}</p>
      </template>
      <template #body>
        <p class="text-[22px] text-[#888888] mb-1">再次確認調整匯率</p>
        <p class="text-2xl max-w-[332px] mx-auto py-7">
          <span class="mr-[23px]">1 USDT</span>
          <span class="mr-7"> = </span>
          <span class="mr-7">{{ newExchangeRate }}</span>
          <span>TWD</span>
        </p>
        <p class="text-lg text-[#888888]">
          目前匯率為 1 USDT = {{ currentExchangeRate }} TWD
        </p>
      </template>
      <template #footer>
        <Button
          label="確認修改"
          :color="`gradient-${color}`"
          text="white"
          size="popup-md"
          @click="confirm"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "RateCard",
  components: {
    Card,
    Button,
  },
  props: {
    newExchangeRate: {
      type: String,
      required: true,
    },
    currentExchangeRate: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "green",
    },
    baseCurrency: {
      type: String,
      default: "",
    },
    quoteCurrency: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    api() {
      const self = this;
      const fn = {
        設定TWD兌USDT匯率: (amount) => {
          return new Promise((resolve, reject) => {
            self.$notify.info("等待操作中");
            self.$store
              .dispatch("ast/設定TWD兌USDT匯率", amount)
              .then((res) => {
                console.log("結果", res);
                self.$notify.success("上鍊成功");

                resolve(res);
              })
              .catch((e) => {
                reject(e);
              });
          });
        },
        設定USDT兌TWD匯率: (amount) => {
          return new Promise((resolve, reject) => {
            self.$notify.info("等待操作中");
            self.$store
              .dispatch("ast/設定USDT兌TWD匯率", amount)
              .then((res) => {
                console.log("結果", res);
                self.$notify.success("上鍊成功");

                resolve(res);
              })
              .catch((e) => {
                reject(e);
              });
          });
        },
      };
      return fn;
    },
    confirm() {
      // 確認新匯率
      if(this.newExchangeRate==''){
        this.$notify.warning("請輸入匯率");
        return
      }
      console.log("test",  this.newExchangeRate);
      this.$modal.show("loading");
      if (this.baseCurrency == "TWD") {
        this.$store
          .dispatch(
            "apiAdmin/RateTWDtoUSDT",
            this.$tools.ObjToForm({
              f數量: this.newExchangeRate,
            })
          )
          .then((res) => {
            if (res.data != 0) {
              //alert("修改成功");
              this.$store.dispatch("apiAdmin/GetRateTWDtoUSDT").then((res) => {
                console.log("TWD->USDT", res);
                this.$store.commit(
                  "trading/setTradingHistoryData",
                  this.$store.getters["apiAdmin/RateTWDRecord"]
                );
              });

              this.$notify.success("修改成功");
              this.$modal.hide("loading");
            } else {
              this.$notify.warning("操作失敗");
              this.$modal.hide("loading");
            }
          });
      } else {
        this.$store
          .dispatch(
            "apiAdmin/RateUSDTtoTWD",
            this.$tools.ObjToForm({
              f數量: this.newExchangeRate,
            })
          )
          .then((res) => {
            console.log(res);
            if (res.data != 0) {
              this.$store.dispatch("apiAdmin/GetRateUSDTtoTWD").then((res) => {
                console.log("USDT->TWD", res);
                this.$store.commit(
                  "trading/setTradingHistoryData",
                  this.$store.getters["apiAdmin/RateUSDTRecord"]
                );
              });

              this.$notify.success("修改成功");
              this.$modal.hide("loading");
            } else {
              this.$notify.warning("操作失敗");
              this.$modal.hide("loading");
            }
          });
      }
      this.$store.commit("togglePopup", false);
    },
  },
};
</script>
