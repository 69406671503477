<template>
  <fieldset v-if="區塊=='左側'" class="lg:px-4 flex flex-col items-center">
    <legend
      :class="[
        textColor[color],
        'text-2xl lg:text-4xl font-bold text-center mb-4 app-color',
      ]"
    >
      {{ baseCurrency }} 兌換 {{ quoteCurrency }} 調整
    </legend>
    <label
      class="flex flex-row text-2xl lg:text-4xl items-center space-x-[11px] py-12"
    >
      <p class="whitespace-nowrap flex-1 text-right">1 USDT =</p>
      <div class="w-[154px] lg:w-[200px]">
        <Input
          class="flex-1"
          outlined
          alignRight
          v-model="newExchangeRate"
          text="black"
          dense
        />
      </div>
      <p class="flex-1 text-left">{{ baseCurrency }}</p>
    </label>
    <p style="min-height: 27px;" class="mb-5 text-center text-[#C2BDBD] text-lg lg:text-2xl">
      <span v-if="baseCurrency=='TWD'">
        目前匯率為 1 USDT = {{ currentExchangeRate }} TWD
      </span>
    </p>
    <div class="w-[100%] xl:w-[421px]">
      <Button
      v-if="baseCurrency=='TWD'"
        size="full"
        :color="`gradient-${color === 'red' ? 'red-100' : color}`"
        text="white"
        label="確認"
        @click="confirmNewExchangeRate"
      />
      <Button
        v-else
        size="full"
        :color="`gradient-${color === 'red' ? 'red-100' : color}`"
        text="white"
        label="尚未啟用"
      />
    </div>
  </fieldset>
  <fieldset v-else-if="區塊=='右側'" class="lg:px-4 flex flex-col items-center">
    <legend
      :class="[
        textColor[color],
        'text-2xl lg:text-4xl font-bold text-center mb-4 app-color2',
      ]"
    >
      {{ baseCurrency }} 兌換 {{ quoteCurrency }} 調整
    </legend>
    <label
      class="flex flex-row text-2xl lg:text-4xl items-center space-x-[11px] py-12"
    >
      <p class="whitespace-nowrap flex-1 text-right">1 USDT =</p>
      <div class="w-[154px] lg:w-[200px]">
        <Input
          class="flex-1"
          outlined
          alignRight
          v-model="newExchangeRate"
          text="black"
          dense
        />
      </div>
      <p class="flex-1 text-left">{{ quoteCurrency }}</p>
    </label>
    <p style="min-height: 27px;" class="mb-5 text-center text-[#C2BDBD] text-lg lg:text-2xl">
      <span  v-if="quoteCurrency=='TWD'">
        目前匯率為 1 USDT = {{ currentExchangeRate }} TWD
      </span>
    </p>
    <div class="w-[100%] xl:w-[421px]">
      <Button
      v-if="quoteCurrency=='TWD'"
        size="full"
        :color="`gradient-${color === 'red' ? 'red-100' : color}`"
        text="white"
        label="確認"
        @click="confirmNewExchangeRate"
      />
      <Button
        v-else
        size="full"
        :color="`gradient-${color === 'red' ? 'red-100' : color}`"
        text="white"
        label="尚未啟用"
      />
    </div>
  </fieldset>
</template>

<script>
import { Button, Input } from "@/components/Shared";

export default {
  name: "EditRate",
  components: {
    Button,
    Input,
  },
  props: {
    baseCurrency: {
      type: String,
      default: "",
    },
    區塊: {
      type: String,
    },
    quoteCurrency: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newExchangeRate: "",
      textColor: { green: "text-green-300", red: "text-red-100" },
    };
  },
  computed: {
    currentExchangeRate: {
      get() {
        return this.$store.getters["apiAdmin/RateCoin"](this.baseCurrency);
      },
    },
  },
  methods: {
    confirmNewExchangeRate() {
      // 點擊確認按鈕，打開再次確認調整匯率視窗
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "RateCard");
      this.$store.commit("setPopupProps", {
        newExchangeRate: this.newExchangeRate,
        currentExchangeRate: this.currentExchangeRate,
        color: this.color,
        baseCurrency: this.baseCurrency,
        quoteCurrency: this.quoteCurrency,
      });
    },
    changeBase() {
      if (this.baseCurrency == "TWD") {
        this.currentExchangeRate = this.$store.getters["apiAdmin/RateTWD"];
      } else {
        this.currentExchangeRate = this.$store.getters["apiAdmin/RateUSDT"];
      }
    },
  },
  watch: {
    baseCurrency() {
      this.newExchangeRate = "";
      //this.changeBase();
    },
  },
  mounted() {
    console.log("base", this.baseCurrency, "que", this.quoteCurrency);
    //this.changeBase();
  },
};
</script>

<style scoped lang="scss">
::v-deep input {
  padding: 5px;
}
@media screen and (max-width:600px) {
  .app-color {
    color: red !important;
  }
  .app-color2 {
    color: green !important;
  }
}
</style>
