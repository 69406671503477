var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col space-y-4 w-full"},[_vm._l((_vm.optionList),function(option){return _c('label',{key:option.value,staticClass:"flex items-center text-lg font-bold text-gray-600",on:{"click":function($event){_vm.selectedItems = option}}},[_c('div',{class:[
        'w-[22px] h-[22px] border-2 border-[#7D7B7B] mr-2',
        'relative',
      ]},[(
          _vm.selectedItems.some(
            (checkedObj) => checkedObj.value === option.value
          )
        )?_c('Icon',{staticClass:"w-[27px] h-[23px] absolute bottom-0 -left-0.5",attrs:{"dense":"","img":require('@/assets/img/icon-check.svg')}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(option.label))])])}),_c('Input',{attrs:{"placeholder":"勾選其他 請輸入審核不通過原因","outlined":"","readonly":!_vm.hasOtherReason},model:{value:(_vm.otherReasonInput),callback:function ($$v) {_vm.otherReasonInput=$$v},expression:"otherReasonInput"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }