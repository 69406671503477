<template>
  <div class="form__group field">
    <input
      class="form__field"
      :placeholder="placeholder"
      :type="type"
      :name="label"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :required="required == true ? true : false"
    />
    <label :for="label" class="form__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  components: {},
  props: {
    required: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: #9b9b9b;
  font-size: 1rem;
  top: 10px;
  left: 4px;
  position: absolute;
}
/* .form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  top: 20px;
} */

.form__label {
  position: absolute;
  top: 0;
  left: 4px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  left: 4px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  /* font-weight: 700; */
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* demo */
body {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.5rem;
  background-color: #222222;
}
</style>
