<template>
  <Card color="red" type="dialog">
    <template #header>
      <p class="">刪除{{ title }}</p>
    </template>
    <template #body>
      <div class="pt-2 pb-[17px]">
        <p class="text-[#888888] mb-4">請再次確認刪除用戶</p>
        <p class="h-[40px]">{{ userData.account }}</p>
      </div>
    </template>
    <template #footer>
      <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
        申請人 &colon; {{ $store.state.login.account }}
      </p>
      <Button
        label="確認刪除"
        color="red"
        text="white"
        size="popup-md"
        @click="confirm(userData.id)"
      />
    </template>
  </Card>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "DeleteCard",
  components: {
    Card,
    Button,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirm(id) {
      switch (this.role) {
        case "manager":
          this.$store.commit("manager/deleteManager", this.userData.account);
          break;
        case "level2":
          this.$store.commit("validation/deleteLevel2Application", id);
          break;
      }
      this.$store.commit("togglePopup", false);
    },
  },
};
</script>
