<template>
  <figure class="bg-white rounded-popup w-full h-full">
    <div
      class="pl-[30px] lg:pl-[60px] pt-3 lg:pt-[30px] pb-[10px] lg:pb-5 pr-2 lg:pr-4 flex items-center"
    >
      <figcaption class="text-[13px] lg:text-lg">
        {{ targetImgName }}
      </figcaption>
      <div class="ml-auto">
        <Icon
          dense
          round
          :img="require('@/assets/img/icon-blackClear.svg')"
          @click="closePreview"
          class="lg:w-[30px] lg:h-[30px] icon"
        />
      </div>
    </div>
    <div class="flex pb-4 lg:pb-9">
      <div
        class="flex items-center justify-center w-[30px] lg:w-[60px] flex-shrink-0"
      >
        <Icon icon="icon-carousel-left" @click="previewPrevious" />
      </div>
      <div class="flex-1 image_container">
        <img
          :src="targetImgURL"
          :alt="targetImgName"
          class="w-full object-contain m-auto"
        />
      </div>
      <div
        class="flex items-center justify-center w-[30px] lg:w-[60px] flex-shrink-0"
      >
        <Icon icon="icon-carousel-right" @click.native="previewNext" />
      </div>
    </div>
  </figure>
</template>

<script>
import { Icon } from "@/components/Shared";
export default {
  name: "ImageCarousell",
  components: { Icon },
  props: {
    imageArray: {
      type: Array,
      default: () => [],
    },
    defaultImageIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentImageIndex: this.defaultImageIndex,
    };
  },
  computed: {
    targetImgURL() {
      return this.imageArray[this.currentImageIndex].url;
    },
    targetImgName() {
      return this.imageArray[this.currentImageIndex].fileName;
    },
  },
  methods: {
    previewPrevious() {
      if (this.currentImageIndex <= 0) {
        return;
      }
      this.currentImageIndex--;
    },
    previewNext() {
      if (this.currentImageIndex >= this.imageArray.length - 1) {
        return;
      }
      this.currentImageIndex++;
    },
    closePreview() {
      this.$emit("closePreview");
      this.$store.commit("mutateDoublePopup", false);
    },
  },
};
</script>

<style scoped lang="scss">
.image_container {
  width: calc(100vw - 150px);
  @media (min-width: 1024px) {
    width: calc(100vw - 270px - 200px);
  }
  @media (min-width: 1440px) {
    width: calc((100vw - 332px) * 0.7);
  }
}
::v-deep.icon {
  > img {
    width: 100%;
    height: 100%;
  }
}
</style>
