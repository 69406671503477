var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSelection),expression:"closeSelection"}],class:[
    _vm.size === 'static'
      ? 'w-[149px] h-[34px] rounded-default'
      : 'w-full h-full',
    'cursor-pointer',
    'relative',
    _vm.color === 'gray' ? 'bg-white text-gray-700' : _vm.displayStyle[_vm.color].default,
  ],on:{"click":_vm.toggleSelection}},[_c('div',{staticClass:"flex flex-row h-full items-center justify-center"},[_c('p',{class:['text-base', { 'font-bold': !_vm.size }]},[_vm._v(" "+_vm._s(_vm.selectedValue)+" ")]),_c('Icon',{attrs:{"icon":_vm.openSelection ? 'icon-chevron-up' : 'icon-chevron-down',"dense":""}})],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.openSelection),expression:"openSelection"}],class:[
      'mt-6 absolute before:absolute before:top-[-7px] before:right-6 before:triangle rounded-default drop-shadow-select text-sm',
      _vm.size === 'static' ? 'right-1' : 'left-2',
      _vm.displayStyle[_vm.color].default,
    ],staticStyle:{"z-index":"1000"}},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:[
        'py-1.5 px-2 flex cursor-pointer',
        _vm.size === 'static' ? 'w-[166px]' : 'w-[100px]',
        option === _vm.selectedValue
          ? _vm.displayStyle[_vm.color].select
          : _vm.displayStyle[_vm.color].default,
        _vm.displayStyle[_vm.color].hover,
        'first:rounded-t-default',
        'last:rounded-b-default',
      ],on:{"click":function($event){_vm.selectedValue = option}}},[_vm._v(" "+_vm._s(option)+" "),(option === _vm.selectedValue)?_c('span',{class:['ml-auto', _vm.displayStyle[_vm.color].select]},[_vm._v("✓")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }