<template>
  <div class="login h-full flex flex-col items-center">
    <div class="h-[90px] mb-[4px]">
      <img class="h-[100%]" alt="TWD Logo" src="@/assets/img/logo2.png" />
    </div>
    <h2 class="text-white mb-[78px] text-2xl lg:text-3xl">
      <img style="width: 122px;margin-top: 10px;" src="@/assets/img/Swap2.png" />
    </h2>
    <p class="text-white mb-[43px] text-2xl">登入您的使用者帳號</p>
    <div class="flex flex-col w-[322px] space-y-[14px]">
      <Input
        v-model="account"
        type="text"
        placeholder="請輸入帳號"
        opacity
        color="white"
        text="white"
        hint="white"
      >
        <template #front>
          <Icon icon="icon-loginLock" />
        </template>
      </Input>
      <Input
        v-model="password"
        type="password"
        placeholder="請輸入登入密碼"
        opacity
        password
        color="white"
        text="white"
        hint="white"
      >
        <template #front>
          <Icon icon="icon-loginLock" />
        </template>
      </Input>
      <Button
        color="gradient-login"
        label="登入"
        text="green"
        size="full"
        @click="accountLogin"
      />
      <!-- <router-link :to="{ name: 'Manager' }" class="w-full">
        </router-link> -->
    </div>
  </div>
</template>

<script>
import { Icon, Input, Button } from "@/components/Shared";
export default {
  name: "Login",
  components: {
    Icon,
    Input,
    Button,
  },
  data() {
    return {
      account: "",
      password: "",
    };
  },
  methods: {
    ObjToFrom: function (obj) {
      let form_data = new FormData();

      for (let key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },
    accountLogin() {
      let obj = { account: this.account, password: this.password };
      this.$store
        .dispatch("apiAdmin/AdminSignIn", this.ObjToFrom(obj))
        .then((res) => {
          console.log("res", res);
          const data = res.data;

          if (data.message == 0) {
            // alert("登入失敗,帳號密碼錯誤");
            this.$notify.warning("登入失敗,帳號密碼錯誤");
            return;
          } else {
            this.$store.commit("login/accountLogin", {
              account: this.account,
              password: this.password,
              token: data.token,
              level: data.message,
            });
            console.log("使用者", this.$store.state.login.token);
            this.$store
              .dispatch("apiAdmin/GetAdminInfo", data.token)
              .then((res) => {
                console.log("取得管理員資訊", res);
                this.$store.commit(
                  "apiAdmin/SET_AdminInfo",
                  this.$deepParseJson(res.data)
                );
                if (data.message == 2) this.$router.push({ name: "Manager" });
                else this.$router.push({ path: "/level2-validation" });
              });
          }
          console.log("登入情況", res.data);
        })
        .catch(() => {});
    },
  },
};
</script>
