import { request } from "@/store/api";

export const apiVerify = {
  namespaced: true,
  state: { 審核列表: [], 會員修改紀錄: [] },
  getters: {},
  mutations: {
    SET_審核列表(state, 審核列表) {
      state.審核列表 = 審核列表;
    },
    SET_會員修改紀錄(state, 會員修改紀錄) {
      state.會員修改紀錄 = 會員修改紀錄;
    },
  },
  actions: {
    A05_顯示審核列表Join會員(state, data) {
      return request
        .addAuth("Verify")
        .get("/VerifyInfoWithMember", { params: data });
    },
    A04_審核駁回(state, data) {
      return request.addFORMAuth("Verify").post("/VerifyNO", data);
    },
    A03_審核通過(state, data) {
      return request.addFORMAuth("Verify").post("/VerifyOK", data);
    },
    A02_顯示審核列表Join(state, data) {
      return request.addFORMAuth("Verify").post("/GetVerifyJoin", data);
    },
    A06_修改_t會員_狀態1(state, data) {
      return request.addFORMAuth("Member").put("/MemberState/Admin", data);
    },
  },
};
