<template>
  <button
    :class="[
      buttonSize[size],
      buttonColor[color],
      outlineColor[outline],
      textColor[text],
      { 'focus:ring-opacity-50 focus:ring-2': !icon },
      { 'border border-solid border-gray-600': defaultStyle },
      { 'rounded-full': shape === 'round' },
      { 'rounded-none': shape === 'square' },
      { 'rounded-default': shape === 'default' },
      { 'cursor-not-allowed': disabled },
    ]"
    :disabled="disabled"
    v-bind="$attrs"
    @click="emitClick"
  >
    <span v-if="!icon"> {{ label }}</span>
    <Icon v-if="icon" :icon="icon" class="transform hover:scale-110" />
  </button>
</template>

<script>
import Icon from "./Icon.vue";
import { buttonSize, outlineColor, buttonColor, textColor } from "./utils";

export default {
  name: "Button",
  components: { Icon },
  props: {
    label: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "",
    },
    gradient: {
      type: String,
      default: "r",
    },
    start: {
      type: String,
      default: "green-100",
    },
    end: {
      type: String,
      default: "green-400",
    },
    outline: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "black",
    },
    size: {
      type: String,
      default: "regular",
    },
    shape: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      defult: "",
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      buttonSize,
      outlineColor,
      buttonColor,
      textColor,
    };
  },
  computed: {},
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
