import { request } from "@/store/api";
import Vue from "vue";
// getSearch = data{ searchAdmin.get(`/Search?searchdata=${data}`)
// getSearchType(){ searchAdmin.get(`/SearchType`)
// function UnixToDate(unix) {
//   var date = new Date(parseInt(unix + "000"));
//   return (
//     date.getFullYear().toString() +
//     "/" +
//     (date.getMonth() + 1).toString().padStart(2, "0") +
//     "/" +
//     date.getDate().toString().padStart(2, "0")
//   );
// }

const objToForm = (obj) => {
  var form_data = new FormData();

  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        form_data.append(key, obj[key][i]);
      }
    } else {
      form_data.append(key, obj[key]);
    }
  }

  return form_data;
};

export const apiMember = {
  namespaced: true,
  state: { members: [] },
  getters: {
    member: function (state) {
      return state.members;
    },
  },
  mutations: {
    SET_members: function (state, members) {
      state.members = members;
    },
    PUSH_members: function (state, members) {
      state.members = state.members.concat(members);
    },
  },
  actions: {
    GetAllMemInfoByCompany(state, data) {
      console.log(state);
      return new Promise((resolve, reject) => {
        request
          .addFORMAuth("Company")
          .post("/GetAllMemInfo", objToForm(data))
          .then((res) => {
            let ary = [];
            res.data.forEach((item) => {
              let dic_status = {
                0: "block",
                1: "unchain",
                2: "normal",
              };
              let memberInfo = JSON.parse(item.fMemInfo);
              let bank = JSON.parse(item.f銀行驗證)[0];
              let iden = JSON.parse(item.f代理人身份驗證);
              let level = item.f資格 == "L2" ? 2 : 3;
              let obj = {
                id: parseInt(item.fId) + 500000, //會和個人戶fId衝突 故+500000
                membership: level,
                date: item.f新增時間,
                type: "company",
                status: dic_status[item.f狀態 + 1],
                apply_name:
                  item.f資格 == "L2" ? "二級企業用戶" : "三級企業用戶",
                account: memberInfo.account,
                tax_number: item.f統一編號,
                wallet_address: memberInfo.bscAddress,
                onChain: item.f狀態 == 1 ? true : false,
                // onLock: false,
                agent: item.f代理人,
                agentID: item.f代理人身份證號,
                company_address: item.f地址,
                bank: bank.名稱,
                bank_branch: bank.分行,
                bank_account: bank.帳號,
                attachment: [
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Front}`,
                    fileName: "代理人身份驗證(正面)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Back}`,
                    fileName: "代理人身份驗證(背面)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Hand}`,
                    fileName: "代理人身份驗證(手持)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${JSON.parse(
                      item.f公司章程驗證
                    )}`,
                    fileName: "公司章程",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${JSON.parse(
                      item.f授權書驗證
                    )}`,
                    fileName: "授權書",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${JSON.parse(
                      item.f設登驗證
                    )}`,
                    fileName: "設立登記",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${bank.照片}`,
                    fileName: "銀行照片",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${bank.手持照片}`,
                    fileName: "銀行照片手持",
                  },
                ],
              };
              let other = JSON.parse(bank.其它照片);
              for (let i = 0; i < other.length; i++) {
                obj.attachment.push({
                  url: `${Vue.prototype.$appsetting.AppUrl}/${other[i]}`,
                  fileName: `其它照片${i + 1}`,
                });
              }
              ary.push(obj);
            });
            resolve(ary);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAllMemInfoByPersonal(state, data) {
      console.log(state);
      return new Promise((resolve, reject) => {
        request
          .addFORMAuth("Personal")
          .post("/GetAllMemInfo", objToForm(data))
          .then((res) => {
            let ary = [];
            res.data.forEach((item) => {
              let dic_status = {
                0: "block",
                1: "unchain",
                2: "normal",
              };
              let memberInfo = JSON.parse(item.fMemInfo);
              let bank = JSON.parse(item.f銀行驗證)[0];
              let iden = JSON.parse(item.f身份驗證);
              let level = item.f資格 == "L2" ? 2 : 3;
              let obj = {
                id: item.fId,
                membership: level,
                date: item.f新增時間,
                type: "personal",
                status: dic_status[item.f狀態 + 1],
                apply_name: item.f資格 == "L2" ? "二級用戶" : "三級用戶",
                account: memberInfo.account,
                identity_number: item.f身份證號,
                wallet_address: memberInfo.bscAddress,
                onChain: item.f狀態 == 1 ? true : false,
                // birth 格式需用 - 隔開 不能用 /
                agent: memberInfo.name,
                birth: item.f出生日.substring(0, 10),
                occupation: item.f職業,
                income: item.f收入來源,
                income_level: item.f收入級距,
                permanent_address: item.f戶籍地址,
                contact_address: item.f通訊地址,
                bank: bank.名稱,
                bank_branch: bank.分行,
                bank_account: bank.帳號,
                attachment: [
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Front}`,
                    fileName: "身份驗證(正面)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Back}`,
                    fileName: "身份驗證(背面)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${iden.Hand}`,
                    fileName: "身份驗證(手持)",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${bank.照片}`,
                    fileName: "銀行照片",
                  },
                  {
                    url: `${Vue.prototype.$appsetting.AppUrl}/${bank.手持照片}`,
                    fileName: "銀行照片手持",
                  },
                ],
              };
              let other = JSON.parse(bank.其它照片);
              for (let i = 0; i < other.length; i++) {
                obj.attachment.push({
                  url: `${Vue.prototype.$appsetting.AppUrl}/${other[i]}`,
                  fileName: `其它照片${i + 1}`,
                });
              }
              ary.push(obj);
            });
            resolve(ary);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
