<template>
  <Card :color="ifOnchain ? 'gray' : 'orange'" type="dialog">
    <template #header>
      <p>{{ ifOnchain ? "斷開" : "綁定" }}管理者白名單</p>
    </template>
    <template #body>
      <div class="pt-2 pb-[21px] flex flex-col items-center">
        <p class="text-[#888888] mb-4">
          請再次確認{{ ifOnchain ? "斷開用戶" : "綁定的地址" }}
        </p>
        <p class="text-lg max-w-[301px] text-center break-all">
          {{ userData.wallet_address }}
        </p>
      </div>
    </template>
    <template #footer>
      <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
        申請人 &colon; {{ $store.state.login.account }}
      </p>
      <Button
        :label="ifOnchain ? '確認斷開' : '確認綁定'"
        :color="ifOnchain ? 'gray' : 'orange'"
        text="white"
        size="popup-md"
        @click="confirm"
      />
    </template>
  </Card>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "ChainCard",
  components: {
    Card,
    Button,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ifOnchain() {
      return this.userData.onChain;
    },
  },
  methods: {
    confirm() {
      this.$store.commit("manager/changeManagerChainStatus", {
        managerAccount: this.userData.account,
        newChainStatus: !this.ifOnchain,
      });
      this.$emit("confirm");
    },
  },
};
</script>
