<template>
  <div class="h-full px-2.5 lg:px-[50px] py-6 lg:py-20 flex flex-col">
    <section class="flex flex-col lg:flex-row items-center mb-7 pl-3 lg:pl-5">
      <h2 class="hidden lg:block text-2xl whitespace-nowrap lg:mr-[46px]">
        三級驗證 申請審核
      </h2>
      <div
        class="w-full lg:flex-shrink-0 lg:w-[280px] mb-[15px] lg:mb-0 lg:mr-2"
      >
        <Input
          placeholder="輸入帳號搜尋"
          filled
          hint="gray"
          text="gray"
          :debounce="300"
          @input="getSearchValue"
          :value="searchWords"
        >
          <template #end>
            <Icon icon="icon-search" />
          </template>
        </Input>
      </div>
      <div class="flex space-x-[9px] items-center mr-auto lg:mr-0 lg:ml-auto">
        <Button
          color="gray"
          label="全部"
          text="white"
          size="status-md"
          @click="$store.commit('validation/getLevel3Application')"
        />
        <Button
          color="red"
          label="申請失敗"
          text="white"
          size="status-md"
          @click="$store.commit('validation/filterLevel3Application', 'failed')"
        />
        <Button
          color="blue"
          label="未審核"
          text="white"
          size="status-md"
          @click="
            $store.commit('validation/filterLevel3Application', 'unapproved')
          "
        />
      </div>
    </section>
    <section class="flex-1 overflow-y-scroll no-scrollbar">
      <Table
        :cols="cols"
        :rows="$store.getters['validation/level3Application']"
        :statusColor="statusColor"
      >
        <template #audit="applier">
          <div class="flex h-full justify-center">
            <Icon icon="icon-check" @click="approveApplication(applier)" />
            <Icon icon="icon-cancel" @click="disapproveApplication(applier)" />
          </div>
        </template>
      </Table>
    </section>
  </div>
</template>

<script>
import { Table, Input, Button, Icon } from "@/components/Shared";
import { formatTwoDigitYear, formatWalletAddress } from "@/utils";

export default {
  name: "Level3",
  components: {
    Table,
    Input,
    Button,
    Icon,
  },
  data() {
    return {
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "date",
          label: "申請時間",
          align: "center",
          format: (val) => formatTwoDigitYear(val),
        },
        {
          name: "account",
          label: "申請帳號",
          align: "center",
        },
        {
          name: "apply_name",
          label: "申請人",
          align: "center",
        },

        {
          name: "wallet_address",
          label: "錢包地址",
          align: "center",
          format: (val) => formatWalletAddress(val),
        },
        {
          name: "audit",
          label: "審核",
          align: "center",
        },
      ],
      statusColor: {
        unapproved: "bg-blue-200",
        failed: "bg-red-100",
      },
      searchWords: "",
    };
  },
  methods: {
    approveApplication(applier) {
      console.log("三級驗證通過", applier);
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "BlockConfirm");
      this.$store.commit("setPopupProps", {
        userData: applier,
        title: "要讓對方通過申請嗎",
        role: "manager",
      });
    },
    disapproveApplication(applier) {
      console.log("三級驗證不通過", applier);
      this.$store.commit("setPopupContent", "FailedConfirm");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        reasonList: this.$store.state.validation.level3FailedReason,
        id: applier.id,
        level: "level3",
      });
    },
    getSearchValue(inputValue) {
      this.searchWords = inputValue;
      this.$store.commit("validation/searchLevel3Application", inputValue);
    },
    api() {
      const self = this;
      const fn = {
        GetMixVerifyJoin: () => {
          //混合t個人戶 and t公司取得資料
          let formdata = self.$tools.ObjToForm({
            fFirstKey: "t公司",
            fSecondKey: "f統一編號",
            searchKey: "f描述",
            searchVal: "L2->L3申請",
          });
          formdata.append("fFirstKey", "t個人戶");
          formdata.append("fSecondKey", "f身份證號");
          formdata.append("searchKey", "f資格");
          formdata.append("searchVal", "L2");
          self.$store
            .dispatch("apiAdmin/GetMixVerifyJoin", formdata)
            .then((res) => {
              let rows = [];
              res.data.map((dt) => {
                let obj = {};
                if (dt.fFirstKey == "t公司") {
                  obj = this.getCompany(dt);
                } else {
                  obj = this.getPerson(dt);
                }
                rows.push(obj);
              });
              rows.sort((a, b) => {
                let aa = new Date(a.date).getTime();
                let bb = new Date(b.date).getTime();
                return aa - bb;
              });
              console.log("rows", rows);
              // this.$store.commit("validation/getLevel2Application");
              this.$store.commit("validation/setLevel3Application", rows);
              this.$store.commit("validation/getLevel3Application");
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status == 403) {
                // alert("沒有權限");
                self.$notify.warning("沒有權限");
                self.$router.push({ path: "/level3-validation" });
              }
            });
        },
      };
      return fn;
    },
    getPerson(dt) {
      dt.fMemInfo = JSON.parse(dt.fMemInfo);
      dt.f身份驗證 = JSON.parse(dt.f身份驗證);
      dt.f銀行驗證 = JSON.parse(dt.f銀行驗證);
      if (
        dt.f銀行驗證[0].其它照片 != null &&
        typeof dt.f銀行驗證[0].其它照片 != "undefined"
      ) {
        dt.f銀行驗證[0].其它照片 = JSON.parse(dt.f銀行驗證[0].其它照片);
      } else {
        dt.f銀行驗證[0].其它照片 = [];
      }
      let membershipObj = { L1: 1, L2: 2, L3: 3 };
      let obj = {
        id: dt.fVid,
        membership: membershipObj[dt.f資格],
        type: "personal",
        date: dt.f提交時間,
        status: "unapproved",
        apply_name: dt.f姓名,
        gender: dt.f性別 == 0 ? "male" : "female",
        account: dt.fMemInfo.account,
        identity_number: dt.f身份證號,
        wallet_address: dt.fMemInfo.bscAddress,
        birth: dt.f出生日.substring(0, 10),
        occupation: dt.f職業,
        income: dt.f收入來源,
        income_level: dt.f收入級距,
        permanent_address: dt.f戶籍地址,
        contact_address: dt.f通訊地址,
        bank: dt.f銀行驗證[0].名稱,
        bank_branch: dt.f銀行驗證[0].分行,
        bank_account: dt.f銀行驗證[0].帳號,
        attachment: [
          {
            url: `${this.$appsetting.AppUrl}/${dt.f身份驗證.Front}`,
            fileName: "身份驗證(正面)",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f身份驗證.Back}`,
            fileName: "身份驗證(背面)",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f身份驗證.Hand}`,
            fileName: "身份驗證(手持)",
          },
        ],
      };
      if (dt.f提交狀態 == 0 && dt.f資格 == "L2") {
        obj.status = "failed";
        dt.f狀態描述 != ""
          ? (obj.failed_reason = JSON.parse(dt.f狀態描述))
          : "";
      }
      for (let i = 0; i < dt.f銀行驗證[0].其它照片.length; i++) {
        obj.attachment.push({
          url: `${this.$appsetting.AppUrl}/${dt.f銀行驗證[0].其它照片[i]}`,
          fileName: `其它照片${i + 1}`,
        });
      }
      return obj;
    },
    getCompany(dt) {
      dt.fMemInfo = JSON.parse(dt.fMemInfo);
      dt.f代理人身份驗證 = JSON.parse(dt.f代理人身份驗證);
      dt.f銀行驗證 = JSON.parse(dt.f銀行驗證);
      if (
        dt.f銀行驗證[0].其它照片 != null &&
        typeof dt.f銀行驗證[0].其它照片 != "undefined"
      ) {
        dt.f銀行驗證[0].其它照片 = JSON.parse(dt.f銀行驗證[0].其它照片);
      } else {
        dt.f銀行驗證[0].其它照片 = [];
      }
      let membershipObj = { L1: 1, L2: 2, L3: 3 };
      let obj = {
        id: dt.fVid,
        membership: membershipObj[dt.f資格], //待確認
        type: "company",
        date: dt.f提交時間,
        status: "unapproved",
        apply_name: dt.f名稱,
        account: dt.fMemInfo.account,
        tax_number: dt.f統一編號,
        agent: dt.f代理人,
        agentID: dt.f代理人身份證號,
        bank: dt.f銀行驗證[0].名稱,
        bank_branch: dt.f銀行驗證[0].分行,
        bank_account: dt.f銀行驗證[0].帳號,
        company_address: dt.f地址, //在clinent端沒有讓人填 要確定要還是不要
        wallet_address: dt.fMemInfo.bscAddress,
        attachment: [
          {
            url: `${this.$appsetting.AppUrl}/${dt.f代理人身份驗證.Front}`,
            fileName: "代理人身份驗證(正面)",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f代理人身份驗證.Back}`,
            fileName: "代理人身份驗證(背面)",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f代理人身份驗證.Hand}`,
            fileName: "代理人身份驗證(手持)",
          },
          {
            url: `${this.$appsetting.AppUrl}/${JSON.parse(dt.f公司章程驗證)}`,
            fileName: "公司章程",
          },
          {
            url: `${this.$appsetting.AppUrl}/${JSON.parse(dt.f授權書驗證)}`,
            fileName: "授權書",
          },
          {
            url: `${this.$appsetting.AppUrl}/${JSON.parse(dt.f設登驗證)}`,
            fileName: "設立登記",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f銀行驗證[0].照片}`,
            fileName: "銀行照片",
          },
          {
            url: `${this.$appsetting.AppUrl}/${dt.f銀行驗證[0].手持照片}`,
            fileName: "銀行照片手持",
          },
        ],
      };
      if (dt.f提交狀態 == 0 && dt.f資格 == "L2") {
        obj.status = "failed";
        dt.f狀態描述 != ""
          ? (obj.failed_reason = JSON.parse(dt.f狀態描述))
          : "";
      }
      for (let i = 0; i < dt.f銀行驗證[0].其它照片.length; i++) {
        obj.attachment.push({
          url: `${this.$appsetting.AppUrl}/${dt.f銀行驗證[0].其它照片[i]}`,
          fileName: `其它照片${i + 1}`,
        });
      }
      return obj;
    },
  },
  created() {
    this.$store.commit("validation/resetLevel3Application");
    this.$store.commit("validation/getLevel3Application");
  },
  mounted() {
    this.api().GetMixVerifyJoin();
  },
};
</script>
