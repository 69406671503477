var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex"},_vm._l((_vm.tabsInfo),function(tab){return _c('div',{key:tab.value,class:[
      'flex-1',
      'text-black',
      'py-[21px] text-center',
      'cursor-pointer',
      'relative before:transition-colors',
      _vm.value === tab.value ? _vm.currentTabColor : 'before:bg-gray-500',
      _vm.decorPseudoClass,
    ],on:{"click":function($event){return _vm.changeTab(tab.value)}}},[_vm._t(tab.value,function(){return [_c('p',[_vm._v(_vm._s(tab.label))])]})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }