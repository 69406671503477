export { income, incomeLevel, bankList, bankBranchList };

const income = [
  "薪資收入",
  "利息收入",
  "經營收入",
  "版權收入",
  "股息收入",
  "租金收入",
];
const incomeLevel = ["低", "中", "中高", "高"];
const bankList = [
  "(004)臺灣銀行",
  "(005)土地銀行",
  "(006)合作金庫",
  "(007)第一銀行",
  "(008)華南銀行",
  "(009)彰化銀行",
  "(017)兆豐銀行",
  "(050)台灣中小企業銀行",
];

const bankBranchList = [
  "台北分行",
  "台中分行",
  "嘉義分行",
  "台南分行",
  "高雄分行",
];
