<template>
  <div
    style="position: relative"
    class="field flex justify-between items-center"
  >
    <vc-date-picker v-show="區間選擇" v-model="range" is-range>
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-center items-center">
          <img :src="require(`@/assets/img/icon-calander-${Color}.svg`)" />
          <input
            :value="inputValue.start"
            v-on="inputEvents.start"
            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
          />
          <img :src="require(`@/assets/img/icon-calander-${Color}.svg`)" />
          <input
            :value="inputValue.end"
            v-on="inputEvents.end"
            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
          />
        </div>
      </template>
    </vc-date-picker>
    <vc-date-picker v-show="!區間選擇" v-model="rangeSingle" mode="single">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-center items-center">
          <img :src="require(`@/assets/img/icon-calander-${Color}.svg`)" />
          <input
            :value="inputValue"
            v-on="inputEvents"
            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
          />
        </div>
      </template>
    </vc-date-picker>
    <dropdown-menu
      v-show="$route.fullPath == '/moneyin' || $route.fullPath == '/team'"
      v-model="show"
      :right="false"
      :hover="false"
      :interactive="true"
      class="flex items-center"
    >
      <button class="flex text-[#cc232a]">
        <slot name="buttonName"
          ><img class="w-[100%]" src="@/assets/img/icon-download.svg"
        /></slot>
      </button>
      <div
        style="right: 20px; top: 28px"
        class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
        slot="dropdown"
      >
        <slot name="dropdownList">
          <ul
            class="py-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li @click="匯出CSV()" class="hover:bg-[#cc232a]">
              <a
                href="#"
                class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                >匯出CSV</a
              >
            </li>
            <!-- <li class="hover:bg-[#cc232a]">
              <a
                href="#"
                class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                >匯出PDF</a
              >
            </li> -->
          </ul>
        </slot>
      </div>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from "@innologica/vue-dropdown-menu";
export default {
  name: "DatePicker",
  components: { DropdownMenu },
  props: {
    defaultChg: {
      type: Boolean,
      default: true,
    },
    初始時間起: {
      type: String,
    },
    初始時間迄: {
      type: String,
    },
    編輯文章時間: {
      type: Date,
    },
    Color: {
      type: String,
      default: "red",
    },
    區間選擇: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      rangeSingle: new Date(),
      range: {
        start: "",
        end: "",
        // start:  new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        // end: new Date(),
      },

      bankList: ["匯出CSV", "匯出PDF"],
      formValues: "匯出",
    };
  },
  created() {
    if (this.$route.fullPath == "/moneyout") {
      this.range.start = localStorage.getItem("入金時間起")
        ? this.入金時間起組合
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      this.range.end = localStorage.getItem("入金時間迄")
        ? this.入金時間迄組合
        : new Date();
    } else if (this.$route.fullPath == "/news") {
      this.range.start = localStorage.getItem("公告中心列表時間起")
        ? localStorage.getItem("公告中心列表時間起")
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      this.range.end = localStorage.getItem("公告中心列表時間迄")
        ? localStorage.getItem("公告中心列表時間迄")
        : new Date();
    } else {
      this.range.start = localStorage.getItem("入金時間起")
        ? localStorage.getItem("入金時間起")
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      this.range.end = localStorage.getItem("入金時間迄")
        ? localStorage.getItem("入金時間迄")
        : new Date();
      console.log("abced", this.range.start);
      console.log("abced", this.range.end);
    }
  },
  watch: {
    range: {
      handler(v) {
        if (!this.區間選擇) return;
        console.log("range", v);
        this.sendToParent();
      },
      immediate: true,
    },

    rangeSingle: {
      handler() {
        if (this.區間選擇) return;
        this.sendToParent();
      },
      immediate: true,
    },
    編輯文章時間: {
      handler(v) {
        console.log("編輯文章時間", v);
        if (v !== null) {
          this.rangeSingle = v;
        }
      },
      immediate: true,
    },
  },
  computed: {
    入金時間起組合() {
      let v = localStorage.getItem("入金時間起")
        ? localStorage.getItem("入金時間起")
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      let v2 = "";
      v2 = `${v.slice(0, 4)}-${v.slice(4, 6)}-${v.slice(6, 8)}`;
      return v2;
    },
    入金時間迄組合() {
      let v = localStorage.getItem("入金時間迄")
        ? localStorage.getItem("入金時間迄")
        : new Date();
      let v2 = "";
      v2 = `${v.slice(0, 4)}-${v.slice(4, 6)}-${v.slice(6, 8)}`;
      return v2;
    },
  },
  methods: {
    handleSuccess() {
      this.show = false;
    },
    sendToParent() {
      this.$emit("valueUpdate", this.區間選擇 ? this.range : this.rangeSingle);
    },
    匯出CSV() {
      this.show = false;
      this.$emit("csvUpdate", this.range);
    },
  },
};
</script>
<style scoped></style>
