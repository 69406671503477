<template>
  <div
    :class="[
      'flex items-center justify-center cursor-pointer',
      { 'px-2': !dense },
      bgColor[color],
      { 'rounded-full text-white': round },
    ]"
    @click="clickIcon"
  >
    <template v-if="icon">
      <span :class="icon" class="flex-shrink-0 appSize" />
    </template>
    <template v-else>
      <img :src="img" :alt="alt" class="flex-shrink-0 appSize" />
    </template>
  </div>
</template>

<script>
import { bgColor } from "./utils";
export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: "",
    },
    img: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "icon",
    },
    round: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roundSize: {
        sm: "w-[17px] h-[17px] lg:w-[37px] lg:h-[37px]",
        md: "w-[19px] h-[19px] lg:w-[34px] lg:h-[34px]",
        lg: "w-[27px] h-[27px]",
        xl: "w-[59px] h-[59px] lg:w-[34px] lg:h-[34px]",
      },
      bgColor,
    };
  },
  methods: {
    clickIcon() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 800px) {
  .appSize {
    width: 10px;
  }
}
</style>