<template>
  <div
    class="flex items-center space-x-2 cursor-pointer"
    @click="$emit('click')"
  >
    <div
      :class="[
        'rounded-full',
        'w-3.5',
        'h-3.5',
        'border',
        'border-white',
        'bg-transparent',
        {
          [selectedStyle]: selected,
        },
      ]"
    />
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "Radio",
  props: {
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStyle:
        "flex items-center justify-center before:block before:w-2 before:h-2 before:bg-white before:rounded-full",
    };
  },
};
</script>
