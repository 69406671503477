<template>
  <div>
    <div class="hidden lg:block" v-if="selectEvent != ''">
      <div class="subgroup-red text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Select User Permissions</strong>
        </h2>
        <h2 class="subtitle">
          <strong>選擇使用者權限</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="text-center pt-2 pb-[17px]">
          <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2>
        </div>
        <div
          class="w-[35%] text-center pt-2 pb-[30px] flex flex-col items-center"
          style="margin: 0 auto"
        >
          <template>
            <Button
              v-for="(item, idx) in options"
              :key="idx"
              class="mb-[17px]"
              :label="item"
              :color="檢查(item) == true ? 'red' : 'gray'"
              text="white"
              size="popup-md"
              @click="toggleAuth(item)"
            />
          </template>
          <div class="w-[60%] mt-[30%] text-sm">
            <Button
              label="確認保存"
              color="green"
              text="white"
              size="popup-md"
              @click="save()"
            />
          </div>
        </div>
      </div>
    </div>
    <popcorn
      class="block lg:hidden"
      name="delManager"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card iconhead="true" color="red" type="dialog">
        <template #header>
          <div class="flex items-center justify-center pt-[8px]">
            <img
              style="max-width: initial"
              src="@/assets/img/settingManager.png"
              alt=""
            />
          </div>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="text-center pt-2 pb-[5px]">
              <h1 class="text-gray-300 text-lg">請選擇使用者權限</h1>
              <h2 class="pt-3 h-[40px] text-lg">{{ selectObj.apply_name }}</h2>
            </div>
            <div
              class="text-center pt-2 pb-[30px] flex flex-col items-center"
              style="margin: 0 auto"
            >
              <template>
                <Button
                  v-for="(item, idx) in options"
                  :key="idx"
                  class="mb-[17px]"
                  :label="item"
                  :color="檢查(item) == true ? 'red' : 'gray'"
                  text="white"
                  size="popup-md"
                  @click="toggleAuth(item)"
                />
              </template>
            </div>
          </template>
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認保存"
            color="green"
            text="white"
            size="popup-md"
            @click="save()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "SettingManager",
  components: { Card, Button },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    selectObj: {
      type: Object,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      select: [],
      delselect: [],
      options: [
        "二級驗證申請",
        "會員名單管理",
        "匯率管理",
        "入金管理",
        "出金管理",
        "戰情分析",
      ],
    };
  },
  mounted() {
    this.$modal.show("delManager");
  },
  computed: {},
  methods: {
    檢查(tar) {
      return (
        (this.$tools.checkAuth(this.selectObj.auth, tar, "READ") &&
          this.delselect.indexOf(tar) == -1) ||
        this.select.indexOf(tar) != -1
      );
    },
    save() {
      this.saveObj(this.select).then((res) => {
        console.log("結果res", res);
        let _d = JSON.parse(JSON.stringify(this.delselect));
        this.delObj(_d);
        this.$notify.success("權限儲存成功");
      });
    },
    saveFinish() {
      this.$emit("successEvent");
      this.$emit("toggleEvent", "");
      this.select = [];
      this.delselect = [];
    },
    saveObj(data) {
      return new Promise((resolve, reject) => {
        if (data.length == 0) resolve();
        let obj = {};
        data.forEach((e) => {
          obj[e] = ["READ", "DELETE", "EDIT"];
        });
        console.log("更新", obj);

        let _obj = {
          fId: this.selectObj.id,
          authority: JSON.stringify(obj),
        };
        let formdata = this.$tools.ObjToForm(_obj);
        this.$store
          .dispatch("apiAdmin/RootModiGenAdminAuth", formdata)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    delObj(data) {
      console.log("剛進來", data);
      if (data.length == 0) {
        this.saveFinish();
        return;
      }
      let _obj = {
        fId: this.selectObj.id,
        authority: data[data.length - 1],
      };

      let formdata = this.$tools.ObjToForm(_obj);
      this.$store
        .dispatch("apiAdmin/RootDelGenAdminAuth", formdata)
        .then(() => {
          data.pop();
          console.log("刪除成功", data);
          this.delObj(data);
        });
    },
    toggleAuth(tar) {
      const chk = this.$tools.checkAuth(this.selectObj.auth, tar, "READ");
      if (chk) {
        this.touchDelSelect(tar);
      } else {
        this.touchSelect(tar);
      }
    },
    touchSelect(tar) {
      //原本不存在 但要新增的
      const idx = this.select.indexOf(tar);
      if (idx == -1) {
        this.select.push(tar);
      } else {
        this.select.splice(idx, 1);
      }
    },
    touchDelSelect(tar) {
      //原本存在 但要刪除的
      const idx = this.delselect.indexOf(tar);
      if (idx == -1) {
        this.delselect.push(tar);
      } else {
        this.delselect.splice(idx, 1);
      }
    },
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("delManager");
    },
    DelGenAdmin() {
      let obj = { fId: this.selectObj.id };
      let formdata = this.$tools.ObjToForm(obj);
      this.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
        let data = res.data;
        if (data != 0) {
          // alert("刪除成功");
          this.$emit("successEvent");
          this.$notify.success("刪除成功");
          this.closeWindow();
        }
      });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
