<template>
  <div class="h-full flex flex-col relative lg:static">
    <!-- tabs 切換區 -->
    <section class="block lg:hidden bg-white flex flex-row text-lg">
      <!-- <div
        class="w-[82px] lg:w-[108px] flex-shrink-0 relative before:bg-gray-700 before:absolute before:bottom-0 before:w-full before:block before:h-1 before:z-10"
      >
        <PairSelect
          :options="baseCurrencyOptions"
          color="gray"
          class=""
          v-model="baseCurrency"
        />
      </div> -->

      <Tabs :tabsInfo="tabsInfo" v-model="currentTab">
        <template #buying>
          <p class="text-sm lg:text-2xl font-bold text-red-100">
            {{ buyingTab }}
          </p>
        </template>
        <template #selling>
          <p class="text-sm lg:text-2xl font-bold text-green-300">
            {{ sellingTab }}
          </p>
        </template>
      </Tabs>
    </section>
    <!-- 主要頁面 -->
    <div
      class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
    >
      <!-- 匯率下拉選單 -->
      <section
        v-if="false"
        class="block lg:hidden flex-none flex flex-row justify-end items-center lg:justify-between lg:space-x-4 mb-4 lg:mb-5"
      >
        <div class="hidden lg:block lg:w-[280px] mr-auto">
          <Input
            v-if="view === 'table'"
            placeholder="輸入帳號搜尋"
            filled
            hint="gray"
            text="gray"
            @update:value="searchValue = $event"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
        </div>
        <PairSelect
          v-if="currentTab === 'buying'"
          :options="buyingTradingPairSelection[baseCurrency]"
          color="red"
          class=""
          size="static"
          v-model="buyingTradingPair"
        />
        <PairSelect
          v-else
          :options="sellingTradingPairSelection[baseCurrency]"
          color="green"
          class=""
          size="static"
          v-model="sellingTradingPair"
        />
        <div class="absolute bottom-16 right-6 lg:static">
          <Icon
            v-if="view === 'table'"
            icon="icon-edit"
            round
            color="blue"
            size="md"
            class="w-[59px] lg:w-[34px] h-[59px] lg:h-[34px] text-[27px] lg:text-sm shadow-icon lg:shadow-none"
            @click="view = 'edit'"
          />
          <Icon
            v-else
            :img="require('@/assets/img/icon-trading.svg')"
            round
            color="dark-gary"
            size="md"
            class="w-[59px] lg:w-[34px] h-[59px] lg:h-[34px] text-[27px] lg:text-sm shadow-icon lg:shadow-none"
            @click="view = 'table'"
          />
        </div>
      </section>
      <!-- table、輸入匯率調整 -->
      <section class="flex-1 overflow-y-scroll no-scrollbar">
        <!-- table -->
        <template v-if="view === 'table'">
          <div class="flex justify-around webSize">
            <div class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Convert TWD to USDT</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>TWD兌換USDT</strong>
                  </h2>
                </div>

                <div class="flex flex-col items-end pt-[45px]">
                  <PairSelect
                    :options="buyingTradingPairSelection['TWD']"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                </div>
              </div>
              <div class="px-4">
                <DatePicker
                  @csvUpdate="csvUpdate"
                  @valueUpdate="selectTime"
                  Color="red"
                  class="pl-4"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">TWD入賬總數</span>
                  <span class="ml-2 text-md">TWD recharge</span>
                </div>
                <AnalyzeInfo
                  MainType="TWD"
                  CoinType="USDT"
                  v-model="TWD"
                  :Rate="TWDRATE"
                  :換算="左邊換算"
                  Color="blue"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">TWD入賬明細</span>
                  <span class="ml-2 text-md">TWD deposit details</span>
                </div>

                <Table
                  :key="重新渲染"
                  class="mt-5"
                  :cols="cols"
                  :rows="TWDMOCKDATA"
                />
              </div>
            </div>
            <div class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div
                  class="subgroup-green text-2xl hidden lg:block lg:flex-grow"
                >
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Convert USDT to TWD</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>USDT兌換TWD</strong>
                  </h2>
                </div>
                <div class="flex flex-col items-end pt-[45px]">
                  <PairSelect
                    :options="buyingTradingPairSelection['USDT']"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  />
                </div>
              </div>
              <div class="px-4">
                <DatePicker
                  @csvUpdate="csvUpdate2"
                  @valueUpdate="selectTime2"
                  Color="green"
                  class="pl-4"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">USDT入賬總數</span>
                  <span class="ml-2 text-md">USDT recharge</span>
                </div>
                <AnalyzeInfo
                  MainType="USDT"
                  CoinType="TWD"
                  v-model="USDT"
                  :Rate="USDTRATE"
                  :換算="右邊換算"
                  Color="green"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">USDT入賬明細</span>
                  <span class="ml-2 text-md">USDT deposit details</span>
                </div>
                <Table
                  :key="重新渲染2"
                  class="mt-5"
                  :cols="cols"
                  :rows="USDTMOCKDATA"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-around appSize">
            <div v-if="currentTab == 'buying'" class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Convert TWD to USDT</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>TWD兌換USDT</strong>
                  </h2>
                </div>

                <div class="flex flex-col items-end pt-[45px]">
                  <PairSelect
                    :options="buyingTradingPairSelection['TWD']"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                </div>
              </div>
              <div class="px-4">
                <DatePicker
                  @csvUpdate="csvUpdate"
                  @valueUpdate="selectTime"
                  Color="red"
                  class="pl-4"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">TWD入賬總數</span>
                  <span class="ml-2 text-md">TWD recharge</span>
                </div>
                <AnalyzeInfo
                  MainType="TWD"
                  CoinType="USDT"
                  v-model="TWD"
                  :Rate="TWDRATE"
                  :換算="左邊換算"
                  Color="blue"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">TWD入賬明細</span>
                  <span class="ml-2 text-md">TWD deposit details</span>
                </div>

                <Table
                  :key="重新渲染"
                  class="mt-5"
                  :cols="cols"
                  :rows="TWDMOCKDATA"
                />
              </div>
            </div>
            <div v-else-if="currentTab == 'selling'" class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div
                  class="subgroup-green text-2xl hidden lg:block lg:flex-grow"
                >
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Convert USDT to TWD</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>USDT兌換TWD</strong>
                  </h2>
                </div>
                <div class="flex flex-col items-end pt-[45px]">
                  <PairSelect
                    :options="buyingTradingPairSelection['USDT']"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  />
                </div>
              </div>
              <div class="px-4">
                <DatePicker
                  @csvUpdate="csvUpdate2"
                  @valueUpdate="selectTime2"
                  Color="green"
                  class="pl-4"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">USDT入賬總數</span>
                  <span class="ml-2 text-md">USDT recharge</span>
                </div>
                <AnalyzeInfo
                  MainType="USDT"
                  CoinType="TWD"
                  v-model="USDT"
                  :Rate="USDTRATE"
                  :換算="右邊換算"
                  Color="green"
                />
                <div class="mt-[30px] mb-[10px] text-[#959595]">
                  <span class="text-2xl text-bolder">USDT入賬明細</span>
                  <span class="ml-2 text-md">USDT deposit details</span>
                </div>
                <Table
                  :key="重新渲染2"
                  class="mt-5"
                  :cols="cols"
                  :rows="USDTMOCKDATA"
                />
              </div>
            </div>
          </div>
        </template>
        <!-- 輸入匯率 -->
        <template v-else>
          <!-- buying-->

          <EditRate
            v-if="currentTab === 'buying'"
            :baseCurrency="buyingTradingPair.split('/')[0]"
            :quoteCurrency="baseCurrency"
            color="red"
          />
          <!-- selling -->
          <EditRate
            v-else
            :baseCurrency="baseCurrency"
            :quoteCurrency="sellingTradingPair.split('/')[1]"
            color="green"
          />
        </template>
      </section>
    </div>
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
  </div>
</template>

<script>
import { Tabs, Icon, Table, Input } from "@/components/Shared";
import { formatTwoDigitYear } from "@/utils";

import PairSelect from "@/components/Trading/PairSelect.vue";
import EditRate from "@/components/Trading/EditRate.vue";
import AnalyzeInfo from "@/components/Analyze/AnalyzeInfo.vue";
import DatePicker from "@/components/FormItems/DatePicker.vue";
export default {
  name: "Analyze",
  components: {
    Tabs,
    Icon,
    Table,
    Input,
    PairSelect,
    EditRate,
    AnalyzeInfo,
    DatePicker,
  },
  data() {
    return {
      重新渲染: 0,
      重新渲染2: 0,
      baseCurrencyOptions: ["USDT", "TWD"],
      currentTab: "buying",
      view: "table",
      buyingTradingPair: "TWD/USDT",
      sellingTradingPair: "USDT/TWD",
      cols: [
        {
          name: "date",
          label: "入賬日期",
          align: "center",
          format: (val) => formatTwoDigitYear(val),
        },
        {
          name: "people",
          label: "人數",
          align: "center",
        },
        {
          name: "rate",
          label: "平均匯率",
          align: "center",
        },
        {
          name: "amount",
          label: "數量",
          align: "center",
        },
      ],
      buyingTradingPairSelection: {
        USDT: ["USDT/TWD"],
        TWD: ["TWD/USDT"],
        // USDT: ["TWD/USDT", "BTC/USDT", "ETH/USDT", "BNB/USDT", "EOS/USDT"],
        // TWD: ["USDT/TWD", "BTC/TWD", "ETH/TWD", "BNB/TWD", "EOS/TWD"],
      },
      sellingTradingPairSelection: {
        TWD: ["TWD/USDT", "TWD/BTC", "TWD/ETH", "TWD/BNB", "TWD/EOS"],
        USDT: ["USDT/TWD", "USDT/BTC", "USDT/ETH", "USDT/BNB", "USDT/EOS"],
      },
      allCurrencyList: ["BUSD", "BTC", "ETH", "BNB", "EOS"],
      USDT: 0,
      USDTRATE: 0,
      右邊換算: 0,
      左邊換算: 0,
      TWD: 100,
      TWDRATE: 0,
      USDTMOCKDATA: [],
      TWDMOCKDATA: [],
    };
  },
  computed: {
    quoteCurrency() {
      return this.$store.state.trading.quoteCurrency;
    },
    baseCurrency: {
      get() {
        return this.$store.state.trading.baseCurrency;
      },
      set(currency) {
        this.$store.commit("trading/changeBaseCurrency", currency);
      },
    },
    tabsInfo() {
      return [
        {
          value: "buying",
          label: this.buyingTradingPair.replace("/", " 兌換 "),
        },
        {
          value: "selling",
          label: this.sellingTradingPair.replace("/", " 兌換 "),
        },
      ];
    },
    buyingTab() {
      return this.tabsInfo.filter((tab) => tab.value === "buying")[0].label;
    },
    sellingTab() {
      return this.tabsInfo.filter((tab) => tab.value === "selling")[0].label;
    },
  },

  watch: {
    baseCurrency(val) {
      if (val == "USDT") {
        this.buyingTradingPair = "TWD/USDT";
        this.sellingTradingPair = "USDT/TWD";
      } else if (val == "TWD") {
        this.buyingTradingPair = "USDT/TWD";
        this.sellingTradingPair = "TWD/USDT";
      }
      this.CoinRecord();
    },
    currentTab(direction) {
      console.log("direction", direction);
      this.CoinRecord();
    },
  },
  created() {
    this.CoinRecord();
  },
  mounted() {
    this.TWD_TO_USDT匯率();
    this.$store.commit("trading/changeBaseCurrency", "USDT");
    // this.mockData();
    this.A04_管理員取得入金紀錄_透過起始結束時間();
    this.A05_查詢_戰情分析列表();
    this.USDTtoTWD();
    // this.TWDtoUSDT();
  },
  methods: {
    TWD_TO_USDT匯率() {
      this.$store
        .dispatch("apiMoneyIn/A04_查詢_取得單筆_tKeyCode", {
          fKey: "TWD_TO_USDT",
        })
        .then((res) => {
          this.TWDRATE = res.data;
          console.log(res.data);
        });
    },
    csvUpdate() {
      this.$store
        .dispatch("apiAdmin/A05_取得CSV_戰情分析", {
          時間起: localStorage.getItem("戰情分析TWD兌換USDT時間起"),
          時間迄: localStorage.getItem("戰情分析TWD兌換USDT時間迄"),
          Model: 0,
        })
        .then((res) => {
          if (res.data == "入金紀錄沒有資料") {
            this.$notify.success(res.data);
          } else {
            var link = document.createElement("a");
            link.href = "https://amlservice.qhr.app/" + res.data;
            //  link.href ='http://220.134.4.63:6382/'+res.data
            link.click();
          }
        });
    },
    csvUpdate2() {
      this.$store
        .dispatch("apiAdmin/A05_取得CSV_戰情分析", {
          時間起: localStorage.getItem("戰情分析USDT兌換TWD時間起字串模式"),
          時間迄: localStorage.getItem("戰情分析USDT兌換TWD時間迄字串模式"),
          Model: 1,
        })
        .then((res) => {
          if (res.data == "出金紀錄沒有資料") {
            this.$notify.success(res.data);
          } else {
            var link = document.createElement("a");
            link.href = "https://amlservice.qhr.app/" + res.data;
            // link.href = "http://220.134.4.63:6382/" + res.data;
            link.click();
          }
        });
    },
    selectTime(val) {
      console.log("aaaaaaaaaaaaaaaaa", typeof val.end == "object");
      if (typeof val.end == "object") {
        const 開始 = val.start;
        const 開始年 = 開始.getFullYear();
        const 開始月 = 開始.getMonth() + 1;
        const 開始日 = 開始.getDate();
        const 開始時間 = `${開始年}-${開始月}-${開始日}`;
        const 結束 = val.end;
        const 結束年 = 結束.getFullYear();
        const 結束月 = 結束.getMonth() + 1;
        const 結束日 = 結束.getDate();
        const 結束時間 = `${結束年}-${結束月}-${結束日}`;
        this.A04_管理員取得入金紀錄_透過起始結束時間(開始時間, 結束時間);
      }
    },
    A04_管理員取得入金紀錄_透過起始結束時間(開始時間, 結束時間) {
      const 七天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      const 七天前年 = 七天前.getFullYear();
      const 七天前月 = 七天前.getMonth() + 1;
      const 七天前日 = 七天前.getDate();
      const 七天前時間 = `${七天前年}-${七天前月}-${七天前日}`;

      const 當前年 = new Date().getFullYear();
      const 當前月 = new Date().getMonth() + 1;
      const 當前日 = new Date().getDate();
      const 當前時間 = `${當前年}-${當前月}-${當前日}`;
      localStorage.setItem(
        "戰情分析TWD兌換USDT時間起",
        開始時間 == undefined ? 七天前時間 : 開始時間
      );
      localStorage.setItem(
        "戰情分析TWD兌換USDT時間迄",
        結束時間 == undefined ? 當前時間 : 結束時間
      );
      this.$store
        .dispatch("apiMoneyIn/A04_管理員取得入金紀錄_透過起始結束時間", {
          時間起: 開始時間 == undefined ? 七天前時間 : 開始時間,
          時間迄: 結束時間 == undefined ? 當前時間 : 結束時間,
        })
        .then((res) => {
          this.TWDMOCKDATA = [];
          this.TWD = 0;
          this.左邊換算 = 0;
          if (res.data) {
            const 總金額及換算結果 = Object.keys(res.data)[0];
            const 列表 = Object.values(res.data)[0];
            if (總金額及換算結果) {
              this.TWD = JSON.parse(總金額及換算結果).TWD;
              this.左邊換算 = JSON.parse(總金額及換算結果).USDT;
              console.log(JSON.parse(總金額及換算結果));
            }
            if (列表) {
              JSON.parse(列表).forEach((i, idx) => {
                this.TWDMOCKDATA[idx] = {
                  date: i.f新增時間.split("T")[0],
                  people: i.人數,
                  rate: i.平均匯率,
                  amount: i.總數量,
                };
              });
              this.重新渲染++;
            }
          }
          // console.log(this.TWDMOCKDATA);
        });
    },
    selectTime2(val) {
      if (typeof val.end == "object") {
        const 開始 = val.start;
        const 開始年 = 開始.getFullYear();
        const 開始月 = 開始.getMonth() + 1;
        const 開始日 = 開始.getDate();
        const 開始時間 = `${開始年}${開始月 < 10 ? "0" + 開始月 : 開始月}${
          開始日 < 10 ? "0" + 開始日 : 開始日
        }`;
        const 結束 = val.end;
        const 結束年 = 結束.getFullYear();
        const 結束月 = 結束.getMonth() + 1;
        const 結束日 = 結束.getDate();
        const 結束時間 = `${結束年}${結束月 < 10 ? "0" + 結束月 : 結束月}${
          結束日 < 10 ? "0" + 結束日 : 結束日
        }`;
        this.A05_查詢_戰情分析列表(開始時間, 結束時間);
      }
    },
    A05_查詢_戰情分析列表(開始時間, 結束時間) {
      const 七天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      const 七天前年 = 七天前.getFullYear();
      const 七天前月 = 七天前.getMonth() + 1;
      const 七天前日 = 七天前.getDate();
      const 七天前時間 = `${七天前年}${
        七天前月 < 10 ? "0" + 七天前月 : 七天前月
      }${七天前日 < 10 ? "0" + 七天前日 : 七天前日}`;
      const 七天前時間字串模式 = `${七天前年}-${七天前月}-${七天前日}`;
      const 當前年 = new Date().getFullYear();
      const 當前月 = new Date().getMonth() + 1;
      const 當前日 = new Date().getDate();
      const 當前時間 = `${當前年}${當前月 < 10 ? "0" + 當前月 : 當前月}${
        當前日 < 10 ? "0" + 當前日 : 當前日
      }`;
      const 當前時間字串模式 = `${當前年}-${當前月}-${當前日}`;
      localStorage.setItem(
        "戰情分析USDT兌換TWD時間起",
        開始時間 == undefined ? 七天前時間 : 開始時間
      );
      localStorage.setItem(
        "戰情分析USDT兌換TWD時間迄",
        結束時間 == undefined ? 當前時間 : 結束時間
      );
      localStorage.setItem(
        "戰情分析USDT兌換TWD時間起字串模式",
        開始時間 == undefined
          ? 七天前時間字串模式
          : `${開始時間.slice(0, 4)}-${開始時間.slice(4, 6)}-${開始時間.slice(
              6,
              8
            )}`
      );
      localStorage.setItem(
        "戰情分析USDT兌換TWD時間迄字串模式",
        結束時間 == undefined
          ? 當前時間字串模式
          : `${結束時間.slice(0, 4)}-${結束時間.slice(4, 6)}-${結束時間.slice(
              6,
              8
            )}`
      );
      this.$store
        .dispatch("apiMoneyOut/A05_查詢_戰情分析列表", {
          時間起: 開始時間 == undefined ? 七天前時間 : 開始時間,
          時間迄: 結束時間 == undefined ? 當前時間 : 結束時間,
        })
        .then((res) => {
          console.log(res);
          this.USDTMOCKDATA = [];
          this.USDT = 0;
          this.右邊換算 = 0;
          if (res.data) {
            const 總金額及換算結果 = Object.keys(res.data)[0];
            const 列表 = Object.values(res.data)[0];
            if (總金額及換算結果) {
              this.USDT = JSON.parse(總金額及換算結果).USDT;
              this.右邊換算 = JSON.parse(總金額及換算結果).TWD;
              console.log(JSON.parse(總金額及換算結果));
            }
            if (列表) {
              JSON.parse(列表).forEach((i, idx) => {
                this.USDTMOCKDATA[idx] = {
                  date: i.入帳日期,
                  people: i.人數,
                  rate: i.平均匯率,
                  amount: i.數量,
                };
              });
              this.重新渲染2++;
            }
          }
        });
    },
    USDTtoTWD() {
      this.$store.dispatch("apiAdmin/GetRateUSDTtoTWD").then((res) => {
        console.log("USDT->TWD", res);
      });
    },
    TWDtoUSDT() {
      this.$store.dispatch("apiAdmin/GetRateTWDtoUSDT").then((res) => {
        console.log("TWD->USDT", res);
      });
    },
    CoinRecord() {
      if (this.baseCurrency == "USDT") {
        if (this.currentTab == "buying") {
          //TWD to USDT
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateTWDRecord"]
          );
        } else {
          //USDT to TWD
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateUSDTRecord"]
          );
        }
      } else if (this.baseCurrency == "TWD") {
        if (this.currentTab == "buying") {
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateUSDTRecord"]
          );
        } else {
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateTWDRecord"]
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.appSize {
  display: none;
}
@media screen and (max-width: 1024px) {
  .webSize {
    display: none;
  }
  .appSize {
    display: flex;
    justify-content: center;
  }
}
</style>