<template>
  <div>
    <DatePickerMoneyIn
      :key="重新渲染"
      :篩選種類="篩選種類"
      @valueUpdate="selectTime"
      v-model="selectedDate"
      class="px-2 border border-gray-100"
      Color="red"
    >
      <template #buttonName>{{ 目前選擇種類 }} ▼</template>
      <template #dropdownList
        ><ul
          class="py-1 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <li @click="篩選種類點選('全部')" class="hover:bg-[#cc232a]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >全部</a
            >
          </li>
          <li @click="篩選種類點選('申請中')" class="hover:bg-[#cc232a]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >申請中</a
            >
          </li>
          <li @click="篩選種類點選('審核中')" class="hover:bg-[#cc232a]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >審核中</a
            >
          </li>

          <li @click="篩選種類點選('審核失敗')" class="hover:bg-[#cc232a]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >審核失敗</a
            >
          </li>
          <li @click="篩選種類點選('完成交易')" class="hover:bg-[#cc232a]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >完成交易</a
            >
          </li>
        </ul></template
      >
    </DatePickerMoneyIn>
    <img
      v-if="$store.state.apiMoneyInPage.入金紀錄.length == 0"
      src="@/assets/img/icon-nomoneyIn.svg"
      alt="male icon"
      class="mx-auto mt-5"
    />
    <div
      v-else
      class="flex w-full mb-3"
      v-for="item in $store.state.apiMoneyInPage.入金紀錄"
      :key="item.fId"
    >
      <div
        class="px-4 py-3 border border-gray-300"
        style="border-radius: 0.7rem; width: 75%"
      >
        <div
          style="align-items: baseline"
          class="pl-2 flex justify-between items-center text-[#989898] text-sm"
        >
          <div style="width: 75%" class="flex flex-ac_1520 column_1520">
            <!-- 1 -->
            <div class="flex" style="padding-bottom: 10px">
              <template v-if="item.會員資料 !== ''">
                <img
                  :src="
                    'https://amlservice.qhr.app/api/' + item.會員資料[0].f大頭貼
                  "
                />
              </template>

              <div
                class="f16 flex-ac"
                style="color: #bbbbbb; padding: 0px 10px"
              >
                <template v-if="item.會員資料 !== ''">
                  {{ item.會員資料[0].f姓名 }}
                </template>
                <template v-else> - </template>
              </div>
            </div>
            <!-- 2 -->
            <div class="flex" style="padding-bottom: 10px">
              <div class="chip_green flex-jac f12" style="margin-right: 10px">
                <template v-if="item.會員資料 !== ''">
                  {{ item.會員資料[0].f身分 }}
                </template>
                <template v-else> - </template>
              </div>
              <template v-if="$route.path == '/moneyin' && item.團隊 !== ''">
                <div
                  v-for="team in item.團隊"
                  :key="team.fId"
                  class="chip_grey flex-jac f12 mr-2"
                  style="margin-right: 10px"
                >
                  {{ team.f團隊名稱 }}
                </div>
              </template>
              <!-- <div class="flex-jac">
                <img src="@/assets/img/icon-detail.svg" alt="" />
              </div> -->
            </div>
          </div>
          <div class="flex">
            <img
              style="margin-right: 12px"
              src="@/assets/img/icon-detail.svg"
              alt="male icon"
              @click="pop('紀錄詳細', item)"
            />
            <div
              :style="{
                cursor:
                  statusText[`@${item.f狀態}`] !== '等待審核' ||
                  !上傳退款單(item).total ||
                  item.f匯款紀錄.TXID != undefined ||
                  item.f匯款紀錄.發票 != undefined
                    ? 'no-drop'
                    : 'pointer',
              }"
              class="aselect"
              :data-value="審核狀態選擇"
              :data-list="審核狀態下拉選單"
            >
              <div
                :class="statusColor[`@${item.f狀態}`]"
                class="selector"
                @click="
                  statusText[`@${item.f狀態}`] !== '等待審核' ||
                  !上傳退款單(item).total ||
                  item.f匯款紀錄.TXID != undefined ||
                  item.f匯款紀錄.發票 != undefined
                    ? null
                    : 審核狀態下拉toggle(item)
                "
              >
                <div class="label">
                  <span>{{ statusText[`@${item.f狀態}`] }}</span>
                </div>
                <div
                  class="arrow"
                  :class="{
                    expanded:
                      審核狀態下拉visible &&
                      入金列表當前點擊item.fId == item.fId,
                  }"
                ></div>
                <div
                  v-show="入金列表當前點擊item.fId == item.fId"
                  :class="{
                    hidden: !審核狀態下拉visible,
                    審核狀態下拉visible,
                  }"
                >
                  <ul>
                    <li
                      :class="{
                        current: item === 審核狀態選擇,
                      }"
                      v-for="item in 審核狀態下拉選單"
                      :key="item"
                      @click="審核狀態下拉(item)"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div
              class="text-white ml-2 py-1 px-3"
              :class="statusColor[`@${item.f狀態}`]"
              style="border-radius: 0.2rem"
            >
              {{ statusText[`@${item.f狀態}`] }}
            </div> -->
          </div>
        </div>
        <div class="pl-2" style="color: #989898">
          {{ item.f新增時間.split(".")[0].replace("T", " ") }}
        </div>
        <div class="pl-2 flex flex-col mt-3 text-[#989898]">
          <!-- <span>當日匯率 1USDT：{{ item.f結算匯率 }} TWD</span> -->
          <!-- <span class="text-2xl text-[#389568]"><span class="text-4xl">
            {{ item.f兌換金額 - item.f稅金費用 }} TWD
          </span>
            / {{ 無條件捨去保留小數點三位數(item.f結算金額, 3) }} USDT</span
          >
          <span class="font-bold text-[#d95b5b]"
            >匯款金額：{{ item.f兌換金額 }} TWD 含稅</span
          > -->

          <span class="text-2xl text-[#389568]"
            ><span class="text-4xl">
              {{ 無條件捨去保留小數點三位數(item.f結算金額, 3) }} USDT
            </span>
          </span>
        </div>
        <!-- <div
          class="mt-3 p-2 flex flex-col bg-[#f4f3f3] text-[#717171]"
          style="border-radius: 0.7rem"
        >
          <span
            >({{ item.f銀行紀錄.銀行代號 }}){{ item.f銀行紀錄.銀行名稱 }}
            {{ item.f銀行紀錄.分行名稱 }} {{ item.f銀行紀錄.銀行帳戶 }}</span
          >
        </div> -->
        <div
          v-show="item.f狀態 == -1 && item.f匯款紀錄.駁回原因 != undefined"
          class="p-2 flex flex-col text-[#717171]"
          style="border-radius: 0.7rem; color: #d95b5b; font-weight: 900"
        >
          原因: {{ item.f匯款紀錄.駁回原因 }}
        </div>
      </div>
      <div style="width: 25%" class="flex flex-col">
        <div class="flex justify-around appColumn">
          <div
            class="cursor-pointer flex flex-col justify-center items-center w-[45%] py-2"
            :class="
              !上傳退款單(item).total ||
              item.f匯款紀錄.TXID != undefined ||
              item.f匯款紀錄.發票 != undefined
                ? 'grayscale'
                : ''
            "
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
            @click="
              !上傳退款單(item).total ||
              item.f匯款紀錄.TXID != undefined ||
              item.f匯款紀錄.發票 != undefined
                ? null
                : 上傳退款單(item).look
                ? 顯示退款單(item.f匯款紀錄)
                : pop('上傳退款單', item)
            "
          >
            <img
              :style="{ width: selectEvent === '' ? '30%' : '50%' }"
              src="@/assets/img/icon-pay-bill.svg"
              alt="male icon"
            />
            <!-- {{ selectObj }} -->
            <span
              :class="{ f14: selectEvent !== '' }"
              v-if="!上傳退款單(item).look"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >上傳退款單</span
            >
            <span
              :class="{ f14: selectEvent !== '' }"
              v-else
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >查看退款單</span
            >
          </div>
          <div
            class="cursor-pointer flex flex-col justify-center items-center w-[45%] py-2"
            :class="
              !查看匯款圖(item).total ||
              item.f匯款紀錄.匯款圖 == '' ||
              item.f狀態 == -2
                ? 'grayscale'
                : ''
            "
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
            @click="
              !查看匯款圖(item).total ||
              item.f匯款紀錄.匯款圖 == '' ||
              item.f狀態 == -2
                ? null
                : 顯示匯款圖(item.f匯款紀錄)
            "
          >
            <img
              :style="{ width: selectEvent === '' ? '30%' : '50%' }"
              src="@/assets/img/icon-pay-image.svg"
              alt="male icon"
            />
            <span
              :class="{ f14: selectEvent !== '' }"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >查看匯款圖</span
            >
          </div>
        </div>
        <div class="flex justify-around mt-3 appColumn">
          <div
            :class="item.f匯款紀錄.TXID == undefined ? 'grayscale' : ''"
            @click="前往BSC鏈按鈕(item)"
            class="flex flex-col justify-center items-center w-[45%] py-2 cursor-pointer"
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
          >
            <img
              :style="{ width: selectEvent === '' ? '30%' : '50%' }"
              src="@/assets/img/icon-pay-bill.svg"
              alt="male icon"
            />

            <span
              :class="{ f14: selectEvent !== '' }"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >前往BSC鏈</span
            >
          </div>

          <!-- <div
            class="flex flex-col justify-center items-center w-[45%] py-2"
            :class="!上傳TXID(item).total ? 'grayscale' : ''"
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
            @click="
              !上傳TXID(item).total
                ? null
                : 上傳TXID(item).look
                ? 顯示TXID(item)
                : pop('上傳TXID', item)
            "
          >
            <img
              style="width: 30%"
              src="@/assets/img/icon-pay-txid.svg"
              alt="male icon"
            />
            <span
              v-if="!上傳TXID(item).look"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >上傳TXID</span
            >
            <span
              v-else
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >查看TXID</span
            >
          </div> -->
          <div
            @click="查看匯款人按鈕(item)"
            class="cursor-pointer flex flex-col justify-center items-center w-[45%] py-2"
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
          >
            <img
              :style="{ width: selectEvent === '' ? '30%' : '50%' }"
              src="@/assets/img/icon-pay-image.svg"
              alt="male icon"
            />
            <span
              :class="{ f14: selectEvent !== '' }"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >查看匯款人</span
            >
          </div>
          <!-- <div
            class="cursor-pointer flex flex-col justify-center items-center w-[45%] py-2"
            :class="
              !上傳發票(item).total || item.f匯款紀錄.TXID == undefined
                ? 'grayscale'
                : ''
            "
            style="border: 2px solid #cc232a; border-radius: 0.5rem"
            @click="
              !上傳發票(item).total || item.f匯款紀錄.TXID == undefined
                ? null
                : 上傳發票(item).look
                ? 顯示發票(item.f匯款紀錄)
                : pop('上傳發票', item)
            "
          >
            <img
              style="width: 30%"
              src="@/assets/img/icon-pay-bill.svg"
              alt="male icon"
            />
            <span
              v-if="!上傳發票(item).look"
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >上傳發票</span
            >
            <span
              v-else
              class="pt-2 text-xl font-medium text-center text-[#cc232a]"
              >查看發票</span
            >
          </div> -->
        </div>
      </div>
    </div>
    <popcorn
      name="紀錄詳細"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">查看紀錄</span>
          <div
            class="mt-3 px-4 py-3 w-[100%] border border-gray-300"
            style="border-radius: 0.7rem"
          >
            <div
              class="pl-2 flex justify-between items-center text-[#989898] text-xs"
            >
              <span>{{
                select.f新增時間.split(".")[0].replace("T", " ")
              }}</span>
              <div class="flex">
                <div
                  class="text-white ml-2 py-1 px-3"
                  :class="statusColor[`@${select.f狀態}`]"
                  style="border-radius: 0.2rem"
                >
                  {{ statusText[`@${select.f狀態}`] }}
                </div>
              </div>
            </div>
            <div class="pl-2 flex flex-col mt-3 text-[#989898] text-sm">
              <span>當日匯率 1USDT：{{ select.f結算匯率 }} TWD</span
              ><span class="text-2xl text-[#389568]"
                ><span class="text-4xl"
                  >{{ select.f兌換金額 - select.f稅金費用 }} TWD</span
                >
                / {{ select.f結算金額 }} USDT</span
              >
              <span class="font-bold text-[#d95b5b]"
                >匯款金額：{{ select.f兌換金額 }} TWD 含稅</span
              >
            </div>
            <div
              class="mt-3 p-2 flex flex-col bg-[#f4f3f3] text-[#717171] text-sm"
              style="border-radius: 0.7rem"
            >
              <span
                >({{ select.f銀行紀錄.銀行代號 }}){{ select.f銀行紀錄.銀行名稱
                }}<br />
                {{ select.f銀行紀錄.分行名稱 }}<br />
                {{ select.f銀行紀錄.銀行帳戶 }}</span
              >
            </div>
          </div>
          <span
            class="text-[#959595] text-sm text-bolder flex items-center mt-5"
            ><span class="text-xl mr-1">修改紀錄</span>Amendment record</span
          >
          <span v-if="取得修改紀錄.length == 0" class="mt-5 text-lg"
            >暫無修改紀錄</span
          >
          <Table
            style="font-size: 14px"
            v-else
            class="mt-5"
            :rows="取得修改紀錄"
            :cols="查看修改紀錄"
            :disableStatus="false"
          >
          </Table>
        </template>
        <template #footer>
          <!-- <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳退款單_EVENT()"
          /> -->
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="上傳退款單"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">上傳退款單及駁回原因</span>
          <ImgUpload
            :readonly="false"
            v-model="退款單"
            @changeEvent="
              (value) => {
                退款單 = value;
              }
            "
          />
          <Input
            style="font-size: 16px; margin-top: 6px"
            outlined
            placeholder="請輸入駁回原因"
            hint="gray"
            v-model="入金駁回原因"
          />
        </template>
        <template #footer>
          <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳退款單_EVENT()"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="上傳發票"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">上傳發票</span>
          <ImgUpload
            :readonly="false"
            v-model="發票"
            @changeEvent="
              (value) => {
                發票 = value;
              }
            "
          />
        </template>
        <template #footer>
          <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳發票_EVENT()"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="上傳TXID"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">上傳TXID</span>
          <Input
            class="mt-5"
            placeholder="請填寫此筆訂單的TXID"
            filled
            hint="gray"
            text="gray"
            v-model="TXID"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
          <img src="@/assets/img/icon-txid.svg" alt="male icon" />
        </template>
        <template #footer>
          <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳TXID_EVENT()"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="查看TXID"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">查看TXID</span>
          <Input
            class="mt-5"
            filled
            hint="gray"
            text="gray"
            :readonly="true"
            v-model="select.f匯款紀錄.TXID"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
        </template>
        <template #footer>
          <!-- <Button
            label="上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳TXID_EVENT()"
          /> -->
        </template>
      </Card>
    </popcorn>
    <Album
      tid="1"
      :album="圖片列表"
      :show="ALBUM.index"
      @closed="ALBUM.index = ''"
      @change="
        (val) => {
          ALBUM.index = val;
        }
      "
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  Card,
  Album,
  Input,
  Button,
  ImgUpload,
  Table,
} from "@/components/Shared";
import DatePickerMoneyIn from "@/components/FormItems/DatePickerMoneyIn.vue";
import { moneyin } from "@/usecase/moneyinPage";
export default {
  name: "MoneyIn",
  inject: ["reload"],
  components: {
    DatePickerMoneyIn,
    Album,
    Card,
    Input,
    Button,
    ImgUpload,
    Table,
  },
  props: {
    fMid: {
      type: Number,
      default: 0,
    },
    目前選擇團隊: {
      type: String,
      default: "",
    },
    searchWords: {
      type: String,
      default: "",
    },
    目前選擇團隊fId: {
      type: Array,
      default: () => [],
    },
    selectEvent: {
      type: String,
      default: "",
    },
    搜尋帳號按鈕: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      篩選種類: false,
      selectedDate: [
        new Date(),
        new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000),
      ],
      會員資料: "",
      狀態判斷: {
        審核中: 91,
        申請中: 0,
        完成交易: 1,
        審核失敗: -1,
        全部: undefined,
      },
      查看修改紀錄: [
        {
          name: "info",
          label: "備忘內容",
          align: "left",
        },
        {
          name: "date",
          label: "修改時間",
          align: "center",
        },
        {
          name: "name",
          label: "修改人",
          align: "center",
        },
      ],
      圖片列表: {},
      ALBUM: {
        index: "",
      },
      select: {},
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      審核狀態選擇: "",
      審核狀態下拉選單: ["審核失敗", "入幣成功"],
      審核狀態下拉visible: false,
      入金列表當前點擊item: {},
      statusText: {
        "@91": "等待審核",
        "@0": "申請中",
        "@1": "入幣成功",
        "@-1": "審核失敗",
        "@-2": "超過匯款時間",
      },
      statusColor: {
        "@91": "sts-gray",
        "@0": "sts-gray",
        "@1": "sts-green",
        "@-1": "sts-red",
        "@-2": "sts-red",
      },
      TXID: "",
      退款單: null,
      入金駁回原因: "",
      發票: null,
      目前選擇種類: "全部",
      重新渲染: 0,
    };
  },
  watch: {
    目前選擇團隊fId(v) {
      moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
        時間起: localStorage.getItem("入金時間起"),
        時間迄: localStorage.getItem("入金時間迄"),
        成員列表: v,
        f狀態: this.狀態判斷[this.目前選擇種類],
      });
    },
    搜尋帳號按鈕(val) {
      if (this.searchWords == "") {
        return;
      }
      if (val === true) {
        this.目前選擇種類 = "全部";
        if (this.fMid != 0) {
          moneyin.時間起迄查詢會員入金紀錄({
            時間起: "2023-1-1",
            時間迄: "2050-12-31",
            SearchVal: this.fMid,
          });
        } else {
          moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
            時間起: localStorage.getItem("入金時間起"),
            時間迄: localStorage.getItem("入金時間迄"),
            成員列表: this.目前選擇團隊fId,
            f狀態: this.狀態判斷[this.目前選擇種類],
          });
          // moneyin.時間起迄查詢入金紀錄({
          //   時間起: localStorage.getItem("入金時間起"),
          //   時間迄: localStorage.getItem("入金時間迄"),
          //   SearchVal: "null",
          //   FuzzyVal: this.searchWords,
          // });
        }
      } else {
        this.目前選擇種類 = "全部";
        if (this.fMid != 0) {
          moneyin.時間起迄查詢會員入金紀錄({
            時間起: "2023-1-1",
            時間迄: "2050-12-31",
            SearchVal: this.fMid,
          });
        } else {
          moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
            時間起: localStorage.getItem("入金時間起"),
            時間迄: localStorage.getItem("入金時間迄"),
            成員列表: this.目前選擇團隊fId,
            f狀態: this.狀態判斷[this.目前選擇種類],
          });
          // moneyin.時間起迄查詢入金紀錄({
          //   時間起: localStorage.getItem("入金時間起"),
          //   時間迄: localStorage.getItem("入金時間迄"),
          //   SearchVal: "null",
          //   FuzzyVal: "null",
          // });
        }
      }
      console.log(val);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.重新渲染++;
      this.時間起迄查詢會員入金紀錄();
    });
  },
  computed: {
    取得修改紀錄: function () {
      let obj = [];
      Object.keys(this.select.f異動紀錄).map((key) => {
        console.log(parseInt(key.replace("_", "")));
        const date = dayjs.unix(parseInt(key.replace("_", "")));
        obj.push({
          info: "更新" + this.select.f異動紀錄[key].info,
          date: date.format("YYYY.MM.DD"),
          name: this.select.f異動紀錄[key].name,
        });
        if (this.select.f匯款紀錄.駁回原因 != undefined) {
          obj[obj.length] = {
            info: "駁回原因:",
            date: this.select.f匯款紀錄.駁回原因,
            name: "",
          };
        }
      });
      return obj;
    },
    團隊管理列表() {
      return this.$store.state.apiMoneyInPage.團隊列表;
    },
  },
  methods: {
    查看匯款人按鈕(item) {
      this.會員資料 = item.會員資料 != "" ? item.會員資料[0] : "";
      this.$emit("memberData", item.會員資料 != "" ? item.會員資料[0] : "");
    },
    前往BSC鏈按鈕(item) {
      if (item.f匯款紀錄.TXID != undefined) {
        window.open(`https://bscscan.com/tx/${item.f匯款紀錄.TXID}`, "_blank");
      }
    },
    審核狀態下拉toggle(item) {
      this.審核狀態選擇 = "";
      this.入金列表當前點擊item = item;
      this.審核狀態下拉visible = !this.審核狀態下拉visible;
    },
    審核狀態下拉(option) {
      this.審核狀態選擇 = option;
      const statusText = {
        入幣成功: 1,
        審核失敗: -1,
      };
      console.log("上傳TXID", statusText[this.審核狀態選擇]);
      if (statusText[this.審核狀態選擇] == 1) {
        this.pop("上傳TXID", this.入金列表當前點擊item);
      } else {
        this.pop("上傳退款單", this.入金列表當前點擊item);
      }
      // if (this.審核狀態選擇 !== "") {
      //   this.入金列表當前點擊item.f狀態 = statusText[this.審核狀態選擇];
      // }
      console.log(this.審核狀態選擇);
    },
    篩選種類點選(v) {
      console.log("bbbbb");
      // this.reload()
      let n = 0;
      if (v == "審核中") {
        n = 91;
      } else if (v == "申請中") {
        n = 0;
      } else if (v == "完成交易") {
        n = 1;
      } else if (v == "審核失敗") {
        n = -1;
      } else if (v == "全部") {
        n = undefined;
      }

      this.重新渲染++;
      this.目前選擇種類 = v;
      if (this.fMid != 0) {
        moneyin.時間起迄查詢會員入金紀錄({
          時間起: localStorage.getItem("入金時間起"),
          時間迄: localStorage.getItem("入金時間迄"),
          SearchVal: this.fMid,
        });
      } else {
        moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
          時間起: localStorage.getItem("入金時間起"),
          時間迄: localStorage.getItem("入金時間迄"),
          成員列表: this.目前選擇團隊fId,
          f狀態: n,
        });
        // moneyin.時間起迄查詢入金紀錄({
        //   時間起: localStorage.getItem("入金時間起"),
        //   時間迄: localStorage.getItem("入金時間迄"),
        //   SearchVal: n,
        //   FuzzyVal: "null",
        // });
      }
    },
    // "@91": "審核中",
    //     "@0": "申請中",
    //     "@1": "完成交易",
    //     "@-1": "審核失敗",
    selectTime(val) {
      console.log("MoneyInPage", val);
      if (typeof val.end == "object") {
        const 開始 = val.start;
        const 開始年 = 開始.getFullYear();
        const 開始月 = 開始.getMonth() + 1;
        const 開始日 = 開始.getDate();
        const 開始時間 = `${開始年}-${開始月 < 10 ? "0" + 開始月 : 開始月}-${
          開始日 < 10 ? "0" + 開始日 : 開始日
        }`;
        const 結束 = val.end;
        const 結束年 = 結束.getFullYear();
        const 結束月 = 結束.getMonth() + 1;
        const 結束日 = 結束.getDate();
        const 結束時間 = `${結束年}-${結束月 < 10 ? "0" + 結束月 : 結束月}-${
          結束日 < 10 ? "0" + 結束日 : 結束日
        }`;
        console.log("當前年", typeof 開始月);
        localStorage.setItem("入金時間起", 開始時間);
        localStorage.setItem("入金時間迄", 結束時間);
        if (this.fMid != 0) {
          moneyin.時間起迄查詢會員入金紀錄({
            時間起: 開始時間,
            時間迄: 結束時間,
            SearchVal: this.fMid,
          });
        } else {
          moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
            時間起: localStorage.getItem("入金時間起"),
            時間迄: localStorage.getItem("入金時間迄"),
            成員列表: this.目前選擇團隊fId,
            f狀態: this.狀態判斷[this.目前選擇種類],
          });
          // moneyin.時間起迄查詢入金紀錄({
          //   時間起: 開始時間,
          //   時間迄: 結束時間,
          //   SearchVal: "null",
          //   FuzzyVal: "null",
          // });
        }
      }
    },
    無條件捨去保留小數點三位數(num, decimal) {
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
    時間起迄查詢會員入金紀錄() {
      const 三天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      const 三天前年 = 三天前.getFullYear();
      const 三天前月 = 三天前.getMonth() + 1;
      const 三天前日 = 三天前.getDate();
      const 三天前時間 = `${三天前年}-${
        三天前月 < 10 ? "0" + 三天前月 : 三天前月
      }-${三天前日 < 10 ? "0" + 三天前日 : 三天前日}`;

      const 當前年 = new Date().getFullYear();
      const 當前月 = new Date().getMonth() + 1;
      const 當前日 = new Date().getDate();
      const 當前時間 = `${當前年}-${當前月 < 10 ? "0" + 當前月 : 當前月}-${
        當前日 < 10 ? "0" + 當前日 : 當前日
      }`;
      localStorage.setItem("入金時間起", 三天前時間);
      localStorage.setItem("入金時間迄", 當前時間);
      if (this.fMid != 0) {
        moneyin.時間起迄查詢會員入金紀錄({
          時間起: 三天前時間,
          時間迄: 當前時間,
          SearchVal: this.fMid,
        });
      } else {
        moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
          時間起: localStorage.getItem("入金時間起"),
          時間迄: localStorage.getItem("入金時間迄"),
          成員列表: this.目前選擇團隊fId,
          f狀態: this.狀態判斷[this.目前選擇種類],
        });
        // moneyin.時間起迄查詢入金紀錄({
        //   時間起: 三天前時間,
        //   時間迄: 當前時間,
        //   SearchVal: "null",
        //   FuzzyVal: "null",
        // });
      }
    },
    successEvent() {},
    pop: function (cron, item) {
      this.$modal.show(cron);
      this.select = item;
    },
    顯示匯款圖: function (src) {
      this.圖片列表 = src;
      this.ALBUM.index = "匯款圖";
    },
    顯示退款單: function (src) {
      this.圖片列表 = src;
      this.ALBUM.index = "退款單";
    },
    顯示TXID: function (item) {
      this.select = item;
      this.$modal.show("查看TXID");
    },
    顯示發票: function (src) {
      this.圖片列表 = src;
      this.ALBUM.index = "發票";
    },
    查看匯款圖: function (item) {
      const enable = item.f狀態 != 0;
      const look = Object.prototype.hasOwnProperty.call(
        item.f匯款紀錄,
        "匯款圖"
      );
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳退款單: function (item) {
      const enable = item.f狀態 == 91;
      const look = Object.prototype.hasOwnProperty.call(
        item.f匯款紀錄,
        "退款單"
      );
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳TXID: function (item) {
      const enable = item.f狀態 == 91;
      const look = Object.prototype.hasOwnProperty.call(item.f匯款紀錄, "TXID");
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳發票: function (item) {
      const enable = item.f狀態 == 91;
      const look = Object.prototype.hasOwnProperty.call(item.f匯款紀錄, "發票");
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳TXID_EVENT() {
      // console.log(this.select, this.退款單);
      moneyin.上傳TXID(this.select.fId, this.TXID).then((res) => {
        this.$notify.success(res.message);
        // this.時間起迄查詢會員入金紀錄();
        if (this.fMid != 0) {
          moneyin.時間起迄查詢會員入金紀錄({
            時間起: "2023-1-1",
            時間迄: "2050-12-31",
            SearchVal: this.fMid,
          });
        } else {
          moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
            時間起: localStorage.getItem("入金時間起"),
            時間迄: localStorage.getItem("入金時間迄"),
            成員列表: this.目前選擇團隊fId,
            f狀態: this.狀態判斷[this.目前選擇種類],
          });
          // moneyin.時間起迄查詢入金紀錄({
          //   時間起: localStorage.getItem("入金時間起"),
          //   時間迄: localStorage.getItem("入金時間迄"),
          //   SearchVal: "null",
          //   FuzzyVal: "null",
          // });
        }
        this.$modal.hide("上傳TXID");
      });
    },
    上傳退款單_EVENT() {
      if (this.入金駁回原因 == "") {
        this.$notify.warning("請輸入駁回原因");
        return;
      }
      if (this.退款單 == null) {
        this.$notify.warning("請上傳退款單");
        return;
      }
      // 入金駁回原因
      // console.log(this.select, this.退款單);
      moneyin
        .上傳退款單(this.select.fId, this.退款單, this.入金駁回原因)
        .then((res) => {
          this.$notify.success(res.message);
          // this.時間起迄查詢會員入金紀錄();
          if (this.fMid != 0) {
            moneyin.時間起迄查詢會員入金紀錄({
              時間起: "2023-1-1",
              時間迄: "2050-12-31",
              SearchVal: this.fMid,
            });
          } else {
            moneyin.A05_查詢_入金紀錄_團隊_全搜尋({
              時間起: localStorage.getItem("入金時間起"),
              時間迄: localStorage.getItem("入金時間迄"),
              成員列表: this.目前選擇團隊fId,
              f狀態: this.狀態判斷[this.目前選擇種類],
            });
            // moneyin.時間起迄查詢入金紀錄({
            //   時間起: localStorage.getItem("入金時間起"),
            //   時間迄: localStorage.getItem("入金時間迄"),
            //   SearchVal: "null",
            //   FuzzyVal: "null",
            // });
          }
          this.$modal.hide("上傳退款單");
        });
    },
    上傳發票_EVENT() {
      if (this.發票 == null) {
        this.$notify.warning("請選擇發票圖檔");
        return;
      }
      moneyin.上傳發票(this.select.fId, this.發票).then((res) => {
        this.$notify.success(res.message);
        // this.時間起迄查詢會員入金紀錄();
        if (this.fMid != 0) {
          moneyin.時間起迄查詢會員入金紀錄({
            時間起: "2023-1-1",
            時間迄: "2050-12-31",
            SearchVal: this.fMid,
          });
        } else {
          moneyin.時間起迄查詢入金紀錄({
            時間起: localStorage.getItem("入金時間起"),
            時間迄: localStorage.getItem("入金時間迄"),
            SearchVal: "null",
            FuzzyVal: "null",
          });
        }
        this.發票 = null;
        this.$modal.hide("上傳發票");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.f14 {
  font-size: 14px;
}
.aselect {
  min-width: 130px;
  z-index: 0;

  @media screen and (max-width: 1024px) {
    min-width: 70px;
  }
  .selector {
    border-radius: 6px;
    border: 1px solid gainsboro;
    // background: rgba(206, 0, 0, var(--tw-bg-opacity));
    position: relative;
    z-index: 1;
    // @media screen and (max-width: 1024px) {
    //   background: #fff;
    //   font-weight: 900;
    //   border: none;
    // }
    .arrow {
      position: absolute;
      right: 10px;
      top: 40%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid #fff;
      transform: rotateZ(0deg) translateY(0px);
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
      @media screen and (max-width: 1024px) {
        // border-top: 10px solid red;
        right: 2px;
      }
    }
    .expanded {
      transform: rotateZ(180deg) translateY(2px);
    }
    .label {
      display: block;
      padding: 6px 10px;
      font-size: 16px;
      color: #ffffff;
      @media screen and (max-width: 1024px) {
        // color: red;
        font-size: 11px;
        padding: 4px 8px;
        > span {
          display: flex;
          width: 100%;
        }
      }
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border: 1px solid gainsboro;
    position: absolute;
    z-index: 1;
    background: #fff;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      text-align: left;
    }
  }
  li {
    padding: 12px;
    color: #666;
    &:hover {
      color: white;
      background: seagreen;
    }
  }
  .current {
    background: #eaeaea;
  }
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
}
</style>
<style scoped>
.color-gray {
  background-color: #686868;
}
.color-red {
  background-color: #d95b5b;
}
.color-gray {
  background-color: #389568;
}

.grayscale {
  -webkit-filter: grayscale(0.5) opacity(0.2);
}

.sts-gray {
  background-color: #686868;
}
.sts-green {
  background-color: #389568;
}

.sts-red {
  background-color: #d95b5b;
}
.chip_green {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #71b57c;
  color: #ffffff;
}
.chip_grey {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #5d5d5d;
  color: #ffffff;
}
@media screen and (max-width: 800px) {
  .chip_green,
  .chip_grey {
    padding: 5px 2px;
  }
  .appColumn {
    flex-direction: column;
    margin-top: 2px;
  }
  .appColumn > div {
    width: 100%;
    margin-top: 2px;
    height: 56px;
  }
  .appColumn span {
    font-size: 12px !important;
  }
  .appColumn img {
    width: 30% !important;
  }
}
@media screen and (max-width: 599px) {
  .text-xl {
    font-size: 16px !important;
  }
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
