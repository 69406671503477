<template>
  <div
    class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
    <div class="flex flex-col lg:flex-row justify-around">
      <div class="lg:flex-1 lg:w-48">
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Mobile Banner Settings</strong>
            </h2>
            <h2 class="subtitle">
              <strong>手機版 Banner設置</strong>
            </h2>
          </div>
        </div>
        <div class="pl-[5%] text-lg block lg:hidden lg:flex-grow text-gray-300">
          <span>手機版 Banner設置</span>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar">
          <FormUpload v-model="mobileBanners" />
          <div class="w-[240px] mt-3 mx-auto text-sm">
            <Button
              label="確認保存"
              color="green"
              text="white"
              size="popup-md"
              @click="DelGenAdmin()"
            />
          </div>
        </div>
      </div>
      <div class="lg:flex-1 lg:w-48">
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Computer Version Banner Settings</strong>
            </h2>
            <h2 class="subtitle">
              <strong>電腦版 Banner設置</strong>
            </h2>
          </div>
        </div>

        <div class="pl-[5%] text-lg block lg:hidden lg:flex-grow text-gray-300">
          <span>電腦版 Banner設置</span>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar">
          <FormUpload v-model="comBanners" />
        </div>
        <div class="w-[240px] mt-3 mx-auto text-sm">
          <Button
            label="確認保存"
            color="green"
            text="white"
            size="popup-md"
            @click="DelGenAdmin()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatWalletAddress } from "@/utils";
import { FormUpload } from "../components/FormItems";
import { Button } from "@/components/Shared";

export default {
  name: "Advertise",
  components: {
    Button,
    FormUpload,
  },
  data() {
    return {
      selectEvent: "",
      selectObj: {},
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "apply_name",
          label: "姓名",
          align: "center",
        },
        { name: "account", label: "帳號", align: "center" },
        {
          name: "wallet_address",
          label: "地址",
          align: "center",
          format: (val) => formatWalletAddress(val),
        },
        { name: "edit", label: "編輯", align: "center" },
      ],

      searchWords: "",
      mobileBanners: [{ file: "", info: "", src: "" }],
      comBanners: [{ file: "", info: "", src: "" }],
    };
  },
  watch: {},
  methods: {
    successEvent() {
      this.api().RootGetAllAdminInfo();
    },
    toggleEvent(event, data = {}) {
      if (this.selectEvent == "") {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }
      console.log("結果", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
    api() {
      const self = this;
      const fn = {
        管理員切換鍊: (address) => {
          self.$modal.show("loading");
          self.$notify.info("等待操作中");
          self.$store
            .dispatch("ast/管理員切換鍊", address)
            .then((res) => {
              console.log("結果", res);
              self.$notify.success("修改成功");
              self.$modal.hide("loading");
              self.api().RootGetAllAdminInfo();
            })
            .catch((e) => {
              console.log("e", e);
              self.$modal.hide("loading");
            });
        },
        RootGetAllAdminInfo: () => {
          self.$store
            .dispatch(
              "apiAdmin/RootGetAllAdminInfo",
              self.$store.state.login.token
            )
            .then((res) => {
              let rows = [];
              let address = [];
              res.data.map((dt) => {
                let chain = JSON.parse(dt.fEmail);
                let obj = {
                  apply_name: dt.fName,
                  account: dt.fAccount,
                  wallet_address: chain.address,
                  id: dt.fId,
                  onChain: chain.onChain,
                  info: chain.info,
                };
                rows.push(obj);
                address.push(chain.address);
              });
              self.$store.commit("manager/setManager", rows); //先顯示
              self.$store.commit("ast/setAdminAddress", address);
              if (self.$store.state.ast.account != "") {
                self.$store
                  .dispatch(
                    "ast/取得管理員上鍊名單",
                    self.$store.state.ast.adminAddress
                  )
                  .then((res) => {
                    console.log("取得管理員上鍊名單結果", res);

                    for (let i = 0; i < rows.length; i++) {
                      rows[i].onChain = res[i];
                    }
                    self.$store.commit("manager/setManager", rows);
                    self.$store.commit("ast/setAdminStatus", res);
                  });
              }
            })
            .catch((e) => {
              console.log("錯誤", e);
              if (e.response.status == 403) {
                // alert("沒有權限");
                self.$notify.warning("沒有權限");
                self.$router.push({ path: "/level2-validation" });
              }
            });
        },
        DelGenAdmin: (manager) => {
          var yes = confirm("確定要刪除此管理員嗎");
          console.log("確定要刪除此管理員嗎", manager);
          if (!yes) {
            return;
          }
          let obj = { fId: manager.id };
          let formdata = this.$tools.ObjToForm(obj);
          self.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
            let data = res.data;
            if (data != 0) {
              // alert("刪除成功");
              self.$notify.success("刪除成功");
              self.api().RootGetAllAdminInfo();
            }
          });
        },
      };
      return fn;
    },
  },
  computed: {},
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    this.api().RootGetAllAdminInfo();
  },
};
</script>

<style>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
</style>
