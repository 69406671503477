<template>
  <div>
    <div class="hidden lg:block" v-if="selectEvent != ''">
      <div class="subgroup-red text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Remove Admin</strong>
        </h2>
        <h2 class="subtitle">
          <strong>刪除管理員</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="text-center pt-2 pb-[17px]">
          <h1 class="text-gray-300">請再次確認刪除用戶</h1>
          <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2>
        </div>

        <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
        <Button
          label="確認刪除"
          color="red"
          text="white"
          size="popup-md"
          @click="DelGenAdmin()"
        />
        <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
          申請人 &colon; {{ $store.state.login.account }}
        </p>
      </div>
    </div>
    <popcorn
      class="block lg:hidden"
      name="delManager"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="red" type="dialog">
        <template #header>
          <p>刪除管理者帳號</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="text-center pt-2 pb-[17px]">
              <h1 class="text-gray-300">請再次確認刪除用戶</h1>
              <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2>
            </div>
          </template>
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認刪除"
            color="red"
            text="white"
            size="popup-md"
            @click="DelGenAdmin()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "DelManager",
  components: { Card, Button },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    selectObj: {
      type: Object,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
    };
  },
  mounted() {
    this.$modal.show("delManager");
  },
  computed: {},
  methods: {
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("delManager");
    },
    DelGenAdmin() {
      let obj = { fId: this.selectObj.id };
      let formdata = this.$tools.ObjToForm(obj);
      this.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
        let data = res.data;
        if (data != 0) {
          // alert("刪除成功");
          this.$emit("successEvent");
          this.$notify.success("刪除成功");
          this.closeWindow();
        }
      });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
