export const tools = {
  ObjToForm(obj) {
    const form_data = new FormData();
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          form_data.append(key, obj[key][i]);
        }
      } else {
        form_data.append(key, obj[key]);
      }
    }
    return form_data;
  },
  checkAuth(object, target, dothing) {
    if (Object.prototype.hasOwnProperty.call(object, target)) {
      if (object[target].indexOf(dothing) != -1) {
        return true;
      }
    }
    return false;
  },
};
