<template>
  <div class="card" :class="[{ 'bg-[#EFEBEB]': type === 'confirm' }]">
    <section
      v-if="!iconhead"
      class="card_header"
      :class="[
        type === 'confirm' ? null : bgColor[color],

        'flex justify-center items-center',
        { 'h-[74px] text-2xl text-white px-[27px]': type === 'dialog' },
        {
          'text-[22px] text-[#6F6F6F] px-[27px] pt-[18px] pb-3':
            type === 'confirm',
        },
        {
          'pt-[12px] pl-4 pr-[22px] pb-[8px] flex-col space-y-[6px]':
            type === 'form',
        },
      ]"
    >
      <!-- 'mb-auto', -->
      <slot name="header">
        <p>{{ title }}</p>
      </slot>

      <slot name="close"></slot>
    </section>
    <section v-else class="card_header">
      <!-- 'mb-auto', -->
      <slot name="header">
        {{ title }}
      </slot>
    </section>
    <section
      class="card_body"
      :class="[
        {
          'w-full px-[22px] pt-5 pb-[18px] text-center text-2xl':
            type === 'dialog',
        },
      ]"
    >
      <slot name="body" />
    </section>
    <section
      class="card_footer"
      :class="[
        {
          'rounded-b-popup px-[22px] pb-[22px]': type === 'dialog',
        },
        {
          'rounded-b-popup text-lg text-[#6E6E6E]': type === 'confirm',
        },
      ]"
    >
      <slot name="footer" />
    </section>
  </div>
</template>

<script>
import { bgColor } from "./utils";
export default {
  name: "Card",
  props: {
    iconhead: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "red-100",
    },
    title: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      // dialog, form, confirm
      type: String,
      default: "confirm",
    },
  },
  data() {
    return {
      bgColor,
      fontSize: {
        dialog: "text-2xl",
      },
    };
  },
};
</script>
