const level2Application = [
  {
    id: 11,
    membership: 2,
    type: "personal",
    date: "2022/1/1",
    status: "failed",
    failed_reason: [
      { label: "身分證驗證失敗", value: "ID Failed" },
      { label: "個人資料驗證失敗", value: "personal data failed" },
      { label: "銀行驗證失敗", value: "bank verification failed" },
    ],
    apply_name: "張桃桃",
    gender: "male",
    account: "0968222032",
    identity_number: "N123456789",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    birth: "1992-1-2",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 12,
    membership: 2,
    type: "personal",
    date: "2022/2/1",
    status: "unapproved",
    apply_name: "張桃",
    gender: "female",
    account: "0978232031",
    identity_number: "N123456788",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    birth: "1992-1-2",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 13,
    membership: 2,
    type: "company",
    date: "2022/3/1",
    status: "failed",
    failed_reason: [
      { label: "公司資料驗證失敗", value: "company data failed" },
    ],
    apply_name: "絕對宇宙有限公司",
    account: "0978222030",
    tax_number: "111111",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 14,
    membership: 2,
    type: "company",
    date: "2022/4/1",
    status: "unapproved",
    apply_name: "絕對宇宙有限公司",
    account: "0998222033",
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
];
const level3Application = [
  {
    id: 21,
    membership: 3,
    type: "personal",
    date: "2022/3/1",
    status: "unapproved",
    apply_name: "張桃桃",
    account: "0968222032",
    identity_number: "N123456789",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    birth: "1992-1-2",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "1234",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 22,
    membership: 3,
    type: "personal",
    date: "2022/1/1",
    status: "failed",
    failed_reason: [
      { label: "錢包中TWD數量不足", value: "TWD insufficient" },
      { label: "其他", value: "others" },
    ],
    apply_name: "張桃桃",
    identity_number: "N123456789",
    account: "0968222032",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    birth: "1992-1-2",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "1234",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 23,
    membership: 3,
    type: "company",
    date: "2022/2/1",
    status: "unapproved",
    apply_name: "絕對宇宙有限公司",
    account: "0968222032",
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
  {
    id: 24,
    membership: 3,
    type: "company",
    date: "2022/4/1",
    status: "unapproved",
    apply_name: "絕對宇宙有限公司",
    account: "0968222032",
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image3" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image4" },
    ],
  },
];

export { level2Application, level3Application };
