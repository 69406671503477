<template>
  <div class="w-full flex">
    <div
      v-for="tab in tabsInfo"
      :key="tab.value"
      @click="changeTab(tab.value)"
      :class="[
        'flex-1',
        'text-black',
        'py-[21px] text-center',
        'cursor-pointer',
        'relative before:transition-colors',
        value === tab.value ? currentTabColor : 'before:bg-gray-500',
        decorPseudoClass,
      ]"
    >
      <slot :name="tab.value">
        <p>{{ tab.label }}</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabsInfo: {
      type: Array,
      default: () => [
        { name: "tab1", label: "tab1" },
        { name: "tab2", label: "tab2" },
      ],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      decorPseudoClass:
        "before:absolute before:bottom-0 before:w-full before:block before:h-1 ",
    };
  },
  computed: {
    currentTabColor() {
      return this.value === this.tabsInfo[0].value
        ? "before:bg-red-100"
        : "before:bg-green-300";
    },
  },
  methods: {
    changeTab(tabValue) {
      this.$emit("input", tabValue);
    },
  },
};
</script>
