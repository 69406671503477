<template>
  <div class="field flex flex-col items-center">
    <div v-for="(item, idx) in value" class="w-[90%] lg:w-[80%]" :key="idx">
      <input
        :ref="'input_' + idx"
        type="file"
        class="invisible"
        @change="UplaodEnd($event, idx)"
      />
      <div class="flex justify-between">
        <p>Page{{ idx + 1 }}</p>
        <div class="flex">
          <p>尺寸為1520px 700px</p>
          <Icon
            v-if="idx == 0"
            dense
            @click="Clean(idx)"
            class="ml-3 w-[15px]"
            :img="require('@/assets/img/icon-clean.svg')"
          />
          <Icon
            v-else
            dense
            class="ml-3 w-[15px]"
            @click="Del(idx)"
            :img="require('@/assets/img/icon-trash.svg')"
          />
        </div>
      </div>
      <div
        class="mt-1 flex justify-center items-center w-full h-[28vh] border-dashed border border-gray-600"
        style="border-radius: 0.375rem"
      >
        <Icon
          v-if="item.src == ''"
          dense
          class="w-[27px] h-[23px]"
          @click="Upload(idx)"
          :img="require('@/assets/img/icon-plus.svg')"
        />
        <img class="h-[100%]" v-else :src="item.src" />
      </div>
      <FormInput
        type="text"
        v-model="value[idx].info"
        label=""
        placeholder="請填入圖片連結"
      />
    </div>
    <div class="flex py-5">
      <Icon
        dense
        class="mx-1 w-[36px]"
        @click="Sub"
        :img="require('@/assets/img/icon-circle-sub.svg')"
      />
      <Icon
        dense
        class="mx-1 w-[36px]"
        @click="Add"
        :img="require('@/assets/img/icon-circle-add.svg')"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from "@/components/Shared";
import { FormInput } from "@/components/FormItems";
export default {
  name: "FormUpload",
  components: {
    Icon,
    FormInput,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
    };
  },
  computed: {},
  methods: {
    Add() {
      this.value.push({ file: "", info: "", src: "" });
    },
    Sub() {
      if (this.value.length == 1) return;
      this.value.pop();
    },
    Del(idx) {
      this.value.splice(idx, 1);
    },
    Clean(idx) {
      this.value[idx].file = "";
      this.value[idx].src = "";
    },
    Upload(idx) {
      this.$refs[`input_${idx}`][0].click();
    },
    UplaodEnd(event, idx) {
      const img = event.target.files[0];
      // console.log(window.URL.createObjectURL(img), idx);
      this.value[idx].file = img;
      this.value[idx].src = window.URL.createObjectURL(img);
    },
  },
};
</script>
<style scoped>
.img-Input {
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: #9b9b9b;
  font-size: 1rem;
  top: 10px;
  left: 4px;
  position: absolute;
}
/* .form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  top: 20px;
} */

.form__label {
  position: absolute;
  top: 0;
  left: 4px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  left: 4px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  /* font-weight: 700; */
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* demo */
body {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.5rem;
  background-color: #222222;
}
</style>
