<template>
  <label
    :class="[
      'w-full flex flex-row items-center space-x-1',
      { 'px-[15px]': !dense },
      { border: outlined },
      { 'bg-gray-300': disabled },
    ]"
    @click="toggleSelection"
    v-click-outside="closeSelection"
  >
    <span class="text-gray-600">{{ label }}</span>
    <div class="flex flex-row flex-1 relative">
      <div class="flex-1">
        <p
          :class="[
            'flex-1',
            'text-gray-600',
            { 'py-[5px]': !dense },
            { 'cursor-pointer': !readonly && !disabled },
            { 'cursor-default ': readonly },
            { 'cursor-not-allowed ': disabled },
            { 'text-right': alignRight },
          ]"
        >
          {{ inputValue ? inputValue : placeholder }}
        </p>
      </div>
      <div
        v-if="!readonly & !disabled"
        class="flex w-4 justify-center ml-[7px]"
      >
        <Icon
          :dense="dense"
          :img="require('@/assets/img/icon-chevron.svg')"
          alt="選單icon"
        />
      </div>
      <ul
        v-show="openSelection"
        class="py-2 absolute max-h-[200px] overflow-y-auto top-full left-0 right-0 bg-white rounded-default shadow-select z-30 text-center"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          class="bg-white hover:bg-gray-100 py-[5px] cursor-pointer"
          @click="inputValue = option"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </label>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  name: "Select",
  components: { Icon },
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "請選擇",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSelection: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    clearContent() {
      if (this.readonly || this.disabled) {
        return;
      } else {
        this.selectValue = "";
      }
    },
    toggleSelection() {
      if (this.readonly || this.disabled) return;
      this.openSelection = !this.openSelection;
    },
    closeSelection() {
      this.openSelection = false;
    },
  },
};
</script>
