export const mockManagerData = [
  {
    apply_name: "管理者1",
    account: "111111",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 1,
    onChain: true,
  },
  {
    apply_name: "管理者2",
    account: "222222",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 2,
    onChain: false,
  },
  {
    apply_name: "管理者3",
    account: "333333",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 3,
    onChain: false,
  },
  {
    apply_name: "管理者4",
    account: "444444",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 4,
    onChain: true,
  },
  {
    apply_name: "管理者5",
    account: "555555",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 5,
    onChain: true,
  },
  {
    apply_name: "管理者6",
    account: "666666",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 6,
    onChain: true,
  },
  {
    apply_name: "管理者7",
    account: "777777",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 7,
    onChain: false,
  },
  {
    apply_name: "管理者8",
    account: "888888",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 8,
    onChain: false,
  },
  {
    apply_name: "管理者9",
    account: "999999",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 9,
    onChain: true,
  },
  {
    apply_name: "管理者10",
    account: "10101010",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 10,
    onChain: true,
  },
  {
    apply_name: "管理者11",
    account: "111111",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 11,
    onChain: true,
  },
  {
    apply_name: "管理者12",
    account: "12121212",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 12,
    onChain: false,
  },
  {
    apply_name: "管理者13",
    account: "1313131313",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 13,
    onChain: false,
  },
  {
    apply_name: "管理者14",
    account: "14141414",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 14,
    onChain: true,
  },
  {
    apply_name: "管理者15",
    account: "15151515",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 15,
    onChain: true,
  },
  {
    apply_name: "管理者16",
    account: "16161616",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 16,
    onChain: true,
  },
  {
    apply_name: "管理者17",
    account: "17171717",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 17,
    onChain: false,
  },
  {
    apply_name: "管理者18",
    account: "18181818",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 18,
    onChain: false,
  },
  {
    apply_name: "管理者19",
    account: "19191919",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 19,
    onChain: true,
  },
  {
    apply_name: "管理者20",
    account: "20202020",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    id: 20,
    onChain: true,
  },
];
