<template>
  <table
    class="w-full table-fixed text-gray-600 text-[13px] lg:text-[16px] font-normal"
  >
    <thead class="rounded-default">
      <tr class="lg:bg-white">
        <th
          v-for="(col, index) in cols"
          :key="index"
          :class="[
            'lg:h-[62px] p-1.5',
            'font-normal',
            'first:rounded-l-default',
            {
              'first:w-[7px] lg:first:w-[20px]': col.name === 'status',
            },
            'last:rounded-r-default',
          ]"
        >
          <template v-if="col.name !== 'status'">
            {{ col.label }}
          </template>
        </th>
      </tr>
    </thead>
    <div class="hidden lg:block lg:h-[8px]"></div>
    <tbody
      class="border-top-4 border-solid border-white even:bg-white odd:bg-[#FCFCFC] lg:odd:bg-white first:before:hidden before:block before:bg-gray-100 lg:before:h-[8px]"
      v-for="client in rows"
      :key="client.id"
      @click="emitRowData(client)"
    >
      <tr class="w-full">
        <td
          :style="{ 'overflow-x': cell.name !== 'edit' ? 'auto' : 'hidden' }"
          style="text-overflow: inherit"
          v-for="(cell, index) in cols"
          align="center"
          :key="index"
          :class="[
            'h-[37px] lg:h-[51px] px-1 lg:px-4 truncate',
            {
              [statusColor[client.status]]:
                cell.name === 'status' && !disableStatus,
            },
          ]"
        >
          <template v-if="cell.name !== 'status'">
            <template v-if="cell.format">
              {{ cell.format(client[cell.name]) }}
            </template>
            <template v-else>{{ client[cell.name] }}</template>
          </template>
          <slot :name="cell.name" v-bind="client" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table",
  props: {
    disableStatus: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Array,
      required: true,
      default: () => [
        {
          name: "status",
          label: "狀態",
          align: "center",
        },
      ],
    },
    rows: {
      type: Array,
      required: true,
    },
    statusColor: {
      type: Object,
      default: () => ({
        normal: "bg-green-300",
        unchain: "bg-orange-100",
        block: "bg-red-100",
        failed: "bg-red-100",
        unapproved: "bg-blue-200",
      }),
    },
  },
  data() {
    return {};
  },
  methods: {
    emitRowData(clientData) {
      this.$emit("getData", clientData);
    },
    formatWalletAddress(address) {
      return address.substring(0, 4) + "..." + address.slice(-6);
    },
  },
};
</script>

<style scoped lang="scss">
table tbody:first-of-type td:first-of-type {
  border-top-left-radius: 5px;
}
@media (min-width: 1040px) {
  table tbody td:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
</style>
