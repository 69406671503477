<template>
  <label
    :class="[
      'w-full',
      'flex items-center',
      'rounded-default',
      { 'bg-gray-400': filled },
      { 'border-solid border-2 border-gray-600': outlined },
      { 'bg-opacity-30': opacity },
      { 'px-[15px]': !dense },
      bgColor[color],
      readonly ? 'text-gray-600' : textColor[text],
    ]"
  >
    <span :class="[{ 'whitespace-nowrap': label }, 'text-gray-600']">
      {{ label }}
    </span>
    <slot name="front" />
    <div
      :class="[
        { 'py-[6px]': !dense },
        'content',
        alignRight ? 'ml-auto' : 'flex-1',
      ]"
    >
      <input
        v-model.trim="inputValue"
        :placeholder="placeholder"
        :type="inputType"
        :class="[
          'bg-transparent',
          'focus:outline-none',
          'w-full',
          { 'cursor-default': readonly },
          { 'cursor-not-allowed': disabled },
          { 'text-right': alignRight },
          placeholderColor[hint],
        ]"
        :readonly="readonly"
        :disabled="disabled"
        @focus="focus = true"
        @blur="focus = false"
      />
    </div>
    <slot name="end" />
    <div
      v-if="clearable && !readonly"
      @click="clearContent"
      class="cursor-pointer ml-3"
    >
      <Icon
        :dense="dense"
        :img="require('@/assets/img/icon-clear.svg')"
        alt="移除內容icon"
        class=""
      />
    </div>
    <Icon
      v-if="password"
      :icon="showPassword ? 'icon-showeye' : 'icon-eye'"
      @click="togglePassword"
    />
  </label>
</template>

<script>
import Icon from "./Icon.vue";
import { textColor, placeholderColor, bgColor } from "./utils";

export default {
  name: "Input",
  components: { Icon },
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "gray",
    },
    hint: {
      type: String,
      default: "gray",
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputType: this.type,
      showPassword: false,
      textColor,
      placeholderColor,
      bgColor,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        const vm = this;
        if (this.debounce) {
          setTimeout(() => {
            vm.$emit("input", value);
          }, vm.debounce);
        } else {
          vm.$emit("input", value);
        }
      },
    },
  },
  methods: {
    clearContent() {
      console.log("clear");
      this.inputValue = "";
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>
