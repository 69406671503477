var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row space-x-2 flex-shrink-0"},[_c('div',{class:[
      'w-[28px] h-[28px]  flex items-center justify-center',
      {
        'bg-white opacity-50 rounded-[2px] ': _vm.value === 'male',
      },
    ],on:{"click":function($event){return _vm.changeOption('male')}}},[_c('img',{attrs:{"src":require("@/assets/img/icon-male.png"),"alt":"male icon"}})]),_c('div',{class:[
      'w-[28px] h-[28px]  flex items-center justify-center',
      {
        'bg-white opacity-50 rounded-[2px] ': _vm.value === 'female',
      },
    ],on:{"click":function($event){return _vm.changeOption('female')}}},[_c('img',{attrs:{"src":require("@/assets/img/icon-female.png"),"alt":"female icon"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }