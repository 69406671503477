<template>
  <div>
    <div class="hidden lg:block" v-if="selectEvent != ''">
      <div class="subgroup-blue text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Add New Administrator</strong>
        </h2>
        <h2 class="subtitle">
          <strong>新增幣商</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="mb-5">
          <FormInput
            type="text"
            v-model="f廠商名稱"
            label="廠商名稱"
            placeholder="請設定廠商名稱"
          />
          <FormInput
            type="text"
            v-model="銀行代號"
            label="銀行代號"
            placeholder="請設定銀行代號"
          />
          <FormInput
            type="text"
            v-model="銀行名稱"
            label="銀行名稱"
            placeholder="請設定銀行名稱"
          />
          <FormInput
            type="text"
            v-model="分行名稱"
            label="分行名稱"
            placeholder="請設定分行名稱"
          />
          <FormInput
            type="text"
            v-model="f匯款帳號"
            label="匯款帳號"
            placeholder="請設定匯款帳號"
          />
          <FormInput
            type="text"
            v-model="f當日限額"
            label="當日限額"
            placeholder="請設定當日限額"
          />
       
        </div>
        <div class="form-btn" @click="firstConfirm">確認</div>
      </div>
    </div>
    <popcorn
      class="block lg:hidden"
      name="addManager"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>新增幣商</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請設定廠商名稱"
                hint="gray"
                v-model="f廠商名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定銀行代號"
                hint="gray"
                v-model="銀行代號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定銀行名稱"
                hint="gray"
                v-model="銀行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定分行名稱"
                hint="gray"
                v-model="分行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定匯款帳號"
                hint="gray"
                v-model="f匯款帳號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定當日限額"
                hint="gray"
                v-model="f當日限額"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="doubleCheck"
      :width="335"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #close>
          <!-- <Icon
            icon="icon-cross"
            class="absolute top-[5px] right-[5px] rounded-full w-[27px] h-[27px] bg-red-100 text-white"
            @click="$modal.hide('doubleCheck')"
          /> -->
        </template>
        <template #header>
          <p>請再次確認新增的幣商</p>
        </template>
        <template #body>
          <template>
            <div class="pt-2 pb-[17px]">
              <div style="width: fit-content;
                  text-align: initial;
                  margin: auto;">
               <p style="font-size: 16px;" class="h-[20px]">廠商名稱: <span style="font-weight: 900;">{{ f廠商名稱 }}</span></p>
              <p style="font-size: 16px;" class="h-[20px]">銀行代號: <span style="font-weight: 900;">{{ 銀行代號 }}</span></p>
              <p style="font-size: 16px;" class="h-[20px]">銀行名稱: <span style="font-weight: 900;">{{ 銀行名稱 }}</span></p>
              <p style="font-size: 16px;" class="h-[20px]">分行名稱: <span style="font-weight: 900;">{{ 分行名稱 }}</span></p>
              <p style="font-size: 16px;" class="h-[20px]">匯款帳號: <span style="font-weight: 900;">{{ f匯款帳號 }}</span></p>
              <p style="font-size: 16px;" class="h-[20px]">當日限額: <span style="font-weight: 900;">{{ f當日限額 }}</span></p>
            </div>
              </div>
            
          </template>
        </template>
        <template #footer>
          <!-- <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p> -->
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認新增"
            color="blue"
            text="white"
            size="popup-md"
            @click="confirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('doubleCheck')"
          />
        </template>
      </Card>
    </popcorn>

    <popcorn
      class="block lg:hidden"
      name="修改幣商"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>修改幣商</p>
        </template>
        <template #body>
  
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請編輯廠商名稱"
                hint="gray"
                v-model="f廠商名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請編輯銀行代號"
                hint="gray"
                v-model="銀行代號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請編輯銀行名稱"
                hint="gray"
                v-model="銀行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請編輯分行名稱"
                hint="gray"
                v-model="分行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請編輯匯款帳號"
                hint="gray"
                v-model="f匯款帳號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請編輯當日限額"
                hint="gray"
                v-model="f當日限額"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button, Input } from "@/components/Shared";
import { FormInput } from "@/components/FormItems";
export default {
  name: "AddCoinManager",
  components: { FormInput, Card, Button, Input },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    管理員查詢幣商_全搜尋處理:{
      type:Function,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      f廠商名稱:'',
      銀行代號:'',
      銀行名稱:'',
      分行名稱:'',
      f匯款帳號:'',
      f當日限額:'',
    };
  },
  watch: {},
  mounted() {
    this.$modal.show("addManager");
  },
  computed: {},
  methods: {
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("addManager");
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
      return re.test(email);
    },
    firstConfirm() {
    
      if (
        this.f廠商名稱 == "" ||
        this.銀行代號 == "" ||
        this.銀行名稱 == "" ||
        this.分行名稱 == "" ||
        this.f匯款帳號 == "" ||
        this.f當日限額 == "" 
      ) {
        // alert("輸入不完整");
        this.$notify.warning("輸入不完整");
        return;
      }

     
      // this.$emit("toggleEvent", "");
      this.$modal.hide("addManager");
      this.$modal.show("doubleCheck");
      // this.doubleConfirm = true;
    },
    confirm() {
      let obj = {
        f廠商名稱:this.f廠商名稱,
        f銀行資訊:JSON.stringify({
          銀行代號:this.銀行代號,
          銀行名稱:this.銀行名稱,
          分行名稱:this.分行名稱,
        }),
        f匯款帳號:this.f匯款帳號,
        f當日限額:this.f當日限額,
      };
      
      this.$store
        .dispatch("apiAdmin/管理員新增幣商", this.$tools.ObjToForm(obj))
        .then((res) => {
          if(res.message=='新增幣商成功。'){
            this.$notify.success("新增成功");
            this.$modal.hide("doubleCheck");
            this.管理員查詢幣商_全搜尋處理()
          }else{
            this.$notify.warning(res.message);
          }
            console.log(res);
        }).catch(err=>{
        if(err.response.data.errors.f當日限額!=undefined){
          this.$notify.warning("當日限額必需為數字");
          this.closeWindow()
          this.$modal.hide("doubleCheck");
        }else{
            this.$notify.warning("操作失敗，請聯絡程式人員");
            this.closeWindow()
          this.$modal.hide("doubleCheck")
          }
          console.log();
        });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
