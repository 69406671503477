<template>
  <nav class="bg-A25F54 text-white text-center text-lg">
    <!-- 手機版 -->
    <div class="lg:hidden px-[45px] py-[50px]">
      <p class="mb-3.5">
        <span>管理者帳號</span>
        <span>{{ $store.state.account }}</span>
      </p>
      <Button
        outline="white"
        text="white"
        label="連結錢包"
        size="wallet"
        class="text-sm"
      />
    </div>
    <!-- 桌機版 -->
    <h1
      style="align-items: end"
      class="hidden lg:flex lg:space-x-[20px] py-10 px-7"
    >
      <div class="flex-jc width100">
        <img style="width: 200px" alt="TWD logo" src="@/assets/img/Logo.svg" />
      </div>
      <!-- <div style="margin-left: 10px;" class="text-left w-[100px] flex items-center justify-center">
        <p class="text-2xl whitespace-nowrap mb-2">TWD SWAP</p>
        <p>後台管理系統</p>
        <img src="@/assets/img/Swap2.png" />
      </div> -->
    </h1>
    <!-- 連結們 -->
    <div
      class="links flex flex-col pb-[50px] lg:pb-[50px] text-right lg:text-left overflow-y-scroll no-scrollbar"
      @click="$store.commit('togglePopup', false)"
    >
      <router-link
        v-for="link in linkActive"
        :key="link.linkName"
        :to="{ name: link.pageName }"
        class="py-[14px] lg:py-[20px] px-[21px] lg:px-[50px] hover:bg-white hover:bg-opacity-20"
        >{{ link.linkName }}</router-link
      >
    </div>
    <div style="flex: 1"></div>
    <!-- 選擇國家 -->
    <div
      @click="showDialog"
      class="flex-row flex py-[14px] lg:py-[20px] px-[21px] lg:px-[50px] hover:bg-white hover:bg-opacity-20"
      style="justify-content: center"
    >
      <img src="@/assets/img/icon_國家.svg" alt="" />
      <div style="padding-top: 5px; padding-left: 6px">選擇國家</div>
    </div>

    <router-link :to="{ name: 'Login' }" class="w-fit mx-auto">
      <Button
        class="text-sm lg:hidden"
        outline="white"
        text="white"
        label="登出 AA"
        size="login"
      />
    </router-link>
  </nav>
  <!-- dialog -->
</template>

<script>
import { Button } from "@/components/Shared";

export default {
  name: "NavBar",
  components: {
    Button,
  },
  computed: {
    linkActive: function () {
      return this.linkList.filter((item) => this.checkAuth(item.checkName));
    },
  },
  data() {
    return {
      isDialogVisible: false,
      linkList: [
        { linkName: "權限管理名單", pageName: "Manager", checkName: "ALL" },
        {
          linkName: "二級驗證申請",
          pageName: "Level2",
          checkName: "二級驗證申請",
        },
        {
          linkName: "會員名單管理",
          pageName: "Membership",
          checkName: "會員名單管理",
        },
        // { linkName: "匯率管理", pageName: "Trading", checkName: "匯率管理" },
        { linkName: "入金管理", pageName: "MoneyIn", checkName: "入金管理" },
        // { linkName: "出金管理", pageName: "MoneyOut", checkName: "出金管理" },
        // { linkName: "戰情分析", pageName: "Analyze", checkName: "戰情分析" },
        // { linkName: "幣商管理", pageName: "CoinManager", checkName: "幣商" },
        { linkName: "團隊管理", pageName: "Team", checkName: "團隊管理" },
        { linkName: "公告中心", pageName: "News", checkName: "公告中心" },
      ],
    };
  },
  methods: {
    checkAuth(tar) {
      if (this.$store.state.apiAdmin.adminInfo.fAuthority == "ALL") return true;
      return this.$tools.checkAuth(
        this.$store.state.apiAdmin.adminInfo.fAuthority,
        tar,
        "READ"
      );
    },
    closeNav() {
      this.$store.commit("switchNav", false);
    },
    showDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
a.router-link-active {
  background: rgba(255, 255, 255, 0.2);
}

.fullscreen-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  /* 对话框内容样式，可以设置宽度、高度等 */
}
</style>
