var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-full table-fixed text-gray-600 text-[13px] lg:text-[16px] font-normal"},[_c('thead',{staticClass:"rounded-default"},[_c('tr',{staticClass:"lg:bg-white"},_vm._l((_vm.cols),function(col,index){return _c('th',{key:index,class:[
          'lg:h-[62px] p-1.5',
          'font-normal',
          'first:rounded-l-default',
          {
            'first:w-[7px] lg:first:w-[20px]': col.name === 'status',
          },
          'last:rounded-r-default',
        ]},[(col.name !== 'status')?[_vm._v(" "+_vm._s(col.label)+" ")]:_vm._e()],2)}),0)]),_c('div',{staticClass:"hidden lg:block lg:h-[8px]"}),_vm._l((_vm.rows),function(client){return _c('tbody',{key:client.id,staticClass:"border-top-4 border-solid border-white even:bg-white odd:bg-[#FCFCFC] lg:odd:bg-white first:before:hidden before:block before:bg-gray-100 lg:before:h-[8px]",on:{"click":function($event){return _vm.emitRowData(client)}}},[_c('tr',{staticClass:"w-full"},_vm._l((_vm.cols),function(cell,index){return _c('td',{key:index,class:[
          'h-[37px] lg:h-[51px] px-1 lg:px-4 truncate',
          {
            [_vm.statusColor[client.status]]:
              cell.name === 'status' && !_vm.disableStatus,
          },
        ],staticStyle:{"text-overflow":"inherit"},style:({ 'overflow-x': cell.name !== 'edit' ? 'auto' : 'hidden' }),attrs:{"align":"center"}},[(cell.name !== 'status')?[(cell.format)?[_vm._v(" "+_vm._s(cell.format(client[cell.name]))+" ")]:[_vm._v(_vm._s(client[cell.name]))]]:_vm._e(),_vm._t(cell.name,null,null,client)],2)}),0)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }