export { buttonSize };

const buttonSize = {
  login: "w-[63px] h-[25px] lg:w-[93px] lg:h-[30px]",
  "status-sm":
    "w-[78px] h-[25px] lg:w-[150px] lg:h-[35px] text-[13px] lg:text-lg",
  "status-md":
    "w-[88px] h-[25px] lg:w-[150px] lg:h-[35px] text-[13px] lg:text-lg",
  "popup-sm": "w-[104px] h-[29px] lg:w-[137px] h-[38px] text-sm lg:text-lg",
  "popup-md": "w-[100%] h-[43px] text-lg",
  full: "w-full py-2",
  wallet: "w-[68px] h-[20px] lg:w-[93px] lg:h-[30px]",
};
