import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueNotify from "vuejs-notify";
import { tools } from "@/assets/tools.js";
import { notify } from "vuejs-notify";
import "@/assets/fontIcon/style.css";
import "@/assets/tailwind/style.css";
import "@/assets/css/loader.css";
import "@/assets/css/kim_1124.scss";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VModal from "vue-js-modal";
import VCalendar from "v-calendar";
import { deepParseJson } from "deep-parse-json";
import dayjs from "dayjs";
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VModal, { componentName: "popcorn" });
// import "tailwindcss/tailwind.css";

Vue.config.productionTip = false;
Vue.component("Datepicker", VueDatepickerUi);
Vue.directive("click-outside", {
  bind: function (el, binding) {
    function clickHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el.__vueClickOutside__ = clickHandler;
    document.addEventListener("click", clickHandler);
  },
  update() { },
  unbind: function (el) {
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
});
console.log("顯示tools", tools);
Vue.prototype.$tools = tools;
Vue.prototype.$appsetting = {
  AppUrl: "https://amlservice.qhr.app",
  // AppUrl: "http://220.134.4.63:6382",
  // AppUrl: "https://localhost:6382",
};
Vue.use(VueNotify);
Vue.prototype.$notify = notify;
Vue.prototype.$deepParseJson = deepParseJson;
Vue.prototype.$dayjs = dayjs;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
