import { request } from "@/store/api";
import qs from "qs";
const objToForm = (obj) => {
  var form_data = new FormData();

  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        form_data.append(key, obj[key][i]);
      }
    } else {
      form_data.append(key, obj[key]);
    }
  }

  return form_data;
};
export const apiTeamManagement = {
  namespaced: true,
  state: { 畫面初始: 0, 團隊管理列表: [], 團隊入金紀錄列表: [], 搜尋團隊負責人會員列表: [], 搜尋團隊小幫手會員列表: [] },
  getters: {
    團隊管理列表(state) {
      return state.團隊管理列表
    },
    團隊入金紀錄列表(state) {
      return state.團隊入金紀錄列表
    },
    搜尋團隊負責人會員列表(state) {
      return state.搜尋團隊負責人會員列表
    },
    搜尋團隊小幫手會員列表(state) {
      return state.搜尋團隊小幫手會員列表
    },
    畫面初始(state) {
      return state.畫面初始
    },
  },
  mutations: {
    SET_團隊管理列表(state, 團隊管理列表) {
      console.log('團隊管理列表', 團隊管理列表);
      state.團隊管理列表 = 團隊管理列表.map(o => ({
        團隊名稱: o.f團隊名稱,
        團隊碼: o.f推薦碼 || '-',
        團隊人數: o.團隊成員 !== null ? o.團隊成員.length : 0,
        所有資訊: o,
      }));
    },
    SET_團隊入金紀錄(state, 團隊入金紀錄列表) {
      console.log('團隊入金紀錄列表', 團隊入金紀錄列表);
      state.團隊入金紀錄列表 = 團隊入金紀錄列表
    },
    SET_搜尋團隊負責人會員列表(state, 搜尋後會員列表) {
      console.log('搜尋後會員列表', 搜尋後會員列表);
      state.搜尋團隊負責人會員列表 = 搜尋後會員列表
    },
    SET_搜尋團隊小幫手會員列表(state, 搜尋後會員列表) {
      console.log('SET_搜尋團隊小幫手會員列表', 搜尋後會員列表);
      state.搜尋團隊小幫手會員列表 = 搜尋後會員列表
    },
    SET_畫面初始(state) {
      console.log('觸發畫面初始');
      state.畫面初始++
    }
  },
  actions: {
    GA04_查詢_t團隊_全搜尋(state, data) {
      return request
        .addAuth("Group")
        .get("/Group", { params: data });
    },
    A01_新增_t團隊(state, data) {
      return request
        .addFORMAuth("Group")
        .post("/Group", objToForm(data))
    },
    A02_修改_t團隊(state, data) {
      return request
        .addFORMAuth("Group")
        .put("/Group", objToForm(data))
    },
    A03_刪除_t團隊(state, data) {
      console.log('查看fId', data);
      return request
        .addFORMAuth("Group")
        .delete("/Group", { params: data })
    },
    A05_查詢_t會員_全搜尋(state, data) {
      return request
        .addFORMAuth("Member")
        .post("/GetAllMemInfo", objToForm(data))
    },
    A05_查詢_入金紀錄_團隊_全搜尋(state, data) {
      return request
        .addAuth("Deposit")
        .get("/DepositInfo/Admin/TimeWtihAll", {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        });
    },
    // A02_上傳匯款單_修改(state, data) {
    //   return request.addAuth("Withdraw").put("/WithdrawInfo/MoneyOrder", data);
    // },
    // A03_上傳退款TXID_修改(state, data) {
    //   return request.addAuth("Withdraw").put("/WithdrawInfo/TXID", data);
    // },
    // A04_查詢_出金紀錄(state, data) {
    //   return request
    //     .addAuth("Withdraw")
    //     .get("/WithdrawInfo/All/", { params: data });
    // },
    // A05_查詢_戰情分析列表(state, data) {
    //   return request
    //     .addAuth("Withdraw")
    //     .get("/WithdrawInfo/Admin/", { params: data });
    // },
  },
};
