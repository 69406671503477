<template>
  <div class="w-[100%]">
    <div class="overflow-x-scroll no-scrollbar flex space-x-2 w-full">
      <div
        v-if="!readonly"
        class="w-[100%] h-[350px] flex-shrink-0 border border-gray-600 border-dashed relative z-0"
      >
        <img
          class="absolute top-0 left-0 right-0 bottom-0 m-auto text-3xl text-gray-600"
          :src="show != '' ? show : require('@/assets/img/icon-pic.svg')"
          alt="male icon"
        />
        <input
          type="file"
          multiple="true"
          accept="image/*"
          @change="uploadFiles"
          class="w-full h-full opacity-0"
          :disabled="readonly"
        />
      </div>
      <!-- <div
        v-for="(img, index) in imageArray"
        :key="index"
        class="w-[114px] h-[76px] flex-shrink-0"
        @click="previewImg(index)"
      >
        <img
          :src="img.url"
          :alt="img.fileName"
          class="w-full h-full object-cover cursor-pointer"
        />
      </div> -->
    </div>
    <!-- <PopupWindow :value="showPreview">
      <template #body>
        <ImageCarousell
          v-if="showPreview"
          @closePreview="showPreview = false"
          :imageArray="imageArray"
          :defaultImageIndex="defaultImageIndex"
        />
      </template>
    </PopupWindow> -->
  </div>
</template>
<script>
// import ImageCarousell from "./ImageCarousell.vue";
export default {
  name: "ImageInputSingle",
  components: {},
  props: {
    value: {
      type: File,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreview: false,
      image: this.value,
      defaultImageIndex: 0,
      acceptableFileType: ["image/png"],
      show: "",
    };
  },
  methods: {
    async uploadFiles(files) {
      const fileList = files.target.files;
      if (fileList.length === 0) return;
      console.log("fileList", fileList[0], typeof fileList[0]);
      this.show = URL.createObjectURL(fileList[0]);
      this.$emit("changeEvent", fileList[0]);
    },
    previewImg(imgIndex) {
      this.defaultImageIndex = imgIndex;
      this.showPreview = true;
      this.$store.commit("mutateDoublePopup", true);
    },
  },
};
</script>
