export {
  textColor,
  placeholderColor,
  backgroundColor as bgColor,
  outlineColor,
  buttonColor,
};

const textColor = {
  white: "text-white",
  black: "text-black",
  gray: "text-gray-600",
  green: "text-green-400",
};

const placeholderColor = {
  white: "placeholder-white",
  black: "placeholder-black",
  gray: "placeholder-gray-600",
};

const backgroundColor = {
  white: "bg-white",
  black: "bg-black",
  gray: "bg-gray-600",
  red: "bg-red-100",
  green: "bg-green-300",
  orange: "bg-orange-100",
  "dark-blue": "bg-blue-300",
  "light-blue": "bg-blue-100",
  blue: "bg-blue-200",
  "dark-gary": "bg-gray-1000",
};

const outlineColor = {
  none: "transparent",
  green:
    "border border-green-300 text-green-300 hover:bg-green-300 hover:text-white focus:bg-green-300  focus:ring-green-300 focus:text-white",
  red: "border border-red-100 text-red-100 hover:bg-red-100 hover:text-white focus:bg-red-100 focus:ring-red-100 focus:text-white",
  white:
    "border border-white hover:bg-white hover:text-green-300 focus:bg-white focus:text-green-300",
};

const buttonColor = {
  green:
    "bg-green-300 hover:bg-green-300 focus:ring-green-300 focus:bg-green-300",
  red: "bg-red-100 hover:bg-red-100 focus:ring-red-100 focus:bg-red-100",
  orange:
    "bg-orange-100 hover:bg-orange-100 focus:ring-orange-100 focus:bg-orange-100",
  gray: "bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:ring-gray-500",
  "light-blue":
    "bg-blue-100 hover:bg-blue-100 focus:ring-blue-100 focus:bg-blue-100",
  blue: "bg-blue-200 hover:bg-blue-200 focus:ring-blue-200 focus:bg-blue-200",
  "dark-blue":
    "bg-blue-300 hover:bg-blue-300 focus:bg-blue-300 focus:ring-blue-300",
  "gradient-login": `bg-gradient-to-r from-white to-green-100  focus:ring-green-100`,
  "gradient-green": "bg-gradient-green",
  "gradient-red": "bg-gradient-red",
  "gradient-red-100": "bg-gradient-red-100",
};
