export const mockCurrencyList = ["BUSD", "BTC", "ETH", "BNB", "EOS"];

export const TWD_USDT_Data = [
  {
    date: "2022/01/01",
    tradingPair: "TWD/BUSD",
    exchangeRate: "28.5",
    editor: "admin",
  },
  {
    date: "2022/01/15",
    tradingPair: "TWD/BUSD",
    exchangeRate: "27.5",
    editor: "Root",
  },
  {
    date: "2022/02/01",
    tradingPair: "TWD/BUSD",
    exchangeRate: "26.5",
    editor: "Root",
  },
  {
    date: "2022/03/01",
    tradingPair: "TWD/BUSD",
    exchangeRate: "28.5",
    editor: "Root",
  },
  {
    date: "2022/41/01",
    tradingPair: "TWD/BUSD",
    exchangeRate: "29.5",
    editor: "Root",
  },
];
export const USDT_TWD_Data = [
  {
    date: "2022/01/01",
    tradingPair: "BUSD/TWD",
    exchangeRate: "28.5",
    editor: "Root",
  },
  {
    date: "2022/01/15",
    tradingPair: "BUSD/TWD",
    exchangeRate: "27.5",
    editor: "Root",
  },
  {
    date: "2022/02/01",
    tradingPair: "BUSD/TWD",
    exchangeRate: "26.5",
    editor: "Root",
  },
  {
    date: "2022/03/01",
    tradingPair: "BUSD/TWD",
    exchangeRate: "28.5",
    editor: "Root",
  },
  {
    date: "2022/41/01",
    tradingPair: "BUSD/TWD",
    exchangeRate: "29.5",
    editor: "Root",
  },
];
