import { level2 } from "./level2.js";
import { level3 } from "./level3.js";

export const validation = {
  namespaced: true,
  mutations: { ...level2, ...level3 },
  state: {
    level2ApplicationSTATIC: [],
    level3ApplicationSTATIC: [],
    level2Application: [],
    level3Application: [],
    level2Applier: {},
    level2FailedReason: [
      { label: "身分證驗證失敗", value: "ID Failed" },
      { label: "個人資料驗證失敗", value: "personal data failed" },
      { label: "銀行驗證失敗", value: "bank verification failed" },
      { label: "手持身份證照片驗證失敗", value: "photo failed" },
      { label: "公司資料驗證失敗", value: "company data failed" },
      { label: "資金來源照片驗證失敗", value: "money resource photo failed" },
      { label: "其他", value: "others" },
    ],
    level3FailedReason: [
      { label: "錢包中TWD數量不足", value: "TWD insufficient" },
      { label: "其他", value: "others" },
    ],
  },
  getters: {
    level2Application({ level2Application }) {
      return level2Application;
    },
    level3Application({ level3Application }) {
      return level3Application;
    },
    level2Applier({ level2Applier }) {
      return level2Applier;
    },
  },
};
