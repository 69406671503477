<template>
  <div class="h-full flex flex-col relative lg:static">
    <!-- tabs 切換區 -->
  
    <section class="block bg-white flex flex-row text-lg sizeHandle">
      <div
        class="w-[82px] lg:w-[108px] flex-shrink-0 relative before:bg-gray-700 before:absolute before:bottom-0 before:w-full before:block before:h-1 before:z-10"
      >
        <!-- <PairSelect
          :options="baseCurrencyOptions"
          color="gray"
          class=""
          v-model="baseCurrency"
        /> -->
      </div>
      <Tabs :tabsInfo="tabsInfo" v-model="currentTab">
        <template #buying>
          <p class="text-sm lg:text-2xl font-bold text-red-100">
            {{ buyingTab }}
          </p>
        </template>
        <template #selling>
          <p class="text-sm lg:text-2xl font-bold text-green-300">
            {{ sellingTab }}
          </p>
        </template>
      </Tabs>
    </section>
    <!-- 主要頁面 -->
    <div
      class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
    >
      <!-- 匯率下拉選單 -->
      <section v-if="false"
        class="block lg:hidden flex-none flex flex-row justify-end items-center lg:justify-between lg:space-x-4 mb-4 lg:mb-5"
      >
        <div class="hidden lg:block lg:w-[280px] mr-auto">
          <Input
            v-if="view === 'table'"
            placeholder="輸入帳號搜尋"
            filled
            hint="gray"
            text="gray"
            @update:value="searchValue = $event"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
        </div>
        <PairSelect
          v-if="currentTab === 'buying'"
          :options="buyingTradingPairSelection[baseCurrency]"
          color="red"
          class=""
          size="static"
          v-model="buyingTradingPair"
        />
        <PairSelect
          v-else
          :options="sellingTradingPairSelection[baseCurrency]"
          color="green"
          class=""
          size="static"
          v-model="sellingTradingPair"
        />
        <div class="absolute bottom-16 right-6 lg:static">
          <Icon
            v-if="view === 'table'"
            icon="icon-edit"
            round
            color="blue"
            size="md"
            class="w-[59px] lg:w-[34px] h-[59px] lg:h-[34px] text-[27px] lg:text-sm shadow-icon lg:shadow-none"
            @click="view = 'edit'"
          />
          <Icon
            v-else
            :img="require('@/assets/img/icon-trading.svg')"
            round
            color="dark-gary"
            size="md"
            class="w-[59px] lg:w-[34px] h-[59px] lg:h-[34px] text-[27px] lg:text-sm shadow-icon lg:shadow-none"
            @click="view = 'table'"
          />
        </div>
      </section>
      <!-- table、輸入匯率調整 -->
      <section class="flex-1 overflow-y-scroll no-scrollbar webSize">
        <!-- table -->
        <template v-if="view === 'table'">
          <div class="flex justify-around">
            <div class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Exchange Rate Management</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>匯率管理</strong>
                  </h2>
                </div>
         
                <div class="flex flex-col items-end pt-[45px]">
                  
                  <PairSelect
                    :區塊="'左側'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <!-- <PairSelect
                    :區塊="'左側'"
                    v-if="currentTab === 'buying'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <PairSelect
                    v-else
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  /> -->
                </div>
              </div>
              <EditRate
              :區塊="'左側'"
                :baseCurrency="buyingTradingPair.split('/')[0]"
                :quoteCurrency="baseCurrency"
                color="red"
              />
              <Table
              v-if="buyingTradingPair.split('/')[0]=='TWD'"
                class="mt-5"
                :cols="cols"
                :rows="$store.getters['apiAdmin/RateTWDRecord']"
              />
            </div>
            <div class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <div
                  class="subgroup-red text-2xl hidden lg:block lg:flex-grow invisible"
                >
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Exchange Rate Management</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>匯率管理</strong>
                  </h2>
                </div>
                <div class="flex flex-col items-end pt-[45px]">
               
               <PairSelect
                  :區塊="'右側'"
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  />
                  <!-- <PairSelect
                  :區塊="'右側'"
                    v-if="currentTab === 'buying'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <PairSelect
                    v-else
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  /> -->
                </div>
              </div>
              <EditRate
              :區塊="'右側'"
                :baseCurrency="baseCurrency"
                :quoteCurrency="sellingTradingPair.split('/')[1]"
                color="green"
              />
              <Table
              v-if="sellingTradingPair.split('/')[1]=='TWD'"
                class="mt-5"
                :cols="cols"
                :rows="$store.getters['apiAdmin/RateUSDTRecord']"
              />
            </div>
          </div>
        </template>
        <!-- 輸入匯率 -->
        <template v-else>
          <!-- buying-->

          <EditRate
            v-if="currentTab === 'buying'"
            :baseCurrency="buyingTradingPair.split('/')[0]"
            :quoteCurrency="baseCurrency"
            color="red"
          />
          <!-- selling -->
          <EditRate
            v-else
            :baseCurrency="baseCurrency"
            :quoteCurrency="sellingTradingPair.split('/')[1]"
            color="green"
          />
        </template>
      </section>
      <section class="flex-1 overflow-y-scroll no-scrollbar appSize">
        <!-- table -->
        <template v-if="view === 'table'">
          <div class="flex justify-around">
            <div v-if="currentTab=='buying'" class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <!-- <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Exchange Rate Management</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>匯率管理</strong>
                  </h2>
                </div> -->
         
                <div class="flex flex-col items-end">
                  
                  <PairSelect
                    :區塊="'左側'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <!-- <PairSelect
                    :區塊="'左側'"
                    v-if="currentTab === 'buying'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <PairSelect
                    v-else
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  /> -->
                </div>
              </div>
              <EditRate
              :區塊="'左側'"
                :baseCurrency="buyingTradingPair.split('/')[0]"
                :quoteCurrency="baseCurrency"
                color="red"
              />
              <Table
              v-if="buyingTradingPair.split('/')[0]=='TWD'"
                class="mt-5"
                :cols="cols"
                :rows="$store.getters['apiAdmin/RateTWDRecord']"
              />
            </div>
            <div v-else-if="currentTab=='selling'" class="flex-1 p-[10px]">
              <div
                class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
              >
                <!-- <div
                  class="subgroup-red text-2xl hidden lg:block lg:flex-grow invisible"
                >
                  <h2 class="sub mb-4 ml-[4px]">
                    <strong>Exchange Rate Management</strong>
                  </h2>
                  <h2 class="subtitle">
                    <strong>匯率管理</strong>
                  </h2>
                </div> -->
                <div class="flex flex-col items-end">
               
               <PairSelect
                  :區塊="'右側'"
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  />
                  <!-- <PairSelect
                  :區塊="'右側'"
                    v-if="currentTab === 'buying'"
                    :options="buyingTradingPairSelection[baseCurrency]"
                    color="red"
                    class=""
                    size="static"
                    v-model="buyingTradingPair"
                  />
                  <PairSelect
                    v-else
                    :options="sellingTradingPairSelection[baseCurrency]"
                    color="green"
                    class=""
                    size="static"
                    v-model="sellingTradingPair"
                  /> -->
                </div>
              </div>
              <EditRate
              :區塊="'右側'"
                :baseCurrency="baseCurrency"
                :quoteCurrency="sellingTradingPair.split('/')[1]"
                color="green"
              />
              <Table
              v-if="sellingTradingPair.split('/')[1]=='TWD'"
                class="mt-5"
                :cols="cols"
                :rows="$store.getters['apiAdmin/RateUSDTRecord']"
              />
            </div>
          </div>
        </template>
        <!-- 輸入匯率 -->
        <template v-else>
          <!-- buying-->

          <EditRate
            v-if="currentTab === 'buying'"
            :baseCurrency="buyingTradingPair.split('/')[0]"
            :quoteCurrency="baseCurrency"
            color="red"
          />
          <!-- selling -->
          <EditRate
            v-else
            :baseCurrency="baseCurrency"
            :quoteCurrency="sellingTradingPair.split('/')[1]"
            color="green"
          />
        </template>
      </section>
    </div>
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
  </div>
</template>

<script>
import { Tabs, Icon, Table, Input } from "@/components/Shared";
import { formatTwoDigitYear } from "@/utils";

import PairSelect from "@/components/Trading/PairSelect.vue";
import EditRate from "@/components/Trading/EditRate.vue";
export default {
  name: "Trading",
  components: {
    Tabs,
    Icon,
    Table,
    Input,
    PairSelect,
    EditRate,
  },
  data() {
    return {
      baseCurrencyOptions: ["USDT", "TWD"],
      currentTab: "buying",
      view: "table",
      buyingTradingPair: "TWD/USDT",
      sellingTradingPair: "USDT/TWD",
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "date",
          label: "日期",
          align: "center",
          format: (val) => formatTwoDigitYear(val),
        },
        {
          name: "tradingPair",
          label: "交易對",
          align: "center",
        },
        {
          name: "exchangeRate",
          label: "調整匯率",
          align: "center",
        },
        {
          name: "editor",
          label: "修改者",
          align: "center",
        },
      ],
      buyingTradingPairSelection: {
        USDT: ["TWD/USDT", "BTC/USDT", "ETH/USDT", "BNB/USDT", "EOS/USDT"],
        TWD: ["USDT/TWD", "BTC/TWD", "ETH/TWD", "BNB/TWD", "EOS/TWD"],
      },
      sellingTradingPairSelection: {
        TWD: ["TWD/USDT", "TWD/BTC", "TWD/ETH", "TWD/BNB", "TWD/EOS"],
        USDT: ["USDT/TWD", "USDT/BTC", "USDT/ETH", "USDT/BNB", "USDT/EOS"],
      },
      allCurrencyList: ["BUSD", "BTC", "ETH", "BNB", "EOS"],
    };
  },
  computed: {
    quoteCurrency() {
      return this.$store.state.trading.quoteCurrency;
    },
    baseCurrency: {
      get() {
        return this.$store.state.trading.baseCurrency;
      },
      set(currency) {
        this.$store.commit("trading/changeBaseCurrency", currency);
      },
    },
    tabsInfo() {
      return [
        {
          value: "buying",
          label: this.buyingTradingPair.replace("/", " 兌換 "),
        },
        {
          value: "selling",
          label: this.sellingTradingPair.replace("/", " 兌換 "),
        },
      ];
    },
    buyingTab() {
      return this.tabsInfo.filter((tab) => tab.value === "buying")[0].label;
    },
    sellingTab() {
      return this.tabsInfo.filter((tab) => tab.value === "selling")[0].label;
    },
  },
  methods: {
    USDTtoTWD() {
      this.$store.dispatch("apiAdmin/GetRateUSDTtoTWD").then((res) => {
        console.log("USDT->TWD", res);
      });
    },
    TWDtoUSDT() {
      this.$store.dispatch("apiAdmin/GetRateTWDtoUSDT").then((res) => {
        console.log("TWD->USDT", res);
      });
    },
    CoinRecord() {
      if (this.baseCurrency == "USDT") {
        if (this.currentTab == "buying") {
          //TWD to USDT
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateTWDRecord"]
          );
        } else {
          //USDT to TWD
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateUSDTRecord"]
          );
        }
      } else if (this.baseCurrency == "TWD") {
        if (this.currentTab == "buying") {
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateUSDTRecord"]
          );
        } else {
          this.$store.commit(
            "trading/setTradingHistoryData",
            this.$store.getters["apiAdmin/RateTWDRecord"]
          );
        }
      }
    },
  },
  watch: {
    baseCurrency(val) {
      if (val == "USDT") {
        this.buyingTradingPair = "TWD/USDT";
        this.sellingTradingPair = "USDT/TWD";
      } else if (val == "TWD") {
        this.buyingTradingPair = "USDT/TWD";
        this.sellingTradingPair = "TWD/USDT";
      }
      this.CoinRecord();
    },
    currentTab(direction) {
      console.log("direction", direction);
      this.CoinRecord();
    },
  },
  created() {
    this.CoinRecord();
  },
  mounted() {
    this.baseCurrency = 123;
    this.$store.commit("trading/changeBaseCurrency", "USDT");
    this.USDTtoTWD();
    this.TWDtoUSDT();
  },
};
</script>
<style scoped>
.sizeHandle,.appSize{
  display: none;
}
@media screen and (max-width:1300px){
  .sizeHandle{
    display: block;
  }
  .webSize{
    display: none;
  }
  .appSize{
    display: flex;
    justify-content: center;
  }
}
</style>