var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
    'w-full h-full',
    'flex items-center',
    'rounded-default',
    { 'px-[15px]': !_vm.dense },
    _vm.readonly ? 'text-gray-600' : _vm.textColor[_vm.text],
  ]},[_c('span',{class:[{ 'whitespace-nowrap': _vm.label }, 'text-gray-600']},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("front"),_c('div',{class:[
      { 'py-[6px]': !_vm.dense },
      'content h-full',
      _vm.alignRight ? 'ml-auto' : 'flex-1',
    ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputValue),expression:"inputValue",modifiers:{"trim":true}}],class:[
        'bg-transparent',
        'focus:outline-none ',
        'w-[175px] lg:w-[350px] h-full',
        'resize-none no-scrollbar',
        { 'cursor-default': _vm.readonly },
        { 'cursor-not-allowed': _vm.disabled },
        { 'text-right': _vm.alignRight },
        _vm.placeholderColor[_vm.hint],
        _vm.type === 'wallet_address' ? 'text-xs' : 'lg:text-sm',
      ],attrs:{"placeholder":_vm.placeholder,"type":_vm.inputType,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":(_vm.inputValue)},on:{"focus":function($event){_vm.focus = true},"blur":[function($event){_vm.focus = false},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value.trim()}}})]),_vm._t("end"),(_vm.clearable && !_vm.readonly)?_c('div',{staticClass:"cursor-pointer ml-3",on:{"click":_vm.clearContent}},[_c('Icon',{attrs:{"dense":_vm.dense,"img":require('@/assets/img/icon-clear.svg'),"alt":"移除內容icon"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }