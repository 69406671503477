import { membership2 } from "./membership2.js";
import { membership3 } from "./membership3.js";
import { request } from "@/store/api";

export const membership = {
  namespaced: true,
  mutations: { ...membership2, ...membership3 },
  state: {
    membership2DataBase: [],
    membership3DataBase: [],
    membership2Data: [],
    membership3Data: [],
    targetMembership2ID: null,
    targetMembership3ID: null,
  },
  actions: {
    A08_修改_t會員_密碼(state, data) {
      return request.addAuth("Member").put("/MemberPassword/Admin", data);
    }
  },
  getters: {
    membership2Data({ membership2Data }) {
      return membership2Data;
    },
    membership3Data({ membership3Data }) {
      return membership3Data;
    },
    targetMembership2Data({ membership2Data, targetMembership2ID }) {
      return membership2Data.filter(
        (member) => member.id === targetMembership2ID
      )[0];
    },
    targetMembership3Data({ membership3Data, targetMembership3ID }) {
      return membership3Data.filter(
        (member) => member.id === targetMembership3ID
      )[0];
    },
  },
};
