import store from "@/store"; // 直接取store
import { tools } from "@/assets/tools.js";
import { deepParseJson } from "deep-parse-json";
export const moneyin = {
  GA04_查詢_t團隊_全搜尋() {
    const data = {
      pageIndex: 1,
      pageSize: 1500,
    }

    store.dispatch("apiMoneyIn/GA04_查詢_t團隊_全搜尋", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("取得GA04_查詢_t團隊_全搜尋", data);
        store.commit("apiMoneyIn/SET_團隊管理列表", data); //deepparseJson
      });
  },
  A05_查詢_入金紀錄_團隊_全搜尋({ 時間起, 時間迄, 成員列表, f狀態 }) {
    console.log('成員列表', 成員列表);
    const data = {
      pageIndex: 1,
      pageSize: 1500,
      時間起,
      時間迄,
      成員列表,
    }
    if (f狀態 != undefined) {
      data.SearchCol = 'f狀態'
      data.SearchVal = f狀態
    }
    console.log(data);


    store.dispatch("apiMoneyIn/A05_查詢_入金紀錄_團隊_全搜尋", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A05_查詢_入金紀錄_團隊_全搜尋", data);
        store.commit("apiMoneyIn/SET_入金紀錄", data); //deepparseJson
      });
  },
  時間起迄查詢會員入金紀錄({
    時間起,
    時間迄,
    fMid,
    f狀態,
    // SearchCol = "fMid",
    // SearchVal = "",
  }) {
    const data = {
      pageIndex: 1,
      pageSize: 1500,
      時間起,
      時間迄,
      SearchCol: [],
      SearchVal: [],
    }
    data.SearchCol[0] = "fMid"
    data.SearchVal[0] = fMid
    if (f狀態 != undefined) {
      data.SearchCol[1] = "f狀態"
      data.SearchVal[1] = f狀態
    }
    store
      .dispatch("apiMoneyIn/A05_管理員取得入金紀錄", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("取得", data);
        store.commit("apiMoneyIn/SET_入金紀錄", data); //deepparseJson
      });
  },
  時間起迄查詢入金紀錄: ({
    時間起,
    時間迄,
    SearchCol = "f狀態",
    SearchVal = "",
    FuzzyCol = "f銀行紀錄",
    FuzzyVal = "",
  } = {}) => {
    if (SearchVal == 'null') {
      if (FuzzyVal == 'null') {
        store
          .dispatch("apiMoneyIn/A05_管理員取得入金紀錄", {
            時間起,
            時間迄,
            pageIndex: 1,
            pageSize: 1500,
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyIn/SET_入金紀錄", data); //deepparseJson
          });
      } else {
        store
          .dispatch("apiMoneyIn/A05_管理員取得入金紀錄", {
            時間起,
            時間迄,
            FuzzyCol,
            FuzzyVal,
            pageIndex: 1,
            pageSize: 1500,
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyIn/SET_入金紀錄", data); //deepparseJson
          });
      }

    } else {
      store
        .dispatch("apiMoneyIn/A05_管理員取得入金紀錄", {
          時間起,
          時間迄,
          SearchCol,
          SearchVal,
          pageIndex: 1,
          pageSize: 1500,
        })
        .then((res) => {
          let data = deepParseJson(res.data);
          console.log("取得", data);
          store.commit("apiMoneyIn/SET_入金紀錄", data); //deepparseJson
        });
    }

  },
  上傳發票: (fId, Bill) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyIn/A01_管理員上傳發票或TXID",
          tools.ObjToForm({ fId, Bill })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  上傳TXID: (fId, TXID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyIn/A01_管理員上傳發票或TXID",
          tools.ObjToForm({ fId, TXID })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  上傳退款單: (fId, Refund, 駁回原因) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyIn/A02_管理員上傳退款單",
          tools.ObjToForm({ fId, Refund, 駁回原因 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
