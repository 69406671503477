export const level2 = {
  getLevel2Application(state) {
    state.level2Application = state.level2ApplicationSTATIC;
  },
  setLevel2Application(state, data) {
    console.log("設定LV2", data);
    //設定api回傳值
    state.level2ApplicationSTATIC = state.level2ApplicationSTATIC.concat(data);
    console.log("設定LV2後", state.level2ApplicationSTATIC);
  },
  resetLevel2Application(state) {
    state.level2ApplicationSTATIC = [];
  },
  filterLevel2Application(state, status) {
    state.level2Application = state.level2ApplicationSTATIC.filter(
      (applier) => applier.status === status
    );
  },
  deleteLevel2Application(state, id) {
    state.level2Application = state.level2ApplicationSTATIC.filter(
      (applier) => applier.id !== id
    );
    state.level2Application = state.level2ApplicationSTATIC.filter(
      (applier) => applier.id !== id
    );
  },
  searchLevel2Application(state, account) {
    state.level2Application = state.level2ApplicationSTATIC.filter((applier) =>
      applier.account.includes(account)
    );
  },
  changeLevel2ApplierStatusByID(state, { id, failedReason }) {
    const targetIndex = state.level2ApplicationSTATIC.findIndex(
      (applier) => applier.id === id
    );
    state.level2ApplicationSTATIC[targetIndex]["status"] = "failed";
    state.level2ApplicationSTATIC[targetIndex]["failed_reason"] = failedReason;
    state.level2Application = state.level2ApplicationSTATIC;
  },
  getLevel2ApplierByID(state, id) {
    state.level2Applier = state.level2ApplicationSTATIC.filter(
      (applier) => applier.id === id
    )[0];
  },
};
