import Web3 from "web3";
import astabi from "@/assets/abi/ast.json";

let getWeb3 = function (abi, address, account) {
  let web3 = new Web3(window.web3.currentProvider);
  return {
    callType: () => {
      return new web3.eth.Contract(abi, address, {
        gasPrice: "0",
        from: account,
      });
    },
    sendType: () => {
      return new web3.eth.Contract(abi, address, {
        gasPrice: "20000000000",
        from: account,
      });
    },
  };
};

// const USDT = "0x713d98fb4d8AcDEa9f8D2198D3aC00D4bEF2C6A5";
// const TWD = "0x15f220bA13C9EEE08E7498Bb89D9c257f895a160";
// const AST = "0x10Fa1C2c8efe06Ef49f43B1D2130248e5284BfDa";
const contract = "0xAb5F1A8B58A2f5acaC2EF97f32A8D1f6186830f9";

export const ast = {
  namespaced: true,
  state: {
    account: "",
    address: "",
    mangerStatus: [],
    adminAddress: [],
    adminStatus: [],
  },
  mutations: {
    setAccount(state, account) {
      state.account = account;
    },
    setAdminAddress(state, AdminAddress) {
      state.adminAddress = AdminAddress;
    },
    setAdminStatus(state, AdminStatus) {
      state.adminStatus = AdminStatus;
    },
  },
  getters: {},
  actions: {
    取得管理員上鍊名單(state, data) {
      return getWeb3(astabi, contract, state.state.account)
        .callType()
        .methods.getratesetter(data)
        .call();
    },
    管理員切換鍊(state, data) {
      console.log("管理員切換鍊", data);

      return getWeb3(astabi, contract, state.state.account)
        .sendType()
        .methods.setratesetter(data)
        .send();
    },
    設定TWD兌USDT匯率(state, data) {
      var weiAmout = (parseFloat(data) * Math.pow(10, 18)).toString();
      console.log("weiAmout", weiAmout, data);
      console.log(weiAmout.toString());
      return getWeb3(astabi, contract, state.state.account)
        .sendType()
        .methods.set_usdt_rate(weiAmout)
        .send();
    },
    設定USDT兌TWD匯率(state, data) {
      var weiAmout = (parseFloat(data) * Math.pow(10, 18)).toString();

      console.log(weiAmout.toString());
      return getWeb3(astabi, contract, state.state.account)
        .sendType()
        .methods.set_TWD_rate(weiAmout)
        .send();
    },
    TWD白名單資格(state, data) {
      return getWeb3(astabi, contract, state.state.account)
        .callType()
        .methods.TWD_white_list(data)
        .call();
    },
    USDT白名單資格(state, data) {
      return getWeb3(astabi, contract, state.state.account)
        .callType()
        .methods.TWD_white_list(data)
        .call();
    },
    批次取得TWD白名單資格(state, data) {
      return getWeb3(astabi, contract, state.state.account)
        .callType()
        .methods.get_TWD_white_list(data)
        .call();
    },
    批次取得USDT白名單資格(state, data) {
      return getWeb3(astabi, contract, state.state.account)
        .callType()
        .methods.get_usdt_white_list(data)
        .call();
    },

    設定兌換TWD白名單(state, data) {
      //討論看是否合併
      return getWeb3(astabi, contract, state.state.account)
        .sendType()
        .methods.set_TWD_white_list(data)
        .send();
    },
    設定兌換USDT白名單(state, data) {
      //討論看是否合併
      return getWeb3(astabi, contract, state.state.account)
        .sendType()
        .methods.set_usdt_white_list(data)
        .send();
    },
  },
};
