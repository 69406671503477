export const level3 = {
  getLevel3Application(state) {
    state.level3Application = state.level3ApplicationSTATIC;
  },
  setLevel3Application(state, data) {
    console.log("設定LV3", data);
    //設定api回傳值
    state.level3ApplicationSTATIC = state.level3ApplicationSTATIC.concat(data);
    console.log("設定LV3後", state.level3ApplicationSTATIC);
  },
  resetLevel3Application(state) {
    state.level3ApplicationSTATIC = [];
  },
  deleteLevel3Application(state, id) {
    state.level3Application = state.level3ApplicationSTATIC.filter(
      (applier) => applier.id !== id
    );
    state.level3Application = state.level3ApplicationSTATIC.filter(
      (applier) => applier.id !== id
    );
  },
  filterLevel3Application(state, status) {
    state.level3Application = state.level3ApplicationSTATIC.filter(
      (applier) => applier.status === status
    );
  },
  searchLevel3Application(state, account) {
    state.level3Application = state.level3ApplicationSTATIC.filter((applier) =>
      applier.account.includes(account)
    );
  },
  changeLevel3ApplierStatusByID(state, { id, failedReason }) {
    const targetIndex = state.level3ApplicationSTATIC.findIndex(
      (applier) => applier.id === id
    );
    state.level3ApplicationSTATIC[targetIndex]["status"] = "failed";
    state.level3ApplicationSTATIC[targetIndex]["failed_reason"] = failedReason;
    state.level3Application = state.level3ApplicationSTATIC;
    console.log(
      "新增該用戶不通過原因",
      state.level3ApplicationSTATIC[targetIndex]
    );
  },
};
