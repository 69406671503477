<template>
  <div 
  v-if="isRouterAlive"
    id="app"
    :class="[{ 'fixed left-0 right-0': $store.getters.isPopupOpen }]"
  >
    <component :is="layout" />
    <PopupWindow
      v-model="popupControl"
      :closeIcon="!$store.getters.popupContent.includes('Confirm')"
      :removeShadow="$store.getters.doublePopup"
    >
      <template #body>
        <component
          :is="$store.getters.popupContent"
          v-bind="$store.getters.popupProps"
          @confirm="$store.commit('togglePopup', false)"
          @cancel="$store.commit('togglePopup', false)"
        />
      </template>
    </PopupWindow>
  </div>
</template>

<script>
import Default from "@/layout/Default.vue";
import Login from "@/layout/Login.vue";
import { PopupWindow, Card } from "@/components/Shared";
import {
  AddCard,
  ChainCard,
  DeleteCard,
  RateCard,
  BlockConfirm,
  FailedConfirm,
  StatusForm,
  ApplyForm,
} from "@/components/PopupCards";

export default {
  components: {
    Default,
    Login,
    PopupWindow,
    Card,
    AddCard,
    ChainCard,
    DeleteCard,
    RateCard,
    BlockConfirm,
    FailedConfirm,
    StatusForm,
    ApplyForm,
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {   
        isRouterAlive: true
      };
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    popupControl: {
      get() {
        return this.$store.getters.isPopupOpen;
      },
      set(value) {
        this.$store.commit("togglePopup", value);
      },
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  },
  mounted() {
    console.log("使用者", this.$store.state.login.token);
    if (this.$store.state.login.token == "") {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Roboto";
  color: #000000;
  overflow: hidden;
}
</style>
