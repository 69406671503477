<template>
  <div
    style="overflow-y: auto"
    class="bg-white h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <div
      @click="selectEvent = ''"
      v-if="selectEvent != ''"
      class="px-4 appArrow"
    >
      <span style="cursor: pointer">&#10154;</span>
    </div>
    <div class="flex justify-around">
      <!-- 左邊 -->
      <div
        :class="selectEvent == '' ? '' : 'lg:w-48 appHandle2'"
        class="area-a"
      >
        <div
          class="mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>NEWS management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>公告中心</strong>
            </h2>
          </div>

          <div class="flex flex-col items-end pt-[10px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入文章名稱搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mb-3">
          <div class="flex width100">
            <Button
              @click="新增公告按鈕()"
              label="新增公告"
              color="gray"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
            />
            <div style="flex: 1"></div>
            <!-- <Button
              label="系統公告下拉"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
            /> -->
            <!-- <select class="select" name="" id="">
              <option value="手機">手機</option>
              <option value="電腦">電腦</option>
            </select> -->
            <div
              class="aselect"
              :data-value="列表文章類別選擇"
              :data-list="列表文章下拉選單"
            >
              <div class="selector" @click="列表文章下拉toggle()">
                <div class="label">
                  <span>{{ 列表文章類別選擇 }}</span>
                </div>
                <div
                  class="arrow"
                  :class="{ expanded: 列表文章下拉visible }"
                ></div>
                <div
                  :class="{ hidden: !列表文章下拉visible, 列表文章下拉visible }"
                >
                  <ul>
                    <li
                      :class="{ current: item === 列表文章類別選擇 }"
                      v-for="item in 列表文章下拉選單"
                      :key="item"
                      @click="列表文章下拉(item)"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-top: 10px">
          <DatePicker
            :key="重新渲染"
            @valueUpdate="selectTimeRead"
            v-model="selectedDate"
            class="px-2 border-D8C1BB"
            Color="red"
          >
          </DatePicker>
        </div>
        <div style="height: 65vh" class="flex-1 overflow-y-scroll no-scrollbar">
          <div
            v-for="item in 最新消息列表"
            :key="item.fId"
            class="border-D8C1BB flex flex-ac"
            style="color: #878787; margin-top: 10px"
          >
            <div class="img_box">
              <img
                style="width: 100%; height: 100%; object-fit: contain"
                :src="$appsetting.AppUrl + '/' + item.f圖示"
              />
            </div>
            <div
              class="flex-column"
              style="
                padding: 0px 10px;
                max-width: fit-content;
                word-break: break-all;
              "
            >
              <div class="f14">{{ item.f類別 }}</div>
              <div class="f16 f700">{{ item.f標題 || "無標題" }}</div>
            </div>
            <div style="flex: 1"></div>
            <div style="min-width: 100px" class="flex-column text-center">
              <div class="f16">
                {{
                  item.f發佈時間 &&
                  item.f發佈時間.replace(
                    /^(\d{4})-(\d{2})-(\d{2}).*$/,
                    "$1/$2/$3"
                  )
                }}
              </div>
              <div class="flex" style="padding-top: 10px">
                <Icon
                  class="searchIconApp"
                  @click="toggleEvent('READ', item)"
                  icon="icon-search"
                />

                <Icon
                  @click="toggleEvent('EDIT', item)"
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-vector.svg')"
                />
                <Icon
                  @click="toggleEvent('DELETE', item)"
                  class="scale-150"
                  :img="require('@/assets/img/icon-frame.svg')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右邊 -->
      <div
        style="height: 85vh"
        :class="
          selectEvent == ''
            ? ''
            : 'flex-1 lg:w-48 px-3 appHandle  overflow-y-scroll no-scrollbar'
        "
      >
        <div
          v-if="selectEvent == 'CREATE' || selectEvent == 'EDIT'"
          class="rightConten"
          style="color: #878787"
        >
          <div>
            <div
              class="mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
            >
              <div class="subgroup-blue text-2xl hidden lg:block lg:flex-grow">
                <h2 class="sub mb-4 ml-[4px]">
                  <strong>{{
                    selectEvent == "CREATE" ? "Add new article" : "Edit article"
                  }}</strong>
                </h2>
                <h2 class="subtitle">
                  <strong
                    >{{ selectEvent == "CREATE" ? "新增文章" : "修改文章" }}
                  </strong>
                </h2>
              </div>

              <div class="flex flex-col items-end pt-[10px]">
                <!-- <Button
                    label="選擇文章分類"
                    color="red"
                    text="white"
                    size="status-sm"
                    class="text-xs lg:text-lg"
                  /> -->
                <div
                  class="aselect"
                  :data-value="新增與編輯文章類別選擇"
                  :data-list="新增與編輯文章下拉選單"
                >
                  <div class="selector" @click="新增與編輯文章下拉toggle()">
                    <div class="label">
                      <span>{{ 新增與編輯文章類別選擇 }}</span>
                    </div>
                    <div
                      class="arrow"
                      :class="{ expanded: 新增與編輯文章下拉visible }"
                    ></div>
                    <div
                      :class="{
                        hidden: !新增與編輯文章下拉visible,
                        新增與編輯文章下拉visible,
                      }"
                    >
                      <ul>
                        <li
                          :class="{
                            current: item === 新增與編輯文章類別選擇,
                          }"
                          v-for="item in 新增與編輯文章下拉選單"
                          :key="item"
                          @click="新增與編輯文章下拉(item)"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex row-re">
              <Icon
                @click="toggleEvent('DELETE', selectObj)"
                class="scale-150"
                :img="require('@/assets/img/icon-frame.svg')"
              />
              <Icon
                v-show="selectEvent !== 'EDIT'"
                @click="toggleEvent('EDIT', selectObj)"
                class="mx-3 scale-150"
                :img="require('@/assets/img/icon-vector.svg')"
              />
            </div>
            <div>文章名稱</div>

            <div style="border-bottom: 1px solid grey; display: flex">
              <Input
                placeholder="請填寫文章標題"
                hint="gray"
                text="gray"
                v-model="文章.標題"
                style="padding: 0 !important"
              >
              </Input>
              <DatePicker
                :key="重新渲染"
                @valueUpdate="selectTimeCreateOrEdit"
                v-model="新增與編輯文章selectedDate"
                class="px-2"
                Color="red"
                :區間選擇="false"
                :編輯文章時間="編輯文章時間"
              >
              </DatePicker>
            </div>
            <div style="padding-top: 30px">
              <div>文章的封面圖片</div>
              <input
                v-show="false"
                type="file"
                ref="fileInput"
                @change="圖片上傳後資訊($event)"
                accept="image/*"
              />
              <div
                @click="上傳圖片()"
                style="
                  width: 100%;
                  height: 180px;
                  background-color: #d9d9d9;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <template v-if="!圖片預覽 && !文章.封面">
                  <img width="50px" src="@/assets/img/image.svg" alt="" />
                  <div>請上傳圖片</div>
                </template>
                <template v-else>
                  <img
                    style="width: 100%; height: 100%; object-fit: contain"
                    :src="圖片預覽 || 文章.封面"
                    alt=""
                  />
                </template>
              </div>
            </div>
            <div style="padding-top: 30px">
              <div>文本編輯器</div>
              <div>
                <vue-editor class="newsEdit" v-model="文章.內容"></vue-editor>
              </div>
            </div>
            <div class="flex justify-between items-center mb-3">
              <div style="width: 100%; text-align: center; margin-top: 16px">
                <Button
                  @click="新增文章按鈕()"
                  label="確認"
                  style="
                    background-color: #283395;
                    width: 43%;
                    min-width: 320px;
                    height: 43px;
                  "
                  text="white"
                  size="status-sm"
                  class="text-xs lg:text-lg mr-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="rightConten"
          style="color: #878787"
          v-else-if="selectEvent == 'READ'"
        >
          <div>
            <div
              class="mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
            >
              <div class="subgroup-blue text-2xl hidden lg:block lg:flex-grow">
                <h2 class="sub mb-4 ml-[4px]">
                  <strong>The new article</strong>
                </h2>
                <h2 class="subtitle">
                  <strong>文章標題 </strong>
                </h2>
              </div>

              <div class="flex flex-col items-end pt-[10px]">
                <!-- <Button
                    label="選擇文章分類"
                    color="red"
                    text="white"
                    size="status-sm"
                    class="text-xs lg:text-lg"
                  /> -->
                <div
                  style="
                    border: 1px solid gainsboro;
                    background: rgba(206, 0, 0, var(--tw-bg-opacity));
                    padding: 6px 26px;
                    font-size: 16px;
                    color: #ffffff;
                    border-radius: 6px;
                  "
                >
                  {{ selectObj.f類別 }}
                </div>
              </div>
            </div>
            <div class="flex row-re">
              <Icon
                @click="toggleEvent('DELETE', selectObj)"
                class="scale-150"
                :img="require('@/assets/img/icon-frame.svg')"
              />
              <Icon
                v-show="selectEvent !== 'EDIT'"
                @click="toggleEvent('EDIT', selectObj)"
                class="mx-3 scale-150"
                :img="require('@/assets/img/icon-vector.svg')"
              />
            </div>
            <div>文章名稱</div>

            <div style="border-bottom: 1px solid grey; display: flex">
              <div
                class="w-full flex items-center rounded-default px-[15px] text-gray-600"
              >
                {{ selectObj.f標題 }}
              </div>
              <div style="display: flex">
                <img :src="require(`@/assets/img/icon-calander-red.svg`)" />
                <span style="padding: 0 16px 0 8px">
                  {{
                    selectObj.f發佈時間.replace(
                      /^(\d{4})-(\d{2})-(\d{2}).*$/,
                      "$1.$2.$3"
                    )
                  }}</span
                >
              </div>
            </div>
            <div style="padding-top: 30px; height: 400px; width: 100%">
              <img
                style="width: 100%; object-fit: contain; height: 100%"
                :src="'https://amlservice.qhr.app/' + selectObj.f圖示"
                alt=""
              />
            </div>
            <div
              style="
                margin-top: 30px;
                word-break: break-all;
                max-height: 400px;
                overflow-y: auto;
              "
            >
              <div v-html="selectObj.f消息內容"></div>
            </div>
          </div>
        </div>

        <DelNews
          style="padding: 0px 20px"
          v-else-if="selectEvent == 'DELETE'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          :列表文章類別選擇="列表文章類別選擇"
          :searchWords="searchWords"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></DelNews>
      </div>
    </div>
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
  </div>
</template>
<!-- :class="手機板 ? '' : ''" -->

<script>
import { Input, Icon, Button } from "@/components/Shared";
import DatePicker from "@/components/FormItems/DatePicker.vue";
import { DelNews } from "@/components/Manager";
import { news } from "@/usecase/news";
import { VueEditor } from "vue2-editor";
export default {
  name: "Team",
  components: {
    Input,
    Icon,
    Button,
    DatePicker,
    DelNews,
    VueEditor,
  },
  computed: {
    最新消息列表() {
      return this.$store.getters["apiNews/最新消息列表"];
    },
    畫面初始() {
      return this.$store.getters["apiNews/畫面初始"];
    },
  },
  data() {
    return {
      列表文章類別選擇: "全部",
      新增與編輯文章類別選擇: "最新活動",
      列表文章下拉選單: ["全部", "最新活動", "官方公告", "系統更新"],
      新增與編輯文章下拉選單: ["最新活動", "官方公告", "系統更新"],
      文章: { 標題: "", 封面: "", 內容: "" },
      列表文章下拉visible: false,
      新增與編輯文章下拉visible: false,
      selectEvent: "",
      searchWords: "",
      編輯文章時間: null,
      重新渲染: 0,
      公告列表: [
        {
          fId: 1,
          分類: "這裡寫文章分類",
          標題: "文章標題標題文章標題標題文章",
          日期: "2023/11/08",
        },
        {
          fId: 2,
          分類: "這裡寫文章分類",
          標題: "文章標題標題文章標題標題文章",
          日期: "2023/11/08",
        },
      ],
      新增與編輯文章selectedDate: new Date(),
      selectedDate: [
        new Date(),
        new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      ],
      圖片預覽: null,
      selectObj: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.時間起迄查詢公告列表();
    });
  },
  watch: {
    列表文章類別選擇(v) {
      news.A10_查詢_t最新消息(
        v,
        localStorage.getItem("公告中心列表時間起"),
        localStorage.getItem("公告中心列表時間迄"),
        this.searchWords
      );
    },
    畫面初始(v) {
      console.log("畫面初始", v);
      console.log(this.selectEvent);
      this.$modal.hide("loading");
      this.toggleEvent("", {});
    },
    searchWords(v) {
      if (v === "") {
        news.A10_查詢_t最新消息(
          this.列表文章類別選擇,
          localStorage.getItem("公告中心列表時間起"),
          localStorage.getItem("公告中心列表時間迄"),
          ""
        );
      }
    },
  },
  methods: {
    新增公告按鈕() {
      this.編輯文章時間 = new Date();
      this.toggleEvent("CREATE", {});
      this.文章 = { 標題: "", 封面: "", 內容: "" };
      this.圖片預覽 = null;
    },
    selectTimeCreateOrEdit(v) {
      console.log(v);
      this.新增與編輯文章selectedDate = v;
    },
    新增文章按鈕() {
      console.log(this.selectEvent == "EDIT");

      const 時間轉換 = this.新增與編輯文章selectedDate
        .toISOString()
        .replace("T", " ")
        .slice(0, 19);
      const o = {
        f類別: this.新增與編輯文章類別選擇,
        f標題: this.文章.標題,
        f發佈時間: 時間轉換,
        f上架時間: 時間轉換,
        icon: this.文章.封面,
        f消息內容: this.文章.內容,
        viewContent: "test",
      };
      console.log(o);
      const conditions = [
        { condition: o.f標題 === "", message: "請填寫文章標題" },
        { condition: o.icon === "", message: "請上傳文章封面" },
        { condition: o.f消息內容 === "", message: "請填寫文章內容" },
      ];

      for (const condition of conditions) {
        if (condition.condition) {
          return this.$notify.warning(condition.message);
        }
      }

      this.$modal.show("loading");
      if (this.selectEvent != "EDIT") {
        news.A01_新增_t最新消息(o);
      } else {
        o.fId = this.selectObj.fId;
        if (this.圖片預覽 === null) {
          delete o.icon;
        }
        news.A02_修改_t最新消息(o);
      }
    },
    上傳圖片() {
      this.$refs.fileInput.click();
    },
    圖片上傳後資訊(event) {
      const file = event.target.files[0];
      if (file) {
        this.文章.封面 = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.圖片預覽 = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    successEvent() {
      console.log("EVENT");
      // this.GA04_查詢_t團隊_全搜尋();
    },
    列表文章下拉toggle() {
      this.列表文章下拉visible = !this.列表文章下拉visible;
    },
    新增與編輯文章下拉toggle() {
      this.新增與編輯文章下拉visible = !this.新增與編輯文章下拉visible;
    },
    列表文章下拉(option) {
      this.列表文章類別選擇 = option;
    },
    新增與編輯文章下拉(option) {
      this.新增與編輯文章類別選擇 = option;
    },
    selectTimeRead(val) {
      if (typeof val.end == "object") {
        const 開始 = val.start;
        const 開始年 = 開始.getFullYear();
        const 開始月 = 開始.getMonth() + 1;
        const 開始日 = 開始.getDate();
        const 開始時間 = `${開始年}-${開始月 < 10 ? "0" + 開始月 : 開始月}-${
          開始日 < 10 ? "0" + 開始日 : 開始日
        }`;
        const 結束 = val.end;
        const 結束年 = 結束.getFullYear();
        const 結束月 = 結束.getMonth() + 1;
        const 結束日 = 結束.getDate();
        const 結束時間 = `${結束年}-${結束月 < 10 ? "0" + 結束月 : 結束月}-${
          結束日 < 10 ? "0" + 結束日 : 結束日
        }`;
        localStorage.setItem("公告中心列表時間起", 開始時間);
        localStorage.setItem("公告中心列表時間迄", 結束時間);

        news.A10_查詢_t最新消息(
          this.列表文章類別選擇,
          localStorage.getItem("公告中心列表時間起"),
          localStorage.getItem("公告中心列表時間迄"),
          this.searchWords
        );
      }
    },
    時間起迄查詢公告列表() {
      const 三天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      const 三天前年 = 三天前.getFullYear();
      const 三天前月 = 三天前.getMonth() + 1;
      const 三天前日 = 三天前.getDate();
      const 三天前時間 = `${三天前年}-${
        三天前月 < 10 ? "0" + 三天前月 : 三天前月
      }-${三天前日 < 10 ? "0" + 三天前日 : 三天前日}`;

      const 當前年 = new Date().getFullYear();
      const 當前月 = new Date().getMonth() + 1;
      const 當前日 = new Date().getDate();
      const 當前時間 = `${當前年}-${當前月 < 10 ? "0" + 當前月 : 當前月}-${
        當前日 < 10 ? "0" + 當前日 : 當前日
      }`;
      localStorage.setItem("公告中心列表時間起", 三天前時間);
      localStorage.setItem("公告中心列表時間迄", 當前時間);

      news.A10_查詢_t最新消息(
        this.列表文章類別選擇,
        localStorage.getItem("公告中心列表時間起"),
        localStorage.getItem("公告中心列表時間迄"),
        this.searchWords
      );
    },
    toggleEvent(event, data = {}) {
      data = this.$deepParseJson(data); //把所有JSONstring 解開
      if (this.selectEvent == "") {
        console.log(111);
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        console.log(222);
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
        console.log(333);
        console.log(this.selectObj);
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        console.log(444);
        this.selectEvent = "";
      } else {
        console.log(555);
        this.selectEvent = "";
      }
      console.log("結果cc", this.selectEvent, this.selectObj);
      if (
        JSON.stringify(this.selectObj) != "{}" &&
        this.selectEvent == "EDIT"
      ) {
        const { f標題, f圖示, f消息內容, f上架時間 } = this.selectObj;
        this.圖片預覽 = null;
        this.文章 = {
          標題: f標題,
          封面: "https://amlservice.qhr.app/" + f圖示,
          內容: f消息內容,
        };
        this.編輯文章時間 = new Date(f上架時間);
        console.log("f上架時間");
      }
    },
    搜尋按鈕() {
      if (this.searchWords == "") {
        return this.$notify.warning("請輸入文章名稱");
      }
      news.A10_查詢_t最新消息(
        this.列表文章類別選擇,
        localStorage.getItem("公告中心列表時間起"),
        localStorage.getItem("公告中心列表時間迄"),
        this.searchWords
      );
      // const arr = this.名單初始.filter((i) => {
      //   return i.account.indexOf(this.searchWords) != -1;
      // });
      // this.$store.commit("manager/setManager", arr);
      // console.log(arr);
    },
  },
};
</script>
<style lang="scss" scoped>
.aselect {
  min-width: 130px;
  @media screen and (max-width: 1024px) {
    min-width: 75px;
  }
  .selector {
    border-radius: 6px;
    border: 1px solid gainsboro;
    background: rgba(206, 0, 0, var(--tw-bg-opacity));
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1024px) {
      background: #fff;
      font-weight: 900;
      border: none;
    }
    .arrow {
      position: absolute;
      right: 10px;
      top: 40%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid #fff;
      transform: rotateZ(0deg) translateY(0px);
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
      @media screen and (max-width: 1024px) {
        border-top: 10px solid red;
        top: 32%;
      }
    }
    .expanded {
      transform: rotateZ(180deg) translateY(2px);
    }
    .label {
      display: block;
      padding: 6px 10px;
      font-size: 16px;
      color: #ffffff;
      @media screen and (max-width: 1024px) {
        color: red;
        font-size: 13px;
        padding: 4px 8px;
        > span {
          display: flex;
          width: 100%;
        }
      }
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border: 1px solid gainsboro;
    position: absolute;
    z-index: 1;
    background: #fff;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      text-align: left;
    }
  }
  li {
    padding: 12px;
    color: #666;
    &:hover {
      color: white;
      background: seagreen;
    }
  }
  .current {
    background: #eaeaea;
  }
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
}
.appHandle {
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  .rightConten {
    height: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
    > div {
      padding: 0 20px;
      @media screen and (max-width: 1024px) {
        padding: 0;
      }
    }
  }
}
.searchIconApp {
  transform: scale(1.2);
  @media screen and (max-width: 800px) {
    transform: scale(0.7);
  }
}
</style>
<style>
.newsEdit {
  width: 100%;
}
.newsEdit .ql-editor {
  height: 250px;
}
.select {
  background-color: rgba(206, 0, 0, var(--tw-bg-opacity));
  color: white;
  border-radius: 5px;
  padding: 0 10px;
}
.area-a {
  flex: 1;
}

.area-b {
  flex: 1;
}

.hidden {
  display: none;
}
.border-D8C1BB {
  border: 1px solid #d8c1bb;
  border-radius: 10px;
  padding: 10px;
}
.img_box {
  width: 90px;
  height: 60px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appArrow {
  display: none;
}
@media screen and (max-width: 1300px) {
  .appArrow {
    font-size: 31px;
    transform: rotate(180deg);
    display: flex;
    justify-content: end;
  }
  .appHandle {
    width: 100%;
  }
  .appHandle2 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .area-b {
    /* display: none; */
  }
  .f16 {
    font-size: 15px !important;
  }
  .f14 {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 768px) {
  .f16 {
    font-size: 14px !important;
  }
  .f14 {
    font-size: 12px !important;
  }
}
</style>
