export const login = {
  namespaced: true,
  state: {
    account: "0968222032",
    password: "",
    token: "",
    level: "",
  },
  getters: {
    get_token(state) {
      return state.token;
    },
  },
  mutations: {
    accountLogin(state, accountInfo) {
      state.account = accountInfo.account;
      state.password = accountInfo.password;
      state.token = accountInfo.token;
      state.level = accountInfo.level;
    },
  },
};
