<template>
  <div
    style="position: relative"
    class="field flex justify-between items-center"
  >
    <vc-date-picker v-model="range" is-range>
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-center items-center">
          <img :src="require(`@/assets/img/icon-calander-${Color}.svg`)" />
          <input
            :value="inputValue.start"
            v-on="inputEvents.start"
            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
          />
          <img :src="require(`@/assets/img/icon-calander-${Color}.svg`)" />
          <input
            :value="inputValue.end"
            v-on="inputEvents.end"
            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
          />
        </div>
      </template>
    </vc-date-picker>

    <dropdown-menu
      v-show="$route.fullPath == '/moneyin' || $route.fullPath == '/team'"
      v-model="show"
      :right="false"
      :hover="false"
      :interactive="true"
      class="flex items-center"
    >
      <button class="flex text-[#cc232a]">
        <slot name="buttonName"
          ><img class="w-[100%]" src="@/assets/img/icon-download.svg"
        /></slot>
      </button>
      <div
        style="right: 20px; top: 28px"
        class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
        slot="dropdown"
      >
        <slot name="dropdownList">
          <ul
            class="py-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li @click="匯出CSV()" class="hover:bg-[#cc232a]">
              <a
                href="#"
                class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                >匯出CSV</a
              >
            </li>
          </ul>
        </slot>
      </div>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from "@innologica/vue-dropdown-menu";
export default {
  name: "DatePicker",
  components: { DropdownMenu },
  props: {
    defaultChg: {
      type: Boolean,
      default: true,
    },
    初始時間起: {
      type: String,
    },
    初始時間迄: {
      type: String,
    },

    Color: {
      type: String,
      default: "red",
    },
  },
  data() {
    return {
      show: false,
      range: {
        start: "",
        end: "",
      },

      bankList: ["匯出CSV", "匯出PDF"],
      formValues: "匯出",
    };
  },
  created() {
    this.range.start = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    this.range.end = new Date();
  },
  watch: {
    range: {
      handler() {
        this.sendToParent();
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleSuccess() {
      this.show = false;
    },
    sendToParent() {
      this.$emit("valueUpdate", this.range);
    },
    匯出CSV() {
      this.show = false;
      this.$emit("csvUpdate", this.range);
    },
  },
};
</script>
<style scoped></style>
