<template>
  <div
    class="h-screen grid grid-rows-mobile lg:grid-cols-none lg:grid-cols-default"
  >
    <Navbar class="hidden lg:flex lg:flex-col lg:row-span-2 lg:col-span-1" />
    <Header class="row-span-1 lg:col-span-1" />
    <main
      style="overflow-x: hidden; overflow-y: auto"
      class="row-span-1 lg:col-span-1 relative bg-gray-100 w-full overflow-scroll"
    >
      <router-view />
      <transition name="slide" mode="out-in">
        <Navbar
          v-show="$store.state.showNav"
          class="lg:hidden absolute top-0 right-0 bottom-0 w-[200px]"
        />
      </transition>
    </main>
    <!-- <div>狀態{{ $store.state.showNav }}</div> -->
  </div>
</template>

<script>
import { Navbar, Header } from "@/components/Layout";
export default {
  name: "DefaultLayout",
  components: { Navbar, Header },
};
</script>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: all 0.5s ease-in-out;
}
.slide-enter-active {
  transform: translateX(100%);
}
/* .slide-enter-from {
  transform: translateX(100%);
} */
.slide-enter-to {
  transform: translateX(0);
}
.slide-leave-to {
  transform: translateX(100%);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
