<template>
  <form class="w-full text-sm lg:text-lg" @submit.prevent="submit">
    <fieldset>
      <Input
        v-model="formValues['account']"
        name="account"
        label="申請帳號"
        placeholder="請輸入申請帳號"
        clearable
        alignRight
        :readonly="readonly || edit"
        dense
      />
    </fieldset>
    <fieldset class="">
      <Input
        v-model="formValues['tax_number']"
        name="tax_number"
        label="公司統編"
        placeholder="請輸入公司統編"
        clearable
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        v-model="formValues['agent']"
        name="agent"
        label="代理人姓名"
        placeholder="請輸入代理人姓名"
        clearable
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        v-model="formValues['agentID']"
        name="agentID"
        label="代理人身份證號"
        placeholder="請輸入代理人證號"
        clearable
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Select
        v-model="formValues['bank']"
        name="bank"
        label="公司銀行名稱"
        placeholder="請選擇銀行"
        :options="bankList"
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Select
        v-model="formValues['bank_branch']"
        name="bank_branch"
        label="公司銀行分行"
        placeholder="請選擇銀行分行"
        :options="bankBranchList"
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        v-model="formValues['bank_account']"
        name="bank_account"
        label="銀行帳號"
        placeholder="請輸入銀行帳號"
        clearable
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <TextArea
        v-model="formValues['company_address']"
        name="company_address"
        label="公司地址"
        placeholder="請輸入公司地址"
        clearable
        alignRight
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <TextArea
        v-model="formValues['wallet_address']"
        name="wallet_address"
        label="錢包地址"
        placeholder="請輸入錢包地址"
        clearable
        alignRight
        :readonly="readonly || edit"
        dense
        type="wallet_address"
      />
    </fieldset>
    <fieldset class="py-3 px-[15px] h-[114px] images">
      <ImageUploader :readonly="readonly" v-model="formValues['attachment']" />
    </fieldset>
    <div class="flex flex-row justify-center space-x-[15px]">
      <slot name="button" v-bind="formValues" />
    </div>
  </form>
</template>

<script>
import { Input, Select, ImageUploader, TextArea } from "@/components/Shared";
import { bankList, bankBranchList } from "@/assets/mockData/form";
export default {
  name: "CompanyForm",
  components: {
    Input,
    Select,
    ImageUploader,
    TextArea,
  },
  props: {
    initialValues: {
      type: Object,
      default: () => ({
        account: "",
        tax_number: "",
        agent: "",
        agentID: "",
        bank: "",
        bank_branch: "",
        bank_account: "",
        company_address: "",
        wallet_address: "",
        attachment: [],
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValues: {
        account: this.initialValues.account,
        tax_number: this.initialValues.tax_number,
        agent: this.initialValues.agent,
        agentID: this.initialValues.agentID,
        bank: this.initialValues.bank,
        bank_branch: this.initialValues.bank_branch,
        bank_account: this.initialValues.bank_account,
        company_address: this.initialValues.company_address,
        wallet_address: this.initialValues.wallet_address,
        attachment: this.initialValues.attachment,
      },
      bankList,
      bankBranchList,
    };
  },
  computed: {},
  methods: {
    submit() {
      console.log("提交表單", this.values);
    },
  },
};
</script>

<style scoped lang="scss">
fieldset:not(.images) {
  padding: 6px 15px;
  height: 45px;
  min-width: 367px;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background: #fcfcfc;
  }
}
</style>
