import store from "@/store"; // 直接取store
import { tools } from "@/assets/tools.js";
import { deepParseJson } from "deep-parse-json";
import Vue from "vue";

export const news = {
  A01_新增_t最新消息(data) {
    store.dispatch("apiNews/A01_新增_t最新消息", data)
      .then((res) => {
        if (res.message == '新增最新消息成功。') {
          Vue.prototype.$notify.success(res.message);
        } else {
          Vue.prototype.$notify.warning(res.message);
        }
        console.log("A01_新增_t最新消息", data);
        news.A10_查詢_t最新消息(
          '全部',
          localStorage.getItem("公告中心列表時間起"),
          localStorage.getItem("公告中心列表時間迄")
        )
        store.commit("apiNews/SET_畫面初始");
      });
  },
  A02_修改_t最新消息(data) {
    store.dispatch("apiNews/A02_修改_t最新消息", data)
      .then((res) => {
        if (res.message == '修改最新消息成功。') {
          Vue.prototype.$notify.success(res.message);
        } else {
          Vue.prototype.$notify.warning(res.message);
        }
        console.log("A02_修改_t最新消息", data);
        news.A10_查詢_t最新消息(
          '全部',
          localStorage.getItem("公告中心列表時間起"),
          localStorage.getItem("公告中心列表時間迄")
        )
        store.commit("apiNews/SET_畫面初始");
      });
  },
  A10_查詢_t最新消息(類別 = '最新活動', 時間起, 時間迄, 搜尋關鍵字 = '') {
    console.log('類別', 類別);
    const data = {
      pageIndex: 1,
      pageSize: 1500,
      時間起,
      時間迄,
    }
    if (類別 !== '全部') {
      data.category = 類別
      // data.keys = 'f類別'
      // data.codes = 類別
    }
    if (搜尋關鍵字 !== '') {
      data.keys = 'f標題'
      data.codes = 搜尋關鍵字
    }

    store.dispatch("apiNews/A10_查詢_t最新消息", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A10_查詢_t最新消息", data);
        store.commit("apiNews/SET_最新消息列表", data); //deepparseJson
      });
  },
  時間起迄查詢出金紀錄: ({
    時間起,
    時間迄,
    SearchCol = "f狀態",
    SearchVal = "",
    FuzzyCol = "f銀行紀錄",
    FuzzyVal = "",
  } = {}) => {
    console.log('這這這這', SearchVal);
    if (SearchVal == 'null') {
      if (FuzzyVal == 'null') {
        store
          .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
            時間起,
            時間迄,
            pageIndex: 1,
            pageSize: 1500,
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
          });
      } else {
        store
          .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
            時間起,
            時間迄,
            FuzzyCol,
            FuzzyVal,
            pageIndex: 1,
            pageSize: 1500
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
          });
      }

    } else {
      store
        .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
          時間起,
          時間迄,
          SearchCol,
          SearchVal,
          pageIndex: 1,
          pageSize: 1500,
        })
        .then((res) => {
          let data = deepParseJson(res.data);
          console.log("取得", data);
          store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
        });
    }

  },
  上傳退款TXID: (出金編號, 退款TXID, 審核失敗原因) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A03_上傳退款TXID_修改",
          tools.ObjToForm({ 出金編號, 退款TXID, 審核失敗原因 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  上傳匯款單: (出金編號, 匯款單) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A02_上傳匯款單_修改",
          tools.ObjToForm({ 出金編號, 匯款單 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
