<template>
  <div style="height: 100%">
    <div
      style="padding: 0px 20px"
      v-show="selectEvent === 'CREATE' || selectEvent === 'EDIT'"
    >
      <div class="text-2xl mb-[22px] lg:mb-7 hidden lg:block lg:flex-grow">
        <h2 style="color: #c9daea" class="sub mb-4 ml-[4px]">
          <strong>{{
            selectEvent === "CREATE" ? "Add new TEAM" : "Edit TEAM"
          }}</strong>
        </h2>
        <h2 style="color: #283395" class="subtitle">
          <strong
            >{{ selectEvent === "CREATE" ? "新增團隊" : "修改團隊" }}
          </strong>
        </h2>
      </div>
      <div
        style="visibility: hidden"
        class="flex justify-between items-center mb-3"
      >
        <div>
          <Button
            color="gray"
            text="white"
            size="status-sm"
            class="text-xs lg:text-lg mr-3"
          />
        </div>
      </div>
    </div>
    <template v-if="selectEvent === 'READ'">
      <div class="readContent">
        <div
          class="subgroup-blue text-2xl lg:flex-grow row flex-ac"
          style="padding-top: 45px"
        >
          <div class="f36">
            <strong style="color: #878787">{{
              selectObj.所有資訊.f團隊名稱
            }}</strong>
          </div>
          <div style="flex: 1"></div>
          <div class="row flex f16 cursor-pointer">
            <div
              @click="團隊tab = '團隊資料'"
              style="margin: 0px 10px; color: #878787; padding-bottom: 5px"
              :style="{
                'border-bottom':
                  團隊tab == '團隊資料' ? '2px solid #ccc' : 'none',
              }"
            >
              團隊資料
            </div>
            <div
              class="cursor-pointer"
              @click="團隊tab = '團隊成員'"
              style="margin: 0px 10px; color: #878787; padding-bottom: 5px"
              :style="{
                'border-bottom':
                  團隊tab == '團隊成員' ? '2px solid #ccc' : 'none',
              }"
            >
              團隊成員
            </div>
            <div
              class="cursor-pointer"
              @click="團隊tab = '入金紀錄'"
              style="margin: 0px 10px; color: #878787; padding-bottom: 5px"
              :style="{
                'border-bottom':
                  團隊tab == '入金紀錄' ? '2px solid #ccc' : 'none',
              }"
            >
              入金紀錄
            </div>
          </div>
        </div>
        <div class="mt-[30px]">
          <div
            v-if="團隊tab === '團隊資料'"
            class="column mt-5 mb-5 h-[80vh] overflow-auto appFz"
          >
            <div class="row-re" style="padding: 10px">
              <div
                style="display: flex; cursor: pointer"
                @click="toggleEvent('EDIT', {})"
              >
                <div style="color: #878787">編輯</div>
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-vector.svg')"
                />
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>團隊名稱</div>
              <div class="text-green">{{ selectObj.所有資訊.f團隊名稱 }}</div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>推薦碼</div>
              <div class="text-green">
                {{ selectObj.所有資訊.f團隊名稱.f推薦碼 || "-" }}
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>收款地址</div>
              <div class="text-green">
                {{ selectObj.所有資訊.f收款地址 || "-" }}
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>團隊負責人</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="團隊負責人是否存在">
                  <span v-for="(item, idx) in 團隊負責人列表" :key="item.fId">
                    {{ item.f姓名 }}
                    <span v-show="idx !== 團隊負責人列表.length - 1">,</span>
                  </span>
                </template>
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>負責人電話</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="團隊負責人是否存在">
                  <span v-for="(item, idx) in 團隊負責人列表" :key="item.fId">
                    {{ item.f連絡電話 }}
                    <span v-show="idx !== 團隊負責人列表.length - 1">,</span>
                  </span>
                </template>
                <template v-else>-</template>
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>負責人郵件</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="團隊負責人是否存在">
                  <span v-for="(item, idx) in 團隊負責人列表" :key="item.fId">
                    {{ item.f信箱 }}
                    <span v-show="idx !== 團隊負責人列表.length - 1">,</span>
                  </span>
                </template>
                <template v-else>-</template>
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>團隊小幫手</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="selectObj.所有資訊.團隊小幫手.length > 0">
                  <span
                    v-for="(item, idx) in selectObj.所有資訊.團隊小幫手"
                    :key="item.fId"
                    >{{ item.f姓名 }}
                    <span
                      v-show="idx !== selectObj.所有資訊.團隊小幫手.length - 1"
                      >,</span
                    >
                  </span>
                </template>
                <template v-else>-</template>

                <!-- {{ selectObj.所有資訊.團隊小幫手 || "-" }} -->
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>小幫手電話</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="selectObj.所有資訊.團隊小幫手.length > 0">
                  <span
                    v-for="(item, idx) in selectObj.所有資訊.團隊小幫手"
                    :key="item.fId"
                    >{{ item.f連絡電話 }}
                    <span
                      v-show="idx !== selectObj.所有資訊.團隊小幫手.length - 1"
                      >,</span
                    >
                  </span>
                </template>
                <template v-else>-</template>
              </div>
            </div>
            <div class="table_team" style="justify-content: space-between">
              <div>小幫手郵件</div>
              <div style="max-width: 500px; text-align: end" class="text-green">
                <template v-if="selectObj.所有資訊.團隊小幫手.length > 0">
                  <span
                    style="margin-right: 2px"
                    v-for="(item, idx) in selectObj.所有資訊.團隊小幫手"
                    :key="item.fId"
                    >{{ item.f信箱 }}
                    <span
                      v-show="idx !== selectObj.所有資訊.團隊小幫手.length - 1"
                      >,</span
                    >
                  </span>
                </template>
                <template v-else>-</template>
              </div>
            </div>
          </div>
          <div
            v-if="團隊tab === '團隊成員'"
            class="column mt-5 mb-5 h-[80vh] overflow-auto appFz"
          >
            <div class="flex row" style="padding: 10px 20px; color: #878787">
              <div style="flex: 1; text-align: center">客戶名稱</div>
              <div style="flex: 3; text-align: center">客戶郵件</div>
              <div style="flex: 2; text-align: center">客戶電話</div>
            </div>
            <template v-if="團隊成員列表.length > 0">
              <div
                v-for="item in 團隊成員列表"
                :key="item.fId"
                class="table_team"
              >
                <div style="flex: 1; text-align: center">{{ item.f姓名 }}</div>
                <div style="flex: 3; text-align: center" class="text-green">
                  {{ item.f信箱 }}
                </div>
                <div style="flex: 2; text-align: center" class="text-green">
                  {{ item.f連絡電話 }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="table_team">
                <div style="flex: 1; text-align: center"></div>
                <div style="flex: 3; text-align: center">無團隊成員</div>
                <div
                  style="flex: 2; text-align: center"
                  class="text-green"
                ></div>
              </div>
            </template>
          </div>
          <div
            v-if="團隊tab === '入金紀錄'"
            class="column mt-5 mb-5 h-[80vh] overflow-auto appFz"
          >
            <MoneyInPage
              :目前選擇團隊fId="selectObj.所有資訊.f團隊成員"
              :selectEvent="'團隊入金'"
            />
            <!-- <div style="padding-bottom: 10px">
              <DatePicker
                :key="重新渲染"
                :篩選種類="篩選種類"
                @valueUpdate="selectTime"
                v-model="selectedDate"
                class="px-2 border border-gray-100"
                Color="red"
              >
                <template #buttonName>{{ 目前選擇種類 }} ▼</template>
                <template #dropdownList
                  ><ul
                    class="py-1 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li
                      @click="篩選種類點選('全部')"
                      class="hover:bg-[#cc232a]"
                    >
                      <a
                        href="#"
                        class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                        >全部</a
                      >
                    </li>
                    <li
                      @click="篩選種類點選('申請中')"
                      class="hover:bg-[#cc232a]"
                    >
                      <a
                        href="#"
                        class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                        >申請中</a
                      >
                    </li>
                    <li
                      @click="篩選種類點選('審核中')"
                      class="hover:bg-[#cc232a]"
                    >
                      <a
                        href="#"
                        class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                        >審核中</a
                      >
                    </li>

                    <li
                      @click="篩選種類點選('審核失敗')"
                      class="hover:bg-[#cc232a]"
                    >
                      <a
                        href="#"
                        class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                        >審核失敗</a
                      >
                    </li>
                    <li
                      @click="篩選種類點選('完成交易')"
                      class="hover:bg-[#cc232a]"
                    >
                      <a
                        href="#"
                        class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                        >完成交易</a
                      >
                    </li>
                  </ul></template
                >
              </DatePicker>
            </div>
            <img
              v-if="團隊入金紀錄列表.length == 0"
              src="@/assets/img/icon-nomoneyIn.svg"
              alt="male icon"
              class="mx-auto mt-5"
            />
            <div style="height: 80%; overflow-y: auto" v-else>
              <div>
                <div
                  v-for="item in 團隊入金紀錄列表"
                  :key="item.fId"
                  style="margin-bottom: 16px"
                  class="flex row"
                >
                  <div
                    class="table_team"
                    style="flex: 3; border-radius: 10px; flex-direction: column"
                  >
                    <div class="flex flex-ac_1520 width100 column_1520">
                      <div class="flex" style="padding-bottom: 10px">
                        <img src="@/assets/img/頭像.png" alt="" />
                        <div
                          class="f16 flex-ac"
                          style="color: #bbbbbb; padding: 0px 10px"
                        >
                          張桃桃
                        </div>
                      </div>
                      <div class="flex" style="padding-bottom: 10px">
                        <div
                          class="chip_green flex-jac f12"
                          style="margin-right: 10px"
                        >
                          個人戶
                        </div>
                        <div
                          class="chip_grey flex-jac f12"
                          style="margin-right: 10px"
                        >
                          心衣團隊
                        </div>
                        <div class="flex-jac">
                          <img src="@/assets/img/icon-detail.svg" alt="" />
                        </div>
                      </div>
                      <div style="flex: 1"></div>
                      <div style="padding-bottom: 10px">
                        <div class="chip_grey flex-jac f16">下拉未做</div>
                      </div>
                    </div>
                    <div style="#989898;padding-top: 10px;">
                      2022.11.08 17:35
                    </div>
                    <div class="f36" style="color: #389568">50,000 USDT</div>
                    <div class="f12">
                      <div style="color: #d95b5b; font-weight: 700">
                        原因：請選擇審核失敗原因
                      </div>
                    </div>
                  </div>
                  <div
                    class=""
                    style="
                      flex: 2;
                      margin-left: 12px;
                      border-radius: 10px;
                      flex-direction: column;
                      display: flex;
                    "
                  >
                    <div class="flex width100 column_1520" style="flex: 1">
                      <div
                        class="red_btn m-rb-1520"
                        style="flex: 1; flex-direction: column; color: #cc232a"
                      >
                        <div>
                          <img
                            style="width: 40px"
                            src="@/assets/img/icon-pay-bill.svg"
                            alt=""
                          />
                        </div>
                        <div style="padding-top: 5px">查看退款單</div>
                      </div>
                      <div
                        class="red_btn m-b-1520"
                        style="flex: 1; flex-direction: column; color: #cc232a"
                      >
                        <div>
                          <img
                            style="width: 40px"
                            src="@/assets/img/icon-pay-image.svg"
                            alt=""
                          />
                        </div>
                        <div style="padding-top: 5px">查看匯款圖</div>
                      </div>
                    </div>
                    <div class="flex width100 column_1520" style="flex: 1">
                      <div
                        class="red_btn m-r-1520"
                        style="flex: 1; flex-direction: column; color: #cc232a"
                      >
                        <div>
                          <img
                            style="width: 40px"
                            src="@/assets/img/icon-pay-bill.svg"
                            alt=""
                          />
                        </div>
                        <div style="padding-top: 5px">前往BSC鏈</div>
                      </div>
                      <div
                        class="red_btn m-t-1520"
                        style="flex: 1; flex-direction: column; color: #cc232a"
                      >
                        <div>
                          <img
                            style="width: 40px"
                            src="@/assets/img/icon-pay-image.svg"
                            alt=""
                          />
                        </div>
                        <div style="padding-top: 5px">查看匯款人</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <!-- 團隊名稱: "",
        推薦碼: "",
        收款地址: "",
        團隊負責人: "",
        團隊小幫手: "", -->
    <template v-else-if="selectEvent === 'CREATE' || selectEvent === 'EDIT'">
      <div style="padding: 0px 20px" class="">
        <div class="table_team_create">
          <span>團隊名稱</span>
          <input
            class="table_team_create"
            type="text"
            v-model="團隊.團隊名稱"
            placeholder="請設定團隊名稱"
          />
        </div>
        <div class="table_team_create">
          <span>推薦碼</span>
          <input
            class="table_team_create"
            type="text"
            v-model="團隊.推薦碼"
            placeholder="請設定推薦碼"
          />
        </div>
        <div class="table_team_create">
          <span>收款地址</span>
          <input
            class="table_team_create"
            type="text"
            v-model="團隊.收款地址"
            placeholder="請設定收款地址"
          />
        </div>
        <div class="table_team_create">
          <span>團隊負責人</span>
          <select v-model="團隊負責人選擇" class="basic simple">
            <option value="--請填入關鍵字後選擇團隊負責人--" disabled selected>
              --請填入關鍵字後選擇團隊負責人--
            </option>
            <option
              v-for="item in 搜尋團隊負責人會員列表"
              :key="item.fId"
              :value="{ fId: item.fId, f姓名: item.f姓名 }"
            >
              {{ item.f姓名 }}
            </option>
          </select>
          <input
            class="table_team_create"
            type="text"
            v-model="團隊.團隊負責人"
            placeholder="請設定團隊負責人"
          />
        </div>
        <div class="table_team_create">
          <span>團隊小幫手</span>
          <select v-model="團隊小幫手選擇" class="basic simple">
            <option value="--請填入關鍵字後選擇團隊小幫手--" disabled selected>
              --請填入關鍵字後選擇團隊小幫手--
            </option>
            <option
              v-for="item in 搜尋團隊小幫手會員列表"
              :key="item.fId"
              :value="{ fId: item.fId, f姓名: item.f姓名 }"
            >
              {{ item.f姓名 }}
            </option>
          </select>
          <input
            class="table_team_create"
            type="text"
            v-model="團隊.團隊小幫手"
            placeholder="請設定團隊小幫手"
          />
        </div>
      </div>
      <div style="text-align: center">
        <Button
          @click="新增或修改團隊按鈕()"
          :label="selectEvent === 'CREATE' ? '新增團隊' : '修改團隊'"
          style="
            background-color: #283395;
            width: 43%;
            min-width: 320px;
            height: 43px;
          "
          text="white"
          size="status-sm"
          class="text-xs lg:text-lg mr-3"
        />
        <!-- <Button
              label="刪除團隊"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
            /> -->
      </div>
    </template>
    <!-- <popcorn
      class="block lg:hidden"
      name="teamInfo"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>新增管理者帳號</p>
        </template>
        <template #body>
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請輸入管理員帳號"
                hint="gray"
                v-model="manager.account"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員密碼"
                hint="gray"
                v-model="manager.password"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員名稱"
                hint="gray"
                v-model="manager.name"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員錢包地址"
                hint="gray"
                v-model="manager.wallet"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員備註"
                hint="gray"
                v-model="manager.info"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]"></p>

          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn> -->
    <popcorn
      name="doubleCheck"
      :width="335"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #close>
          <!-- <Icon
            icon="icon-cross"
            class="absolute top-[5px] right-[5px] rounded-full w-[27px] h-[27px] bg-red-100 text-white"
            @click="$modal.hide('doubleCheck')"
          /> -->
        </template>
        <template #header>
          <p>請再次確認新增的帳號</p>
        </template>
        <template #body>
          <template>
            <div class="pt-2 pb-[17px]">
              <p class="h-[40px]">{{ manager.account }}</p>
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認新增"
            color="blue"
            text="white"
            size="popup-md"
            @click="confirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('doubleCheck')"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button, Icon } from "@/components/Shared";
// import DatePicker from "@/components/FormItems/DatePicker.vue";
import { teamManagement } from "@/usecase/teamManagement";

// import { FormInput } from "@/components/FormItems";
export default {
  name: "Team_info",
  components: {
    Card,
    Button,
    Icon,
    // DatePicker,
    MoneyInPage: () => import("@/components/Membership/MoneyInPage.vue"),
  },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    selectObj: {
      type: Object,
      default: () => ({}),
    },
    toggleEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      篩選種類: false,
      團隊負責人選擇: "",
      團隊小幫手選擇: "",
      團隊: {
        團隊名稱: "",
        推薦碼: "",
        收款地址: "",
        團隊負責人: "",
        團隊小幫手: "",
      },
      manager: {
        account: "",
        password: "",
        wallet: "",
        name: "",
        info: "",
      },
      團隊tab: "團隊資料",
      defaultStyle: !this.color && !this.outline && !this.icon,
      團隊資訊: [
        {
          團隊名稱: "桃桃",
          推薦碼: "NCAUU21",
          收款地址: "0xf36467Af24caF7F4837688BC281471D0d7D37d01",
          團隊負責人: "張小陶",
          負責人電話: "0968222032",
          負責人郵件: "tao@absolute-uv.com",
          團隊小幫手: "呆們",
          小幫手電話: "0977987329",
          小幫手郵件: "tao@absolute-uv.com",
        },
      ],
      團隊成員: [
        {
          名稱: "A",
          郵件: "fff@gmail.com",
          電話: "0910111111",
        },
        {
          名稱: "A",
          郵件: "fff@gmail.com",
          電話: "0910111111",
        },
        {
          名稱: "A",
          郵件: "fff@gmail.com",
          電話: "0910111111",
        },
      ],
      selectedDate: [
        new Date(),
        new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000),
      ],
      目前選擇種類: "全部",
      重新渲染: 0,
    };
  },
  watch: {
    selectObj: {
      handler(v) {
        console.log("selectObj", v);
        if (this.selectEvent === "EDIT" && JSON.stringify(v) != "{}") {
          this.團隊.團隊名稱 = v.團隊名稱;
          this.團隊.推薦碼 = v.團隊碼;
          this.團隊.收款地址 = v.所有資訊.f收款地址;
          this.團隊.團隊負責人 =
            v.所有資訊.團隊負責人.length > 0
              ? v.所有資訊.團隊負責人[0].f姓名
              : "";
          this.團隊.團隊小幫手 =
            v.所有資訊.團隊小幫手.length > 0
              ? v.所有資訊.團隊小幫手[0].f姓名
              : "";

          //   團隊: {
          //   團隊名稱: "",
          //   推薦碼: "",
          //   收款地址: "",
          //   團隊負責人: "",
          //   團隊小幫手: "",
          // },
        }
      },
      immediate: true,
    },
    "團隊.團隊負責人": {
      handler(v) {
        console.log("團隊負責人", v);
        if (v) {
          teamManagement.A05_查詢_t會員_全搜尋(
            {
              pageIndex: 1,
              pageSize: 1500,
              fuzzySearchKeys: "f姓名",
              fuzzySearchVals: v,
            },
            "團隊負責人"
          );
        } else {
          this.團隊負責人選擇 = "--請填入關鍵字後選擇團隊負責人--";
          teamManagement.A05_查詢_t會員_全搜尋(
            {
              fuzzySearchKeys: "f姓名",
              fuzzySearchVals: null,
            },
            "團隊負責人"
          );
        }
      },
      immediate: true,
    },
    團隊負責人選擇(v) {
      if (v !== "" && v !== "--請填入關鍵字後選擇團隊負責人--") {
        this.團隊.團隊負責人 = v.f姓名;
      }
    },
    "團隊.團隊小幫手": {
      handler(v) {
        if (v) {
          teamManagement.A05_查詢_t會員_全搜尋(
            {
              pageIndex: 1,
              pageSize: 1500,
              fuzzySearchKeys: "f姓名",
              fuzzySearchVals: v,
            },
            "團隊小幫手"
          );
        } else {
          this.團隊小幫手選擇 = "--請填入關鍵字後選擇團隊小幫手--";
          teamManagement.A05_查詢_t會員_全搜尋(
            {
              fuzzySearchKeys: "f姓名",
              fuzzySearchVals: null,
            },
            "團隊小幫手"
          );
        }
      },
      immediate: true,
    },
    團隊小幫手選擇(v) {
      if (v !== "" && v !== "--請填入關鍵字後選擇團隊小幫手--") {
        this.團隊.團隊小幫手 = v.f姓名;
      }
    },
    畫面初始() {
      this.toggleEvent("", {});
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.重新渲染++;
      this.時間起迄查詢團隊入金紀錄();
      this.$store.commit("apiTeamManagement/SET_搜尋團隊負責人會員列表", []);
      this.$store.commit("apiTeamManagement/SET_搜尋團隊小幫手會員列表", []);
      this.團隊負責人選擇 = "--請填入關鍵字後選擇團隊負責人--";
      this.團隊小幫手選擇 = "--請填入關鍵字後選擇團隊小幫手--";
    });
    // this.$modal.show("teamInfo");
  },
  computed: {
    團隊負責人是否存在() {
      return (
        this.selectObj?.所有資訊?.團隊負責人 &&
        this.selectObj.所有資訊.團隊負責人.length > 0
      );
    },
    團隊負責人列表() {
      return this.團隊負責人是否存在 ? this.selectObj.所有資訊.團隊負責人 : [];
    },

    團隊成員是否存在() {
      return (
        this.selectObj?.所有資訊?.團隊成員 &&
        this.selectObj.所有資訊.團隊成員.length > 0
      );
    },
    團隊成員列表() {
      return this.團隊負責人是否存在 && this.selectObj.所有資訊.團隊成員 != null
        ? this.selectObj.所有資訊.團隊成員
        : [];
    },
    團隊入金紀錄列表() {
      return this.$store.state.apiTeamManagement.團隊入金紀錄列表;
    },
    搜尋團隊負責人會員列表() {
      return this.$store.state.apiTeamManagement.搜尋團隊負責人會員列表;
    },
    搜尋團隊小幫手會員列表() {
      return this.$store.state.apiTeamManagement.搜尋團隊小幫手會員列表;
    },
    畫面初始() {
      return this.$store.state.apiTeamManagement.畫面初始;
    },
  },
  methods: {
    新增或修改團隊按鈕() {
      const requiredFields = ["團隊名稱", "推薦碼", "收款地址"];

      for (const field of requiredFields) {
        if (!this.團隊[field]) {
          return this.$notify.warning(`請設定${field}`);
        }
      }
      if (
        this.團隊負責人選擇.fId == "" ||
        this.團隊負責人選擇.fId == undefined
      ) {
        return this.$notify.warning("請設定團隊負責人");
      }
      if (
        this.團隊小幫手選擇.fId == "" ||
        this.團隊小幫手選擇.fId == undefined
      ) {
        return this.$notify.warning("請設定團隊小幫手");
      }
      console.log(this.團隊);
      console.log(this.團隊負責人選擇);
      console.log(this.團隊小幫手選擇);
      this.selectEvent === "CREATE"
        ? teamManagement.A01_新增_t團隊({
            f團隊名稱: this.團隊.團隊名稱,
            f推薦碼: this.團隊.推薦碼,
            f收款地址: this.團隊.收款地址,
            f團隊負責人: this.團隊負責人選擇.fId,
            f團隊小幫手: this.團隊小幫手選擇.fId,
          })
        : teamManagement.A02_修改_t團隊({
            fId: this.selectObj.所有資訊.fId,
            f團隊名稱: this.團隊.團隊名稱,
            f推薦碼: this.團隊.推薦碼,
            f收款地址: this.團隊.收款地址,
            f團隊負責人: this.團隊負責人選擇.fId,
            f團隊小幫手: this.團隊小幫手選擇.fId,
          });
    },
    篩選種類點選(v) {
      console.log("bbbbb", this.selectObj);
      // this.reload()
      let n = "";
      if (v == "審核中") {
        n = 91;
      } else if (v == "申請中") {
        n = 0;
      } else if (v == "完成交易") {
        n = 1;
      } else if (v == "審核失敗") {
        n = -1;
      } else {
        n = null;
      }

      this.重新渲染++;
      this.目前選擇種類 = v;

      teamManagement.A05_查詢_入金紀錄_團隊_全搜尋({
        時間起: localStorage.getItem("團隊入金紀錄時間起"),
        時間迄: localStorage.getItem("團隊入金紀錄時間迄"),
        成員列表: this.selectObj.所有資訊.f團隊成員,
        SearchCol: "f狀態",
        SearchVal: n,
      });
    },
    selectTime(val) {
      console.log("bbbbb", this.selectObj);
      if (typeof val.end == "object") {
        const 開始 = val.start;
        const 開始年 = 開始.getFullYear();
        const 開始月 = 開始.getMonth() + 1;
        const 開始日 = 開始.getDate();
        const 開始時間 = `${開始年}-${開始月 < 10 ? "0" + 開始月 : 開始月}-${
          開始日 < 10 ? "0" + 開始日 : 開始日
        }`;
        const 結束 = val.end;
        const 結束年 = 結束.getFullYear();
        const 結束月 = 結束.getMonth() + 1;
        const 結束日 = 結束.getDate();
        const 結束時間 = `${結束年}-${結束月 < 10 ? "0" + 結束月 : 結束月}-${
          結束日 < 10 ? "0" + 結束日 : 結束日
        }`;
        localStorage.setItem("團隊入金紀錄時間起", 開始時間);
        localStorage.setItem("團隊入金紀錄時間迄", 結束時間);

        teamManagement.A05_查詢_入金紀錄_團隊_全搜尋({
          時間起: localStorage.getItem("團隊入金紀錄時間起"),
          時間迄: localStorage.getItem("團隊入金紀錄時間迄"),
          成員列表: this.selectObj.所有資訊.f團隊成員,
          SearchCol: "f狀態",
          SearchVal: this.目前選擇種類,
        });
      }
    },
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("teamInfo");
    },
    時間起迄查詢團隊入金紀錄() {
      if (this.selectObj.所有資訊 == undefined) return;
      const 三天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      const 三天前年 = 三天前.getFullYear();
      const 三天前月 = 三天前.getMonth() + 1;
      const 三天前日 = 三天前.getDate();
      const 三天前時間 = `${三天前年}-${
        三天前月 < 10 ? "0" + 三天前月 : 三天前月
      }-${三天前日 < 10 ? "0" + 三天前日 : 三天前日}`;

      const 當前年 = new Date().getFullYear();
      const 當前月 = new Date().getMonth() + 1;
      const 當前日 = new Date().getDate();
      const 當前時間 = `${當前年}-${當前月 < 10 ? "0" + 當前月 : 當前月}-${
        當前日 < 10 ? "0" + 當前日 : 當前日
      }`;
      localStorage.setItem("團隊入金紀錄時間起", 三天前時間);
      localStorage.setItem("團隊入金紀錄時間迄", 當前時間);

      teamManagement.A05_查詢_入金紀錄_團隊_全搜尋({
        時間起: 三天前時間,
        時間迄: 當前時間,
        成員列表: this.selectObj.所有資訊.f團隊成員,
        SearchCol: "f狀態",
        SearchVal: this.目前選擇種類,
      });
    },
    // validEmail: function (email) {
    //   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
    //   return re.test(email);
    // },
    // firstConfirm() {
    //   if (
    //     this.manager.account == "" ||
    //     this.manager.password == "" ||
    //     this.manager.wallet == "" ||
    //     this.manager.name == ""
    //   ) {
    //     // alert("輸入不完整");
    //     this.$notify.warning("輸入不完整");
    //     return;
    //   }

    //   if (!this.validEmail(this.manager.account)) {
    //     this.$notify.warning("帳號格式不正確");
    //     return;
    //   }
    //   // this.$emit("toggleEvent", "");
    //   this.$modal.hide("teamInfo");
    //   this.$modal.show("doubleCheck");
    //   // this.doubleConfirm = true;
    // },
    // confirm() {
    //   let obj = {
    //     fAccount: this.manager.account,
    //     fPWd: this.manager.password,
    //     fName: this.manager.name,
    //     fEmail: JSON.stringify({
    //       address: this.manager.wallet,
    //       onChain: false,
    //       info: this.manager.info,
    //     }),
    //   };
    //   console.log("當前角色", this.role, this.$tools);
    //   this.$store
    //     .dispatch("apiAdmin/SignUpNewGenAdmin", this.$tools.ObjToForm(obj))
    //     .then((res) => {
    //       if (res.data != 0) {
    //         this.$emit("toggleEvent", "");
    //         this.$emit("successEvent");
    //         this.$notify.success("新增成功");
    //         this.$modal.hide("doubleCheck");
    //       } else {
    //         // alert("操作失敗，請聯絡程式人員");
    //         this.$notify.warning("操作失敗，請聯絡程式人員");
    //       }
    //     });
    // },
  },
};
</script>
<style scoped>
select.simple {
  background: #f5f5f5;
  border: 0;
  padding: 6px;
}
.readContent {
  padding: 0px 20px;
  height: 100%;
}
.red_btn {
  border-radius: 10px;
  border: red solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip_green {
  padding: 0px 10px;
  border-radius: 5px;
  background-color: #71b57c;
  color: #ffffff;
}
.chip_grey {
  padding: 0px 10px;
  border-radius: 5px;
  background-color: #5d5d5d;
  color: #ffffff;
}
.text-green {
  color: #389568;
}
.table_team {
  width: 100%;
  padding: 20px;
  border: 1px solid #ececec;
  color: #878787;
  display: flex;
}
.table_team_create {
  color: #878787;
  margin-bottom: 14px;
}
.table_team_create span {
  padding: 5px 7px;
  font-size: 14px;
}
.table_team_create input {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px 7px;
  outline: none;
  margin-top: 5px;
  font-size: 16px;
}
.table_team_create input:-ms-input-placeholder {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}

.table_team_create input::-ms-input-placeholder {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}

.table_team_create input::-webkit-input-placeholder {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 780px) {
  .readContent {
    padding: 0;
  }
  .chip_green,
  .chip_grey {
    padding: 5px 2px;
  }
  .f36 {
    font-size: 30px;
  }
  .f16 {
    font-size: 14px !important;
  }
}
</style>
<style>
.column_1520 {
  flex-direction: unset;
}
.m-rb-1520 {
  margin-right: 10px;
  margin-bottom: 5px;
}
.m-b-1520 {
  margin-bottom: 5px;
}
.m-r-1520 {
  margin-right: 10px;
  margin-top: 5px;
}
.m-t-1520 {
  margin-top: 5px;
}
.flex-ac_1520 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1520px) {
  .column_1520 {
    flex-direction: column;
  }
  .m-rb-1520 {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .m-r-1520 {
    margin-right: 0px;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .flex-ac_1520 {
    display: flex;
    align-items: unset;
  }
  .m-t-1520 {
    margin-top: 0px;
  }
}
</style>
