import { TWD_USDT_Data, USDT_TWD_Data } from "@/assets/mockData/mockTrading.js";

export const trading = {
  namespaced: true,
  state: {
    baseCurrency: "USDT",
    direction: "buying",
    TWD_USDT_Data: [],
    USDT_TWD_Data: [],
    tradingHistoryData: [],
  },
  mutations: {
    changeBaseCurrency(state, newBaseCurrency) {
      state.baseCurrency = newBaseCurrency;
    },
    setTWD_USDT_Data(state, data) {
      state.TWD_USDT_Data = data;
    },
    setUSDT_TWD_Data(state, data) {
      state.USDT_TWD_Data = data;
    },
    setTradingHistoryData(state, TradingHistoryData) {
      state.tradingHistoryData = TradingHistoryData;
    },
    getTradingHistoryData(state, direction) {
      // state.tradingHistoryData =
      //   direction === "buying" ? state.TWD_USDT_Data : state.USDT_TWD_Data;
      state.tradingHistoryData =
        direction === "buying" ? TWD_USDT_Data : USDT_TWD_Data;
    },
    changeTradingDirection(state, direction) {
      state.direction = direction;
    },
  },
  getters: {
    tradingHistoryData({ tradingHistoryData }) {
      return tradingHistoryData;
    },
    baseCurrency({ baseCurrency }) {
      return baseCurrency;
    },
    counterCurrency({ counterCurrency }) {
      return counterCurrency;
    },
    direction({ direction }) {
      return direction;
    },
  },
};
