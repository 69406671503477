<template>
  <div
    v-show="openPopup"
    class="fixed z-40 top-[60px] lg:top-0 bottom-0 left-0 lg:left-[270px] xl:left-[314px] right-0 max-w-full max-h-full bg-black"
    :class="removeShadow ? 'bg-opacity-0' : 'bg-opacity-70'"
  >
    <div class="w-full h-full flex p-5 overflow-scroll no-scrollbar">
      <div class="modal_content bg-white relative z-50 rounded-popup m-auto">
        <Icon
          v-if="closeIcon"
          @click="closePopup"
          icon="icon-cross"
          class="absolute -top-3 -right-3 rounded-full w-[27px] h-[27px] bg-red-100 text-white"
        />
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  name: "PopupWindow",
  components: {
    Icon,
  },
  props: {
    closeIcon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    removeShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    openPopup() {
      return this.value;
    },
  },
  methods: {
    closePopup() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
