<template>
  <div
    class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
    <div class="flex justify-around">
      <div class="flex-1" :class="selectEvent == '' ? '' : 'lg:w-48'">
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Authority Management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>權限管理名單</strong>
            </h2>
          </div>
          <div class="flex flex-col items-end pt-[45px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入帳號搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
            <Button
              label="新增管理者"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mt-3"
              @click="toggleEvent('CREATE')"
            />
          </div>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar">
          <Table
            :rows="$store.getters['manager/managerData']"
            :cols="cols"
            :disableStatus="true"
          >
            <template #edit="manager">
              <div
                v-if="manager.account != 'ROOT'"
                class="flex h-full justify-center"
              >
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-vector.svg')"
                  @click="toggleEvent('SETTING', manager)"
                />
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-frame.svg')"
                  @click="toggleEvent('DELETE', manager)"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
      <div :class="selectEvent == '' ? '' : 'flex-1 lg:w-48'">
        <AddManager
          v-if="selectEvent == 'CREATE'"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></AddManager>
        <DelManager
          v-else-if="selectEvent == 'DELETE'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></DelManager>
        <SettingManager
          v-else-if="selectEvent == 'SETTING'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></SettingManager>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, Input, Icon, Button } from "@/components/Shared";
import { formatWalletAddress } from "@/utils";
import { AddManager, DelManager, SettingManager } from "@/components/Manager";

export default {
  name: "Manager",
  components: {
    Table,
    Input,
    Icon,
    Button,
    AddManager,
    DelManager,
    SettingManager,
  },
  data() {
    return {
      selectEvent: "",
      selectObj: {},
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "apply_name",
          label: "姓名",
          align: "center",
        },
        { name: "account", label: "帳號", align: "center" },
        {
          name: "wallet_address",
          label: "地址",
          align: "center",
          format: (val) => formatWalletAddress(val),
        },
        { name: "edit", label: "編輯", align: "center" },
      ],

      searchWords: "",
      名單初始:[]
    };
  },
  watch: {
    searchWords(val){
      if(val ==''){
        this.api().RootGetAllAdminInfo()
      }
    }
  },
  methods: {
    搜尋按鈕(){
      if(this.searchWords==''){
        this.$notify.warning("請輸入帳號");
        return
      }
      const arr = this.名單初始.filter(i=>{
        return i.account.indexOf(this.searchWords)!=-1
      })
      this.$store.commit("manager/setManager", arr)
      console.log(arr);
    },
    successEvent() {
      return this.api().RootGetAllAdminInfo();
    },
    toggleEvent(event, data = {}) {
      if (this.selectEvent == "") {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }
      console.log("結果", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
    api() {
      const self = this;
      const fn = {
        管理員切換鍊: (address) => {
          self.$modal.show("loading");
          self.$notify.info("等待操作中");
          self.$store
            .dispatch("ast/管理員切換鍊", address)
            .then((res) => {
              console.log("結果", res);
              self.$notify.success("修改成功");
              self.$modal.hide("loading");
              self.api().RootGetAllAdminInfo();
            })
            .catch((e) => {
              console.log("e", e);
              self.$modal.hide("loading");
            });
        },
        RootGetAllAdminInfo: () => {
          self.$store
            .dispatch(
              "apiAdmin/RootGetAllAdminInfo",
              self.$store.state.login.token
            )
            .then((res) => {
        
              let rows = [];
              let address = [];
              res.data.map((dt) => {
                dt = this.$deepParseJson(dt);
                let chain = dt.fEmail;
                let obj = {
                  apply_name: dt.fName,
                  account: dt.fAccount,
                  auth: dt.fAuthority,
                  wallet_address: chain.address,
                  id: dt.fId,
                  onChain: chain.onChain,
                };
                rows.push(obj);
                address.push(chain.address);
              });
              console.log(address);
              this.名單初始 = rows
              self.$store.commit("manager/setManager", rows); //先顯示
              self.$store.commit("ast/setAdminAddress", address);
              if (self.$store.state.ast.account != "") {
                self.$store
                  .dispatch(
                    "ast/取得管理員上鍊名單",
                    self.$store.state.ast.adminAddress
                  )
                  .then((res) => {
                    console.log("取得管理員上鍊名單結果", res);

                    for (let i = 0; i < rows.length; i++) {
                      rows[i].onChain = res[i];
                    }
                    self.$store.commit("manager/setManager", rows);
                    self.$store.commit("ast/setAdminStatus", res);
                  });
              }
            })
            .catch((e) => {
              console.log("錯誤", e);
              if (e.response.status == 403) {
                // alert("沒有權限");
                self.$notify.warning("沒有權限");
                self.$router.push({ path: "/level2-validation" });
              }
            });
        },
        DelGenAdmin: (manager) => {
          var yes = confirm("確定要刪除此管理員嗎");
          console.log("確定要刪除此管理員嗎", manager);
          if (!yes) {
            return;
          }
          let obj = { fId: manager.id };
          let formdata = this.$tools.ObjToForm(obj);
          self.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
            let data = res.data;
            if (data != 0) {
              // alert("刪除成功");
              self.$notify.success("刪除成功");
              self.api().RootGetAllAdminInfo();
            }
          });
        },
      };
      return fn;
    },
  },
  computed: {},
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    this.api().RootGetAllAdminInfo();
  },
};
</script>

<style>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}
.subgroup-green .sub {
  color: #c9eadc;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
.subgroup-green .subtitle {
  color: #389568;
  -webkit-text-stroke: 0.5px #389568;
}
</style>
