<template>
  <div
    class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
    <div
      @click="selectEvent = ''"
      v-if="selectEvent != ''"
      class="px-4 appArrow"
    >
      <span style="cursor: pointer">&#10154;</span>
    </div>
    <div class="flex justify-around">
      <!-- 左邊 -->
      <div
        class="flex-1"
        :class="selectEvent == '' ? '' : 'lg:w-48 appHandle2'"
      >
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Authority Management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>二級驗證申請</strong>
            </h2>
          </div>
          <div class="flex flex-col items-end pt-[45px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入帳號搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mb-3">
          <div>
            <Button
              label="全部"
              color="gray"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '全部'"
            />
            <Button
              label="審核失敗"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '審核失敗'"
            />
            <Button
              label="未審核"
              color="blue"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '未審核'"
            />
          </div>
          <dropdown-menu
            v-model="show"
            :right="true"
            :hover="false"
            :interactive="true"
          >
            <button class="text-[#cc232a]">
              <img class="w-[100%]" src="@/assets/img/icon-download.svg" />
            </button>
            <div
              style="right: 30px"
              class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
              slot="dropdown"
            >
              <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li @click="匯出CSV()" class="hover:bg-[#cc232a]">
                  <a
                    href="#"
                    class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                    >匯出CSV</a
                  >
                </li>
                <!-- <li class="hover:bg-[#cc232a]">
                  <a
                    href="#"
                    class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                    >匯出PDF</a
                  >
                </li> -->
              </ul>
            </div>
          </dropdown-menu>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar">
          <Table
            v-if="審核[searchTarget].length != 0"
            :rows="!搜尋中 ? 審核[searchTarget] : 搜尋後rows"
            :cols="cols"
            :disableStatus="false"
          >
            <template #edit="manager">
              <div
                v-if="manager.account != 'ROOT'"
                class="flex h-full justify-center"
              >
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-scale.svg')"
                  @click="toggleEvent('DETAIL', manager)"
                />
                <!-- <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-frame.svg')"
                  @click="toggleEvent('DELETE', manager)"
                /> -->
              </div>
            </template>
          </Table>
          <img
            v-else
            class="w-[180px]"
            style="margin: 0 auto"
            src="@/assets/img/icon-nodata.svg"
            alt="male icon"
          />
        </div>
      </div>
      <!-- 右邊 -->
      <div :class="selectEvent == '' ? '' : 'flex-1 lg:w-48 px-3 appHandle'">
        <VerifyMembership
          v-if="selectEvent == 'DETAIL'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></VerifyMembership>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, Input, Icon, Button } from "@/components/Shared";
import { VerifyMembership } from "@/components/Membership";
import { verify } from "@/usecase/verify";
import DropdownMenu from "@innologica/vue-dropdown-menu";

export default {
  name: "Level2",
  components: {
    Table,
    Input,
    Icon,
    Button,
    VerifyMembership,
    DropdownMenu,
  },
  data() {
    return {
      selectEvent: "",
      selectObj: {},
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "f新增時間",
          label: "申請時間",
          align: "center",
          format: (val) => {
            return val.replace("T", " ").split(".")[0];
          },
        },
        { name: "f信箱", label: "申請帳號", align: "center" },
        {
          name: "f姓名",
          label: "申請人",
          align: "center",
        },
        { name: "edit", label: "編輯", align: "center" },
      ],

      searchWords: "",
      searchTarget: "全部",
      bankList: ["匯出CSV", "匯出PDF"],
      formValues: "匯出",
      show: false,

      搜尋中: false,
      搜尋後rows: [],
    };
  },
  watch: {
    searchWords(val) {
      if (val == "") {
        this.搜尋中 = false;
        this.搜尋後rows = [];
      }
    },
  },
  methods: {
    匯出CSV() {
      let config = {
        headers: { Authorization: "Bearer " + this.$store.state.login.token },
      };
      // fetch("http://220.134.4.63:6382/api/Export/CSV/VerifyemberInfo", config)
      fetch("https://amlservice.qhr.app/api/Export/CSV/VerifyemberInfo", config)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response.data);
          // const blob = new Blob([response], { type: 'application/vnd.ms-excel;charset=utf-8' })
          // console.log(blob);
          var link = document.createElement("a");
          link.href = "https://amlservice.qhr.app/" + response.data;
          // link.href = "http://220.134.4.63:6382/" + response.data;
          link.click();
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
    },
    搜尋按鈕() {
      this.搜尋中 = true;
      this.搜尋後rows = this.審核[this.searchTarget].filter((i) => {
        return i.f信箱.indexOf(this.searchWords) != -1;
      });
    },
    successEvent() {
      console.log("EVENT");
      this.A05_顯示審核列表Join會員();
    },
    toggleEvent(event, data = {}) {
      data = this.$deepParseJson(data); //把所有JSONstring 解開
      if (this.selectEvent == "") {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }
      console.log("結果", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
    A05_顯示審核列表Join會員() {
      verify.查詢審核列表();
    },
  },
  computed: {
    審核: function () {
      return {
        全部: this.$store.state.apiVerify.審核列表,
        審核失敗: this.$store.state.apiVerify.審核列表.filter(
          (m) => m.審核狀態 == 2
        ),
        未審核: this.$store.state.apiVerify.審核列表.filter(
          (m) => m.審核狀態 == 0
        ),
      };
    },
  },
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    this.A05_顯示審核列表Join會員();

    // this.api().RootGetAllAdminInfo();
  },
};
</script>

<style scoped>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}
.subgroup-green .sub {
  color: #c9eadc;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
.subgroup-green .subtitle {
  color: #389568;
  -webkit-text-stroke: 0.5px #389568;
}
.appArrow {
  display: none;
}
@media screen and (max-width: 1300px) {
  .appArrow {
    font-size: 31px;
    transform: rotate(180deg);
    display: flex;
    justify-content: end;
  }
  .appHandle {
    width: 100%;
  }
  .appHandle2 {
    display: none;
  }
}
</style>
