import { mockManagerData } from "@/assets/mockData";

export const manager = {
  namespaced: true,
  state: {
    managerData: [],
  },
  getters: {
    managerData({ managerData }) {
      return managerData;
    },
  },
  mutations: {
    getManagerData(state) {
      console.log("取得", state);
      //state.managerData = mockManagerData;
    },
    addManager(state, newManager) {
      //mockManagerData.push(newManager);
      console.log(newManager);
      state.managerData.push(newManager);
    },
    setManager(state, AllNew) {
      state.managerData = AllNew;
    },
    deleteManager(state, managerAccount) {
      state.managerData = mockManagerData.filter(
        (manager) => manager.account !== managerAccount
      );
    },
    changeManagerChainStatus(state, { managerAccount, newChainStatus }) {
      console.log(managerAccount);
      const targetIndex = mockManagerData.findIndex(
        (manager) => manager.account === managerAccount
      );
      console.log(mockManagerData, targetIndex);
      mockManagerData[targetIndex].onChain = newChainStatus;
      state.managerData = mockManagerData;
      console.log("取得2", state);
    },
  },
};
