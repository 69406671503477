import store from "@/store"; // 直接取store
import { tools } from "@/assets/tools.js";
import { deepParseJson } from "deep-parse-json";
export const teamManagement = {
  A01_新增_t團隊(data) {
    console.log('A01_新增_t團隊', data);
    store.dispatch("apiTeamManagement/A01_新增_t團隊", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A01_新增_t團隊", data);
        teamManagement.GA04_查詢_t團隊_全搜尋()
        store.commit("apiTeamManagement/SET_畫面初始"); //deepparseJson
      });
  },
  A02_修改_t團隊(data) {
    console.log('A02_修改_t團隊', data);
    store.dispatch("apiTeamManagement/A02_修改_t團隊", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A02_修改_t團隊", data);
        teamManagement.GA04_查詢_t團隊_全搜尋()
        store.commit("apiTeamManagement/SET_畫面初始"); //deepparseJson
      });
  },
  A05_查詢_t會員_全搜尋(data, 類別) {
    console.log('A05_查詢_t會員_全搜尋', data);
    store.dispatch("apiTeamManagement/A05_查詢_t會員_全搜尋", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A05_查詢_t會員_全搜尋", data);
        // teamManagement.GA04_查詢_t團隊_全搜尋()
        store.commit(`apiTeamManagement/${類別 == '團隊負責人' ? 'SET_搜尋團隊負責人會員列表' : 'SET_搜尋團隊小幫手會員列表'}`, data === '' ? [] : data); //deepparseJson
      });
  },
  GA04_查詢_t團隊_全搜尋(搜尋團隊名稱) {
    console.log('搜尋團隊名稱', 搜尋團隊名稱);
    const data = {
      pageIndex: 1,
      pageSize: 1500,
    }
    if (搜尋團隊名稱 && 搜尋團隊名稱 != undefined) {
      data.FuzzyCol = 'f團隊名稱'
      data.FuzzyVal = 搜尋團隊名稱
    }
    store.dispatch("apiTeamManagement/GA04_查詢_t團隊_全搜尋", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("取得GA04_查詢_t團隊_全搜尋", data);
        store.commit("apiTeamManagement/SET_團隊管理列表", data); //deepparseJson
        store.commit("apiMoneyInPage/SET_入金紀錄", data); //deepparseJson

      });
  },
  A05_查詢_入金紀錄_團隊_全搜尋({ 時間起, 時間迄, 成員列表, SearchCol, SearchVal }) {

    console.log('SearchCol', SearchCol);
    console.log('SearchVal', SearchVal);
    const data = {
      pageIndex: 1,
      pageSize: 1500,
      時間起,
      時間迄,
      成員列表,
    }
    console.log(data);

    if (SearchVal !== undefined && SearchVal !== null && SearchVal !== '全部') {
      data.SearchCol = SearchCol
      data.SearchVal = SearchVal
    }
    store.dispatch("apiTeamManagement/A05_查詢_入金紀錄_團隊_全搜尋", data)
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("A05_查詢_入金紀錄_團隊_全搜尋", data);
        store.commit("apiTeamManagement/SET_團隊入金紀錄", data); //deepparseJson
        store.commit("apiMoneyInPage/SET_入金紀錄", data); //deepparseJson

      });
  },
  上傳退款TXID: (出金編號, 退款TXID, 審核失敗原因) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A03_上傳退款TXID_修改",
          tools.ObjToForm({ 出金編號, 退款TXID, 審核失敗原因 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  上傳匯款單: (出金編號, 匯款單) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A02_上傳匯款單_修改",
          tools.ObjToForm({ 出金編號, 匯款單 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
