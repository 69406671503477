import { request } from "@/store/api";
export const apiMoneyOut = {
  namespaced: true,
  state: { 入金紀錄: [] },
  getters: {},
  mutations: {
    SET_入金紀錄(state, 入金紀錄) {
      console.log(入金紀錄);
      state.入金紀錄 = 入金紀錄;
    },
  },
  actions: {
    A01_查詢_單筆(state, data) {
      return request
        .addAuth("Withdraw")
        .get("/WithdrawInfo/All/", { params: data });
    },
    A02_上傳匯款單_修改(state, data) {
      return request.addAuth("Withdraw").put("/WithdrawInfo/MoneyOrder", data);
    },
    A03_上傳退款TXID_修改(state, data) {
      return request.addAuth("Withdraw").put("/WithdrawInfo/TXID", data);
    },
    A04_查詢_出金紀錄(state, data) {
      return request
        .addAuth("Withdraw")
        .get("/WithdrawInfo/All/", { params: data });
    },
    A05_查詢_戰情分析列表(state, data) {
      return request
        .addAuth("Withdraw")
        .get("/WithdrawInfo/Admin/", { params: data });
    },
  },
};
