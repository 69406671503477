<template>
  <div>
    <div class="hidden lg:block" v-if="selectEvent != ''">
      <div class="subgroup-red text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Remove Admin</strong>
        </h2>
        <h2 class="subtitle">
          <strong></strong>
        </h2>
        刪除公告
      </div>
      <div class="mt-[30px]">
        <div class="text-center pt-2 pb-[17px]">
          <h1 class="text-gray-300 pb-[17px]">請再次確認刪除公告</h1>
          <h2 class="h-[80px] text-5xl">{{ selectObj.f標題 }}</h2>
        </div>

        <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
        <Button
          label="確認刪除"
          color="red"
          text="white"
          size="popup-md"
          @click="DelNews()"
        />
      </div>
    </div>
    <popcorn
      class="block lg:hidden"
      name="delManager"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="red" type="dialog">
        <template #header>
          <p>刪除公告</p>
        </template>
        <template #body>
          <template>
            <div class="text-center pt-2 pb-[17px]">
              <h1 class="text-gray-300">請再次確認刪除公告</h1>
              <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2>
            </div>
          </template>
        </template>
        <template #footer>
          <Button
            label="確認刪除"
            color="red"
            text="white"
            size="popup-md"
            @click="DelNews()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button } from "@/components/Shared";
import { news } from "@/usecase/news";

export default {
  name: "DelNews",
  components: { Button, Card },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },

    selectObj: {
      type: Object,
    },
    列表文章類別選擇: {
      type: String,
      default: "",
    },
    searchWords: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
    };
  },
  mounted() {
    this.$modal.show("delManager");
  },
  computed: {},
  methods: {
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("delManager");
    },
    DelNews() {
      let obj = { fId: this.selectObj.fId };
      this.$store.dispatch("apiNews/A08_刪除_t最新消息", obj).then((res) => {
        console.log(res);
        if (res.message == "刪除最新消息成功。") {
          this.$emit("successEvent");
          this.$notify.success(res.message);
          this.closeWindow();
        } else {
          this.$notify.warning(res.message);
        }
        news.A10_查詢_t最新消息(
          this.列表文章類別選擇,
          localStorage.getItem("公告中心列表時間起"),
          localStorage.getItem("公告中心列表時間迄"),
          this.searchWords
        );
      });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
