<template>
  <div
    class="bg-gray-100 h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
    <div class="flex justify-around">
      <div class="flex-1" :class="selectEvent == '' ? '' : 'lg:w-48'">
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Authority Management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>幣商管理</strong>
            </h2>
            <div>
              <div style="display: flex; align-items: center">
                <div
                  @click="$refs.file.click()"
                  style="
                    display: flex;
                    align-items: center;
                    border: 2px solid #ce0000;
                    width: fit-content;
                    padding: 3px 6px;
                    border-radius: 6px;
                    margin-top: 12px;
                    cursor: pointer;
                    margin-bottom: 3px;
                  "
                >
                  <span style="font-size: 15px; font-weight: 900"
                    >上傳excel查詢</span
                  >
                  <img
                    style="width: 20px; margin-left: 6px"
                    src="@/assets/img/icon-download.svg"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    margin-left: 13px;
                  "
                >
                  <span
                    @click="範例下載()"
                    style="font-size: 13px; font-weight: 800; cursor: pointer"
                    >範例下載</span
                  >
                  <img
                    style="
                      width: 20px;
                      margin-left: 6px;
                      margin-left: 3px;
                      width: 13px;
                    "
                    src="@/assets/img/icon-download.svg"
                  />
                </div>
              </div>
            </div>
            <input
              ref="file"
              type="file"
              v-show="false"
              v-on:change="handleFileUpload()"
            />
          </div>
          <div class="flex flex-col items-end pt-[45px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入名稱或編號搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
            <div class="appBtn">
              <div class="appHandle">
                <div
                  @click="$refs.file.click()"
                  style="
                    display: flex;
                    align-items: center;
                    border: 2px solid #ce0000;
                    width: fit-content;
                    padding: 3px 6px;
                    border-radius: 6px;
                    margin-top: 12px;
                    cursor: pointer;
                    margin-bottom: 10px;
                  "
                >
                  <span style="font-size: 15px; font-weight: 900"
                    >上傳excel查詢</span
                  >
                  <img
                    style="width: 20px; margin-left: 6px"
                    src="@/assets/img/icon-download.svg"
                  />
                </div>
                <div
                  style="display: flex; align-items: center; margin-left: 10px"
                >
                  <span
                    @click="範例下載()"
                    style="font-size: 13px; font-weight: 800; cursor: pointer"
                    >範例下載</span
                  >
                  <img
                    style="
                      width: 20px;
                      margin-left: 6px;
                      margin-left: 3px;
                      width: 13px;
                    "
                    src="@/assets/img/icon-download.svg"
                  />
                </div>
              </div>
              <Button
                label="新增幣商"
                color="red"
                text="white"
                size="status-sm"
                class="text-xs lg:text-lg mt-3"
                @click="toggleEvent('CREATE')"
              />
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar">
          <!-- {{$store.getters['manager/managerData']}} -->

          <Table
            :key="渲染後讀取"
            :rows="幣種rows"
            :cols="cols"
            :disableStatus="true"
          >
            <template #edit="manager">
              <div
                style="align-items: center"
                v-if="manager.account != 'ROOT'"
                class="flex h-full justify-center"
              >
                <img
                  style="
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    margin: 0 3px;
                  "
                  @click="toggleEvent('SETTING', manager)"
                  :src="require('@/assets/img/icon-vector.svg')"
                  alt="icon"
                  class="flex-shrink-0"
                />

                <img
                  style="
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    margin: 0 3px;
                  "
                  @click="toggleEvent('DELETE', manager)"
                  :src="require('@/assets/img/icon-frame.svg')"
                  alt="icon"
                  class="flex-shrink-0"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
      <div>
        <AddCoinManager
          v-if="selectEvent == 'CREATE'"
          :selectEvent="selectEvent"
          :管理員查詢幣商_全搜尋處理="管理員查詢幣商_全搜尋處理"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></AddCoinManager>
        <DelCoinManager
          v-else-if="selectEvent == 'DELETE'"
          :selectObj="selectObj"
          :管理員查詢幣商_全搜尋處理="管理員查詢幣商_全搜尋處理"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></DelCoinManager>
        <SettingCoinManager
          v-else-if="selectEvent == 'SETTING'"
          :selectObj="selectObj"
          :管理員查詢幣商_全搜尋處理="管理員查詢幣商_全搜尋處理"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></SettingCoinManager>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, Input, Icon, Button } from "@/components/Shared";
import { 銀行資訊解析 } from "@/utils";
import {
  AddCoinManager,
  DelCoinManager,
  SettingCoinManager,
} from "@/components/Manager";

export default {
  name: "Manager",
  components: {
    Table,
    Input,
    Icon,
    Button,
    AddCoinManager,
    DelCoinManager,
    SettingCoinManager,
  },
  data() {
    return {
      範例excel路徑: "",
      selectEvent: "",
      selectObj: {},
      幣種rows: [],
      渲染後讀取: 0,
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        { name: "f廠商名稱", label: "廠商名稱", align: "center" },
        {
          name: "f匯款帳號",
          label: "匯款帳號",
          align: "center",
        },

        { name: "f廠商編號", label: "廠商編號", align: "center" },
        { name: "f當日限額", label: "當日限額", align: "center" },
        // { name: "f總匯款量", label: "總匯款量", align: "center" },
        {
          name: "f銀行資訊",
          label: "銀行資訊",
          align: "center",
          format: (val) => 銀行資訊解析(val),
        },
        { name: "edit", label: "編輯", align: "center" },
      ],

      searchWords: "",
      名單初始: [],
    };
  },
  watch: {
    searchWords(val) {
      if (val == "") {
        this.管理員查詢幣商_全搜尋處理();
      }
    },
  },
  methods: {
    範例下載() {
      var link = document.createElement("a");
      link.href = "https://amlservice.qhr.app/" + this.範例excel路徑;
      // link.href ='http://220.134.4.63:6382/'+this.範例excel路徑
      link.click();
    },
    範例excel檔案初始() {
      this.$store.dispatch("apiAdmin/管理員取得EXCEL的範本路徑").then((res) => {
        this.範例excel路徑 = res.data;
        console.log(res.data);
      });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.$store
        .dispatch("apiAdmin/管理員透過幣商EXCEL查詢會員資料", {
          EXCEL: this.$refs.file.files[0],
        })
        .then((res) => {
          console.log(res);
          if (res.message == "查詢成功。") {
            this.$notify.success("查詢成功，已下載excel檔案");
            var link = document.createElement("a");
            link.href = "https://amlservice.qhr.app/" + res.data;
            // link.href = "http://220.134.4.63:6382/" + res.data;
            link.click();
          }
        });
      console.log(this.file);
    },
    搜尋按鈕() {
      if (this.searchWords == "") {
        this.$notify.warning("請輸入帳號");
        return;
      }
      const arr = this.幣種rows.filter((i) => {
        if (i.f廠商名稱.indexOf(this.searchWords) != -1) {
          return i.f廠商名稱.indexOf(this.searchWords) != -1;
        } else if (i.f廠商編號.indexOf(this.searchWords) != -1) {
          return i.f廠商編號.indexOf(this.searchWords) != -1;
        }
      });
      this.幣種rows = arr;
      console.log(arr);
    },
    successEvent() {
      return this.api().RootGetAllAdminInfo();
    },
    toggleEvent(event, data = {}) {
      if (this.selectEvent == "") {
        console.log(1);
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        console.log(2);
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }
      console.log("結果", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
    api() {
      const self = this;
      const fn = {
        管理員切換鍊: (address) => {
          self.$modal.show("loading");
          self.$notify.info("等待操作中");
          self.$store
            .dispatch("ast/管理員切換鍊", address)
            .then((res) => {
              console.log("結果", res);
              self.$notify.success("修改成功");
              self.$modal.hide("loading");
              self.api().RootGetAllAdminInfo();
            })
            .catch((e) => {
              console.log("e", e);
              self.$modal.hide("loading");
            });
        },
        RootGetAllAdminInfo: () => {
          self.$store
            .dispatch(
              "apiAdmin/RootGetAllAdminInfo",
              self.$store.state.login.token
            )
            .then((res) => {
              let rows = [];
              let address = [];
              res.data.map((dt) => {
                dt = this.$deepParseJson(dt);
                let chain = dt.fEmail;
                let obj = {
                  apply_name: dt.fName,
                  account: dt.fAccount,
                  auth: dt.fAuthority,
                  wallet_address: chain.address,
                  id: dt.fId,
                  onChain: chain.onChain,
                };
                rows.push(obj);
                address.push(chain.address);
              });
              console.log(address);
              this.名單初始 = rows;
              self.$store.commit("manager/setManager", rows); //先顯示
              self.$store.commit("ast/setAdminAddress", address);
              if (self.$store.state.ast.account != "") {
                self.$store
                  .dispatch(
                    "ast/取得管理員上鍊名單",
                    self.$store.state.ast.adminAddress
                  )
                  .then((res) => {
                    console.log("取得管理員上鍊名單結果", res);

                    for (let i = 0; i < rows.length; i++) {
                      rows[i].onChain = res[i];
                    }
                    self.$store.commit("manager/setManager", rows);
                    self.$store.commit("ast/setAdminStatus", res);
                  });
              }
            })
            .catch((e) => {
              console.log("錯誤", e);
              if (e.response.status == 403) {
                // alert("沒有權限");
                self.$notify.warning("沒有權限");
                self.$router.push({ path: "/level2-validation" });
              }
            });
        },
        DelGenAdmin: (manager) => {
          var yes = confirm("確定要刪除此管理員嗎");
          console.log("確定要刪除此管理員嗎", manager);
          if (!yes) {
            return;
          }
          let obj = { fId: manager.id };
          let formdata = this.$tools.ObjToForm(obj);
          self.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
            let data = res.data;
            if (data != 0) {
              // alert("刪除成功");
              self.$notify.success("刪除成功");
              self.api().RootGetAllAdminInfo();
            }
          });
        },
      };
      return fn;
    },
    管理員查詢幣商_全搜尋處理() {
      this.selectEvent = "";
      this.$store
        .dispatch("apiAdmin/管理員查詢幣商_全搜尋處理", {
          pageIndex: 1,
          pageSize: 1500,
        })
        .then((res) => {
          res.data.forEach((i, idx) => {
            this.幣種rows[idx] = {
              f廠商名稱: i.f廠商名稱,
              f匯款帳號: i.f匯款帳號,
              f廠商編號: i.f廠商編號,
              f當日限額: i.f當日限額,
              f總匯款量: i.f總匯款量,
              f銀行資訊: i.f銀行資訊,
            };
          });
          this.渲染後讀取++;
          console.log(this.幣種rows);
        });
    },
  },
  computed: {},
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    this.範例excel檔案初始();
    this.管理員查詢幣商_全搜尋處理();
    // this.api().RootGetAllAdminInfo();
  },
};
</script>

<style scoped>
.img {
  max-width: inherit !important;
}
.appHandle {
  display: none;
}
@media screen and (max-width: 1024px) {
  .appHandle {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .appBtn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
</style>
<style>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}
.subgroup-green .sub {
  color: #c9eadc;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
.subgroup-green .subtitle {
  color: #389568;
  -webkit-text-stroke: 0.5px #389568;
}
</style>
