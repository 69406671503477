<template>
  <Card :color="headerColor" type="form">
    <template #header>
      <div class="flex text-white w-full justify-between text-xs">
        <p class="text-base">
          {{ formType === "PersonalForm" ? "個人用戶申請" : "公司用戶申請" }}
        </p>
        <p class="text-sm">申請時間 &colon; {{ userData.date }}</p>
      </div>
      <div class="flex w-full pl-5 mt-auto items-center">
        <div class="flex-1 flex space-x-[5px]">
          <Icon
            dense
            :img="
              userData.membership === 2
                ? require('@/assets/img/membership2.png')
                : require('@/assets/img/membership3.png')
            "
            :alt="`${userData.membership} 級會員icon`"
          />
          <p class="text-white text-2xl">
            {{ userData.apply_name }}
          </p>
          <Icon
            dense
            v-if="userData.type === 'personal'"
            :img="
              userData.gender === 'male'
                ? require('@/assets/img/icon-male.png')
                : require('@/assets/img/icon-female.png')
            "
            :alt="`${userData.gender} icon`"
          />
        </div>
        <div class="ml-auto">
          <Icon
            v-if="
              userData.status !== 'failed' && userData.status !== 'unapproved'
            "
            icon="icon-edit"
            @click="formStatus = { readonly: false, edit: true }"
          />
        </div>
      </div>
    </template>

    <template #body>
      <div class="min-w-[335px] sm:min-w-[600px] w-full">
        <component :is="formType" :initialValues="userData" v-bind="formStatus">
          <template #button="formValues" v-if="formStatus.edit">
            <Button
              label="編輯完成"
              color="green"
              size="popup-sm"
              text="white"
              @click="completeEdit(formValues)"
            />
          </template>
        </component>
      </div>
      <PopupWindow :value="failedConfirmState">
        <template #body>
          <FailedConfirm
            :reasonList="$store.state.validation.level2FailedReason"
            :id="userData.id"
            level="level2"
            @confirm="confirmFailed"
            @cancel="failedConfirmState = false"
          />
        </template>
      </PopupWindow>
    </template>
    <template #footer v-if="!formStatus.edit">
      <template v-if="userData.status === 'failed'">
        <div
          class="py-3.5 px-4 flex flex-col justify-center items-center space-x-[15px]"
        >
          <p class="text-sm text-[#2897C7] mb-2">
            原因 &colon;
            {{
              userData.failed_reason.map((reason) => reason.label).join(" ; ")
            }}
          </p>
          <p
            class="text-sm text-red-100 px-5 py-1 border border-red-100 rounded-default"
          >
            審核失敗
          </p>
        </div>
      </template>
      <template v-else>
        <div
          class="py-3.5 px-4 flex flex-row justify-center items-center space-x-[15px]"
        >
          <Button
            v-for="(button, index) in buttonGroup"
            :key="index"
            v-bind="button"
            @click="button.action"
          />
        </div>
      </template>

      <div
        v-if="userData.status !== 'unapproved'"
        class="pb-4 px-4 flex flex-row justify-between w-full text-gray-300 font-[13px]"
      >
        <p>審核人&colon; {{ $store.state.account }}</p>
        <p>審核時間&colon; 2022/02/14 07:27</p>
      </div>
    </template>
    <template #footer v-else>
      <div class="py-2" />
    </template>
  </Card>
</template>

<script>
import { Card, Radio, Button, PopupWindow, Icon } from "@/components/Shared";
import { PersonalForm, CompanyForm } from "@/components/FormType";
export default {
  name: "StatusForm",
  components: {
    Icon,
    Card,
    CompanyForm,
    PersonalForm,
    Radio,
    Button,
    PopupWindow,
    FailedConfirm: () =>
      import("@/components/PopupCards/Confirm/FailedConfirm.vue"),
  },
  props: {
    initialValues: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      failedConfirmState: false,
      userData: this.initialValues,
      formStatus: { readonly: true, edit: false },
      blockButton: {
        label: "停用帳戶",
        color: "red",
        size: "popup-sm",
        text: "white",
        action: this.blockAccount,
      },
      unBlockButton: {
        label: "解除停用",
        color: "blue",
        size: "popup-sm",
        text: "white",
        action: this.unblockAccount,
      },
      unchainButton: {
        label: "解除上鏈",
        color: "orange",
        size: "popup-sm",
        text: "white",
        action: this.unchainAccount,
      },
      chainButton: {
        label: "立即上鏈",
        color: "orange",
        size: "popup-sm",
        text: "white",
        action: this.chainAccount,
      },
      approveButton: {
        label: "同意",
        color: "green",
        size: "popup-sm",
        text: "white",
        action: this.approveApplication,
      },
      disapproveButton: {
        label: "不同意",
        color: "red",
        size: "popup-sm",
        text: "white",
        action: this.disapproveApplication,
      },
      statusColor: {
        unapproved: "blue",
        block: "red",
        failed: "red",
        normal: "green",
        unchain: "orange",
      },
    };
  },
  computed: {
    formType() {
      return (
        this.initialValues.type &&
        this.initialValues.type[0].toUpperCase() +
          this.initialValues.type.slice(1) +
          "Form"
      );
    },
    headerColor() {
      return this.statusColor[this.userData.status];
    },
    buttonGroup() {
      const group = {
        blue: [this.approveButton, this.disapproveButton],
        orange: [this.blockButton, this.chainButton],
        green: [this.blockButton, this.unchainButton],
        red: [this.unBlockButton],
        false: [this.blockButton, this.chainButton],
        true: [this.blockButton, this.unchainButton],
      };
      let gp = [];
      if (typeof this.userData.onChain == "undefined") {
        gp = group[this.headerColor];
      } else {
        gp = group[this.userData.onChain];
      }
      return gp;
    },
  },
  methods: {
    blockAccount() {
      let type = "Personal";
      let id = parseInt(this.userData.id); //500019
      if (this.userData.type == "company") {
        type = "Company";
        id -= 500000;
      }
      this.$store
        .dispatch(
          `apiAdmin/AdminModifyData${type}`,
          this.$tools.ObjToForm({
            fId: id,
            f狀態: -1,
          })
        )
        .then((res) => {
          if (res.data != 0) {
            this.$store.commit(
              `membership/blockMembership${this.userData.membership}`,
              { id: this.userData.id }
            );
            this.userData =
              this.$store.getters[
                `membership/targetMembership${this.userData.membership}Data`
              ];
            this.$notify.success("操作成功");
          } else {
            this.$notify.warning("操作失敗");
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    unblockAccount() {
      console.log("解開停用帳戶");
      let type = "Personal";
      let id = parseInt(this.userData.id); //500019
      if (this.userData.type == "company") {
        type = "Company";
        id -= 500000;
      }
      this.$store
        .dispatch(
          `apiAdmin/AdminModifyData${type}`,
          this.$tools.ObjToForm({
            fId: id,
            f狀態: 0,
          })
        )
        .then((res) => {
          if (res.data != 0) {
            this.$store.commit(
              `membership/unblockMembership${this.userData.membership}`,
              { id: this.userData.id, onChain: this.userData.onChain }
            );
            this.userData =
              this.$store.getters[
                `membership/targetMembership${this.userData.membership}Data`
              ];
            this.$notify.success("操作成功");
          } else {
            this.$notify.warning("操作失敗");
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    chainAccount() {
      this.設定白名單(this.userData);
    },
    unchainAccount() {
      this.設定白名單(this.userData);
    },
    設定白名單(member) {
      this.$modal.show("loading");
      if (member.membership == 2) {
        this.$store
          .dispatch("ast/設定兌換TWD白名單", member.wallet_address)
          .then((res) => {
            console.log(res);
            this.$notify.success("修改成功");
            this.getL2();

            this.$modal.hide("loading");
          })
          .catch((e) => {
            console.log(e);
            this.$modal.hide("loading");
          });
      } else {
        this.$store
          .dispatch("ast/設定兌換USDT白名單", member.wallet_address)
          .then((res) => {
            console.log(res);
            this.$notify.success("修改成功");
            this.getL3();

            this.$modal.hide("loading");
          })
          .catch((e) => {
            console.log(e);
            this.$modal.hide("loading");
          });
      }
    },
    verifyOK() {
      this.$store
        .dispatch(
          "apiAdmin/VerifyOK",
          this.$tools.ObjToForm({
            fId: this.userData.id,
          })
        )
        .then((res) => {
          if (res.data != 0) {
            this.$store.commit(
              "validation/deleteLevel2Application",
              this.userData.id
            );
            this.$store.commit("togglePopup", false);
            this.$notify.success("操作成功");
          }
        });
    },
    approveApplication() {
      // 點選同意，關閉視窗
      console.log("點選同意，關閉視窗", this.userData);

      this.$store
        .dispatch("ast/TWD白名單資格", this.userData.wallet_address)
        .then((res) => {
          if (!res) {
            this.$store
              .dispatch("ast/設定兌換TWD白名單", this.userData.wallet_address)
              .then((res) => {
                console.log(res);
                this.verifyOK();
              });
          } else {
            this.verifyOK();
          }
        });
    },
    disapproveApplication() {
      // 點選不同意，開啟不同意原因視窗
      this.failedConfirmState = true;
      this.$store.commit("mutateDoublePopup", true);
    },
    confirmFailed() {
      // this.$store.commit("mutateDoublePopup", false);
      this.$store
        .dispatch(
          "apiAdmin/VerifyNO",
          this.$tools.ObjToForm({
            fId: this.$store.getters["validation/level2Applier"].id,
            駁回原因: JSON.stringify(
              this.$store.getters["validation/level2Applier"].failed_reason
            ),
          })
        )
        .then((res) => {
          if (res.data != 0) {
            this.$store.commit(
              "validation/getLevel2ApplierByID",
              this.initialValues.id
            );
            this.userData = this.$store.getters["validation/level2Applier"];
            this.failedConfirmState = false;
          }
        });
      console.log("刪除", this.userData);
    },
    completeEdit(formData) {
      console.log(formData);
      this.formStatus = { readonly: true, edit: false };
      this.$store.commit("membership/editMembership2Data", {
        id: Date.now(),
        membership: 2,
        type: this.formType,
        gender: this.gender,
        apply_name: this.apply_name,
        ...formData,
        status: "unchain",
      });
    },
    getL2() {
      let TWDWhiteList = [];
      let membership = [];
      this.$store.commit("membership/setMembership2Data", []);
      this.$store
        .dispatch("apiMember/GetAllMemInfoByCompany", {
          pageindex: 1,
          pagesize: 100,
          f資格: "L2",
        })
        .then((res) => {
          // this.$store.commit("membership/pushMembership2Data", res);
          membership = membership.concat(res);
          this.$store
            .dispatch("apiMember/GetAllMemInfoByPersonal", {
              pageindex: 1,
              pagesize: 100,
              f資格: "L2",
            })
            .then((resin) => {
              membership = membership.concat(resin);
              //準備取得白名單
              membership.forEach((item) => {
                TWDWhiteList.push(item.wallet_address);
              });
              this.$store
                .dispatch("ast/批次取得TWD白名單資格", TWDWhiteList)
                .then((resBlock) => {
                  console.log("結果", resBlock);
                  for (let i = 0; i < membership.length; i++) {
                    membership[i].onChain = resBlock[i];
                  }
                  this.$store.commit(
                    "membership/pushMembership2Data",
                    membership
                  );
                  this.$store.commit("togglePopup", false);
                  location.reload();
                });
            });
        });
    },
    getL3() {
      let USDTWhiteList = [];
      let membership = [];
      this.$store.commit("membership/setMembership3Data", []);
      this.$store
        .dispatch("apiMember/GetAllMemInfoByCompany", {
          pageindex: 1,
          pagesize: 100,
          f資格: "L3",
        })
        .then((res) => {
          membership = membership.concat(res);
          this.$store
            .dispatch("apiMember/GetAllMemInfoByPersonal", {
              pageindex: 1,
              pagesize: 100,
              f資格: "L3",
            })
            .then((resin) => {
              membership = membership.concat(resin);

              //準備取得白名單
              membership.forEach((item) => {
                USDTWhiteList.push(item.wallet_address);
              });
              //準備取得白名單結果
              this.$store
                .dispatch("ast/批次取得USDT白名單資格", USDTWhiteList)
                .then((resBlock) => {
                  console.log("結果", resBlock);
                  for (let i = 0; i < membership.length; i++) {
                    membership[i].onChain = resBlock[i];
                  }
                  this.$store.commit(
                    "membership/pushMembership3Data",
                    membership
                  );
                  this.$store.commit("togglePopup", false);
                  location.reload();
                });
              console.log("結果", USDTWhiteList);
            });
        });
    },
  },
  watch: {
    initialValues(newValue) {
      this.userData = newValue;
    },
    failedConfirmState(newState) {
      this.$store.commit("mutateDoublePopup", newState);
    },
  },
};
</script>
