<template>
  <div class="h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col">
    <popcorn
      name="loading"
      :width="100"
      :minHeight="100"
      :height="100"
      :adaptive="true"
      :clickToClose="false"
    >
      <div
        class="dots-bars-4"
        style="
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      ></div>
    </popcorn>
    <div
      @click="selectEvent = ''"
      v-if="selectEvent != ''"
      class="px-4 appArrow"
    >
      <span style="cursor: pointer">&#10154;</span>
    </div>

    <div class="flex justify-around">
      <div
        class="flex-1"
        :class="selectEvent == '' ? '' : 'lg:w-48 appHandle2'"
      >
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Authority Management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>會員名單管理</strong>
            </h2>
          </div>
          <div class="flex flex-col items-end pt-[45px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入帳號搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mb-3">
          <div>
            <Button
              label="全部"
              color="gray"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '全部'"
            />
            <Button
              label="一級身分"
              color="blue"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '一級身分'"
            />
            <Button
              label="二級個人"
              color="green"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '二級個人'"
            />
            <Button
              label="二級公司"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
              @click="searchTarget = '二級公司'"
            />
          </div>
          <dropdown-menu
            v-model="show"
            :right="true"
            :hover="false"
            :interactive="true"
          >
            <button class="text-[#cc232a]">
              <img class="w-[100%]" src="@/assets/img/icon-download.svg" />
            </button>
            <div
              style="right: 30px"
              class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
              slot="dropdown"
            >
              <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li @click="匯出CSV()" class="hover:bg-[#cc232a]">
                  <a
                    href="#"
                    class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                    >匯出CSV</a
                  >
                </li>
                <!-- <li class="hover:bg-[#cc232a]">
                  <a
                    href="#"
                    class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
                    >匯出PDF</a
                  >
                </li> -->
              </ul>
            </div>
          </dropdown-menu>
        </div>
        <div class="flex-1 overflow-y-scroll no-scrollbar h-[70vh]">
          <Table
            v-if="修改審核[searchTarget].length != 0"
            :rows="修改審核[searchTarget]"
            :cols="修改紀錄列表"
            :disableStatus="false"
          >
            <template #edit="manager">
              <div
                v-if="manager.account != 'ROOT'"
                class="flex h-full justify-center"
              >
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-vector.svg')"
                  @click="togglePop('memberDetail', manager)"
                />
              </div>
            </template>
          </Table>
          <img
            v-else
            class="w-[180px]"
            style="margin: 0 auto"
            src="@/assets/img/icon-nodata.svg"
            alt="male icon"
          />
          <Table
            class="mt-5"
            v-if="審核[searchTarget].length != 0"
            :rows="!搜尋中 ? 審核[searchTarget] : 搜尋後rows"
            :cols="cols"
            :disableStatus="false"
          >
            <template #edit="manager">
              <div
                v-if="manager.account != 'ROOT'"
                class="flex h-full justify-center"
              >
                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-scale.svg')"
                  @click="toggleEvent('DETAIL', manager)"
                />

                <Icon
                  class="px-3 scale-150"
                  :img="require('@/assets/img/icon-vector.svg')"
                  @click="
                    $modal.show('幣商修改');
                    selectObj = manager;
                  "
                />
              </div>
            </template>
          </Table>
          <img
            v-else
            class="w-[180px]"
            style="margin: 0 auto"
            src="@/assets/img/icon-nodata.svg"
            alt="male icon"
          />
        </div>
      </div>

      <div :class="selectEvent == '' ? '' : 'flex-1 lg:w-48 px-3 appHandle'">
        <DetailMembership
          v-if="selectEvent == 'DETAIL'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></DetailMembership>
      </div>
    </div>
    <popcorn
      name="Detail"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>新增白名單</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請輸入白名單姓名"
                hint="gray"
                v-model="manager.name"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入白名單錢包地址"
                hint="gray"
                v-model="manager.wallet"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入白名單備註"
                hint="gray"
                v-model="manager.info"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="chainOff"
      :width="325"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <img
            class="w-[70%] my-5 mx-auto"
            src="@/assets/img/icon-chainOff.svg"
          />
          <span>請確認是否<br />停用此用戶</span>
          <p class="text-sm text-[#cccccc] mt-4">用戶將無法進行鏈上交易</p>
        </template>
        <template #footer>
          <Button label="確認停用" color="red" text="white" size="popup-md" />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('chainOff')"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="chainOn"
      :width="325"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <img
            class="w-[70%] my-5 mx-auto"
            src="@/assets/img/icon-chainOn.svg"
          />
          <span>請確認是否<br />將此用戶恢復使用</span>
          <p class="text-sm text-[#cccccc] mt-4">用戶將可進行鏈上交易</p>
        </template>
        <template #footer>
          <Button label="確認恢復" color="green" text="white" size="popup-md" />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('chainOn')"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="memberDetail"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
      @closed="PopClose()"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">申請{{ selectObj.修改訴求 }}</span>
          <div class="flex items-center my-5">
            <div class="flex items-center">
              <img
                v-if="selectObj.f大頭貼 == ''"
                class="w-[30px]"
                src="@/assets/img/icon-user.svg"
                alt="male icon"
              />
              <img
                v-else
                class="w-[30px]"
                :src="$appsetting.AppUrl + '\\' + selectObj.f大頭貼"
                alt="大頭貼"
              />
            </div>
            <span class="ml-3 text-xl">{{ selectObj.f姓名 }}</span>
            <div
              class="ml-3 text-sm text-white px-2 py-1 rounded"
              :class="
                selectObj.f身分 == '個人戶' ? 'bg-[#71b57c]' : 'bg-[#b57175]'
              "
              style="border-radius: 0.35rem"
            >
              {{ selectObj.f資格 }}{{ selectObj.f身分 }}
            </div>
          </div>
          <template>
            <div
              v-for="(item, key) in selectObj.f描述[selectObj.修改訴求]"
              :key="key"
            >
              <span>{{ key }}</span>
              <template v-if="typeof item === 'object'">
                <div
                  v-for="(iitem, kkey) in item"
                  :key="kkey"
                  class="relative bg-[#d9d9d9] rounded w-[100%] p-3 my-[8px] text-lg break-all"
                >
                  <span
                    class="text-xs"
                    style="position: absolute; left: 0; top: 0"
                    >{{ kkey }}</span
                  >
                  <img
                    v-if="
                      iitem.indexOf('.jpg') != -1 || iitem.indexOf('.png') != -1
                    "
                    class="w-[100%]"
                    :src="$appsetting.AppUrl + '\\' + iitem"
                    alt="圖片遺失"
                  />
                  <span v-else>{{ iitem }}</span>
                </div>
              </template>
              <template v-if="typeof item === 'string'">
                <div
                  class="bg-[#d9d9d9] rounded w-[100%] p-3 my-[8px] text-lg break-all"
                >
                  {{ item }}
                </div>
              </template>
            </div>
            <!-- <div class="bg-[#d9d9d9] rounded w-[100%] p-3 my-[8px]">
              {{ selectObj.f描述[selectObj.修改訴求] }}
            </div> -->
          </template>

          <span class="text-[#959595] text-sm flex items-center mt-5"
            ><span class="text-xl mr-1">修改紀錄</span>Amendment record</span
          >
        </template>
        <template #footer>
          <div class="flex items-center">
            <Button
              class="mx-2"
              label="不同意修改"
              color="red"
              text="white"
              size="popup-md"
              @click="駁回會員修改審核(selectObj.fVid)"
            />
            <Button
              class="mx-2"
              label="同意修改"
              color="green"
              text="white"
              size="popup-md"
              @click="通過會員修改審核(selectObj.fVid)"
            />
          </div>

          <Button
            class="mt-3"
            label="關閉"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('memberDetail')"
          />
        </template>
      </Card>
    </popcorn>

    <popcorn
      name="幣商修改"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">修改此會員幣商</span>
          <div class="flex items-center my-2">
            <!-- <span class="ml-3 text-xl">{{ selectObj.f姓名 }}</span> -->
            <Input
              style="font-size: 16px"
              outlined
              placeholder="請輸入廠商編號"
              hint="black"
              v-model="f廠商編號"
            />
          </div>
        </template>
        <template #footer>
          <div class="flex items-center">
            <Button
              class="mx-2"
              label="確認"
              color="green"
              text="white"
              size="popup-md"
              @click="確認修改幣商(selectObj)"
            />
            <Button
              label="取消"
              color="gray"
              text="white"
              size="popup-md"
              @click="$modal.hide('幣商修改')"
            />
          </div>
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Table, Input, Icon, Button } from "@/components/Shared";
import { DetailMembership } from "@/components/Membership";
import DropdownMenu from "@innologica/vue-dropdown-menu";
import { verify } from "@/usecase/verify";
export default {
  name: "Membership",
  components: {
    Card,
    Table,
    Input,
    Icon,
    Button,
    DetailMembership,
    DropdownMenu,
  },
  data() {
    return {
      f廠商編號: "",
      selectPop: "",
      selectEvent: "",
      selectObj: {},
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "f姓名",
          label: "申請人",
          align: "center",
        },
        { name: "f信箱", label: "申請帳號", align: "center" },
        { name: "fBSC地址", label: "錢包地址", align: "center" },
        { name: "edit", label: "查看/幣商修改", align: "center" },
        // { name: "coinEdit", label: "幣商", align: "center" },
      ],

      searchWords: "",
      searchTarget: "全部",
      bankList: ["匯出CSV"],
      // bankList: ["匯出CSV", "匯出PDF"],
      formValues: "匯出",
      show: false,

      搜尋中: false,
      搜尋後rows: [],
    };
  },
  watch: {
    searchWords(val) {
      if (val == "") {
        this.搜尋中 = false;
        this.搜尋後rows = [];
      }
    },
  },
  methods: {
    確認修改幣商() {
      console.log(this.selectObj.fId);
      if (this.f廠商編號 === "") {
        this.$notify.warning("輸入不完整");
        return;
      }
      const obj = {
        fId: this.selectObj.fId,
        f廠商編號: this.f廠商編號,
      };
      const formdata = this.$tools.ObjToForm(obj);
      this.$store
        .dispatch("apiAdmin/管理員修改會員的幣商", formdata)
        .then((res) => {
          if (res.message == "修改會員成功。") {
            this.$notify.success("修改成功");
            this.f廠商編號 = "";
            this.$modal.hide("幣商修改");
          } else {
            this.f廠商編號 = "";
            this.$notify.warning(res.message);
            this.$modal.hide("幣商修改");
          }
        })
        .catch(() => {
          this.f廠商編號 = "";
        });
    },
    匯出CSV() {
      let config = {
        headers: { Authorization: "Bearer " + this.$store.state.login.token },
      };
      // fetch("http://220.134.4.63:6382/api/Export/CSV/MemberInfo", config)
      fetch("https://amlservice.qhr.app/api/Export/CSV/MemberInfo", config)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response.data);
          // const blob = new Blob([response], { type: 'application/vnd.ms-excel;charset=utf-8' })
          // console.log(blob);
          var link = document.createElement("a");
          link.href = "https://amlservice.qhr.app/" + response.data;
          // link.href = "http://220.134.4.63:6382/" + response.data;
          link.click();
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
    },
    搜尋按鈕() {
      this.搜尋中 = true;
      this.搜尋後rows = this.審核[this.searchTarget].filter((i) => {
        return i.f信箱.indexOf(this.searchWords) != -1;
      });
    },
    successEvent() {
      console.log("EVENT");
      this.A05_查詢_t會員_全搜尋();
    },
    togglePop(pop, data = {}) {
      data = this.$deepParseJson(data); //把所有JSONstring 解開
      console.log(data);
      if (this.selectPop == "") {
        this.selectPop = pop;
        this.selectObj = data;
        this.$modal.show(this.selectPop);
      }
    },
    PopClose() {
      this.selectPop = "";
      this.selectObj = {};
    },
    toggleEvent(event, data = {}) {
      data = this.$deepParseJson(data); //把所有JSONstring 解開
      if (this.selectEvent == "") {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }
      console.log("結果", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
    A05_查詢_t會員_全搜尋() {
      let obj = {
        generalSearchKeys: "f狀態",
        generalsearchVals: "1",
        pageIndex: 1,
        pageSize: 1500,
      };
      this.$store.dispatch("apiAdmin/GetAllMemInfo", obj).then((res) => {
        let data = res.data;
        data.map((item) => {
          // console.log(item);
          item["status"] = "unapproved";
          if (item.f資格 == "L1") {
            item["status"] = "unapproved";
          } else if (item.f資格 == "L2" && item.f身分 == "個人戶") {
            item["status"] = "normal";
          } else if (item.f資格 == "L2" && item.f身分 == "公司戶") {
            item["status"] = "block";
          }
          return item;
        });
        console.log(data);
        this.$store.commit("apiAdmin/SET_會員列表", data);
      });
    },
    通過會員修改審核(fId) {
      verify.通過會員修改審核(fId).then((res) => {
        this.$notify.success(res.message);
        verify.查詢會員修改審核列表();
        this.togglePop("memberDetail");
      });
    },
    駁回會員修改審核(fId) {
      verify.通過會員修改審核(fId).then((res) => {
        this.$notify.success(res.message);
        verify.查詢會員修改審核列表();
        this.togglePop("memberDetail");
      });
    },
  },
  computed: {
    修改紀錄列表: function () {
      return verify.查詢會員修改審核列表TABLE;
    },
    審核: function () {
      return {
        全部: this.$store.state.apiAdmin.會員列表,
        一級身分: this.$store.state.apiAdmin.會員列表.filter(
          (m) => m.f資格 == "L1"
        ),
        二級個人: this.$store.state.apiAdmin.會員列表.filter(
          (m) => m.f資格 == "L2" && m.f身分 == "個人戶"
        ),
        二級公司: this.$store.state.apiAdmin.會員列表.filter(
          (m) => m.f資格 == "L2" && m.f身分 == "公司戶"
        ),
      };
    },
    修改審核: function () {
      return {
        全部: this.$store.state.apiVerify.會員修改紀錄,
        一級身分: this.$store.state.apiVerify.會員修改紀錄.filter(
          (m) => m.f資格 == "L1"
        ),
        二級個人: this.$store.state.apiVerify.會員修改紀錄.filter(
          (m) => m.f資格 == "L2" && m.f身分 == "個人戶"
        ),
        二級公司: this.$store.state.apiVerify.會員修改紀錄.filter(
          (m) => m.f資格 == "L2" && m.f身分 == "公司戶"
        ),
      };
    },
  },
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    this.A05_查詢_t會員_全搜尋();
    // verify.查詢會員修改審核列表();
    verify.查詢會員修改審核列表();
    // this.api().RootGetAllAdminInfo();

    // this.$modal.show("memberDetail");
  },
};
</script>

<style scoped>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}
.subgroup-green .sub {
  color: #c9eadc;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
.subgroup-green .subtitle {
  color: #389568;
  -webkit-text-stroke: 0.5px #389568;
}
.appArrow {
  display: none;
}
@media screen and (max-width: 1300px) {
  .appArrow {
    font-size: 31px;
    transform: rotate(180deg);
    display: flex;
    justify-content: end;
  }
  .appHandle {
    width: 100%;
  }
  .appHandle2 {
    display: none;
  }
}
</style>
