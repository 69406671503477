import { request } from "@/store/api";
export const apiNews = {
  namespaced: true,
  state: { 最新消息列表: [], 畫面初始: 0 },
  getters: {
    最新消息列表(state) {
      return state.最新消息列表
    },
    畫面初始(state) {
      return state.畫面初始
    }
  },
  mutations: {
    SET_最新消息列表(state, 最新消息列表) {
      console.log('最新消息列表', 最新消息列表);
      state.最新消息列表 = 最新消息列表

    },
    SET_畫面初始(state) {
      state.畫面初始++

    },
  },
  actions: {
    A10_查詢_t最新消息(state, data) {
      console.log('data', data);
      return request
        .addFORMAuth("News")
        .post("/GetAllNews", data);
    },
    A01_新增_t最新消息(state, data) {
      console.log('data', data);
      return request
        .addFORMAuth("News")
        .post("/AddNews", data);
    },
    A02_修改_t最新消息(state, data) {
      console.log('data', data);
      return request
        .addFORMAuth("News")
        .post("/UpdateNews", data);
    },
    A08_刪除_t最新消息(state, data) {
      console.log('查看fId', data);
      return request
        .addFORMAuth("News")
        .delete("/NewsInfo", { params: data })
    },
    // A02_上傳匯款單_修改(state, data) {
    //   return request.addAuth("Withdraw").put("/WithdrawInfo/MoneyOrder", data);
    // },
    // A03_上傳退款TXID_修改(state, data) {
    //   return request.addAuth("Withdraw").put("/WithdrawInfo/TXID", data);
    // },
    // A04_查詢_出金紀錄(state, data) {
    //   return request
    //     .addAuth("Withdraw")
    //     .get("/WithdrawInfo/All/", { params: data });
    // },
    // A05_查詢_戰情分析列表(state, data) {
    //   return request
    //     .addAuth("Withdraw")
    //     .get("/WithdrawInfo/Admin/", { params: data });
    // },
  },
};
