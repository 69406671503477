<template>
  <div class="bg-mobile lg:bg-login h-screen pt-[50px] lg:pt-[103px]">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "LoginLayout",
};
</script>
