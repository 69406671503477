<template>
  <div class="flex flex-row space-x-2 flex-shrink-0">
    <div
      :class="[
        'w-[28px] h-[28px]  flex items-center justify-center',
        {
          'bg-white opacity-50 rounded-[2px] ': value === 'male',
        },
      ]"
      @click="changeOption('male')"
    >
      <img src="@/assets/img/icon-male.png" alt="male icon" />
    </div>
    <div
      :class="[
        'w-[28px] h-[28px]  flex items-center justify-center',
        {
          'bg-white opacity-50 rounded-[2px] ': value === 'female',
        },
      ]"
      @click="changeOption('female')"
    >
      <img src="@/assets/img/icon-female.png" alt="female icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "GenderRadio",
  props: {
    value: {
      type: String,
      default: "male",
    },
  },
  data() {
    return {};
  },
  methods: {
    changeOption(option) {
      this.$emit("input", option);
    },
  },
};
</script>
