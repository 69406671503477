<template>
  <div class="relative z-20">
    <div class="overflow-x-scroll no-scrollbar flex space-x-2 w-full absolute">
      <div
        v-if="!readonly"
        class="w-[114px] h-[76px] flex-shrink-0 border border-gray-600 border-dashed relative z-0"
      >
        <Icon
          icon="icon-plus"
          class="absolute top-0 left-0 right-0 bottom-0 m-auto text-3xl text-gray-600"
        />
        <input
          type="file"
          multiple="true"
          accept="image/*"
          @change="uploadFiles"
          class="w-full h-full opacity-0"
          :disabled="readonly"
        />
      </div>
      <div
        v-for="(img, index) in imageArray"
        :key="index"
        class="w-[114px] h-[76px] flex-shrink-0"
        @click="previewImg(index)"
      >
        <img
          :src="img.url"
          :alt="img.fileName"
          class="w-full h-full object-cover cursor-pointer"
        />
      </div>
    </div>
    <PopupWindow :value="showPreview">
      <template #body>
        <ImageCarousell
          v-if="showPreview"
          @closePreview="showPreview = false"
          :imageArray="imageArray"
          :defaultImageIndex="defaultImageIndex"
        />
      </template>
    </PopupWindow>
  </div>
</template>
<script>
import { Icon, PopupWindow } from "@/components/Shared";
import ImageCarousell from "./ImageCarousell.vue";
export default {
  name: "ImageInput",
  components: { Icon, ImageCarousell, PopupWindow },
  props: {
    value: {
      type: Array,
      default: () => [{ url: "", fileName: "" }],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreview: false,
      imageArray: this.value,
      defaultImageIndex: 0,
      acceptableFileType: ["image/png"],
    };
  },
  methods: {
    async uploadFiles(files) {
      const fileList = files.target.files;
      if (fileList.length === 0) return;
      this.fileList = [...fileList];
      this.fileList.map((file) => {
        this.imageArray.push({
          url: URL.createObjectURL(file),
          fileName: file.name,
        });
        return this.imageArray;
      });
    },
    previewImg(imgIndex) {
      this.defaultImageIndex = imgIndex;
      this.showPreview = true;
      this.$store.commit("mutateDoublePopup", true);
    },
  },
};
</script>
