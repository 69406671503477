var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.區塊=='左側')?_c('fieldset',{staticClass:"lg:px-4 flex flex-col items-center"},[_c('legend',{class:[
      _vm.textColor[_vm.color],
      'text-2xl lg:text-4xl font-bold text-center mb-4 app-color',
    ]},[_vm._v(" "+_vm._s(_vm.baseCurrency)+" 兌換 "+_vm._s(_vm.quoteCurrency)+" 調整 ")]),_c('label',{staticClass:"flex flex-row text-2xl lg:text-4xl items-center space-x-[11px] py-12"},[_c('p',{staticClass:"whitespace-nowrap flex-1 text-right"},[_vm._v("1 USDT =")]),_c('div',{staticClass:"w-[154px] lg:w-[200px]"},[_c('Input',{staticClass:"flex-1",attrs:{"outlined":"","alignRight":"","text":"black","dense":""},model:{value:(_vm.newExchangeRate),callback:function ($$v) {_vm.newExchangeRate=$$v},expression:"newExchangeRate"}})],1),_c('p',{staticClass:"flex-1 text-left"},[_vm._v(_vm._s(_vm.baseCurrency))])]),_c('p',{staticClass:"mb-5 text-center text-[#C2BDBD] text-lg lg:text-2xl",staticStyle:{"min-height":"27px"}},[(_vm.baseCurrency=='TWD')?_c('span',[_vm._v(" 目前匯率為 1 USDT = "+_vm._s(_vm.currentExchangeRate)+" TWD ")]):_vm._e()]),_c('div',{staticClass:"w-[100%] xl:w-[421px]"},[(_vm.baseCurrency=='TWD')?_c('Button',{attrs:{"size":"full","color":`gradient-${_vm.color === 'red' ? 'red-100' : _vm.color}`,"text":"white","label":"確認"},on:{"click":_vm.confirmNewExchangeRate}}):_c('Button',{attrs:{"size":"full","color":`gradient-${_vm.color === 'red' ? 'red-100' : _vm.color}`,"text":"white","label":"尚未啟用"}})],1)]):(_vm.區塊=='右側')?_c('fieldset',{staticClass:"lg:px-4 flex flex-col items-center"},[_c('legend',{class:[
      _vm.textColor[_vm.color],
      'text-2xl lg:text-4xl font-bold text-center mb-4 app-color2',
    ]},[_vm._v(" "+_vm._s(_vm.baseCurrency)+" 兌換 "+_vm._s(_vm.quoteCurrency)+" 調整 ")]),_c('label',{staticClass:"flex flex-row text-2xl lg:text-4xl items-center space-x-[11px] py-12"},[_c('p',{staticClass:"whitespace-nowrap flex-1 text-right"},[_vm._v("1 USDT =")]),_c('div',{staticClass:"w-[154px] lg:w-[200px]"},[_c('Input',{staticClass:"flex-1",attrs:{"outlined":"","alignRight":"","text":"black","dense":""},model:{value:(_vm.newExchangeRate),callback:function ($$v) {_vm.newExchangeRate=$$v},expression:"newExchangeRate"}})],1),_c('p',{staticClass:"flex-1 text-left"},[_vm._v(_vm._s(_vm.quoteCurrency))])]),_c('p',{staticClass:"mb-5 text-center text-[#C2BDBD] text-lg lg:text-2xl",staticStyle:{"min-height":"27px"}},[(_vm.quoteCurrency=='TWD')?_c('span',[_vm._v(" 目前匯率為 1 USDT = "+_vm._s(_vm.currentExchangeRate)+" TWD ")]):_vm._e()]),_c('div',{staticClass:"w-[100%] xl:w-[421px]"},[(_vm.quoteCurrency=='TWD')?_c('Button',{attrs:{"size":"full","color":`gradient-${_vm.color === 'red' ? 'red-100' : _vm.color}`,"text":"white","label":"確認"},on:{"click":_vm.confirmNewExchangeRate}}):_c('Button',{attrs:{"size":"full","color":`gradient-${_vm.color === 'red' ? 'red-100' : _vm.color}`,"text":"white","label":"尚未啟用"}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }