<template>
  <div>
    <div
      class="mb-[30px] py-[2.25rem] px-4 rounded w-[100%] flex"
      :class="'bg-line-' + Color"
    >
      <div class="flex-auto w-[20%] flex justify-center items-center">
        <img :src="require(`@/assets/img/icon-${MainType=='TWD'?'TBT':MainType}.svg`)" />
      </div>
      <div class="flex-auto w-[80%] flex flex-col ">
        <span class="text-5xl text-white appFz"
          >$ {{ 無條件捨去保留小數點(value,0) }} {{ MainType }}</span
        >
        <span class="text-3xl ml-1 my-2 text-[#b1fff6] appFz"
          >$ <span class="ml-3">{{無條件捨去保留小數點( 換算,0) }}</span>
          {{ CoinType }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyzeInfo",
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    換算: {
      type: Number,
      default: 0,
    },
    Rate: {
      type: Number,
      default: 28,
    },
    Color: {
      type: String,
      default: "blue",
    },
    MainType: {
      type: String,
      default: "TWD",
    },
    CoinType: {
      type: String,
      default: "USDT",
    },
  },
  computed: {
    exchange: function () {
      console.log("rate", this.Rate);
      let val = parseFloat(this.value);
      val = val / this.Rate;
      return this.無條件捨去保留小數點(val,0)
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
    };
  },
  watch: {},
  mounted() {},
  methods: {
    無條件捨去保留小數點(num, decimal) {
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
    MoneyShow(value) {
      return value
      // const parts = value.toString().split(".");
      // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return parts.join("."); // '$' +
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
.rounded {
  border-radius: 0.75rem;
}
.bg-line-blue {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#133282+40,133282+40,3ca6c8+100 */
  background: #133282; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #133282 40%,
    #133282 40%,
    #3ca6c8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #133282 40%,
    #133282 40%,
    #3ca6c8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #133282 40%,
    #133282 40%,
    #3ca6c8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#133282', endColorstr='#3ca6c8',GradientType=0 ); /* IE6-9 */
}

.bg-line-green {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#138242+40,138242+43,3cc977+100 */
  background: #138242; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #138242 40%,
    #138242 43%,
    #3cc977 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #138242 40%,
    #138242 43%,
    #3cc977 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #138242 40%,
    #138242 43%,
    #3cc977 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#138242', endColorstr='#3cc977',GradientType=0 ); /* IE6-9 */
}
@media screen and (max-width:599px){
  .appFz{
    font-size: 37px !important;
  }
}
@media screen and (max-width:450px){
  .appFz{
    font-size: 30px !important;
  }
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
