import { request } from "@/store/api";
import qs from "qs";
// const objToForm = (obj) => {
//   var form_data = new FormData();

//   for (var key in obj) {
//     if (Array.isArray(obj[key])) {
//       for (let i = 0; i < obj[key].length; i++) {
//         form_data.append(key, obj[key][i]);
//       }
//     } else {
//       form_data.append(key, obj[key]);
//     }
//   }

//   return form_data;
// };
export const apiMoneyIn = {
  namespaced: true,
  state: { 入金紀錄: [], 團隊列表: [] },
  getters: {
    團隊管理列表(state) {
      return state.團隊列表
    },
  },
  mutations: {
    SET_入金紀錄(state, 入金紀錄) {
      state.入金紀錄 = 入金紀錄;
    },
    SET_團隊管理列表(state, 團隊列表) {
      state.團隊列表 = 團隊列表;
    },
  },

  actions: {
    GA04_查詢_t團隊_全搜尋(state, data) {
      return request
        .addAuth("Group")
        .get("/Group", { params: data });
    },
    A05_查詢_入金紀錄_團隊_全搜尋(state, data) {
      return request
        .addAuth("Deposit")
        .get("/DepositInfo/Admin/TimeWtihAll", {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        });
    },
    A01_管理員上傳發票或TXID(state, data) {
      return request.addAuth("Deposit").put("/DepositInfo/Admin/Success", data);
    },
    A02_管理員上傳退款單(state, data) {
      return request.addAuth("Deposit").put("/DepositInfo/Admin/Fail", data);
    },
    A04_管理員取得入金紀錄_透過起始結束時間(state, data) {
      return request
        .addAuth("Deposit")
        .get("/DepositInfo/Admin/Time", { params: data });
    },
    A04_管理員取得入金紀錄_起迄搜尋(state, data) {
      return request
        .addAuth("Deposit")
        .get("/DepositInfo/Admin/Time", { params: data });
    },
    A05_管理員取得入金紀錄(state, data) {
      return request
        .addAuth("Deposit")
        .get("/DepositInfo/Admin/TimeWtihAll", {
          params: data, paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        });
    },
    A04_查詢_取得單筆_tKeyCode(state, data) {
      return request
        .addFORMAuth("KeyCode")
        .post("/GetCodeBytKey", data);
    },
  },
};
