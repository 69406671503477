<template>
  <Card :color="color" type="dialog">
    <template #header>
      <Icon v-if="icon" :img="require('@/assets/img/membership3.png')" />
      <p>新增{{ title }}</p>
    </template>
    <template #body>
      <template v-if="doubleConfirm">
        <div class="pt-2 pb-[17px]">
          <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
          <p class="h-[40px]">{{ account }}</p>
        </div>
      </template>
      <template v-else>
        <div class="pt-[39px] pb-[32px] text-xl">
          <Input
            outlined
            placeholder="請輸入管理員帳號"
            hint="gray"
            v-model="account"
            class="mb-[14px]"
          />
          <Input
            outlined
            placeholder="請輸入管理員密碼"
            hint="gray"
            v-model="pwd"
            class="mb-[14px]"
          />
          <Input
            outlined
            placeholder="請輸入管理員名稱"
            hint="gray"
            v-model="name"
            class="mb-[14px]"
          />
          <Input
            outlined
            placeholder="請輸入管理員錢包地址"
            hint="gray"
            v-model="address"
            class="mb-[14px]"
          />
        </div>
      </template>
    </template>
    <template #footer>
      <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
        申請人 &colon; {{ $store.state.login.account }}
      </p>
      <Button
        v-if="doubleConfirm"
        label="確認新增"
        :color="color"
        text="white"
        size="popup-md"
        @click="confirm"
      />
      <Button
        v-else
        label="確認"
        :color="color"
        text="white"
        size="popup-md"
        @click="firstConfirm()"
      />
    </template>
  </Card>
</template>

<script>
import { Card, Button, Input, Icon } from "@/components/Shared";

export default {
  name: "AddCard",
  components: {
    Card,
    Button,
    Input,
    Icon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "blue",
    },
    role: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      label: "確認",
      account: "",
      pwd: "",
      address: "",
      name: "",
      doubleConfirm: false,
    };
  },
  methods: {
    firstConfirm() {
      if (
        this.account == "" ||
        this.pwd == "" ||
        this.address == "" ||
        this.name == ""
      ) {
        // alert("輸入不完整");
        this.$notify.warning("輸入不完整");
        return;
      }
      this.doubleConfirm = true;
    },
    confirm() {
      let obj = {
        fAccount: this.account,
        fPWd: this.pwd,
        fName: this.name,
        fEmail: JSON.stringify({ address: this.address, onChain: false }),
      };
      switch (this.role) {
        case "manager":
          this.$store
            .dispatch("apiAdmin/SignUpNewGenAdmin", this.$tools.ObjToForm(obj))
            .then((res) => {
              if (res.data != 0) {
                this.$store.commit("manager/addManager", {
                  apply_name: this.name,
                  account: this.account,
                  wallet_address: this.address,
                  id: Date.now(),
                  onChain: false,
                });
              } else {
                // alert("操作失敗，請聯絡程式人員");
                this.$notify.warning("操作失敗，請聯絡程式人員");
              }
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status == 400) {
                // alert("錯誤," + JSON.stringify(e.response.data.errors));
                this.$notify.warning("錯誤");
              }
              if (e.response.status == 500) {
                // alert("伺服器錯誤");
                this.$notify.warning("伺服器錯誤");
              }
              if (e.response.status == 403) {
                // alert("沒有權限");
                this.$notify.warning("沒有權限");
              }
            });

          break;
        case "membership3":
          this.$store.commit("membership/addMembership3Data", {
            id: Date.now(),
            membership: 3,
            type: "company",
            status: "normal",
            apply_name: "新增用戶",
            account: this.account,
            onChain: false,
            // onLock: false,
            tax_number: "45678974",
            agent: "張桃桃",
            agentID: "N123456789",
            bank: "兆豐銀行",
            bank_branch: "高雄分行",
            bank_account: "00814531392927",
            company_address: "屏東縣萬丹鄉萬榮街９６號",
            wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
          });
          break;
      }
      this.$emit("confirm");
    },
  },
};
</script>
