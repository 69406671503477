import Vue from "vue";
import Vuex from "vuex";
import { trading } from "./trading.js";
import { login } from "./login.js";
import { membership } from "./membership";
import { validation } from "./validation";
import { manager } from "./manager";
import { apiAdmin } from "./api/apiAdmin";
import { apiMember } from "./api/apiMember";
import { apiVerify } from "./api/apiVerify";
import { apiMoneyIn } from "./api/apiMoneyIn";
import { apiMoneyInPage } from "./api/apiMoneyInPage";
import { apiMoneyOut } from "./api/apiMoneyOut";
import { apiTeamManagement } from "./api/apiTeamManagement";
import { apiNews } from "./api/apiNews";
import { ast } from "./ast.js";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isPopupOpen: false,
    popupContent: "",
    popupProps: {},
    showNav: false,
    activePage: "",
    doublePopup: false,
    recordAlert: false,
  },
  plugins: [createPersistedState()],
  getters: {
    isPopupOpen({ isPopupOpen }) {
      return isPopupOpen;
    },
    popupProps({ popupProps }) {
      return popupProps;
    },
    popupContent({ popupContent }) {
      return popupContent;
    },
    showNav({ showNav }) {
      return showNav;
    },
    activePage({ activePage }) {
      return activePage;
    },
    doublePopup({ doublePopup }) {
      return doublePopup;
    },
  },
  mutations: {
    togglePopup(state, popupStatus = true) {
      console.log("close");
      state.isPopupOpen = popupStatus;
      if (popupStatus === false) state.popupContent = "";
    },
    toggleNav(state, status = true) {
      state.showNav = status;
    },
    switchNav(state) {
      state.showNav = !state.showNav;
    },
    getActivePage(state, pageName) {
      state.activePage = pageName;
    },
    setPopupProps(state, props = {}) {
      state.popupProps = props;
    },
    setPopupContent(state, cardName = "") {
      state.popupContent = cardName;
    },
    mutateDoublePopup(state, status = true) {
      state.doublePopup = status;
    },
    // closeAlert(state,data){
    //   state.recordAlert = data
    // }
  },
  actions: {},
  modules: {
    trading,
    login,
    membership,
    validation,
    manager,
    apiAdmin,
    apiMember,
    apiVerify,
    apiMoneyIn,
    apiMoneyInPage,
    apiMoneyOut,
    ast,
    apiTeamManagement,
    apiNews
  },
});
