var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSelection),expression:"closeSelection"}],class:[
    'w-full flex flex-row items-center space-x-1',
    { 'px-[15px]': !_vm.dense },
    { border: _vm.outlined },
    { 'bg-gray-300': _vm.disabled },
  ],on:{"click":_vm.toggleSelection}},[_c('span',{staticClass:"text-gray-600"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"flex flex-row flex-1 relative"},[_c('div',{staticClass:"flex-1"},[_c('p',{class:[
          'flex-1',
          'text-gray-600',
          { 'py-[5px]': !_vm.dense },
          { 'cursor-pointer': !_vm.readonly && !_vm.disabled },
          { 'cursor-default ': _vm.readonly },
          { 'cursor-not-allowed ': _vm.disabled },
          { 'text-right': _vm.alignRight },
        ]},[_vm._v(" "+_vm._s(_vm.inputValue ? _vm.inputValue : _vm.placeholder)+" ")])]),(!_vm.readonly & !_vm.disabled)?_c('div',{staticClass:"flex w-4 justify-center ml-[7px]"},[_c('Icon',{attrs:{"dense":_vm.dense,"img":require('@/assets/img/icon-chevron.svg'),"alt":"選單icon"}})],1):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.openSelection),expression:"openSelection"}],staticClass:"py-2 absolute max-h-[200px] overflow-y-auto top-full left-0 right-0 bg-white rounded-default shadow-select z-30 text-center"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,staticClass:"bg-white hover:bg-gray-100 py-[5px] cursor-pointer",on:{"click":function($event){_vm.inputValue = option}}},[_vm._v(" "+_vm._s(option)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }