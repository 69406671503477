const membership2Data = [
  {
    id: 1,
    membership: 2,
    type: "personal",
    status: "block",
    apply_name: "二級用戶1",
    account: "0968222032",
    identity_number: "N123456789",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: false,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 2,
    membership: 2,
    type: "company",
    status: "block",
    apply_name: "二級企業用戶",
    account: "0968222032",
    tax_number: "45678974",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // onLock: false,
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號屏東縣萬丹鄉萬榮街９６號",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 3,
    membership: 2,
    type: "personal",
    status: "unchain",
    apply_name: "二級用戶2",
    account: "0968222032",
    identity_number: "N123456789",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: false,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 4,
    membership: 2,
    type: "company",
    status: "unchain",
    apply_name: "二級企業用戶",
    account: "0968222032",
    tax_number: "45678974",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: false,
    // onLock: false,
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 5,
    membership: 2,
    type: "personal",
    status: "normal",
    apply_name: "二級用戶2",
    account: "0968222032",
    identity_number: "N123456789",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 6,
    membership: 2,
    type: "company",
    status: "normal",
    apply_name: "二級企業用戶",
    account: "0968222032",
    tax_number: "45678974",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // onLock: false,
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 7,
    membership: 2,
    type: "company",
    status: "unchain",
    apply_name: "二級企業用戶",
    account: "0968222032",
    tax_number: "45678974",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: false,
    // onLock: false,
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 8,
    membership: 2,
    type: "company",
    status: "block",
    apply_name: "二級企業用戶",
    account: "0968222032",
    tax_number: "45678974",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // onLock: true,
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
];

const membership3Data = [
  {
    id: 10,
    membership: 3,
    type: "company",
    status: "block",
    apply_name: "三級企業用戶",
    account: "0968222032",
    onChain: false,
    // onLock: true,
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 11,
    membership: 3,
    type: "personal",
    status: "block",
    apply_name: "三級用戶",
    identity_number: "N123456789",
    account: "0968222032",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 12,
    membership: 3,
    type: "company",
    status: "unchain",
    apply_name: "三級企業用戶",
    account: "0968222032",
    onChain: false,
    // onLock: false,
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 13,
    membership: 3,
    type: "personal",
    status: "normal",
    apply_name: "三級用戶",
    identity_number: "N123456789",
    account: "0968222032",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: true,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 14,
    membership: 3,
    type: "personal",
    status: "unchain",
    apply_name: "三級用戶",
    identity_number: "N123456789",
    account: "0968222032",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    onChain: false,
    // birth 格式需用 - 隔開 不能用 /
    birth: "1992-12-29",
    occupation: "老師",
    income: "薪資所得",
    income_level: "月薪四萬以下",
    permanent_address: "屏東縣萬丹鄉萬榮街９６號",
    contact_address: "屏東縣萬丹鄉萬榮街９６號",
    bank: "台灣銀行",
    bank_branch: "台北分行",
    bank_account: "00814531392927",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
  {
    id: 15,
    membership: 3,
    type: "company",
    status: "normal",
    apply_name: "三級企業用戶",
    account: "0968222032",
    onChain: true,
    // onLock: false,
    tax_number: "45678974",
    agent: "張桃桃",
    agentID: "N123456789",
    bank: "兆豐銀行",
    bank_branch: "高雄分行",
    bank_account: "00814531392927",
    company_address: "屏東縣萬丹鄉萬榮街９６號",
    wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    attachment: [
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image1" },
      { url: require("@/assets/img/sampleIMG.png"), fileName: "image2" },
    ],
  },
];

export { membership2Data, membership3Data };
