<template>
  <form class="w-full text-sm lg:text-lg" @submit.prevent="submit">
    <fieldset>
      <Input
        name="account"
        label="申請帳號"
        placeholder="請輸入帳號"
        clearable
        alignRight
        v-model="formValues['account']"
        :readonly="readonly || edit"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        name="identity_number"
        label="身份證號"
        placeholder="請輸入身份證號"
        clearable
        alignRight
        v-model="formValues['identity_number']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        name="birth"
        label="出生日期"
        placeholder="請輸入出生日期"
        clearable
        alignRight
        v-model="formValues['birth']"
        :readonly="readonly"
        dense
        type="date"
        class="relative"
      />
    </fieldset>
    <fieldset>
      <Input
        name="occupation"
        label="職業"
        placeholder="請輸入職業"
        clearable
        alignRight
        v-model="formValues['occupation']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Select
        name="income"
        label="收入來源"
        placeholder="請選擇"
        :options="income"
        alignRight
        v-model="formValues['income']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Select
        name="income_level"
        label="收入等級"
        placeholder="請選擇"
        :options="incomeLevel"
        alignRight
        v-model="formValues['income_level']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <TextArea
        name="permanent_address"
        label="戶籍地址"
        placeholder="請輸入戶籍地址"
        clearable
        alignRight
        v-model="formValues['permanent_address']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <TextArea
        name="contact_address"
        label="通訊地址"
        placeholder="請輸入通訊地址"
        clearable
        alignRight
        v-model="formValues['contact_address']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <TextArea
        name="wallet_address"
        label="錢包地址"
        placeholder="請輸入錢包地址"
        clearable
        alignRight
        v-model="formValues['wallet_address']"
        :readonly="readonly || edit"
        dense
        type="wallet_address"
      />
    </fieldset>
    <fieldset>
      <Select
        name="bank"
        label="銀行名稱"
        placeholder="請選擇銀行"
        :options="bankList"
        alignRight
        v-model="formValues['bank']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Select
        name="bank_branch"
        label="銀行分行"
        placeholder="請選擇銀行分行"
        :options="bankBranchList"
        alignRight
        v-model="formValues['bank_branch']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset>
      <Input
        name="bank_account"
        label="銀行帳號"
        placeholder="請輸入銀行帳號"
        clearable
        alignRight
        v-model="formValues['bank_account']"
        :readonly="readonly"
        dense
      />
    </fieldset>
    <fieldset class="py-3 px-[15px] h-[114px] images">
      <ImageUploader :readonly="readonly" v-model="formValues['attachment']" />
    </fieldset>
    <div class="flex flex-row justify-center space-x-[15px]">
      <slot name="button" v-bind="formValues" />
    </div>
  </form>
</template>

<script>
import { Input, Select, ImageUploader, TextArea } from "@/components/Shared";
import {
  income,
  incomeLevel,
  bankList,
  bankBranchList,
} from "@/assets/mockData/form";
export default {
  name: "PersonalForm",
  components: {
    Input,
    Select,
    ImageUploader,
    TextArea,
  },
  props: {
    initialValues: {
      type: Object,
      default: () => ({
        account: "",
        identity_number: "",
        birth: "",
        occupation: "",
        income: "",
        income_level: "",
        permanent_address: "",
        contact_address: "",
        wallet_address: "",
        bank: "",
        bank_branch: "",
        bank_account: "",
        attachment: [],
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValues: {
        account: this.initialValues.account,
        identity_number: this.initialValues.identity_number,
        birth: this.initialValues.birth,
        occupation: this.initialValues.occupation,
        income: this.initialValues.income,
        income_level: this.initialValues.income_level,
        permanent_address: this.initialValues.permanent_address,
        contact_address: this.initialValues.contact_address,
        wallet_address: this.initialValues.wallet_address,
        bank: this.initialValues.bank,
        bank_branch: this.initialValues.bank_branch,
        bank_account: this.initialValues.bank_account,
        attachment: this.initialValues.attachment,
      },
      income,
      incomeLevel,
      bankList,
      bankBranchList,
    };
  },
  computed: {},
  methods: {
    submit() {
      console.log("提交表單", this.formValues);
    },
    confirm() {
      this.$store.commit("togglePopup", false);
    },
  },
};
</script>

<style scoped lang="scss">
fieldset:not(.images) {
  padding: 6px 15px;
  height: 45px;
  min-width: 367px;
  display: flex;
  align-items: stretch;
  &:nth-of-type(odd) {
    background: #fcfcfc;
  }
}

::v-deep input[type="date" i]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 20px;
  width: 100px;
  opacity: 0;
}
</style>
