import axios from "axios";
import { Service } from "axios-middleware";
import Vue from "vue";
import store from "../../store"; // 直接取store

var isInit = false;
var token = "login/get_token"; //JWT_token 的Store getter位置

function AXIOS() {
  console.log(isInit);
  if (!isInit) {
    const service = new Service(axios);
    service.register({
      onRequest(config) {
        console.log("onRequest");
        return config;
      },
      onSync(promise) {
        console.log("onSync");
        return promise;
      },
      onResponse(response) {
        let data = JSON.parse(response.data);
        console.log(typeof data == "object", Object.hasOwn(data, "data"));
        if (typeof data == "object" && Object.hasOwn(data, "data")) {
          if (data.code != 200) {
            console.log("錯誤");
            Vue.prototype.$notify.warning(data.code + " " + data.message);
            return;
          }
          console.log("onData", data);
          return data;
        } else {
          if (response.status != 200) {
            console.log("錯誤");
            Vue.prototype.$notify.warning(response.status + " 錯誤");
            return;
          }
          console.log("onResponse", data);
          return response;
        }
      },
    });
    isInit = true;
  }
  return axios;
}

export const request = {
  getBaseURL: (controller) => {
    let obj = {
      baseURL: `${Vue.prototype.$appsetting.AppUrl}/api/${controller}/`,
    };
    return JSON.parse(JSON.stringify(obj));
  },
  request: (ct) => {
    return AXIOS().create(request.getBaseURL(ct));
  },
  //single
  addAuth: (ct) => {
    let copy = request.getBaseURL(ct);
    copy["headers"] = { Authorization: `Bearer ${store.getters[token]}` };
    return AXIOS().create(copy);
  },
  addJSON: (ct) => {
    let copy = request.getBaseURL(ct);
    copy["headers"] = { "Content-Type": "application/json" };
    return AXIOS().create(copy);
  },
  addFORM: (ct) => {
    let copy = request.getBaseURL(ct);
    copy["headers"] = { "Content-Type": "multipartf/orm-data" };
    return AXIOS().create(copy);
  },
  //muti
  addJSONAuth: (ct) => {
    let copy = request.getBaseURL(ct);
    copy["headers"] = {
      Authorization: `Bearer ${store.getters[token]}`,
      "Content-Type": "application/json",
    };
    return AXIOS().create(copy);
  },
  addFORMAuth: (ct) => {
    let copy = request.getBaseURL(ct);
    copy["headers"] = {
      Authorization: `Bearer ${store.getters[token]}`,
      "Content-Type": "multipart/form-data",
    };
    return AXIOS().create(copy);
  },
};
