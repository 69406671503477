var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._b({class:[
    _vm.buttonSize[_vm.size],
    _vm.buttonColor[_vm.color],
    _vm.outlineColor[_vm.outline],
    _vm.textColor[_vm.text],
    { 'focus:ring-opacity-50 focus:ring-2': !_vm.icon },
    { 'border border-solid border-gray-600': _vm.defaultStyle },
    { 'rounded-full': _vm.shape === 'round' },
    { 'rounded-none': _vm.shape === 'square' },
    { 'rounded-default': _vm.shape === 'default' },
    { 'cursor-not-allowed': _vm.disabled },
  ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.emitClick}},'button',_vm.$attrs,false),[(!_vm.icon)?_c('span',[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),(_vm.icon)?_c('Icon',{staticClass:"transform hover:scale-110",attrs:{"icon":_vm.icon}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }