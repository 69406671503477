<template>
  <div>
    <div class="hidden lg:block" v-if="selectEvent != ''">
      <div class="subgroup-blue text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Add New Administrator</strong>
        </h2>
        <h2 class="subtitle">
          <strong>新增管理員</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="mb-5">
          <FormInput
            type="text"
            v-model="manager.name"
            label="管理者姓名"
            placeholder="請設定管理者姓名"
          />
          <FormInput
            type="text"
            v-model="manager.account"
            label="管理者帳號"
            placeholder="請設定管理者帳號"
          />
          <FormInput
            type="password"
            v-model="manager.password"
            label="管理者密碼"
            placeholder="請設定管理者密碼"
          />
          <FormInput
            type="text"
            v-model="manager.wallet"
            label="管理者錢包地址"
            placeholder="請設定管理者錢包地址"
          />
          <FormInput
            type="text"
            v-model="manager.info"
            label="管理者備註"
            placeholder="請設定管理者備註"
          />
        </div>
        <div class="form-btn" @click="firstConfirm">確認</div>
      </div>
    </div>
    <popcorn
      class="block lg:hidden"
      name="addManager"
      :width="'80%'"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #header>
          <p>新增管理者帳號</p>
        </template>
        <template #body>
          <!-- <template v-if="doubleConfirm">
            <div class="pt-2 pb-[17px]">
              <p class="text-[#888888] mb-4">請再次確認新增的帳號</p>
              <p class="h-[40px]">{{ account }}</p>
            </div>
          </template> -->
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請輸入管理員帳號"
                hint="gray"
                v-model="manager.account"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員密碼"
                hint="gray"
                v-model="manager.password"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員名稱"
                hint="gray"
                v-model="manager.name"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員錢包地址"
                hint="gray"
                v-model="manager.wallet"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請輸入管理員備註"
                hint="gray"
                v-model="manager.info"
                class="mb-[14px]"
              />
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認"
            color="blue"
            text="white"
            size="popup-md"
            @click="firstConfirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="doubleCheck"
      :width="335"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="blue" type="dialog">
        <template #close>
          <!-- <Icon
            icon="icon-cross"
            class="absolute top-[5px] right-[5px] rounded-full w-[27px] h-[27px] bg-red-100 text-white"
            @click="$modal.hide('doubleCheck')"
          /> -->
        </template>
        <template #header>
          <p>請再次確認新增的帳號</p>
        </template>
        <template #body>
          <template>
            <div class="pt-2 pb-[17px]">
              <p class="h-[40px]">{{ manager.account }}</p>
            </div>
          </template>
        </template>
        <template #footer>
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認新增"
            color="blue"
            text="white"
            size="popup-md"
            @click="confirm()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="$modal.hide('doubleCheck')"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button, Input } from "@/components/Shared";
import { FormInput } from "@/components/FormItems";
export default {
  name: "AddManager",
  components: { FormInput, Card, Button, Input },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
    };
  },
  watch: {},
  mounted() {
    this.$modal.show("addManager");
  },
  computed: {},
  methods: {
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("addManager");
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
      return re.test(email);
    },
    firstConfirm() {
      if (
        this.manager.account == "" ||
        this.manager.password == "" ||
        this.manager.wallet == "" ||
        this.manager.name == ""
      ) {
        // alert("輸入不完整");
        this.$notify.warning("輸入不完整");
        return;
      }

      if (!this.validEmail(this.manager.account)) {
        this.$notify.warning("帳號格式不正確");
        return;
      }
      // this.$emit("toggleEvent", "");
      this.$modal.hide("addManager");
      this.$modal.show("doubleCheck");
      // this.doubleConfirm = true;
    },
    confirm() {
      let obj = {
        fAccount: this.manager.account,
        fPWd: this.manager.password,
        fName: this.manager.name,
        fEmail: JSON.stringify({
          address: this.manager.wallet,
          onChain: false,
          info: this.manager.info,
        }),
      };
      console.log("當前角色", this.role, this.$tools);
      this.$store
        .dispatch("apiAdmin/SignUpNewGenAdmin", this.$tools.ObjToForm(obj))
        .then((res) => {
          if (res.data != 0) {
            this.$emit("toggleEvent", "");
            this.$emit("successEvent");
            this.$notify.success("新增成功");
            this.$modal.hide("doubleCheck");
          } else {
            // alert("操作失敗，請聯絡程式人員");
            this.$notify.warning("操作失敗，請聯絡程式人員");
          }
        });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
