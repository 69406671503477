import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Manager from "@/views/Manager.vue";
import Level2 from "@/views/Level2.vue";
import Level3 from "@/views/Level3.vue";
import Membership from "@/views/Membership";
import Trading from "@/views/Trading.vue";
// import Record from "@/views/Record.vue";
import Advertise from "@/views/Advertise.vue";
import Analyze from "@/views/Analyze.vue";
import CoinManager from "@/views/CoinManager.vue";
import MoneyIn from "@/views/MoneyIn.vue";
import Team from "@/views/Team.vue";
import News from "@/views/News.vue";
import MoneyOut from "@/views/MoneyOut.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "Login",
      pageName: "登入頁",
    },
  },
  {
    path: "/manager-management",
    name: "Manager",
    component: Manager,
    meta: {
      layout: "Default",
      pageName: "管理者名單管理",
    },
  },
  {
    path: "/level2-validation",
    name: "Level2",
    component: Level2,
    meta: {
      layout: "Default",
      pageName: "二級驗證 申請審核",
    },
  },
  {
    path: "/level3-validation",
    name: "Level3",
    component: Level3,
    meta: {
      layout: "Default",
      pageName: "三級驗證 申請審核",
    },
  },
  {
    path: "/membership",
    name: "Membership",
    component: Membership,
    meta: {
      layout: "Default",
      pageName: "會員名單管理",
    },
  },
  {
    path: "/trading-pair",
    name: "Trading",
    component: Trading,
    meta: {
      layout: "Default",
      pageName: "匯率管理",
    },
  },
  {
    path: "/advertise",
    name: "Advertise",
    component: Advertise,
    meta: {
      layout: "Default",
      pageName: "廣告管理",
    },
  },
  {
    path: "/moneyin",
    name: "MoneyIn",
    component: MoneyIn,
    meta: {
      layout: "Default",
      pageName: "入金管理",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      layout: "Default",
      pageName: "團隊管理",
    },
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      layout: "Default",
      pageName: "公告中心",
    },
  },
  {
    path: "/moneyout",
    name: "MoneyOut",
    component: MoneyOut,
    meta: {
      layout: "Default",
      pageName: "出金管理",
    },
  },
  {
    path: "/analyze",
    name: "Analyze",
    component: Analyze,
    meta: {
      layout: "Default",
      pageName: "戰情分析",
    },
  },
  {
    path: "/CoinManager",
    name: "CoinManager",
    component: CoinManager,
    meta: {
      layout: "Default",
      pageName: "幣商",
    },
  },
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const pageName = to.meta.pageName;
  store.commit("getActivePage", pageName);
  next();
});

export default router;
