<template>
  <div>
    <div class="hidden lg:block" v-if="false">
      <div class="subgroup-red text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Select User Permissions</strong>
        </h2>
        <h2 class="subtitle">
          <strong>幣商編輯</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="text-center pt-2 pb-[17px]">
          <h2 class="h-[80px] text-5xl">{{ selectObj }}</h2>
        </div>
        <div
          class="w-[35%] text-center pt-2 pb-[30px] flex flex-col items-center"
          style="margin: 0 auto"
        >
     
          <div class="w-[60%] mt-[30%] text-sm">
            <Button
              label="確認保存"
              color="green"
              text="white"
              size="popup-md"
              @click="save()"
            />
          </div>
        </div>
      </div>
    </div>
    <popcorn
      class="block "
      name="delManager"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card iconhead="true" color="red" type="dialog">

        <template #header>
          <div class="flex items-center justify-center pt-[8px]">
            <img
              style="max-width: initial"
              src="@/assets/img/settingManager.png"
              alt=""
            />
          </div>
        </template>
        <template #body>
           <div class="text-center pt-2 pb-[5px]">
              <h1 class="text-gray-300 text-lg">幣商編輯</h1>
            </div>
          <template>
            <div class="pt-[12px] text-xl">
              <Input
                outlined
                placeholder="請設定廠商名稱"
                hint="black"
                v-model="f廠商名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定銀行代號"
                hint="gray"
                v-model="銀行代號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定銀行名稱"
                hint="gray"
                v-model="銀行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定分行名稱"
                hint="gray"
                v-model="分行名稱"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定匯款帳號"
                hint="gray"
                v-model="f匯款帳號"
                class="mb-[14px]"
              />
              <Input
                outlined
                placeholder="請設定當日限額"
                hint="gray"
                v-model="f當日限額"
                class="mb-[14px]"
              />
            </div>
          </template>
    
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
          <Button
            label="確認保存"
            color="green"
            text="white"
            size="popup-md"
            @click="save()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button,Input } from "@/components/Shared";
export default {
  name: "SettingCoinManager",
  components: { Card, Button ,Input},
  props: {
   
    selectObj: {
      type: Object,
    },
    管理員查詢幣商_全搜尋處理:{
      type:Function,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      select: [],
      delselect: [],
      options: [
        "二級驗證申請",
        "會員名單管理",
        "匯率管理",
        "入金管理",
        "出金管理",
        "戰情分析",
      ],
      f廠商名稱:'',
      銀行代號:'',
      銀行名稱:'',
      分行名稱:'',
      f匯款帳號:'',
      f當日限額:'',
    };
  },
  mounted() {
    console.log(typeof this.selectObj.f銀行資訊=='object');
    this.$modal.show("delManager");
    if(typeof this.selectObj.f銀行資訊!='object'){
      this.selectObj.f銀行資訊 = JSON.parse(this.selectObj.f銀行資訊)
    }
    this.f廠商名稱 =this.selectObj.f廠商名稱
    this.f匯款帳號 =this.selectObj.f匯款帳號
    this.f當日限額 =this.selectObj.f當日限額
    this.銀行代號 =this.selectObj.f銀行資訊.銀行代號
    this.分行名稱 =this.selectObj.f銀行資訊.分行名稱
    this.銀行名稱 =this.selectObj.f銀行資訊.銀行名稱
  },
  computed: {},
  methods: {
    檢查(tar) {
      return (
        (this.$tools.checkAuth(this.selectObj.auth, tar, "READ") &&
          this.delselect.indexOf(tar) == -1) ||
        this.select.indexOf(tar) != -1
      );
    },
    save() {
      if (
        this.f廠商名稱 == "" ||
        this.銀行代號 == "" ||
        this.銀行名稱 == "" ||
        this.分行名稱 == "" ||
        this.f匯款帳號 == "" ||
        this.f當日限額 == "" 
      ) {
        // alert("輸入不完整");
        this.$notify.warning("輸入不完整");
        return;
      }
      let obj = {
        f廠商名稱:this.f廠商名稱,
        f銀行資訊:JSON.stringify({
          銀行代號:this.銀行代號,
          銀行名稱:this.銀行名稱,
          分行名稱:this.分行名稱,
        }),
        f匯款帳號:this.f匯款帳號,
        f當日限額:this.f當日限額,
        f廠商編號:this.selectObj.f廠商編號,
      };
       
      this.$store
        .dispatch("apiAdmin/管理員修改幣商", this.$tools.ObjToForm(obj))
        .then(res=>{
          console.log(res);
          if(res.message=='修改幣商成功。'){
            this.$notify.success("修改成功");
            this.$modal.hide("doubleCheck");
            this.管理員查詢幣商_全搜尋處理()
          }else{
            this.$notify.warning(res.message);
          }
        }).catch(err=>{
        if(err.response.data.errors.f當日限額!=undefined){
          this.$notify.warning("當日限額必需為數字");
          this.closeWindow()
          this.$modal.hide("doubleCheck");
        }else{
            this.$notify.warning("操作失敗，請聯絡程式人員");
            this.closeWindow()
          this.$modal.hide("doubleCheck")
          }
          console.log();
        });
    },
    saveFinish() {
      this.$emit("successEvent");
      this.$emit("toggleEvent", "");
      this.select = [];
      this.delselect = [];
    },
    saveObj(data) {
      return new Promise((resolve, reject) => {
        if (data.length == 0) resolve();
        let obj = {};
        data.forEach((e) => {
          obj[e] = ["READ", "DELETE", "EDIT"];
        });
        console.log("更新", obj);

        let _obj = {
          fId: this.selectObj.id,
          authority: JSON.stringify(obj),
        };
        let formdata = this.$tools.ObjToForm(_obj);
        this.$store
          .dispatch("apiAdmin/RootModiGenAdminAuth", formdata)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    delObj(data) {
      console.log("剛進來", data);
      if (data.length == 0) {
        this.saveFinish();
        return;
      }
      let _obj = {
        fId: this.selectObj.id,
        authority: data[data.length - 1],
      };

      let formdata = this.$tools.ObjToForm(_obj);
      this.$store
        .dispatch("apiAdmin/RootDelGenAdminAuth", formdata)
        .then(() => {
          data.pop();
          console.log("刪除成功", data);
          this.delObj(data);
        });
    },
    toggleAuth(tar) {
      const chk = this.$tools.checkAuth(this.selectObj.auth, tar, "READ");
      if (chk) {
        this.touchDelSelect(tar);
      } else {
        this.touchSelect(tar);
      }
    },
    touchSelect(tar) {
      //原本不存在 但要新增的
      const idx = this.select.indexOf(tar);
      if (idx == -1) {
        this.select.push(tar);
      } else {
        this.select.splice(idx, 1);
      }
    },
    touchDelSelect(tar) {
      //原本存在 但要刪除的
      const idx = this.delselect.indexOf(tar);
      if (idx == -1) {
        this.delselect.push(tar);
      } else {
        this.delselect.splice(idx, 1);
      }
    },
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("delManager");
    },
    DelGenAdmin() {
      let obj = { fId: this.selectObj.id };
      let formdata = this.$tools.ObjToForm(obj);
      this.$store.dispatch("apiAdmin/DelGenAdmin", formdata).then((res) => {
        let data = res.data;
        if (data != 0) {
          // alert("刪除成功");
          this.$emit("successEvent");
          this.$notify.success("刪除成功");
          this.closeWindow();
        }
      });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
