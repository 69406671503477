var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center space-x-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{class:[
      'rounded-full',
      'w-3.5',
      'h-3.5',
      'border',
      'border-white',
      'bg-transparent',
      {
        [_vm.selectedStyle]: _vm.selected,
      },
    ]}),_c('span',[_vm._v(_vm._s(_vm.label))])])
}
var staticRenderFns = []

export { render, staticRenderFns }