<template>
  <div
    style="overflow-y: hidden"
    class="bg-white h-full px-2.5 lg:px-[50px] py-6 lg:py-5 flex flex-col"
  >
    <div
      @click="selectEvent = ''"
      v-if="selectEvent != ''"
      class="px-4 appArrow"
    >
      <span style="cursor: pointer">&#10154;</span>
    </div>
    <div class="flex justify-around overflow-y-scroll no-scrollbar h-[70vh]">
      <!-- 左邊 -->
      <div
        class="flex-1"
        :class="selectEvent == '' ? '' : 'lg:w-48 appHandle2'"
      >
        <div
          class="px-4 mb-[22px] lg:mb-7 lg:flex lg:space-x-[13px] items-start text-right lg:text-left"
        >
          <div class="subgroup-red text-2xl hidden lg:block lg:flex-grow">
            <h2 class="sub mb-4 ml-[4px]">
              <strong>Team management</strong>
            </h2>
            <h2 class="subtitle">
              <strong>團隊管理</strong>
            </h2>
          </div>

          <div class="flex flex-col items-end pt-[45px]">
            <div class="w-full lg:w-[280px] mb-[14px] lg:mb-0">
              <Input
                placeholder="輸入團隊名稱搜尋"
                filled
                hint="gray"
                text="gray"
                v-model="searchWords"
              >
                <template #end>
                  <Icon @click="搜尋按鈕()" icon="icon-search" />
                </template>
              </Input>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mb-3">
          <div>
            <Button
              @click="toggleEvent('CREATE', {})"
              label="新增團隊"
              color="gray"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
            />
            <!-- <Button
              label="刪除團隊"
              color="red"
              text="white"
              size="status-sm"
              class="text-xs lg:text-lg mr-3"
            /> -->
          </div>
        </div>
        <div class="fflex-1 overflow-y-scroll no-scrollbar h-[70vh]">
          <!-- :rows="$store.getters['teamManagement/團隊管理列表']" -->
          <Table
            :rows="$store.getters['apiTeamManagement/團隊管理列表']"
            :cols="cols"
            :disableStatus="true"
          >
            <template #edit="manager">
              <div
                v-if="manager.account != 'ROOT'"
                style="justify-content: center"
                class="flex"
              >
                <Icon
                  style=""
                  class="searchIconApp"
                  @click="toggleEvent('READ', manager)"
                  icon="icon-search"
                />

                <Icon
                  class="scale-150 px-2"
                  :img="require('@/assets/img/icon-vector.svg')"
                  @click="toggleEvent('EDIT', manager)"
                />
                <Icon
                  style="max-width: max-content"
                  class="scale-150"
                  :img="require('@/assets/img/icon-frame.svg')"
                  @click="toggleEvent('DELETE', manager)"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
      <!-- 右邊 -->
      <div
        style="height: 100%"
        :class="selectEvent == '' ? '' : 'flex-1 lg:w-48 px-3 appHandle'"
      >
        <!-- <VerifyMembership
          v-if="selectEvent == 'DETAIL'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></VerifyMembership> -->
        <Team_info
          v-if="
            selectEvent == 'CREATE' ||
            selectEvent == 'READ' ||
            selectEvent == 'EDIT'
          "
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          @toggleEvent="toggleEvent"
          :toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></Team_info>
        <DelTeam
          style="padding: 0px 20px"
          v-else-if="selectEvent == 'DELETE'"
          :selectObj="selectObj"
          :selectEvent="selectEvent"
          :toggleEvent="toggleEvent"
          @toggleEvent="toggleEvent"
          @successEvent="successEvent"
        ></DelTeam>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, Input, Icon, Button } from "@/components/Shared";
// import { VerifyMembership } from "@/components/Membership";
import { teamManagement } from "@/usecase/teamManagement";
import { Team_info, DelTeam } from "@/components/Manager";

export default {
  name: "Level2",
  components: {
    Table,
    Input,
    Icon,
    Button,
    Team_info,
    DelTeam,

    // VerifyMembership,
  },
  data() {
    return {
      selectEvent: "",
      selectObj: {},
      cols: [
        {
          name: "status",
          label: "",
          align: "center",
        },
        {
          name: "團隊名稱",
          label: "團隊名稱",
          align: "center",
        },
        { name: "團隊碼", label: "團隊碼", align: "center" },
        {
          name: "團隊人數",
          label: "團隊人數",
          align: "center",
        },
        { name: "edit", label: "編輯", align: "center" },
      ],

      searchWords: "",
      searchTarget: "全部",
      bankList: ["匯出CSV", "匯出PDF"],
      formValues: "匯出",
      show: false,

      搜尋中: false,
      搜尋後rows: [],
    };
  },
  watch: {
    searchWords(val) {
      if (val === "") {
        teamManagement.GA04_查詢_t團隊_全搜尋();
      }
    },
  },
  methods: {
    初始畫面() {
      this.selectEvent = "";
    },
    匯出CSV() {
      let config = {
        headers: { Authorization: "Bearer " + this.$store.state.login.token },
      };
      // fetch("http://220.134.4.63:6382/api/Export/CSV/VerifyemberInfo", config)
      fetch("https://amlservice.qhr.app/api/Export/CSV/VerifyemberInfo", config)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response.data);
          // const blob = new Blob([response], { type: 'application/vnd.ms-excel;charset=utf-8' })
          // console.log(blob);
          var link = document.createElement("a");
          link.href = "https://amlservice.qhr.app/" + response.data;
          // link.href = "http://220.134.4.63:6382/" + response.data;
          link.click();
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
    },
    搜尋按鈕() {
      console.log(this.searchWords);
      teamManagement.GA04_查詢_t團隊_全搜尋(this.searchWords);
    },
    successEvent() {
      console.log("EVENT");
      // this.GA04_查詢_t團隊_全搜尋();
    },
    toggleEvent(event, data = {}) {
      data = this.$deepParseJson(data); //把所有JSONstring 解開
      if (this.selectEvent == "") {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (this.selectEvent != event && event != "") {
        //不同event
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) != JSON.stringify(data) &&
        event != ""
      ) {
        this.selectEvent = event;
        this.selectObj = data;
      } else if (
        this.selectEvent == event &&
        JSON.stringify(this.selectObj) == JSON.stringify(data)
      ) {
        this.selectEvent = "";
      } else {
        this.selectEvent = "";
      }

      console.log("結果cc", this.selectEvent, this.selectObj);
    },
    changeChainStatus(manager) {
      this.$store.commit("setPopupContent", "chainCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", { userData: manager });
    },
    deleteManager(manager) {
      this.$store.commit("setPopupContent", "DeleteCard");
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupProps", {
        userData: manager,
        role: "manager",
        title: "管理者",
      });
    },
    addManager() {
      this.$store.commit("togglePopup", true);
      this.$store.commit("setPopupContent", "AddCard");
      this.$store.commit("setPopupProps", {
        title: "管理者帳號",
        color: "dark-blue",
        role: "manager",
      });
    },
  },
  computed: {
    審核: function () {
      return {
        全部: this.$store.state.apiVerify.審核列表,
        審核失敗: this.$store.state.apiVerify.審核列表.filter(
          (m) => m.審核狀態 == 2
        ),
        未審核: this.$store.state.apiVerify.審核列表.filter(
          (m) => m.審核狀態 == 0
        ),
      };
    },
  },
  created() {
    this.$store.commit("manager/getManagerData");
  },
  mounted() {
    teamManagement.GA04_查詢_t團隊_全搜尋();

    // this.api().RootGetAllAdminInfo();
  },
};
</script>
<style>
tr {
  border: 1px solid #d8c1bb;
  border-radius: 10px;
}
</style>
<style scoped>
.sub {
  font-size: 0.9em;
  font-size: bolder;
  font-weight: bolder;
}
.subtitle {
  font-size: 1.5em;
  font-size: bolder;
  font-weight: bolder;
}

.subgroup-red .sub {
  color: #eac9c9;
}

.subgroup-blue .sub {
  color: #c9daea;
}
.subgroup-green .sub {
  color: #c9eadc;
}

.subgroup-red .subtitle {
  color: #cc232a;
  -webkit-text-stroke: 0.5px #cc232a;
}
.subgroup-blue .subtitle {
  color: #283395;
  -webkit-text-stroke: 0.5px #283395;
}
.subgroup-green .subtitle {
  color: #389568;
  -webkit-text-stroke: 0.5px #389568;
}
.appArrow {
  display: none;
}
.searchIconApp {
  transform: scale(1.2);
}
@media screen and (max-width: 1300px) {
  .appArrow {
    font-size: 31px;
    transform: rotate(180deg);
    display: flex;
    justify-content: end;
  }
  .appHandle {
    width: 100%;
  }
  .appHandle2 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .searchIconApp {
    transform: scale(0.7);
  }
}
</style>
