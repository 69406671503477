import dayjs from "dayjs";
export function formatWalletAddress(fullAddress) {
  if(fullAddress!=undefined){
  return fullAddress.substring(0, 4) + "..." + fullAddress.slice(-6);
  }
}

export function formatTwoDigitYear(fullYear) {
  return dayjs(fullYear).format("YY/MM/DD");
}

export function 銀行資訊解析(data) {
  if(data!=undefined){
  return JSON.parse(data).銀行代號 + ' '+ JSON.parse(data).銀行名稱;
  }
}