<template>
  <Card type="confirm">
    <template #header>
      <p class="pt-2">請填入審核不通過的原因</p>
    </template>
    <template #body>
      <div class="flex flex-col space-y-5 items-center py-5 w-full px-[34px]">
        <CheckboxGroup
          :optionList="reasonList"
          v-model="failedReason"
          :otherReason="otherReason"
          @getOtherReason="otherReason = $event"
        />
      </div>
    </template>
    <template #footer>
      <div
        class="flex w-full border-t border-[#ABABAB] text-center cursor-pointer"
      >
        <div class="flex-grow border-r border-[#ABABAB] p-4" @click="confirm">
          確 認
        </div>
        <div class="flex-grow p-4" @click="$emit('cancel')">取 消</div>
      </div>
    </template>
  </Card>
</template>

<script>
import { Card, CheckboxGroup } from "@/components/Shared";
export default {
  name: "FailedConfirm",
  components: {
    Card,
    CheckboxGroup,
  },
  props: {
    reasonList: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
    level: {
      type: String,
      default: "level2",
    },
  },
  data() {
    return {
      failedReason: this.reasonList.filter((reason, index) => index === 0),
      otherReason: "",
    };
  },
  computed: {
    includeOtherReasonFailedList() {
      if (!this.otherReason) return this.failedReason;
      return [
        ...this.failedReason.filter((reason) => reason.value !== "others"),
        { value: "others", label: `其他 - ${this.otherReason}` },
      ];
    },
  },
  methods: {
    confirm() {
      this.$store
        .dispatch(
          "apiAdmin/VerifyNO",
          this.$tools.ObjToForm({
            fId: this.id,
            駁回原因: JSON.stringify(this.failedReason),
          })
        )
        .then((res) => {
          if (res.data != 0) {
            if (this.level === "level2") {
              this.$store.commit("validation/changeLevel2ApplierStatusByID", {
                id: this.id,
                failedReason: this.includeOtherReasonFailedList,
              });
            } else {
              this.$store.commit("validation/changeLevel3ApplierStatusByID", {
                id: this.id,
                failedReason: this.includeOtherReasonFailedList,
              });
            }
            this.$emit("cancel");
            this.$store.commit("togglePopup", false);
          }
        });
    },
  },
};
</script>
