<template>
  <div>
    <div class="hidden lg:block" v-if="false">
      <div class="subgroup-red text-2xl lg:flex-grow">
        <h2 class="sub mb-4 ml-[4px]">
          <strong>Remove Admin</strong>
        </h2>
        <h2 class="subtitle">
          <strong>刪除幣商</strong>
        </h2>
      </div>
      <div class="mt-[30px]">
        <div class="text-center pt-2 pb-[17px]">
          <!-- <h1 class="text-gray-300">請再次確認刪除用戶</h1>
          <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2> -->
        </div>

        <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->
        <Button
          label="確認刪除"
          color="red"
          text="white"
          size="popup-md"
          @click="DelGenAdmin()"
        />
        <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
          申請人 &colon; {{ $store.state.login.account }}
        </p>
      </div>
    </div>
    <popcorn
      class="block"
      name="delManager"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <Card color="red" type="dialog">
        <template #header>
          <p>刪除幣商</p>
        </template>
        <template #body>
          <div
            style="width: fit-content; text-align: left; margin: auto"
            class="pt-2 pb-[17px]"
          >
            <div>
              廠商名稱:
              <span style="font-weight: 900">{{ selectObj.f廠商名稱 }}</span>
            </div>
            <div>
              廠商編號:
              <span style="font-weight: 900">{{ selectObj.f廠商編號 }}</span>
            </div>
          </div>

          <!-- <template>
            <div class="text-center pt-2 pb-[17px]">
              <h1 class="text-gray-300">請再次確認刪除用戶</h1>
              <h2 class="h-[80px] text-5xl">{{ selectObj.apply_name }}</h2>
            </div>
          </template> -->
        </template>
        <template #footer>
          <!-- <Button
            v-if="doubleConfirm"
            label="確認新增"
            :color="color"
            text="white"
            size="popup-md"
            @click="confirm"
          /> -->

          <Button
            label="確認刪除"
            color="red"
            text="white"
            size="popup-md"
            @click="DelGenAdmin()"
          />
          <Button
            class="mt-3"
            label="取消"
            color="gray"
            text="white"
            size="popup-md"
            @click="closeWindow"
          />
          <p class="text-right text-[#B7B7B7] mb-2 text-[13px]">
            申請人 &colon; {{ $store.state.login.account }}
          </p>
        </template>
      </Card>
    </popcorn>
  </div>
</template>

<script>
import { Card, Button } from "@/components/Shared";
export default {
  name: "DelCoinManager",
  inject: ["reload"],
  components: { Card, Button },
  props: {
    selectEvent: {
      type: String,
      default: "",
    },
    selectObj: {
      type: Object,
    },
    管理員查詢幣商_全搜尋處理: {
      type: Function,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
    };
  },
  mounted() {
    this.$modal.show("delManager");
  },
  computed: {},
  methods: {
    closeWindow: function () {
      this.$emit("toggleEvent", "");
      this.$modal.hide("delManager");
    },
    DelGenAdmin() {
      console.log(this.$store.getters["login/get_token"]);
      const axios = require("axios");
      const FormData = require("form-data");
      let data = new FormData();
      data.append("f廠商編號", this.selectObj.f廠商編號);

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "https://amlservice.qhr.app/api/CoinDealers/CoinDealersInfo",
        // url: 'http://220.134.4.63:6382/api/CoinDealers/CoinDealersInfo',
        headers: {
          Authorization: `Bearer ${this.$store.getters["login/get_token"]}`,
        },
        data: data,
      };

      axios.request(config).then((res) => {
        console.log(23);
        console.log(res);
        if (res.message == "刪除幣商成功。") {
          this.$notify.success("刪除成功");
          this.$modal.hide("delManager");
          this.管理員查詢幣商_全搜尋處理();
          setTimeout(() => {
            this.reload();
          }, 100);
        } else {
          this.$notify.warning(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.form-btn {
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
  background: #283395;
  text-align: center;
  color: white;
  padding: 6px 0px;
  border-radius: 5px;
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
