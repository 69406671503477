import { request } from "@/store/api";
// getSearch = data{ searchAdmin.get(`/Search?searchdata=${data}`)
// getSearchType(){ searchAdmin.get(`/SearchType`)
function UnixToDate(unix) {
  var date = new Date(parseInt(unix + "000"));
  return (
    date.getFullYear().toString() +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getDate().toString().padStart(2, "0")
  );
}

export const apiAdmin = {
  namespaced: true,
  state: {
    RateUSDT: 0,
    RateTWD: 0,
    RateUSDTRecord: [],
    RateTWDRecord: [],
    adminInfo: {},
    會員列表: [],
  },
  getters: {
    RateCoin(state) {
      return (coin) => {
        if (coin == "TWD") {
          return state.RateTWD;
        } else {
          return state.RateUSDT;
        }
      };
    },
    RateUSDT(state) {
      return state.RateUSDT;
    },
    RateTWD(state) {
      return state.RateTWD;
    },
    RateUSDTRecord(state) {
      return state.RateUSDTRecord;
    },
    RateTWDRecord(state) {
      return state.RateTWDRecord;
    },
  },
  mutations: {
    SET_RateUSDT(state, RateUSDT) {
      state.RateUSDT = RateUSDT;
    },
    SET_RateTWD(state, RateTWD) {
      state.RateTWD = RateTWD;
    },
    SET_RateUSDTRecord(state, RateUSDTRecord) {
      state.RateUSDTRecord = RateUSDTRecord;
    },
    SET_RateTWDRecord(state, RateTWDRecord) {
      state.RateTWDRecord = RateTWDRecord;
    },
    SET_AdminInfo(state, info) {
      state.adminInfo = info;
    },
    SET_會員列表(state, data) {
      state.會員列表 = data;
    },
  },
  actions: {
    AdminSignIn(state, data) {
      return request.addFORM("Administer").post("/AdminSignIn", data);
    },
    //Root新增一般管理員
    SignUpNewGenAdmin(state, data) {
      return request.addFORMAuth("Administer").post("/SignUpNewGenAdmin", data);
    },
    //Root刪除一般管理員
    DelGenAdmin(state, data) {
      return request.addFORMAuth("Administer").post("/DelGenAdmin", data);
    },
    //Root刪除一般管理員
    DelGenAdminbyAcc(state, data) {
      return request.addFORMAuth("Administer").post("/DelGenAdminbyAcc", data);
    },
    //Root修改一般管理員資料
    RootModiGenAdminInfo(state, data) {
      return request
        .addFORMAuth("Administer")
        .post("/RootModiGenAdminInfo", data);
    },
    //Root修改一般管理員權限
    RootModiGenAdminAuth(state, data) {
      return request
        .addFORMAuth("Administer")
        .post("/RootModiGenAdminAuth", data);
    },
    //Root刪除一般管理員權限
    RootDelGenAdminAuth(state, data) {
      return request
        .addFORMAuth("Administer")
        .post("/RootDelGenAdminAuth", data);
    },
    //Root取出所有Admin資料
    RootGetAllAdminInfo() {
      console.log("JWT");
      return request.addFORMAuth("Administer").post("/RootGetAllAdminInfo");
    },
    //一般管理員登入
    GenAdminModiOwnInfo(state, data) {
      return request
        .addFORMAuth("Administer")
        .post("/GenAdminModiOwnInfo", data);
    },
    //一般管理員修改自己的資料
    ModifyComicDashboard(state, data) {
      return request
        .addFORMAuth("Administer")
        .post("/ModifyComicDashboard", data);
    },
    //取得所有資料表
    RootGetAllTableInfo() {
      return request.addAuth("Administer").get("/RootGetAllTableInfo");
    },
    //取得所有資料表
    GetAdminInfo() {
      return request.addAuth("Administer").get("/AdminInfo/Admin");
    },
    //---------------幣商------------------------------------------
    //A05_管理員查詢幣商_全搜尋處理
    管理員查詢幣商_全搜尋處理(state, data) {
      return request.addAuth("CoinDealers").get("/CoinDealersInfo", { params: data });
    },
     //A07_管理員取得EXCEL的範本路徑
     管理員取得EXCEL的範本路徑() {
      return request.addAuth("CoinDealers").get("/CoinDealersInfo/EXCELSample");
    },
     //A01_管理員新增幣商
     管理員新增幣商(state, data) {
      return request.addFORMAuth("CoinDealers").post("/CoinDealersInfo", data);
    },
      //A02_管理員修改幣商
      管理員修改幣商(state, data) {
        return request.addFORMAuth("CoinDealers").put("/CoinDealersInfo", data);
      },
     //A04_管理員修改會員的幣商
     管理員修改會員的幣商(state, data) {
      return request.addFORMAuth("CoinDealers").put("/CoinDealersInfo/Member", data);
    },
   //A06_管理員透過幣商EXCEL查詢會員資料
   管理員透過幣商EXCEL查詢會員資料(state, data) {
    return request.addFORMAuth("CoinDealers").post("/CoinDealersInfo/EXCEL",  data);
  },
     //---------------------------------------------------------
    //顯示審核列表
    GetVerify(state, data) {
      return request.addAuth("Verify").get("/GetVerify", { params: data });
    },
    GetVerifyJoin(state, data) {
      return request.addAuth("Verify").post("/GetVerifyJoin", data);
    },
    GetMixVerifyJoin(state, data) {
      return request.addAuth("Verify").post("/GetMixVerifyJoin", data);
    },
    //審核ok
    VerifyOK(state, data) {
      return request.addFORMAuth("Verify").post("/VerifyOK", data);
    },
    //審核駁回
    VerifyNO(state, data) {
      return request.addFORMAuth("Verify").post("/VerifyNO", data);
    },
    //---------------------------------------------------------
    //顯示兌換列表
    SaveUSDT(state, data) {
      return request.addFORMAuth("Exchange").post("/SaveUSDT", data);
    },
    SaveTWD(state, data) {
      return request.addFORMAuth("Exchange").post("/SaveTWD", data);
    },
    RateUSDTtoTWD(state, data) {
      return request.addFORMAuth("Exchange").post("/RateUSDTtoTWD", data);
    },
    RateTWDtoUSDT(state, data) {
      return request.addFORMAuth("Exchange").post("/RateTWDtoUSDT", data);
    },
    GetUSDTOut() {
      return request.addAuth("Exchange").get("/GetUSDTOut");
    },
    GetUSDTIn() {
      return request.addAuth("Exchange").get("/GetUSDTIn");
    },
    //USDT -> TWD
    GetRateUSDTtoTWD(state) {
      console.log(state);
      return new Promise((resolve, reject) => {
        request
          .request("Exchange")
          .get("/GetRateUSDTtoTWD")
          .then((res) => {
            state.commit("SET_RateUSDT", res.data.fCode);
            let f異動紀錄 = {};
            if (res.data.f異動紀錄 != "")
              f異動紀錄 = JSON.parse(res.data.f異動紀錄);
            let list = [];
            if (Object.keys(f異動紀錄).length != 0) {
              Object.keys(f異動紀錄).forEach((key, idx) => {
                let data = f異動紀錄[key];
                let record = data.info.split("_");

                let obj = {
                  nextDate: UnixToDate(key.substring(1, key.length)),
                  nextEditor: data.name,
                  date: list.length > 1 ? list[list.length - 1].nextDate : "",
                  tradingPair: "USDT/TWD",
                  exchangeRate: `1 USDT = ${record[1]} TWD`,
                  editor:
                    list.length > 1 ? list[list.length - 1].nextEditor : "",
                };
                if (obj.editor == "") {
                  obj.editor = "初始值";
                  obj.date = obj.nextDate;
                }
                list.push(obj);
                console.log("排序", idx);
              });
              list.push({
                date: list[list.length - 1].nextDate,
                tradingPair: "USDT/TWD",
                exchangeRate: `1 USDT = ${res.data.fCode} TWD`,
                editor: list[list.length - 1].nextEditor,
              });
              console.log("USDT/TWD", list);
              list.reverse();
            }

            state.commit("SET_RateUSDTRecord", list);
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //TWD -> USDT
    GetRateTWDtoUSDT(state) {
      return new Promise((resolve, reject) => {
        request
          .request("Exchange")
          .get("/GetRateTWDtoUSDT")
          .then((res) => {
            state.commit("SET_RateTWD", res.data.fCode);
            let f異動紀錄 = {};
            if (res.data.f異動紀錄 != "")
              f異動紀錄 = JSON.parse(res.data.f異動紀錄);
            let list = [];
            if (Object.keys(f異動紀錄).length != 0) {
              Object.keys(f異動紀錄).forEach((key) => {
                let data = f異動紀錄[key];
                let record = data.info.split("_");
                let obj = {
                  nextDate: UnixToDate(key.substring(1, key.length)),
                  nextEditor: data.name,
                  date: list.length > 1 ? list[list.length - 1].nextDate : "",
                  tradingPair: "TWD/USDT",
                  exchangeRate: `1 USDT = ${record[1]} TWD`,
                  editor:
                    list.length > 1 ? list[list.length - 1].nextEditor : "",
                };
                if (obj.editor == "") {
                  obj.editor = "初始值";
                  obj.date = obj.nextDate;
                }

                list.push(obj);
              });
              list.push({
                date: list[list.length - 1].nextDate,
                tradingPair: "TWD/USDT",
                exchangeRate: `1 USDT = ${res.data.fCode} TWD`,
                editor: list[list.length - 1].nextEditor,
              });
              console.log("TWD/USDT", list);
              list.reverse();
              console.log(list);
            }
            state.commit("SET_RateTWDRecord", list);
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetUSDT(state) {
      console.log(state);
      return request.request("Exchange").get("/GetUSDT");
    },
    GetTWD(state) {
      console.log(state);
      return request.request("Exchange").get("/GetTWD");
    },
    // A05_取得CSV_戰情分析
    A05_取得CSV_戰情分析(state, data) {
      return request
        .addAuth("Export")
        .get("/CSV/Analysis", { params: data });
    },
    //---------------------------------------------------------
    //顯示會員列表
    //公司
    GetCompanyAllMemInfo(state, data) {
      return request.addFORMAuth("Company").post("/GetAllMemInfo", data);
    },
    //管理員新增公司
    AdminSingUpCompany(state, data) {
      return request.addFORMAuth("Company").post("/AdminSingUp", data);
    },
    //管理員修改公司
    AdminModifyDataCompany(state, data) {
      return request.addFORMAuth("Company").post("/AdminModifyData", data);
    },
    //個人戶
    GetPersonalAllMemInfo(state, data) {
      return request.addFORMAuth("Personal").post("/GetAllMemInfo", data);
    },
    //管理員新增個人戶
    AdminSingUpPersonal(state, data) {
      return request.addFORMAuth("Personal").post("/AdminSingUp", data);
    },
    //管理員修改個人戶
    AdminModifyDataPersonal(state, data) {
      return request.addFORMAuth("Personal").post("/AdminModifyData", data);
    },
    //會員
    GetAllMemInfo(state, data) {
      return request.addFORMAuth("Member").post("/GetAllMemInfo", data);
    },
  },
};
