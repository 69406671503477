<template>
  <div

    :class="[
      size === 'static'
        ? 'w-[149px] h-[34px] rounded-default'
        : 'w-full h-full',
      'cursor-pointer',
      'relative',
      color === 'gray' ? 'bg-white text-gray-700' : displayStyle[color].default,
    ]"
    @click="toggleSelection"
    v-click-outside="closeSelection"
  >

    <div class="flex flex-row h-full items-center justify-center">
      <p :class="['text-base', { 'font-bold': !size }]">
        {{ selectedValue }}
      </p>
      <Icon
        :icon="openSelection ? 'icon-chevron-up' : 'icon-chevron-down'"
        dense
      />
    </div>
    <ul
    style="z-index: 1000;"
      v-show="openSelection"
      :class="[
        'mt-6 absolute before:absolute before:top-[-7px] before:right-6 before:triangle rounded-default drop-shadow-select text-sm',
        size === 'static' ? 'right-1' : 'left-2',
        displayStyle[color].default,
      ]"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        :class="[
          'py-1.5 px-2 flex cursor-pointer',
          size === 'static' ? 'w-[166px]' : 'w-[100px]',
          option === selectedValue
            ? displayStyle[color].select
            : displayStyle[color].default,
          displayStyle[color].hover,
          'first:rounded-t-default',
          'last:rounded-b-default',
        ]"
        @click="selectedValue = option"
      >
        {{ option }}
        <span
          v-if="option === selectedValue"
          :class="['ml-auto', displayStyle[color].select]"
          >&#10003;</span
        >
      </li>
    </ul>
  </div>
 
</template>

<script>
import { Icon } from "@/components/Shared";
export default {
  name: "TradingSelect",
  components: { Icon },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    color: {
      type: String,
      default: "green",
    },
    區塊: {
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openSelection: false,
      displayStyle: {
        green: {
          default: "bg-green-300 text-white",
          hover: "hover:bg-white hover:text-green-300",
          select: "bg-white text-green-300",
        },
        red: {
          default: "bg-red-200 text-white",
          hover: "hover:bg-white hover:text-red-200",
          select: "bg-white text-red-200",
        },
        gray: {
          default: "bg-gray-700 text-white",
          hover: "hover:bg-white hover:text-gray-700",
          select: "bg-white text-gray-700",
        },
      },
    };
  },
  computed: {
    selectedValue: {
      get() {
        console.log("項目", this.value);
        return this.value || this.options[0];
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    toggleSelection() {
      this.openSelection = !this.openSelection;
    },
    closeSelection() {
      this.openSelection = false;
    },
  },
};
</script>
