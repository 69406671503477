<template>
  <Card color="blue" type="form">
    <template #header>
      <div class="flex text-white w-full justify-between text-xs pr-3">
        <div class="flex space-x-4 text-[11px] lg:text-[13px]">
          <Radio
            label="公司戶"
            :selected="formType === 'company'"
            @click="formType = 'company'"
          />
          <Radio
            label="個人"
            :selected="formType === 'personal'"
            @click="formType = 'personal'"
          />
        </div>
        <p class="text-sm">申請時間 &colon; 2022/2/24 12:00</p>
      </div>
      <div class="w-full flex flex-row items-center space-x-[5px] pl-5">
        <img src="@/assets/img/membership2.png" alt="membership2 icon" />
        <Input
          v-model="apply_name"
          placeholder="請輸入名稱"
          hint="white"
          class="text-2xl"
          text="white"
          dense
        />
        <GenderRadio v-model="gender" />
      </div>
      <!-- </div> -->
    </template>
    <template #body>
      <div class="min-w-[335px] sm:min-w-[600px] w-full">
        <component :is="formName">
          <template #button="formValues">
            <Button
              label="確定新增"
              color="green"
              size="popup-sm"
              text="white"
              @click="submitForm(formValues)"
            />
          </template>
        </component>
      </div>
    </template>
    <template #footer>
      <div class="py-2" />
    </template>
  </Card>
</template>

<script>
import { Input, Card, Radio, GenderRadio, Button } from "@/components/Shared";
import { PersonalForm, CompanyForm } from "@/components/FormType";

export default {
  name: "ApplyForm",
  components: {
    Input,
    Card,
    Radio,
    GenderRadio,
    Button,
    PersonalForm,
    CompanyForm,
  },
  props: {
    buttons: {
      type: Array,
      default: () => [{ color: "green", label: "確認新增" }],
    },
  },
  data() {
    return {
      formType: "personal",
      apply_name: "",
      gender: "male",
    };
  },
  computed: {
    formName() {
      return this.formType[0].toUpperCase() + this.formType.slice(1) + "Form";
    },
  },
  methods: {
    confirm() {
      this.$store.commit("togglePopup", false);
    },
    submitForm(formData) {
      this.$store.commit("membership/addMembership2Data", {
        id: Date.now(),
        type: this.formType,
        membership: 2,
        gender: this.gender,
        apply_name: this.apply_name,
        ...formData,
        status: "unchain",
      });
      this.$emit("confirm");
    },
  },
};
</script>
