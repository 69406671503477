// import { membership2Data } from "@/assets/mockData/membership.js";

export const membership2 = {
  getMembership2Data(state) {
    state.membership2Data = state.membership2DataBase;
  },
  //New
  setMembership2Data(state, ary) {
    state.membership2DataBase = ary;
  },
  pushMembership2Data(state, ary) {
    let data = state.membership2DataBase.concat(ary);
    console.log("結合", data);
    state.membership2DataBase = data;
  },
  filterMembership2Data(state, status) {
    state.membership2Data = state.membership2DataBase.filter(
      (applier) => applier.status === status
    );
  },
  searchMembership2Data(state, account) {
    state.membership2Data = state.membership2DataBase.filter((applier) =>
      applier.account.includes(account)
    );
  },
  addMembership2Data(state, formData) {
    state.membership2DataBase.push(formData);
    state.membership2Data = state.membership2DataBase;
  },
  blockMembership2(state, { id }) {
    state.targetMembership2ID = id;
    const targetIndex = state.membership2DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership2DataBase[targetIndex]["status"] = "block";
    state.membership2Data = state.membership2DataBase;
  },
  unblockMembership2(state, { id, onChain }) {
    console.log(onChain);
    state.targetMembership2ID = id;
    const targetIndex = state.membership2DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership2DataBase[targetIndex]["status"] = onChain
      ? "normal"
      : "unchain";
    state.membership2Data = state.membership2DataBase;
  },
  chainMembership2(state, { id }) {
    state.targetMembership2ID = id;
    const targetIndex = state.membership2DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership2DataBase[targetIndex]["status"] = "normal";
    state.membership2DataBase[targetIndex]["onChain"] = true;
    state.membership2Data = state.membership2DataBase;
  },
  unchainMembership2(state, { id }) {
    state.targetMembership2ID = id;
    const targetIndex = state.membership2DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership2DataBase[targetIndex]["status"] = "unchain";
    state.membership2DataBase[targetIndex]["onChain"] = false;
    state.membership2Data = state.membership2DataBase;
  },
  editMembership2Data(state, { id, newData }) {
    state.targetMembership2ID = id;
    const targetIndex = state.membership2DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership2DataBase[targetIndex] = newData;
    state.membership2Data = state.membership2DataBase;
  },
};
