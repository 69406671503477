<template>
  <div class="flex flex-col space-y-4 w-full">
    <label
      v-for="option in optionList"
      :key="option.value"
      @click="selectedItems = option"
      class="flex items-center text-lg font-bold text-gray-600"
    >
      <div
        :class="[
          'w-[22px] h-[22px] border-2 border-[#7D7B7B] mr-2',
          'relative',
        ]"
      >
        <Icon
          v-if="
            selectedItems.some(
              (checkedObj) => checkedObj.value === option.value
            )
          "
          dense
          class="w-[27px] h-[23px] absolute bottom-0 -left-0.5"
          :img="require('@/assets/img/icon-check.svg')"
        />
      </div>
      <span>{{ option.label }}</span>
    </label>
    <Input
      placeholder="勾選其他 請輸入審核不通過原因"
      outlined
      v-model="otherReasonInput"
      :readonly="!hasOtherReason"
    />
  </div>
</template>

<script>
import { Icon, Input } from "@/components/Shared";
export default {
  name: "CheckboxGroup",
  components: {
    Icon,
    Input,
  },
  props: {
    optionList: {
      type: Array,
      default: () => [{ label: "選項1", value: "value1" }],
    },
    value: {
      type: Array,
      default: () => [],
    },
    otherReason: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(newSelectedItem) {
        let newSelection = this.value;
        const alreadyInSelection = (selectedItem) =>
          selectedItem.value === newSelectedItem.value;
        if (newSelection.some(alreadyInSelection)) {
          newSelection = newSelection.filter(
            (selectedItem) => selectedItem.value !== newSelectedItem.value
          );
        } else {
          newSelection = [...newSelection, newSelectedItem];
        }
        this.$emit("input", newSelection);
      },
    },
    hasOtherReason() {
      return this.selectedItems.some(
        (checkedObj) => checkedObj.value === "others"
      );
    },
    otherReasonInput: {
      get() {
        return this.otherReason;
      },
      set(inputValue) {
        this.$emit("getOtherReason", this.hasOtherReason ? inputValue : "");
      },
    },
  },
  watch: {
    hasOtherReason(newValue) {
      if (!newValue) this.$emit("getOtherReason", "");
    },
  },
};
</script>
