<template>
  <popcorn
    :name="'album' + tid"
    :width="screenWidth > 720 ? '40%' : '80%'"
    :height="'90%'"
    :scrollable="true"
    :adaptive="true"
    :clickToClose="true"
    @closed="$emit('closed')"
  >
    <div class="w-[100%] p-3">
      <div class="flex overflow-x-auto mt-3">
        <div
          v-for="(item, key) in album"
          :key="key"
          class="h-[140px] min-w-[150px] mr-3 overflow-hidden relative"
        >
          <template v-if="isImg(item)">
            <img
              class="w-[100%]"
              style="position: absolute; top: 0; bottom: 0; margin: auto 0"
              :src="$appsetting.AppUrl + '\\' + item"
              :alt="key"
              @click="$emit('change', key)"
            />
            <div
              class="w-[100%] bg-[#122738] text-white"
              style="position: absolute; bottom: 0; opacity: 0.5"
            >
              {{ key }}
            </div>
          </template>
        </div>
      </div>
      <div class="overflow-y-auto h-[70vh]">
        <img
          v-if="show == ''"
          class="w-[100%]"
          src="@/assets/img/icon-user.svg"
          alt="male icon"
        />
        <img
          v-else
          class="w-[100%]"
          :src="$appsetting.AppUrl + '\\' + album[show]"
          :alt="show"
        />
      </div>
    </div>
  </popcorn>
</template>

<script>
export default {
  name: "Album",
  components: {},
  props: {
    tid: {
      type: String,
      default: "0",
    },
    album: {
      type: Object,
      required: true,
    },
    show: {
      type: String,
      default: "",
      require: true,
    },
  },
  data() {
    return {
      defaultStyle: !this.color && !this.outline && !this.icon,
      contentshow: "",
    };
  },
  computed: {
    screenWidth: function () {
      return window.innerWidth;
    },
  },
  methods: {
    isImg(str) {
      if (str.toLowerCase().indexOf(".jpg") != -1) {
        return true;
      }

      if (str.toLowerCase().indexOf(".png") != -1) {
        return true;
      }
      if (str.toLowerCase().indexOf(".jpeg") != -1) {
        return true;
      }

      if (str.toLowerCase().indexOf(".gif") != -1) {
        return true;
      }
      return false;
    },
  },
  watch: {
    show: function () {
      if (this.show != "") this.$modal.show(`album${this.tid}`);
    },
  },
  mounted() {
    console.log("觸發", this.$modal);
  },
};
</script>
