// import { membership3Data } from "@/assets/mockData/membership.js";

export const membership3 = {
  getMembership3Data(state) {
    state.membership3Data = state.membership3DataBase;
  },
  //New
  setMembership3Data(state, ary) {
    state.membership3DataBase = ary;
  },
  pushMembership3Data(state, ary) {
    let data = state.membership3DataBase.concat(ary);
    console.log("結合", data);
    state.membership3DataBase = data;
  },

  filterMembership3Data(state, status) {
    state.membership3Data = state.membership3DataBase.filter(
      (applier) => applier.status === status
    );
  },
  searchMembership3Data(state, account) {
    state.membership3Data = state.membership3DataBase.filter((applier) =>
      applier.account.includes(account)
    );
  },
  addMembership3Data(state, memberAccount) {
    state.membership3DataBase.push(memberAccount);
    state.membership3Data = state.membership3DataBase;
  },
  blockMembership3(state, { id }) {
    state.targetMembership3ID = id;
    const targetIndex = state.membership3DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership3DataBase[targetIndex]["status"] = "block";
    state.membership3Data = state.membership3DataBase;
  },
  unblockMembership3(state, { id, onChain }) {
    state.targetMembership3ID = id;
    const targetIndex = state.membership3DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership3DataBase[targetIndex]["status"] = onChain
      ? "normal"
      : "unchain";
    state.membership3Data = state.membership3DataBase;
  },
  chainMembership3(state, { id }) {
    state.targetMembership3ID = id;
    const targetIndex = state.membership3DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership3DataBase[targetIndex]["status"] = "normal";
    state.membership3DataBase[targetIndex]["onChain"] = true;
    state.membership3Data = state.membership3DataBase;
  },
  unchainMembership3(state, { id }) {
    state.targetMembership3ID = id;
    const targetIndex = state.membership3DataBase.findIndex(
      (member) => member.id === id
    );
    state.membership3DataBase[targetIndex]["status"] = "unchain";
    state.membership3DataBase[targetIndex]["onChain"] = false;
    state.membership3Data = state.membership3DataBase;
  },
};
