import store from "@/store"; // 直接取store
import { tools } from "@/assets/tools.js";
import { deepParseJson } from "deep-parse-json";
export const verify = {
  查詢會員修改審核列表TABLE: [
    {
      name: "status",
      label: "",
      align: "center",
    },
    {
      name: "f姓名",
      label: "申請人",
      align: "center",
    },
    {
      name: "修改訴求",
      label: "修改訴求",
      align: "center",
    },
    { name: "edit", label: "編輯", align: "center" },
  ],
  查詢審核列表: ({
    fFirstKey = "t會員",
    fSecondKey = "fId",
    searchKey = ["f簡介"],
    searchVal = ["資格L1 -> L2 資格申請"],
  } = {}) => {
    store
      .dispatch(
        "apiVerify/A02_顯示審核列表Join",
        tools.ObjToForm({ fFirstKey, fSecondKey, searchKey, searchVal })
      )
      .then((res) => {
        console.log('aaaa', res);
        let data = deepParseJson(res.data);
        data.map((item) => {
          if (item.審核狀態 == 0) {
            item["status"] = "unapproved";
          } else if (item.審核狀態 == 1) {
            item["status"] = "normal";
          } else {
            item["status"] = "block";
          }
          return item;
        });
        store.commit("apiVerify/SET_審核列表", data); //deepparseJson
      });
  },
  查詢會員修改審核列表: ({
    fFirstKey = "t會員",
    fSecondKey = "fId",
    searchKey = ["f簡介", "a.f狀態"],
    searchVal = ["會員修改資料", "0"],
  } = {}) => {
    store
      .dispatch(
        "apiVerify/A02_顯示審核列表Join",
        tools.ObjToForm({ fFirstKey, fSecondKey, searchKey, searchVal })
      )
      .then((res) => {
        let data = deepParseJson(res.data);
        data.map((item) => {
          if (item.f資格 == "L1") {
            item["status"] = "unapproved";
          } else if (item.f資格 == "L2" && item.f身分 == "個人戶") {
            item["status"] = "normal";
          } else if (item.f資格 == "L2" && item.f身分 == "公司戶") {
            item["status"] = "block";
          }
          item["修改訴求"] = Object.keys(item.f描述)[0];
          return item;
        });
        store.commit("apiVerify/SET_會員修改紀錄", data); //deepparseJson
      });
  },
  test_查詢會員修改審核列表: (狀態) => {
    store
      .dispatch("apiVerify/A05_顯示審核列表Join會員", { 狀態 })
      .then((res) => {
        let data = deepParseJson(res.data);
        data.map((item) => {
          if (item.f資格 == "L1") {
            item["status"] = "unapproved";
          } else if (item.f資格 == "L2" && item.f身分 == "個人戶") {
            item["status"] = "normal";
          } else if (item.f資格 == "L2" && item.f身分 == "公司戶") {
            item["status"] = "block";
          }
          item["修改訴求"] = Object.keys(item.f描述)[0];
          return item;
        });
        store.commit("apiVerify/SET_會員修改紀錄", data); //deepparseJson
      });
  },
  通過會員修改審核: (fId) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("apiVerify/A03_審核通過", tools.ObjToForm({ fId }))
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  駁回會員修改審核: (fId) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("apiVerify/A04_審核駁回", tools.ObjToForm({ fId }))
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  A06_修改_t會員_狀態1: (data) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("Member/MemberState/Admin", tools.ObjToForm(data))
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
