var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:[{ 'bg-[#EFEBEB]': _vm.type === 'confirm' }]},[(!_vm.iconhead)?_c('section',{staticClass:"card_header",class:[
      _vm.type === 'confirm' ? null : _vm.bgColor[_vm.color],

      'flex justify-center items-center',
      { 'h-[74px] text-2xl text-white px-[27px]': _vm.type === 'dialog' },
      {
        'text-[22px] text-[#6F6F6F] px-[27px] pt-[18px] pb-3':
          _vm.type === 'confirm',
      },
      {
        'pt-[12px] pl-4 pr-[22px] pb-[8px] flex-col space-y-[6px]':
          _vm.type === 'form',
      },
    ]},[_vm._t("header",function(){return [_c('p',[_vm._v(_vm._s(_vm.title))])]}),_vm._t("close")],2):_c('section',{staticClass:"card_header"},[_vm._t("header",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_c('section',{staticClass:"card_body",class:[
      {
        'w-full px-[22px] pt-5 pb-[18px] text-center text-2xl':
          _vm.type === 'dialog',
      },
    ]},[_vm._t("body")],2),_c('section',{staticClass:"card_footer",class:[
      {
        'rounded-b-popup px-[22px] pb-[22px]': _vm.type === 'dialog',
      },
      {
        'rounded-b-popup text-lg text-[#6E6E6E]': _vm.type === 'confirm',
      },
    ]},[_vm._t("footer")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }